import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_THIRD_PARTY_API_URL || 'http://localhost:50100/api';

const thirdPartyApiClient = axios.create({
  baseURL: API_BASE_URL,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const thirdPartyService = {
  // Verificar empresa en MySQL y MongoDB
  checkCompany: async (uid_empresa_externo) => {
    try {
      const response = await thirdPartyApiClient.get(`/company/check/${uid_empresa_externo}`);
      return response.data;
    } catch (error) {
      console.error('Error al verificar la empresa:', error);
      return { 
        success: false, 
        message: error.response?.data?.message || 'Error al verificar la empresa'
      };
    }
  },

  // Sincronizar empresa entre MySQL y MongoDB
  syncCompany: async (uid_empresa_externo, forceUpdate = false) => {
    try {
      const response = await thirdPartyApiClient.post('/company/sync', {
        uid: uid_empresa_externo,
        forceUpdate
      });
      return response.data;
    } catch (error) {
      console.error('Error al sincronizar la empresa:', error);
      return { 
        success: false, 
        message: error.response?.data?.message || 'Error al sincronizar la empresa'
      };
    }
  },

  // Verificar usuario en MySQL
  verifyUser: async (username, password) => {
    try {
      const response = await thirdPartyApiClient.post('/company/verify-mysql-user', {
        username,
        password
      });
      return response.data;
    } catch (error) {
      console.error('Error al verificar el usuario:', error);
      return { 
        success: false, 
        message: error.response?.data?.message || 'Error al verificar el usuario'
      };
    }
  },

  // Sincronizar usuario entre MySQL y MongoDB
  syncUser: async (username, password) => {
    try {
      const response = await thirdPartyApiClient.post('/company/sync-user', {
        username,
        password
      });
      return response.data;
    } catch (error) {
      console.error('Error al sincronizar el usuario:', error);
      return { 
        success: false, 
        message: error.response?.data?.message || 'Error al sincronizar el usuario'
      };
    }
  }
};

export default thirdPartyService;