import React, { createContext, useState, useContext, useEffect, useRef } from 'react';

const SoundContext = createContext();

export const SoundProvider = ({ children }) => {
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(0.7); // Default volume
  const [activeAudios, setActiveAudios] = useState([]);
  
  // Use refs to maintain current values for use in closures
  const isMutedRef = useRef(isMuted);
  const volumeRef = useRef(volume);
  
  // Keep refs in sync with state
  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);
  
  useEffect(() => {
    volumeRef.current = volume;
  }, [volume]);

  // Play a sound with the given path
  const playSound = (soundPath) => {
    if (isMutedRef.current || !soundPath) return null;
    
    // Create new audio instance
    try {
      // If the sound path doesn't start with http or https, assume it's a local file
      const fullPath = soundPath.startsWith('http') 
        ? soundPath 
        : `${window.location.origin}${soundPath}`;
        
      const audio = new Audio(fullPath);
      audio.volume = volumeRef.current;
      audio.muted = isMutedRef.current;
      
      // Store reference to manage it later
      setActiveAudios(prev => [...prev, audio]);
      
      // Play sound and remove from active audios when done
      audio.play().catch(err => console.error('Error playing sound:', err));
      
      audio.onended = () => {
        setActiveAudios(prev => prev.filter(a => a !== audio));
      };
      
      return audio;
    } catch (error) {
      console.error('Error initializing audio:', error);
      return null;
    }
  };

  // Stop all sounds
  const stopAllSounds = () => {
    activeAudios.forEach(audio => {
      audio.pause();
      audio.currentTime = 0;
    });
    setActiveAudios([]);
  };

  // Toggle mute state
  const toggleMute = () => {
    setIsMuted(prevMuted => {
      const newMuted = !prevMuted;
      // Actualizar inmediatamente los audios activos
      activeAudios.forEach(audio => {
        audio.muted = newMuted;
      });
      return newMuted;
    });
  };

  // Adjust volume (0-1)
  const changeVolume = (newVolume) => {
    const clampedVolume = Math.max(0, Math.min(1, newVolume));
    setVolume(clampedVolume);
    
    // Update volume of all active audio elements immediately
    activeAudios.forEach(audio => {
      audio.volume = clampedVolume;
    });
  };

  // Effect to handle muting all active audios is redundant with our immediate updates,
  // but keeping it for safety
  useEffect(() => {
    activeAudios.forEach(audio => {
      audio.muted = isMuted;
    });
  }, [isMuted, activeAudios]);

  // Effect to handle volume changes for all active audios
  useEffect(() => {
    activeAudios.forEach(audio => {
      audio.volume = volume;
    });
  }, [volume, activeAudios]);

  return (
    <SoundContext.Provider 
      value={{ 
        playSound, 
        stopAllSounds, 
        toggleMute, 
        changeVolume, 
        isMuted, 
        volume,
        activeAudios: activeAudios.length
      }}
    >
      {children}
    </SoundContext.Provider>
  );
};

export const useSound = () => useContext(SoundContext);