// CenterMapOnGeocerca.js
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

const CenterMapOnGeocerca = ({ geocercas, selectedGeocercaId }) => {
  const map = useMap();

  useEffect(() => {
    if (selectedGeocercaId) {
      const selectedGeocerca = geocercas.find(g => g.id === selectedGeocercaId);
      if (selectedGeocerca) {
        let center;
        if (selectedGeocerca.puntos.type === 'Point') {
          center = [
            selectedGeocerca.puntos.puntos[0].latitud,
            selectedGeocerca.puntos.puntos[0].longitud,
          ];
        } else {
          // Para polígonos, calcular el centro
          const points = selectedGeocerca.puntos.puntos;
          const lat = points.reduce((sum, p) => sum + Number(p.latitud), 0) / points.length;
          const lng = points.reduce((sum, p) => sum + Number(p.longitud), 0) / points.length;
          center = [lat, lng];
        }
        map.setView(center, 15); // Ajusta el zoom al nivel deseado
      }
    }
  }, [selectedGeocercaId, geocercas, map]);

  return null;
};

export default CenterMapOnGeocerca;
