// MapComponent.js

import React , { useEffect, useState,useCallback } from 'react';
import GoogleMapComponent from './GoogleMapComponent';
import LeafletMapComponent from './LeafletMapComponent';
import GoogleMapComponent_v1 from './GoogleMapComponent_v1';

const MapComponent = ({
  center,
  zoom,
  userLocation,
  geocercas,
  onCreated,
  onEdited,
  onDeleted,
  onMoved,
  drawnItemsRef, 
  renderGeocercas,
  useGoogleMaps,
  googleMapRef,
  leafletMapRef,
  handleTypeChange,
  currentOverlay,
  setCurrentOverlay,
  drawingManager,
  setDrawingManager,
  setOverlayMap,
  isFirstLoad,
  setIsFirstLoad,
  onLoad,
  selectedGeocercaId, // Añadimos esta prop
}) => {

  const [isMapLoaded, setIsMapLoaded] = useState(false);

  console.log('🚀 MapComponent montado');

  // const handleLoad = useCallback(() => {
  //   setIsMapLoaded(true);
  //   onLoad?.();
  // }, [onLoad]);

  const handleLoad = useCallback(() => {
    setIsMapLoaded(true);
    // Otras acciones...
  }, []);

  if (useGoogleMaps) {
    console.log('Usando Google Maps');
    console.log('googleMapRef:', googleMapRef);
    return (
      <GoogleMapComponent_v1
        center={userLocation}
        zoom={zoom}
        userLocation={userLocation}
        geocercas={geocercas}
        onCreated={onCreated}
        onEdited={onEdited}
        onDeleted={onDeleted}
        onMoved={onMoved}
        drawnItemsRef={drawnItemsRef}
        mapRef={googleMapRef}
        renderGeocercas={renderGeocercas}
        handleTypeChange={handleTypeChange}
        currentOverlay={currentOverlay}
        setCurrentOverlay={setCurrentOverlay}
        drawingManager={drawingManager}
        setDrawingManager={setDrawingManager}
        setOverlayMap={setOverlayMap}
        isFirstLoad={isFirstLoad}
        setIsFirstLoad={setIsFirstLoad}
        onLoad={handleLoad}

      />
    );
  }

  console.log('Usando Leaflet Maps');
  console.log('leafletMapRef:', leafletMapRef);
  return (  
    <LeafletMapComponent
      center={userLocation}
      zoom={zoom}
      userLocation={userLocation}
      geocercas={geocercas}
      onCreated={onCreated}
      onEdited={onEdited}
      onDeleted={onDeleted}
      onMoved={onMoved}
      drawnItemsRef={drawnItemsRef}      
      mapRef={leafletMapRef}
      renderGeocercas={renderGeocercas}
      isFirstLoad={isFirstLoad}
        setIsFirstLoad={setIsFirstLoad}
        onLoad={handleLoad}
        selectedGeocercaId={selectedGeocercaId} // Pasamos la geocerca seleccionada
    />
  );
};

export default MapComponent;