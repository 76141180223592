import React, { useContext,useState, useEffect, useRef } from "react";

import { AuthContext } from "../../../contexts/AuthContext";
import { groupService } from "../../../services/apiService";


import {
  TextField,
  Button,
  Box,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Tooltip,
  Dialog, // Para los modales
  DialogTitle, // Título del modal
  DialogContent, // Contenido principal del modal
  DialogContentText, // Texto dentro del modal
  DialogActions, // Botones de acción del modal
  InputAdornment,
  Paper,
} from "@mui/material";
import {
  eventoService,
  RiesgoService,
  ClasificacionEventoService,
} from "../../../services/apiService";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ErrorIcon from "@mui/icons-material/Error";
import ListaAudios from "./AlertaSonidoForm";
const EVENTOS_TIEMPO = [
  "6091ba29662e2b2e74500a40",
  "6091ba1d662e2b2e74500a3d",
  "6091ba3e662e2b2e74500a43",
  "6189b3ea2a2e016f281d2a05",
  "6075ed43662e2b1ef042aabe",
  "67cdffc5e6c16775470cf6de",
  
];
const EVENTOS_TIPO_MARCADO = [
  "6091ba3e662e2b2e74500a43",
  "6189b3ea2a2e016f281d2a05",
  "6091ba52662e2b2e74500a46",
];
const EVENTOS_VECES_VELOCIDAD = ["6091ba52662e2b2e74500a46"];
const EVENTOS_DISTANCIA = ["6189b3ea2a2e016f281d2a05"];
const EVENTO_BOTON_PANICO = ["6761c644c3e4e161ae6ea34e","67cdff4ce6c16775470cf6dd"]

const NuevaAlertaForm = ({
  idAlerta,
  configuracion,
  setConfiguracion,
  handleGuardarPrincipal,
  resetForm,
  actualizarComponente,
  viewNew, // condicion para mostrar el boton nuevo o no
  nextComponent,
  viewEdit,
  errorAlertaNombre,
  errorVehiculoDistancia,
  errorExcesoVeces,
  errorExcesoVelocidad,
  actualizarErrores
}) => {
  const [eventos, setEventos] = useState([]);
  const [riesgos, setRiesgos] = useState([]);

  const [errors, setErrrors] = useState({});
  const inputRef = useRef(null);
  const selectRef = useRef(null);

  const [errorNombre, setErrorNombre] = useState(""); // Estado para manejar errores en "Nombre"
  const [errorTiempo, setErrorTiempo] = useState(""); // Estado para manejar errores en "Tiempo"
  const [errorVeces, setErrorVeces] = useState(""); // Estado para manejar errores en "Veces"
  const [errorVelocidad, setErrorVelocidad] = useState(""); //Estado para manejar errores en "velocidad_movimiento"
  const [errorDistancia, setErrorDistancia] = useState(""); // Estado para manejar errores en "distancia_vehiculos"

  // Estado para el modal de cancelar
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

  // Abrir y cerrar modal de cancelar
  const handleOpenCancelDialog = () => setCancelDialogOpen(true);
  const handleCloseCancelDialog = () => setCancelDialogOpen(false);
  // Estado para controlar la apertura del modal de guardar
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const handleOpenSaveDialog = () => setSaveDialogOpen(true);
  const handleCloseSaveDialog = () => setSaveDialogOpen(false);
  const [clasificaciones, setClasificaciones] = useState([]);
  const [asignaciones, setAsignaciones] = useState({});
  const [clasificacionSeleccionada, setClasificacionSeleccionada] =
    useState(null); // Clasificación seleccionada
  const [eventosFiltrados, setEventosFiltrados] = useState([]); // Eventos filtrados según la clasificación

  const { groups, setGroups } = useContext(AuthContext);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [loadingGroups, setLoadingGroups] = useState(false);

  useEffect(() => {
    console.log("Groups in NuevaAlertaForm:", groups);
  }, [groups]);

  useEffect(() => {
    const fetchGroups = async () => {
      if ((!groups || groups.length === 0) && !loadingGroups) {
        setLoadingGroups(true);
        try {
          console.log("Fetching groups with groupService");
          const fetchedGroups = await groupService.getGroups();
          console.log("Fetched groups:", fetchedGroups);
          
          if (fetchedGroups && fetchedGroups.length > 0) {
            setGroups(fetchedGroups);
          }
        } catch (error) {
          console.error("Error fetching groups:", error);
        } finally {
          setLoadingGroups(false);
        }
      }
    };
    
    fetchGroups();
  }, [groups, setGroups]);

  const loadEventos = async () => {
    try {
      const response = await eventoService.getEventos();
      if (response && response.statusCode === 200) {
        setEventos(response.data);
      }
    } catch (error) {
      console.error("Error cargando eventos:", error);
    }
  };

  const loadRiesgos = async () => {
    try {
      const response = await RiesgoService.getRiesgos();
      if (response && response.statusCode === 200) {
        setRiesgos(response.data);
      }
    } catch (error) {
      console.error("Error cargando riesgos:", error);
    }
  };

  const loadClasificaciones = async () => {
    // Trae todos las clasifiaciones activas, si le pasas uid_clasificacion trae la asignacion de eventos de una clasificacion
    try {
      const response =
        await ClasificacionEventoService.getClasificacionesActivas();
      if (response && response.statusCode === 200) {
        setClasificaciones(response.data);
      }
    } catch (error) {
      console.error("Error cargando eventos:", error);
    }
  };

  const loadAsignaciones = async () => {
    //Se obtienen todas las asignaciones
    try {
      const response = await ClasificacionEventoService.FiltrarAsignaciones();
      if (response && response.statusCode === 200) {
        setAsignaciones(response.data);
      }
    } catch (error) {
      console.error("Error cargando asignaciones:", error);
    }
  };

  const handleChange = (e) => {
    const newErrors = {};
    const { name, value } = e.target;

    // if (name === "nombre") {
    //   if (value.length > 50) return;
    //   if (value.length === 0) {
    //     inputRef.current.focus();
    //     newErrors.nombre = "El nombre es obligatorio";
    //   }
    // }
    if (name === "nombre") {
      if (value.length > 50) return;
      // Si hay un valor en el campo nombre, notificar al padre que no hay error
      if (value.length > 0 && errorAlertaNombre) {
        // Esta línea es la que notifica al padre que el error ya no existe
        actualizarErrores('errorNombre', false);
      }
      if (value.length === 0) {
        inputRef.current.focus();
        newErrors.nombre = "El nombre es obligatorio";
      }
    }

    if (name === "tipo" && value.length === 0) {
      selectRef.current.focus();
      newErrors.tipo_alerta = "Tipo es obligatorio";
      return;
    }
    if (name === "tiempo" && value.length > 3) return;
    if (name === "veces" && value.length > 3) return;
    if (name === "velocidad_movimiento" && value.length > 3) return;
    //if (name === "distancia_vehiculos" && value.length > 3) return;
      // Validación específica para la distancia mínima en el evento de acercamiento
  if (name === "distancia_vehiculos") {
    if (value.length > 3) return;
    
    // Comprobar si el evento actual es el de acercamiento
    if (configuracion.tipo_alerta._id === "6189b3ea2a2e016f281d2a05") {
      const numericValue = parseInt(value, 10);
      
      // Validar que el valor sea mayor a 10
      if (isNaN(numericValue) || numericValue <= 10) {
        actualizarErrores('errorVehiculoDistancia', true);
        newErrors.distancia_vehiculos = "La distancia debe ser mayor a 10 metros";
      } else {
        actualizarErrores('errorVehiculoDistancia', false);
      }
    }
  }

    setErrrors(newErrors);
    // Validación específica para el campo "Nombre"
    // if (name === "nombre") {
    //   if (value.length > 50) {
    //     setErrorNombre("El nombre no puede tener más de 50 caracteres.");
    //     return;
    //   } else {
    //     setErrorNombre(""); // Resetea el error si la longitud es válida
    //   }
    // }

    // if (name === "tiempo") {
    //   const numericValue = parseInt(value, 10);
    //   if (value.length > 3) return;
    //   if (isNaN(numericValue) || numericValue < 0 || numericValue > 999) {
    //     setErrorTiempo("El tiempo tiene que tener un valor entre 0 y 999");
    //   } else {
    //     setErrorTiempo("");
    //   }
    // }

    // if (name === "veces") {
    //   const numericValue = parseInt(value, 10);
    //   if (value.length > 3) return;

    //   if (isNaN(numericValue) || numericValue < 0 || numericValue > 999) {
    //     setErrorVeces(
    //       "La cantidad de veces tiene que tener un valor entre 0 y 999"
    //     );
    //   } else {
    //     setErrorVeces("");
    //   }
    // }

    // if (name === "velocidad_movimiento") {
    //   const numericValue = parseInt(value, 10);
    //   if (value.length > 3) return;

    //   if (isNaN(numericValue) || numericValue < 0 || numericValue > 999) {
    //     setErrorVelocidad(
    //       "La velocidad tiene que tener un valor entre 0 y 999"
    //     );
    //   } else {
    //     setErrorVelocidad("");
    //   }
    // }

    // if (name === "distancia_vehiculos") {
    //   if (value.length > 3) return;

    //   const numericValue = parseInt(value, 10);
    //   if (isNaN(numericValue) || numericValue < 0 || numericValue > 999) {
    //     setErrorDistancia(
    //       "La distancia tiene que tener un valor entre 0 y 999"
    //     );
    //   } else {
    //     setErrorDistancia("");
    //   }
    // }

    setConfiguracion((prev) => ({
      ...prev,
      [name]: value,
    }));
    // if (!validate()) return;
  };

  // Manejar selección de tipo de alerta
  const handleSelectTipoAlerta = (eventoId) => {
    const eventoSeleccionado = eventos.find((evento) => evento.id === eventoId);

    let tipoMarcado = 0; // Valor por defecto para tipo_marcado

    // Asignar tipo_marcado basado en el tipo elegido
    if (eventoId === "6091ba1d662e2b2e74500a3d") {
      //Entrada
      tipoMarcado = 1; // Dentro
    } else if (eventoId === "6091ba29662e2b2e74500a40") {
      //Salida
      tipoMarcado = 2; // Fuera
    }

    setConfiguracion((prev) => ({
      ...prev,
      tipo_alerta: {
        _id: eventoSeleccionado.id,
        nombre: eventoSeleccionado.nombre,
        evento: eventoSeleccionado.eventos || [],
      },
      tipo_marcado: tipoMarcado, // Establecer tipo_marcado automáticamente
    }));
  };

  useEffect(() => {
    loadClasificaciones();
    loadEventos();
    loadRiesgos();
    loadAsignaciones();
  }, []);

  // useEffect(() => {
  //   const cargarAsignacionesEdicion = async () => {
  //     if (configuracion.tipo_alerta._id) { // Si hay un tipo de alerta configurado
  //       console.log("evento guardado en base dd",configuracion.tipo_alerta._id);
  //       try {
  //         // Cargar todas las asignaciones
  //         const response = await ClasificacionEventoService.FiltrarAsignaciones({});
  //         if (response && response.statusCode === 200) {
  //           // Buscar la asignación que contiene el tipo de alerta actual
  //           const asignacion = response.data.find((a) =>
  //             a.eventos.includes(configuracion.tipo_alerta._id)
  //           );
  //           if (asignacion) {

  //             // Buscar la clasificación asociada a la asignación encontrada
  //             const clasificacion = clasificaciones.find(
  //               (c) => c.id === asignacion.uid_clasificacion
  //             );
  //             console.log("clasificacion",clasificacion);
  //             console.log("eventos",asignacion.eventos);

  //             // Actualizar clasificación seleccionada y eventos filtrados
  //             setClasificacionSeleccionada(clasificacion.id || null); // Seleccionar clasificación asociada
  //             setEventosFiltrados(asignacion.eventos || []); // Filtrar los eventos correspondientes
  //           } else {
  //             console.warn("No se encontró una asignación para el tipo de alerta actual.");
  //             setClasificacionSeleccionada(null); // No hay clasificación asociada
  //             setEventosFiltrados([]); // Limpiar eventos filtrados
  //           }
  //         }
  //       } catch (error) {
  //         console.error("Error al cargar asignaciones en edición:", error);
  //         setClasificacionSeleccionada(null); // Asegurar que se limpie en caso de error
  //         setEventosFiltrados([]); // Limpiar eventos filtrados
  //       }
  //     }
  //   };

  //   cargarAsignacionesEdicion();
  // }, [configuracion.tipo_alerta._id, clasificaciones]); // Dependencias

  // Handler for group change
  const handleGroupChange = (event) => {
    const groupId = event.target.value;
    setSelectedGroup(groupId);
    actualizarComponente("grupoId", groupId);
  };
  
  // Add effect to set initial selected group
  useEffect(() => {
    if (configuracion.grupoId) {
      setSelectedGroup(configuracion.grupoId);
    }
  }, [configuracion.grupoId]);


  const isFormEmpty =
    errorNombre ||
    errorTiempo ||
    errorVeces ||
    errorVelocidad ||
    errorDistancia;
  return (
    <Paper
      sx={{
        p: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <Box width={"100%"}>
          <Grid container spacing={2} p={1}>
            {/*Tipos*/}
            <Grid item xs={12} md={3}>
              <FormControl variant="outlined" size="small" fullWidth>
                <TextField
                  select
                  inputRef={selectRef}
                  label="Tipo"
                  variant="outlined"
                  name="tipo"
                  fullWidth
                  size="small"
                  error={!!errors.tipo_alerta}
                  value={
                    configuracion.tipo_alerta._id || "6091ba1d662e2b2e74500a3d"
                  }
                  onChange={(e) => handleSelectTipoAlerta(e.target.value)} // Construye tipo_alerta
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <Tooltip
                            title="Selecciona un evento para la alerta"
                            placement="top"
                          >
                            <ErrorIcon fontSize="small" />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    },
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: (selected) => {
                      if (!selected) {
                        return "Seleccionar un tipo";
                      }
                      return eventos.find((evento) => evento.id === selected)
                        ?.nombre;
                    },
                  }}
                >
                  {eventos
                    // .filter((evento) => {
                    //   // Si hay una clasificación seleccionada, solo mostramos los eventos asignados a ella
                    //   if (
                    //     clasificacionSeleccionada &&
                    //     eventosFiltrados.length > 0
                    //   ) {
                    //     return eventosFiltrados.includes(evento.id);
                    //   }
                    //   // En modo edición, incluimos el evento guardado
                    //   if (configuracion.tipo_alerta._id) {
                    //     return evento.id === configuracion.tipo_alerta._id;
                    //   }
                    //   return false; // Si no hay filtro, no mostramos Nada
                    // })
                    .map((evento) => (
                      <MenuItem key={evento.id} value={evento.id}>
                        {evento.nombre}
                      </MenuItem>
                    ))}
                </TextField>
              </FormControl>
            </Grid>
            {configuracion.tipo_alerta._id && (
              <>
              {/* //&& configuracion.tipo_alerta._id !== "67cdff4ce6c16775470cf6dd" */}
                {EVENTOS_TIPO_MARCADO.includes(
                  configuracion.tipo_alerta._id
                )  && (
                  <Grid item xs={12} md={3}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <InputLabel>Tipo Marcado</InputLabel>
                      <Select
                        label="Tipo Marcado"
                        name="tipo_marcado" // Clave en el estado
                        value={configuracion.tipo_marcado}
                        onChange={handleChange}
                        startAdornment={
                          <InputAdornment position="start">
                            <Tooltip
                              title="Selecciona un tipo de marcado para la alerta"
                              placement="top"
                            >
                              <ErrorIcon fontSize="small" />
                            </Tooltip>
                          </InputAdornment>
                        }
                      >
                         {configuracion.tipo_alerta._id !== "6189b3ea2a2e016f281d2a05" && (
                            <MenuItem value={0}>General</MenuItem>
                          )}
                        <MenuItem value={1}>Dentro de la geocerca</MenuItem>
                        <MenuItem value={2}>Fuera de la geocerca</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {EVENTOS_TIEMPO.includes(configuracion.tipo_alerta._id) && (
                  <Grid item xs={12} md={3}>
                    <TextField
                      label="Tiempo (Min.)"
                      name="tiempo"
                      variant="outlined"
                      size="small"
                      fullWidth
                      error={!!errorTiempo} // Muestra un error si hay texto en errorNombre
                      value={configuracion.tiempo}
                      onChange={handleChange}
                      slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">
                              <Tooltip
                                title="Tiempo que debe transcurrir para que se genere la alerta."
                                placement="top"
                              >
                                <ErrorIcon fontSize="small" />
                              </Tooltip>
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                  </Grid>
                )}

                {EVENTOS_VECES_VELOCIDAD.includes(
                  configuracion.tipo_alerta._id
                ) && (
                  <>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Veces"
                        name="veces"
                        variant="outlined"
                        size="small"
                        fullWidth
                        error={errorExcesoVeces} // Muestra un error si hay texto en errorNombre
                        value={configuracion.veces}
                        onChange={handleChange}
                        slotProps={{
                          input: {
                            startAdornment: (
                              <InputAdornment position="start">
                                <Tooltip
                                  title="Cantidad de veces que tiene que pasar para que se genere la alerta."
                                  placement="top"
                                >
                                  <ErrorIcon fontSize="small" />
                                </Tooltip>
                              </InputAdornment>
                            ),
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Velocidad (Km/h)"
                        name="velocidad_movimiento"
                        value={configuracion.velocidad_movimiento}
                        onChange={handleChange}
                        size="small"
                        fullWidth
                        error={errorExcesoVelocidad} // Muestra un error si hay texto en errorNombre
                        slotProps={{
                          input: {
                            startAdornment: (
                              <InputAdornment position="start">
                                <Tooltip
                                  title="Velocidad que no debe ser sobrepasada."
                                  placement="top"
                                >
                                  <ErrorIcon fontSize="small" />
                                </Tooltip>
                              </InputAdornment>
                            ),
                          },
                        }}
                      />
                    </Grid>
                  </>
                )}

                {EVENTOS_DISTANCIA.includes(configuracion.tipo_alerta._id) && (
                  <Grid item xs={12} md={3}>
                    <TextField
                      label="Distancia (Metros)"
                      name="distancia_vehiculos"
                      variant="outlined"
                      value={configuracion.distancia_vehiculos}
                      onChange={handleChange}
                      size="small"
                      fullWidth
                      error={errorVehiculoDistancia} 
                      helperText={errorVehiculoDistancia ? "La distancia debe ser mayor a 10 metros" : ""}
                      slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">
                              <Tooltip
                                title="Distancia entre los vehículos. Debe ser mayor a 10 metros."
                                placement="top"
                              >
                                <ErrorIcon fontSize="small" />
                              </Tooltip>
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                  </Grid>
                )}

                {/**
                 * ✅ Mostrar los campos `veces`, `tiempo` y `tipo marcado`
                 * si el `id` no está en ninguno de los grupos EVENTOS_TIEMPO, EVENTOS_TIPO_MARCADO,
                 * EVENTOS_VECES_VELOCIDAD o EVENTOS_DISTANCIA.
                 */}
                {!(
                  EVENTOS_TIEMPO.includes(configuracion.tipo_alerta._id) ||
                  EVENTOS_TIPO_MARCADO.includes(
                    configuracion.tipo_alerta._id
                  ) ||
                  EVENTOS_VECES_VELOCIDAD.includes(
                    configuracion.tipo_alerta._id
                  ) ||
                  EVENTOS_DISTANCIA.includes(configuracion.tipo_alerta._id) ||
                  EVENTO_BOTON_PANICO.includes(configuracion.tipo_alerta._id)
                ) && (
                  <>
                    <Grid item xs={12} md={3}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel>Tipo Marcado</InputLabel>
                        <Select
                          label="Tipo Marcado"
                          name="tipo_marcado"
                          value={configuracion.tipo_marcado}
                          onChange={handleChange}
                          startAdornment={
                            <InputAdornment position="start">
                              <Tooltip
                                title="Indica donde se genera la alerta en relación a las geocercas."
                                placement="top"
                              >
                                <ErrorIcon fontSize="small" />
                              </Tooltip>
                            </InputAdornment>
                          }
                        >
                           {configuracion.tipo_alerta._id !== "6189b3ea2a2e016f281d2a05" && (
                            <MenuItem value={0}>General</MenuItem>
                          )}
                          <MenuItem value={1}>Dentro de la geocerca</MenuItem>
                          <MenuItem value={2}>Fuera de la geocerca</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </Box>
      </Box>
      <Box display="flex" alignItems="flex-start">
        <Box width="100%">
          <Grid container spacing={2} p={1}>
            {/*Nombre de la alerta*/}
            <Grid item xs={12} md={3}>
              <FormControl variant="outlined" size="small" fullWidth>
              <TextField
                label="Nombre"
                inputRef={inputRef}
                name="nombre"
                placeholder="Nombre"
                variant="outlined"
                size="small"
                fullWidth
                defaultValue={"Nombre"}
                value={configuracion.nombre}
                onChange={handleChange}
                error={!!errors.nombre || errorAlertaNombre} // Modificado: ahora responde también a errorAlertaNombre
                helperText={errorAlertaNombre && "El nombre es obligatorio"} // Mensaje de error
                inputProps={{
                  maxLength: 50 // Límite de caracteres
                }}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <Tooltip
                          title="Ingresa el nombre de la alerta"
                          placement="top"
                        >
                          <ErrorIcon fontSize="small" />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  },
                }}
              />

                {/* <TextField
                  label="Nombre"
                  inputRef={inputRef}
                  name="nombre"
                  placeholder="Nombre"
                  variant="outlined"
                  size="small"
                  fullWidth
                  defaultValue={"Nombre"}
                  value={configuracion.nombre}
                  onChange={handleChange}
                  error={!!errors.nombre} // Muestra un error si hay texto en errorNombre
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <Tooltip
                            title="Ingresa el nombre de la alerta"
                            placement="top"
                          >
                            <ErrorIcon fontSize="small" />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    },
                  }}
                /> */}
              </FormControl>
            </Grid>

            {/*Riesgo*/}
            <Grid item xs={12} md={3}>
              <FormControl variant="outlined" size="small" fullWidth>
                <TextField
                  select
                  label="Riesgo"
                  inputRef={selectRef}
                  name="riesgo" // Clave en el estado
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={configuracion.riesgo}
                  onChange={handleChange}
                  error={!!errors.riesgo}
                  renderValue={(selected) => {
                    const riesgo = riesgos.find((ries) => ries.id === selected);
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        {/* Circulito con color dinámico */}
                        <Box
                          sx={{
                            width: 12,
                            height: 12,
                            borderRadius: "50%",
                            backgroundColor:
                              riesgo?.nombre === "Alto"
                                ? "#f54242"
                                : riesgo?.nombre === "Bajo"
                                ? "#78db53"
                                : riesgo?.nombre === "Medio"
                                ? "#dbd653"
                                : "#a8a8a8", // Color predeterminado
                          }}
                        />
                        {riesgo?.nombre || "Selecciona un riesgo"}
                      </Box>
                    );
                  }}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <Tooltip
                            title="Selecciona el nivel de riesgo de la alerta"
                            placement="top"
                          >
                            <ErrorIcon fontSize="small" />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    },
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: (selected) => {
                      if (!selected) {
                        return "Seleccionar un riesgo";
                      }
                      return riesgos.find((riesgo) => riesgo.id === selected)
                        ?.nombre;
                    },
                  }}
                >
                  {riesgos
                    .sort((a, b) => {
                      const prioridad = { Alto: 1, Medio: 2, Bajo: 3 };
                      return prioridad[a.nombre] - prioridad[b.nombre];
                    })
                    .map((riesgo) => (
                      <MenuItem key={riesgo.id} value={riesgo.id}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Box
                            sx={{
                              width: 12,
                              height: 12,
                              borderRadius: "50%",
                              backgroundColor:
                                riesgo.nombre === "Alto"
                                  ? "#f54242"
                                  : riesgo.nombre === "Bajo"
                                  ? "#78db53"
                                  : riesgo.nombre === "Medio"
                                  ? "#dbd653"
                                  : "#a8a8a8", // Color predeterminado
                            }}
                          />
                          {riesgo.nombre}
                        </Box>
                      </MenuItem>
                    ))}
                </TextField>
              </FormControl>
            </Grid>

            {/*Sonido*/}
            {viewNew && (
              <Grid item xs={12} md={3}>
                <ListaAudios
                  configuracion={configuracion}
                  actualizarComponente={actualizarComponente}
                  handleGuardarPrincipal={handleGuardarPrincipal}
                  idAlerta={idAlerta}
                  editMode={false}
                />
              </Grid>
            )}

{viewNew && (
    <Grid item xs={12} md={3}>
      <FormControl variant="outlined" size="small" fullWidth>
        <InputLabel id="group-select-label">Grupo</InputLabel>
        <Select
          labelId="group-select-label"
          id="group-select"
          value={selectedGroup}
          label="Grupo"
          onChange={handleGroupChange}
          startAdornment={
            <InputAdornment position="start">
              <Tooltip
                title="Selecciona el grupo al que pertenecen los vehículos"
                placement="top"
              >
                <ErrorIcon fontSize="small" />
              </Tooltip>
            </InputAdornment>
          }
        >
          <MenuItem value="">
            <em>Ninguno</em>
          </MenuItem>
          {/* Handle both possible group structures */}
          {groups && groups.length > 0 ? (
            groups.map((group) => {
              // Determine the group ID and name based on available properties
              const groupId = group.grupo_id || group.GrupoUid;
              const groupName = group.nombre || group.Nombre;
              
              // Only render if we actually have an ID and name
              return groupId && groupName ? (
                <MenuItem key={groupId} value={groupId}>
                  {groupName}
                </MenuItem>
              ) : null;
            })
          ) : (
            <MenuItem value="" disabled>
              Cargando grupos...
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </Grid>
  )}

            {/* Clasificaciones*/}
            {/* <Grid item xs={12} md={3}>
              <FormControl variant="outlined" size="small" fullWidth>
                <TextField
                  select
                  label="Clasificaciones"
                  value={clasificacionSeleccionada?.id || ""}
                  onChange={async (event) => {
                    const selectedId = event.target.value;
                    const selectedClasificacion = clasificaciones.find(
                      (c) => c.id === selectedId
                    );

                    setClasificacionSeleccionada(selectedClasificacion);

                    if (selectedId) {
                      try {
                        const response =
                          await ClasificacionEventoService.FiltrarAsignaciones(
                            selectedId
                          );
                        if (response && response.statusCode === 200) {
                          const asignacionFiltrada = response.data.find(
                            (asignacion) =>
                              asignacion.uid_clasificacion === selectedId
                          );

                          const eventosFiltrados =
                            asignacionFiltrada?.eventos || [];
                          setEventosFiltrados(eventosFiltrados);
                        }
                      } catch (error) {
                        console.error("Error al cargar asignaciones:", error);
                        setEventosFiltrados([]);
                      }
                    } else {
                      setEventosFiltrados([]);
                    }
                  }}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <Tooltip title="Seleccione una clasificación para desplegar los tipos de alertas.">
                            <ErrorIcon fontSize="small" />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    },
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: (selected) => {
                      if (!selected) {
                        return "Seleccionar clasificación";
                      }
                      return clasificaciones.find((c) => c.id === selected)
                        ?.nombre;
                    },
                  }}
                  size="small"
                  fullWidth
                  variant="outlined"
                >
                  {clasificaciones.map((clasificacion) => (
                    <MenuItem key={clasificacion.id} value={clasificacion.id}>
                      {clasificacion.nombre}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid> */}
          </Grid>
        </Box>
      </Box>

      <Box
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        <Box>
          <Grid
            container
            spacing={1}
            paddingRight={viewNew ? 0 : 1}
            paddingTop={1}
          >
            <Grid item>
              {viewEdit && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (
                      !errorNombre &&
                      !errorTiempo &&
                      !errorVeces &&
                      !errorVelocidad &&
                      !errorDistancia
                    ) {
                      // Verifica si no hay errores antes de cualquier acción
                      if (!viewNew) {
                        handleOpenSaveDialog(); // Abrir modal de confirmación si viewNew es false
                      } else {
                        handleGuardarPrincipal(); // Guardar directamente si viewNew es true
                        if (viewNew) nextComponent(0);
                      }
                    }
                  }}
                  endIcon={viewNew ? <NavigateNextIcon /> : <SaveIcon />}
                  disabled={isFormEmpty}
                >
                  {viewNew ? "Siguiente" : "Guardar"}
                </Button>
              )}
            </Grid>

            <Grid item>
              {/** Botón NUEVO */}
              {!viewNew && ( // ✅ Ajuste: Mostrar el botón si viewNew es false
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenCancelDialog} // Abrir modal de cancelar
                  endIcon={<CancelIcon />}
                >
                  Cancelar
                </Button>
              )}
            </Grid>
            {/* // Modal de confirmación para cancelar */}
            <Dialog open={cancelDialogOpen} onClose={handleCloseCancelDialog}>
              <DialogTitle>Confirmar cancelación</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  ¿Estás seguro de que deseas cancelar esta operación? Los
                  cambios realizados no se guardarán.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseCancelDialog} color="primary">
                  No
                </Button>
                <Button
                  onClick={() => {
                    resetForm(); // Lógica al confirmar la cancelación
                    setCancelDialogOpen(false); // Cerrar modal
                  }}
                  color="secondary"
                  autoFocus
                >
                  Sí, cancelar
                </Button>
              </DialogActions>
            </Dialog>
            {/* // Modal para guardar si view es false */}
            <Dialog open={saveDialogOpen} onClose={handleCloseSaveDialog}>
              <DialogTitle>Confirmar guardado</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  ¿Estás seguro de que deseas guardar los cambios realizados?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseSaveDialog} color="primary">
                  Cancelar
                </Button>
                <Button
                  onClick={() => {
                    handleGuardarPrincipal(); // Guardar cambios
                    setSaveDialogOpen(false); // Cerrar modal
                  }}
                  color="secondary"
                  autoFocus
                >
                  Guardar
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
};

export default NuevaAlertaForm;
