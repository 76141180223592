import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ErrorIcon from "@mui/icons-material/Error";
import { useEffect, useRef, useState } from "react";
import CustomAudioPlayer from "./CustomAudioPlayer";
import NextButton from "./NextButton";

export default function ListaAudios({
  configuracion,
  actualizarComponente,
  handleGuardarPrincipal,
  idAlerta,
  editMode,
  handleClose,
}) {
  const initialAudio = {
    fileName: "",
    filePath: "",
  };

  const [selectedAudio, setSelectedAudio] = useState(initialAudio);
  const audioRef = useRef(null);

  const soundsContext = require.context(
    "../../../../public/alertas_audio",
    false,
    /\.(mp3|wav)$/
  );

  const soundFiles = soundsContext.keys().map((key) => {
    const fileName = key
      .replace("./", "")
      .replace(/\.(mp3|wav)$/, "")
      .replace(/[^a-zA-Z0-9 ]/g, " ")
      .toUpperCase();
    const filePath = `${process.env.PUBLIC_URL}/alertas_audio/${key.replace(
      "./",
      ""
    )}`;
    return { fileName, filePath };
  });

  const handleSelect = (event) => {
    const audio = soundFiles.find(
      (file) => file.filePath === event.target.value
    );
    if (audio) {
      setSelectedAudio(audio);
      actualizarComponente("url_sonido", audio.filePath);
    } else {
      setSelectedAudio(initialAudio);
      actualizarComponente("url_sonido", "");
    }
  };

  function isEnableButton() {
    return selectedAudio.filePath.length > 0 && idAlerta !== "";
  }

  useEffect(() => {
    if (configuracion.url_sonido === "") {
      if (selectedAudio.filePath !== "") {
        setSelectedAudio(initialAudio);
      }
    } else {
      if (selectedAudio.filePath === "") {
        const matchingAudio = soundFiles.find(
          (audio) => audio.filePath === configuracion.url_sonido
        );

        if (matchingAudio) {
          setSelectedAudio(matchingAudio);
          actualizarComponente("url_sonido", matchingAudio.filePath);
        }
      }
    }
  }, [configuracion.url_sonido, soundFiles]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.load();
    }
  }, [selectedAudio]);

  return (
    <>
      {editMode ? (
        // Vista normal cuando editMode = True
        <Box
          flex={1} // Permite que el primer componente ocupe el espacio disponible
          sx={{
            display: "flex",
            alignItems: "center", // Asegura que el contenido interno se alinee verticalmente
            justifyContent: "center",
            gap: 2,
          }}
        >
          {/*Selector de sonido */}
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel id="sonido-label">Sonido</InputLabel>
            <Select
              labelId="sonido-label"
              id="select-sonido"
              label="Sonido"
              value={selectedAudio.filePath}
              onChange={handleSelect}
              renderValue={() => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                  }}
                  gap={1}
                >
                  <CustomAudioPlayer src={selectedAudio.filePath} />
                  <Typography variant="body1">
                    {selectedAudio.fileName}
                  </Typography>
                </Box>
              )}
            >
              {soundFiles.map((audio) => (
                <MenuItem key={audio.filePath} value={audio.filePath}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    {/* Checkbox */}
                    <Checkbox
                      size="small"
                      checked={selectedAudio.filePath === audio.filePath}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          handleSelect({ target: { value: "" } });
                        }
                      }}
                    />
                    {/* Audio */}
                    <CustomAudioPlayer src={audio.filePath} />
                    {/* File Name */}
                    <Typography variant="body1">{audio.fileName}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* Boton */}
          <NextButton
            handleGuardarPrincipal={handleGuardarPrincipal}
            isEditMode={true}
            idAlerta={idAlerta}
            handleClose={handleClose}
            ModalName={"sonido"}
          />
        </Box>
      ) : (
        // Vista normal cuando editMode = false
        <>
          <FormControl variant="outlined" size="small" fullWidth>
            <TextField
              select
              label="Sonido"
              variant="outlined"
              fullWidth
              size="small"
              value={selectedAudio.filePath}
              onChange={handleSelect}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <Tooltip
                        title="Selecciona un evento para la alerta"
                        placement="top"
                      >
                        <ErrorIcon fontSize="small" />
                      </Tooltip>
                    </InputAdornment>
                  ),
                },
              }}
              SelectProps={{
                displayEmpty: true,
                renderValue: (selected) => {
                  if (!selected) {
                    return "Seleccionar un sonido";
                  }
                  const selectedSound = soundFiles.find(
                    (sound) => sound.filePath === selected
                  );
                  return (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          // textOverflow: "ellipsis",
                          // overflow: "hidden",
                          // whiteSpace: "nowrap",
                        }}
                      >
                        <CustomAudioPlayer src={selectedSound.filePath} />
                        <Typography variant="subtitle2">
                          {selectedSound.fileName}
                        </Typography>
                      </Box>
                    </>
                  );
                },
                // MenuProps: {
                //   PaperProps: {
                //     sx: {
                //       mt: 0.6,
                //       transform: "translateY(-5px) translateX(18px)!important",
                //     },
                //   },
                // },
              }}
            >
              {soundFiles.map((audio) => (
                <MenuItem key={audio.filePath} value={audio.filePath}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    {/* Checkbox */}
                    <Checkbox
                      sx={{
                        width: "10px",
                        height: "10px",
                      }}
                      size="small"
                      checked={selectedAudio.filePath === audio.filePath}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          handleSelect({ target: { value: "" } });
                        }
                      }}
                    />
                    {/* Audio */}
                    <CustomAudioPlayer src={audio.filePath} />
                    {/* File Name */}
                    <Typography variant="body1">{audio.fileName}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </>
      )}
    </>
  );
}
