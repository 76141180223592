// GoogleMapView.js
import React, { useState, useEffect, useRef, memo, useMemo } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  OverlayView,
  Polygon,
  useGoogleMap,
  Circle,Polyline, 
} from "@react-google-maps/api";
import { Box, Tooltip,Typography } from "@mui/material";
import { createVehicleMarkerHTML } from "../../utils/markerUtils";
import { getAlertIcon } from "../../utils/alertUtils";
import useGeocercas from "../../services/useGeocercas";
import { createPopupContent } from "../../utils/popupUtils";
import { RenderPolygon, RenderCircle } from "../../utils/MapShapes";
import { vehiculoService } from "../../services/apiService";
import vehicleIcon from "../../assets/svg_images/svg_vehiculo.svg";
import "./googlemaps_style.css";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ClearIcon from '@mui/icons-material/Clear';
import WarningIcon from "../../assets/warning.svg";
import LocationIcon from "../../assets/location.svg";
import IconButton from '@mui/material/IconButton';
import TimelineIcon from '@mui/icons-material/Timeline';


window.activeInfoWindow = null;

// const ActiveAlertOverlay = memo(({ alert, onClose }) => {
//   const position = useMemo(() => {
//     if (!alert) return null;
//     return {
//       lat: alert.ubicacion.lat,
//       lng: alert.ubicacion.lng,
//     };
//   }, [alert]);

//   if (!alert || !position) return null;

//   // Obtener la rotación del vehículo
//   const rotation = alert.cog ? parseInt(alert.cog) : 0;
//   const vehicleColor = alert.Color || "#000000";

//   return (
//     <OverlayView
//       position={position}
//       mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
//       getPixelPositionOffset={(width, height) => ({
//         x: -(width / 2),
//         y: -height,
//       })}
//     >
  

//       <div
//   className="alert-container"
//   style={{
//     position: "relative",
//     width: "auto",
//     height: "auto",
//     display: "inline-block",
//     transform: "translate(-50%, -100%)",
//     animation: "fadeIn 0.3s ease-in-out",
//   }}
// >
//   {/* Contenedor con posicionamiento relativo para el marcador y el botón */}
//   <div style={{ position: "relative" }}>
//     {/* Botón posicionado absolutamente sobre el marcador */}
//     <Tooltip 
//       title="Cerrar y volver a la vista general" 
//       arrow 
//       placement="top"
//     >
//       <button 
//         className="close-button clear-alert-button" 
//         onClick={onClose}
//         style={{
//           position: "absolute",
//           top: "-20px", // Ajusta este valor para posicionar verticalmente
//           right: "-20px", // Ajusta este valor para posicionar horizontalmente
//           zIndex: 20, // Z-index mayor para asegurar que esté encima
//           background: "transparent",
//           border: "none",
//           cursor: "pointer",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           padding: "0",
//           width: "36px",
//           height: "36px"
//         }}
//       >
//         <LocationOnIcon style={{ color: "#FF0000", fontSize: "36px" }} />
//       </button>
//     </Tooltip>
    
//     {/* Marcador del vehículo */}
//     <div 
//       className="vehicle-marker-container" 
//       style={{ position: "relative", textAlign: "center" }}
//       dangerouslySetInnerHTML={{ 
//         __html: createVehicleMarkerHTML(alert)
//       }}
//     />
//   </div>
// </div>

//     </OverlayView>
//   );
// });


const ActiveAlertOverlay = memo(({ alert, onClose }) => {
  const position = useMemo(() => {
    if (!alert) return null;
    return {
      lat: alert.ubicacion.lat,
      lng: alert.ubicacion.lng,
    };
  }, [alert]);

  if (!alert || !position) return null;

  return (
    <OverlayView
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={(width, height) => ({
        x: -(width / 2),
        y: -height,
      })}
    >
      <div
        className="alert-container"
        style={{
          position: "relative",
          width: "auto",
          height: "auto",
          display: "inline-block",
          transform: "translate(-50%, -100%)",
          animation: "fadeIn 0.3s ease-in-out",
        }}
      >
        <div style={{ position: "relative" }}>
          <Tooltip 
            title="Cerrar y volver a la vista general" 
            arrow 
            placement="top"
          >
            <button 
              className="close-button clear-alert-button" 
              onClick={onClose}
              style={{
                position: "absolute",
                top: "-10px", // Reducido de -15px a -10px
                right: "-10px", // Reducido de -15px a -10px
                zIndex: 20,
                background: "white",
                border: "none",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0",
                width: "24px", // Reducido de 28px a 24px
                height: "24px", // Reducido de 28px a 24px
                borderRadius: "50%",
                boxShadow: "0 2px 6px rgba(0,0,0,0.3)"
              }}
            >
              <ClearIcon style={{ color: "#FF3B30", fontSize: "16px" }} />
            </button>
          </Tooltip>
          
          <div className="pin-animation-wrapper">
            <div className="dynamic-pin">
              <div className="pin-shadow"></div>
              <div className="pin-container">
                <div className="pin-top">
                  <div className="pin-icon">
                    <LocationOnIcon style={{ fontSize: "20px", color: "white" }} />
                  </div>
                </div>
                <div className="pin-point"></div>
              </div>
            </div>
            
            <div className="pin-label" style={{ marginTop: "0" }}>
              {alert.unidad || "Ubicación"}
            </div>
          </div>
          
          <style jsx="true">{`
            .pin-animation-wrapper {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: flex-start;
              padding-bottom: 0px;
            }
            
            .dynamic-pin {
              position: relative;
              width: 40px;
              height: 50px;
              animation: dynamic-bounce 3s infinite cubic-bezier(0.4, 0.0, 0.2, 1);
              transform-origin: bottom center;
              perspective: 500px;
            }
            
            .pin-shadow {
              position: absolute;
              width: 14px;
              height: 5px;
              bottom: 1px;
              left: 50%;
              transform: translateX(-50%);
              background: rgba(0,0,0,0.2);
              border-radius: 50%;
              filter: blur(2px);
              animation: shadow-dynamic 3s infinite cubic-bezier(0.4, 0.0, 0.2, 1);
            }
            
            .pin-container {
              position: relative;
              display: flex;
              flex-direction: column;
              align-items: center;
              animation: pin-rotate 3s infinite cubic-bezier(0.4, 0.0, 0.2, 1);
              transform-style: preserve-3d;
            }
            
            .pin-top {
              width: 36px;
              height: 36px;
              background: linear-gradient(135deg, #FF3B30 0%, #FF3B30 70%, #e53529 100%);
              border-radius: 50% 50% 50% 3px;
              box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
              transform: rotate(-45deg);
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 2;
            }
            
            .pin-icon {
              transform: rotate(45deg);
              display: flex;
              justify-content: center;
              align-items: center;
            }
            
            .pin-point {
              width: 14px;
              height: 14px;
              background: linear-gradient(135deg, #FF3B30 0%, #e53529 100%);
              transform: rotate(45deg);
              margin-top: -8px;
              border-radius: 0 3px 3px 3px;
              box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
              z-index: 1;
            }
            
            .pin-label {
              margin-top: 3px;
              background-color: rgba(0, 0, 0, 0.7);
              color: white;
              padding: 3px 8px;
              border-radius: 14px;
              font-size: 11px;
              font-weight: 500;
              white-space: nowrap;
              max-width: 120px;
              overflow: hidden;
              text-overflow: ellipsis;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
              text-align: center;
              letter-spacing: 0.3px;
              border: 1px solid rgba(255, 255, 255, 0.1);
            }
            
            /* Efecto de pulso detrás del pin */
            .dynamic-pin::after {
              content: '';
              position: absolute;
              top: 15px;
              left: 15px;
              width: 10px;
              height: 10px;
              background-color: rgba(255, 59, 48, 0.3);
              border-radius: 50%;
              z-index: 0;
              opacity: 0.7;
              animation: pulse-dynamic 3s infinite;
            }
            
            @keyframes dynamic-bounce {
              0% { transform: translateY(0) scale(1); }
              10% { transform: translateY(-15px) scale(1.05); }
              20% { transform: translateY(0) scale(1); }
              30% { transform: translateY(-7px) scale(1.02); }
              40% { transform: translateY(0) scale(1); }
              50% { transform: translateY(0) scale(1); }
              60% { transform: translateY(0) scale(1); }
              70% { transform: translateY(-5px) scale(1.01); }
              80% { transform: translateY(0) scale(1); }
              100% { transform: translateY(0) scale(1); }
            }
            
            @keyframes pin-rotate {
              0% { transform: rotateY(0deg); }
              10% { transform: rotateY(0deg); }
              20% { transform: rotateY(180deg); }
              30% { transform: rotateY(360deg); }
              40% { transform: rotateY(360deg); }
              50% { transform: rotateY(360deg); }
              60% { transform: rotateY(360deg) rotateZ(0deg); }
              70% { transform: rotateY(360deg) rotateZ(10deg); }
              80% { transform: rotateY(360deg) rotateZ(-10deg); }
              90% { transform: rotateY(360deg) rotateZ(0deg); }
              100% { transform: rotateY(0deg); }
            }
            
            @keyframes pulse-dynamic {
              0% { transform: scale(1); opacity: 0.7; }
              20% { transform: scale(3.5); opacity: 0; }
              21% { transform: scale(1); opacity: 0.7; }
              40% { transform: scale(3.5); opacity: 0; }
              41% { transform: scale(1); opacity: 0.7; }
              60% { transform: scale(3.5); opacity: 0; }
              61% { transform: scale(1); opacity: 0.7; }
              80% { transform: scale(3.5); opacity: 0; }
              100% { transform: scale(1); opacity: 0; }
            }
            
            @keyframes shadow-dynamic {
              0% { transform: translateX(-50%) scale(1); opacity: 0.2; }
              10% { transform: translateX(-50%) scale(0.7); opacity: 0.1; }
              20% { transform: translateX(-50%) scale(1); opacity: 0.2; }
              30% { transform: translateX(-50%) scale(0.7); opacity: 0.1; }
              40% { transform: translateX(-50%) scale(1); opacity: 0.2; }
              50% { transform: translateX(-50%) scale(1); opacity: 0.2; }
              70% { transform: translateX(-50%) scale(0.8); opacity: 0.1; }
              80% { transform: translateX(-50%) scale(1); opacity: 0.2; }
              100% { transform: translateX(-50%) scale(1); opacity: 0.2; }
            }
          `}</style>
        </div>
      </div>
    </OverlayView>
  );
});

const ActiveAlertOverlay_2 = memo(({ alert, onClose }) => {
  const position = useMemo(() => {
    if (!alert) return null;
    return {
      lat: alert?.ubicacion?.lat,
      lng: alert?.ubicacion?.lng,
    };
  }, [alert]);

  if (!alert || !position) {
    return null;
  }

  return (
    <OverlayView
      position={{
        lat: alert.ubicacion.lat,
        lng: alert.ubicacion.lng,
      }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={(width, height) => ({
        x: -(width / 2),
        y: -height,
      })}
    >
      <div
        className="alert-container"
        style={{
          position: "relative",
          width: "auto",
          height: "auto",
          display: "inline-block",
          transform: "translate(-50%, -100%)",
          animation: "fadeIn 0.3s ease-in-out",
        }}
      >
        <style>
          {`
            @keyframes fadeIn {
              from { opacity: 0; transform: translate(-50%, -90%); }
              to { opacity: 1; transform: translate(-50%, -100%); }
            }
            @keyframes bounce {
              0%, 100% { transform: translateY(0); }
              50% { transform: translateY(-5px); }
            }
            .alert-container {
              position: relative;
              padding-bottom: 15px; /* Espacio para la flecha */              
            }
            .vehicle-icon_2 {
              animation: bounce 1s ease-in-out infinite;
            }
            .alert-icons2 {
              display: flex; 
              align-items: center !important;  
              justify-content: center !important;
              position: relative;
            }
            .popup-card {
              position: relative;
              border-radius: 8px;
              box-shadow: 0 2px 4px rgba(0,0,0,0.2);
              margin-bottom: 15px; /* Espacio para la flecha */
            }
            /* Triángulo principal */
            .popup-card:after {
              content: '';
              position: absolute;
              bottom: -15px; /* Ajustado para hacer la flecha más visible */
              left: 50%;
              transform: translateX(-50%);
              border-left: 15px solid transparent;
              border-right: 15px solid transparent;
              border-top: 15px solid white;
            }
            /* Sombra del triángulo */
            .popup-card:before {
              content: '';
              position: absolute;
              bottom: -16px;
              left: 50%;
              transform: translateX(-50%);
              border-left: 16px solid transparent;
              border-right: 16px solid transparent;
              border-top: 16px solid rgba(0,0,0,0.1);
              z-index: -1;
            }
          `}
        </style>

        {/* <div style={{ position: "absolute", top: 0, right: 0, zIndex: 10 }}>
          <button className="close-button clear-alert-button" onClick={onClose}>
            &times;
          </button>
        </div> */}

        {/* <div dangerouslySetInnerHTML={{ __html: createPopupContent(alert) }} /> */}

        {/* <div className="alert-icons2">
          <div className="vehicle-icon_2" style={{ fontSize: '32px' }}>🚗</div>
        </div> */}
        <div className="alert-icons2">
          <img
            src={vehicleIcon}
            className="vehicle-icon_2"
            style={{ width: 32, height: 32 }}
          />
        </div>
      </div>
    </OverlayView>
  );
});

export const createCustomMarker = (alert) => {
  const div = document.createElement("div");
  div.className = "custom-marker";
  
  // Añadir estilos inline para asegurar visibilidad
  div.style.minWidth = "40px";
  div.style.minHeight = "40px";
  div.style.display = "flex";
  div.style.alignItems = "center";
  div.style.justifyContent = "center";
  div.style.position = "relative";
  div.style.cursor = "pointer";
  div.style.transform = "translate(-50%, -50%)";

  // Usar un contenido fallback en caso de que el HTML no se genere correctamente
  try {
    const html = createVehicleMarkerHTML(alert);
    if (html) {
      div.innerHTML = html;
    } else {
      throw new Error("HTML para marcador vacío");
    }
  } catch (error) {
    console.warn("Error al crear HTML del marcador:", error);
    // Fallback básico para asegurar que algo se muestra
    div.innerHTML = `
      <div style="position: relative; text-align: center;">
        <div style="
          width: 32px; 
          height: 32px; 
          background-color: #4285F4; 
          border-radius: 50%; 
          display: flex; 
          align-items: center; 
          justify-content: center;
          border: 2px solid white;
          box-shadow: 0 2px 4px rgba(0,0,0,0.3);
          color: white;
          font-weight: bold;
        ">
          ${alert.unidad?.substring(0, 2) || '?'}
        </div>
        
        <!-- Etiqueta fallback -->
        <div style="
          position: absolute;
          bottom: -20px;
          left: 50%;
          transform: translateX(-50%);
          background-color: rgba(0,0,0,0.6);
          color: white;
          border-radius: 10px;
          padding: 2px 6px;
          font-size: 10px;
          font-weight: bold;
          white-space: nowrap;
          box-shadow: 0 1px 2px rgba(0,0,0,0.3);
          border: 1px solid white;
        ">
          ${alert.unidad || 'Sin ID'}
        </div>
      </div>
    `;
  }
  
  return div;
};


const ActiveMarkerOverlay = ({ alert, onClose }) => {
  if (!alert) return null;

  return (
    <OverlayView
      position={{ lat: alert.ubicacion.lat, lng: alert.ubicacion.lng }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={(width, height) => ({
        x: -(width / 2),
        y: -height,
      })}
    >
      <div className="active-marker-container">
        <div className="pulse-animation" />
        <div className="vehicle-icon">🚗</div>
        <img
          src={getAlertIcon(alert.alerta)}
          alt={alert.alerta}
          className="alert-icon"
        />
        <button onClick={onClose} className="close-button">
          &times;
        </button>
      </div>
    </OverlayView>
  );
};

const UserLocationMarker = ({ position, map }) => {
  useEffect(() => {
    if (!map || !window.google) return;

    const homeIcon = document.createElement("div");
    homeIcon.innerHTML = `
      <div class="user-marker-container">
        <div class="pulse"></div>
        <div style="
          font-size: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        ">
          🏠
        </div>
      </div>
    `;

    const marker = new window.google.maps.marker.AdvancedMarkerElement({
      position: position,
      map: map,
      content: homeIcon,
      title: "Tu ubicación actual",
    });

    return () => {
      marker.setMap(null);
    };
  }, [position, map]);

  return null;
};

// Modificar el componente VehicleMarkers para manejar cargas iniciales


const VehicleMarkers = ({ 
  alerts = [], 
  allAlerts = [], 
  map, 
  setSelectedVehicleRoute, 
  setVehicleRouteCoordinates, 
  setIsRouteVisible,
  activeVehicleId, 
  setActiveVehicleId,
  setAlertCounters,
  setAlertDataForRoute 
}) => {
  const markersRef = useRef({});
  const clusterRef = useRef(null);
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [isClusterReady, setIsClusterReady] = useState(false);
  const allAlertsRef = useRef(allAlerts);


  // Configurar el clusterer cuando el mapa esté listo
  useEffect(() => {
    if (!map || !window.google) {
      return;
    }
    
    const setupCluster = async () => {
      try {
        // Importar dinámicamente las clases necesarias
        const { MarkerClusterer, SuperClusterAlgorithm } = await import("@googlemaps/markerclusterer");
        
        // Crear un algoritmo de clustering
        const algorithm = new SuperClusterAlgorithm({
          radius: 100,
          maxZoom: 15,
          minPoints: 3  // Aumentar el umbral para reducir la agrupación excesiva
        });
        
        // Crear el clusterer
        clusterRef.current = new MarkerClusterer({
          map,
          algorithm,
          markers: [],
          renderer: {
            render: ({ count, position }) => {
              const container = document.createElement("div");
              container.className = "marker-cluster";
              container.innerHTML = `
                <div style="
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  background-color: rgba(66, 133, 244, 0.8);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: white;
                  font-weight: bold;
                  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
                  border: 2px solid white;
                  transform: translate(-50%, -50%);
                ">${count}</div>
              `;
              
              const marker = new window.google.maps.marker.AdvancedMarkerElement({
                map,
                position,
                content: container,
                zIndex: 1000
              });
              
              return marker;
            }
          }
        });
        
        setIsClusterReady(true);
        console.log("✅ Clusterer configurado correctamente");
        
      } catch (error) {
        console.error("❌ Error al configurar el clusterer:", error);
      }
    };
    
    setupCluster();
    
    return () => {
      if (clusterRef.current) {
        clusterRef.current.clearMarkers();
        clusterRef.current = null;
      }
    };
  }, [map]);
  
   useEffect(() => {
    allAlertsRef.current = allAlerts;
    console.log("allAlerts actualizado en ref:", allAlerts.length);
  }, [allAlerts]);


  // Actualizar marcadores cuando cambien las alertas
  useEffect(() => {
    if (!map || !window.google) {
      return;
    }


    const handleMarkerClick = async (alert) => {

      const currentAllAlerts = allAlertsRef.current;
      
      console.log("handleMarkerClick - allAlerts length:", currentAllAlerts.length);
      
      if (!alert || !alert.ubicacion) {
        console.error("Alerta inválida o sin ubicación", alert);
        return;
      }

      setSelectedAlert(alert);
      
      // Log para diagnóstico
      console.log("Click en vehículo:", alert.unidad, "ID:", alert.UID_Vehiculo);
      
        // Usar alerts como fallback si allAlerts está vacío
      // Usar la referencia para obtener las alertas
      const alertsToUse = currentAllAlerts && currentAllAlerts.length > 0 ? 
        currentAllAlerts : alerts;

      // Obtener todas las alertas para este vehículo
      const vehicleId = alert.UID_Vehiculo;

      if (!vehicleId) {
        console.error("ID de vehículo no disponible");
        return;
      }

      setActiveVehicleId(vehicleId); // Establecer el vehículo activo
      const vehicleAlerts = alertsToUse.filter(a => a.UID_Vehiculo === vehicleId);
      
      // Log para diagnóstico
      console.log(`Encontradas ${vehicleAlerts.length} alertas para vehículo ${alert.unidad}`);
      
      // Contar alertas por tipo
      const counters = {};
      vehicleAlerts.forEach(a => {
        if (!a.alert_type) return;
        counters[a.alert_type] = (counters[a.alert_type] || 0) + 1;
      });
      setAlertCounters(counters);

      if (vehicleAlerts.length < 2) {
        console.log("No hay suficientes puntos para trazar una ruta");
        // Mostrar mensaje alternativo o usar otro enfoque
        // Por ejemplo, podríamos mostrar un punto único con información
        setSelectedVehicleRoute(vehicleId);
        setVehicleRouteCoordinates([{
          lat: parseFloat(alert.ubicacion.lat),
          lng: parseFloat(alert.ubicacion.lng)
        }]);
        setIsRouteVisible(true);
        return;
      }
      
      // Ordenar por fecha (asumiendo que existe un campo fecha o timestamp)
      const sortedAlerts = [...vehicleAlerts].sort((a, b) => 
        new Date(a.FechaHora_Evento).getTime() - new Date(b.FechaHora_Evento).getTime()
      );
      
      // Crear coordenadas de la ruta
      const routeCoords = sortedAlerts
        .map(alert => {
          const lat = parseFloat(alert.ubicacion.lat);
          const lng = parseFloat(alert.ubicacion.lng);
          
          // Validar cada coordenada individualmente para mejor diagnóstico
          if (isNaN(lat)) console.log("Latitud inválida:", alert.ubicacion.lat);
          if (isNaN(lng)) console.log("Longitud inválida:", alert.ubicacion.lng);
          
          return {
            lat: lat,
            lng: lng
          };
        })
        .filter(coord => !isNaN(coord.lat) && !isNaN(coord.lng));
      
      // Log para diagnóstico
      console.log(`Generadas ${routeCoords.length} coordenadas válidas para la ruta`);
      
      if (routeCoords.length < 2) {
        console.log("No hay suficientes coordenadas válidas para trazar una ruta");
        // Aquí podrías mostrar un mensaje al usuario
        return;
      }
      
       // Actualizar estado para mostrar la ruta
       setSelectedVehicleRoute(vehicleId);
       setVehicleRouteCoordinates(routeCoords);
       setAlertDataForRoute(sortedAlerts.filter((_, idx) => idx < routeCoords.length));
       setIsRouteVisible(false);


       // Calculate bounds for all points to adjust map view
      if (routeCoords.length > 0 && map) {
        const bounds = new window.google.maps.LatLngBounds();
        routeCoords.forEach(coord => bounds.extend(coord));
        
        // Add padding to the bounds
        map.fitBounds(bounds, {
          padding: { top: 50, right: 50, bottom: 50, left: 50 }
        });

        // Optional: Set a slightly higher zoom level to give better context
        setTimeout(() => {
          const currentZoom = map.getZoom();
          if (currentZoom > 14) {
            map.setZoom(currentZoom - 1);
          }
        }, 100);
      }


       console.log("Ruta activada:", vehicleId);
    };
    
       
    console.log("Procesando marcadores...", alerts.length);
    const activeMarkers = [];
    
    // Crear o actualizar marcadores
    alerts.forEach(alert => {
      const { id, ubicacion, unidad, UID_Vehiculo } = alert;
      
      if (!ubicacion || !ubicacion.lat || !ubicacion.lng) return;
      
      const position = {
        lat: parseFloat(ubicacion.lat),
        lng: parseFloat(ubicacion.lng)
      };
      
      if (isNaN(position.lat) || isNaN(position.lng)) return;
      
      const showMarker = !activeVehicleId || UID_Vehiculo === activeVehicleId;
      
      if (!showMarker) {
        // Ocultar marcador si existe
        if (markersRef.current[id]) {
          markersRef.current[id].map = null;
        }
        return;
      }

      
      if (!markersRef.current[id]) {
        try {
          const markerDiv = document.createElement('div');
          markerDiv.className = 'custom-marker';
          markerDiv.style.cssText = `
            min-width: 30px;
            min-height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            cursor: pointer;
          `;
          
          markerDiv.innerHTML = `
            <div style="
              background-color: #000;
              color: white;
              border-radius: 50%;
              width: 24px;
              height: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 2px solid white;
              box-shadow: 0 2px 4px rgba(0,0,0,0.3);
              font-size: 10px;
              font-weight: bold;
            ">${unidad?.substring(0, 2) || '?'}</div>
          `;
          
          const marker = new window.google.maps.marker.AdvancedMarkerElement({
            position,
            content: createCustomMarker(alert),//markerDiv,
            title: `Unidad: ${unidad}`,
            map: isClusterReady ? null : map,  // Solo añadir al mapa si el clusterer no está listo
            //anchor: new google.maps.Point(60, 60)
            gmpDraggable: false,
            gmpClickable: true, 
            //position: position
          });
          
       
          try {
            // Usar gmp-click con addEventListener 
            marker.addEventListener('gmp-click', () => {
              handleMarkerClick(alert);
            });
          } catch (error) {
            console.warn("Error al agregar gmp-click listener, intentando método alternativo:", error);
            // Fallback a addListener para versiones estables
            marker.addListener('click', () => {
              handleMarkerClick(alert);
            });
          }
                  
          markersRef.current[id] = marker;
          activeMarkers.push(marker);
        } catch (error) {
          console.error(`Error al crear marcador para alerta ${id}:`, error);
        }
      } else {
        markersRef.current[id].position = position;
        activeMarkers.push(markersRef.current[id]);
      }
    });
    
    // Limpiar marcadores que ya no existen
    Object.keys(markersRef.current).forEach(id => {
      if (!alerts.find(alert => alert.id === id)) {
        if (markersRef.current[id]) {
          markersRef.current[id].map = null;
          delete markersRef.current[id];
        }
      }
    });
    
    // Si el clusterer está listo, añadir marcadores
    if (isClusterReady && clusterRef.current) {
      console.log("Actualizando clusterer con", activeMarkers.length, "marcadores");
      
      // Eliminar marcadores actuales del clusterer
      clusterRef.current.clearMarkers();
      
      // Añadir nuevos marcadores
      if (activeMarkers.length > 0) {
        clusterRef.current.addMarkers(activeMarkers);
      }
      
      // Forzar actualización visual
      setTimeout(() => {
        if (map) {
          window.google.maps.event.trigger(map, 'resize');
          window.google.maps.event.trigger(map, 'bounds_changed');
        }
      }, 250);
    } else if (!isClusterReady) {
      // Si el clusterer no está listo, mostrar los marcadores directamente en el mapa
      activeMarkers.forEach(marker => {
        marker.map = map;
      });
    }
    
    return () => {
      if (!isClusterReady) {
        // Solo limpiar los marcadores si no estamos usando el clusterer
        Object.values(markersRef.current).forEach(marker => {
          if (marker) marker.map = null;
        });
      }
    };
  },    [alerts, map, isClusterReady, activeVehicleId]);

  return null;
  // return selectedAlert ? (
  //   <>
  //     <InfoWindow
  //       position={{
  //         lat: parseFloat(selectedAlert.ubicacion.lat),
  //         lng: parseFloat(selectedAlert.ubicacion.lng),
  //       }}
  //       onCloseClick={() => {
  //         setSelectedAlert(null);
  //         setIsRouteVisible(false); // Ocultar la ruta al cerrar la ventana
  //       }}
  //     >
  //       <div
  //         className="custom-popup-content"
  //         dangerouslySetInnerHTML={{ __html: createPopupContent(selectedAlert) }}
  //       />
  //     </InfoWindow>
  //   </>
  // ) : null;
};

const MapResizer = ({ expandMap, map }) => {
  useEffect(() => {
    if (map) {
      window.google.maps.event.trigger(map, "resize");
    }
  }, [expandMap, map]);

  return null;
};

const calcularCentroGeocerca = (geocerca) => {
  if (geocerca.puntos.type === "Point") {
    return {
      lat: Number(geocerca.puntos.puntos[0].latitud),
      lng: Number(geocerca.puntos.puntos[0].longitud),
    };
  } else {
    const points = geocerca.puntos.puntos;
    const lat =
      points.reduce((sum, p) => sum + Number(p.latitud), 0) / points.length;
    const lng =
      points.reduce((sum, p) => sum + Number(p.longitud), 0) / points.length;
    return { lat, lng };
  }
};

function CenterMapOnGeocerca({ geocercas, selectedGeocercas }) {
  const map = useGoogleMap();

  useEffect(() => {
    if (selectedGeocercas.length === 1) {
      const selectedGeocerca = geocercas.find(
        (g) => g.id === selectedGeocercas[0]
      );
      if (selectedGeocerca) {
        let center;
        if (selectedGeocerca.puntos.type === "Point") {
          center = {
            lat: Number(selectedGeocerca.puntos.puntos[0].latitud),
            lng: Number(selectedGeocerca.puntos.puntos[0].longitud),
          };
        } else {
          // Para polígonos, calcular el centro
          const points = selectedGeocerca.puntos.puntos;
          const lat =
            points.reduce((sum, p) => sum + Number(p.latitud), 0) /
            points.length;
          const lng =
            points.reduce((sum, p) => sum + Number(p.longitud), 0) /
            points.length;
          center = { lat, lng };
        }
        if (map) {
          map.panTo(center);
          map.setZoom(15); // Zoom level 15 para mejor vista
        }
      }
    }
  }, [selectedGeocercas, geocercas, map]);

  return null;
}


const MapViewUpdater = ({ center, activeAlert, map }) => {
  useEffect(() => {
    if (map && center) {
      const formattedCenter = Array.isArray(center)
        ? { lat: center[0], lng: center[1] }
        : center;
      if (
        Number.isFinite(formattedCenter.lat) &&
        Number.isFinite(formattedCenter.lng)
      ) {
        console.log("Panning to:", formattedCenter);
        map.panTo(formattedCenter);
      } else {
        console.error("Coordenadas inválidas:", formattedCenter);
      }
    }
  }, [center, map]);

  useEffect(() => {
    if (map && activeAlert) {
      const { lat, lng } = activeAlert.ubicacion;
      if (Number.isFinite(lat) && Number.isFinite(lng)) {
        // Primero establecer el zoom a un nivel que muestre bien la ubicación (16 es un buen valor para ver detalles)
        map.setZoom(20);
        
        // Pequeña pausa para que el zoom se ajuste antes de centrar
        setTimeout(() => {
          map.panTo({ lat, lng });
        }, 50);
      } else {
        console.error(
          "Coordenadas inválidas en activeAlert:",
          activeAlert.ubicacion
        );
      }
    }
  }, [activeAlert, map]);

  return null;
};

// const MapViewUpdater = ({ center, activeAlert, map }) => {
//   useEffect(() => {
//     if (map && center) {
//       const formattedCenter = Array.isArray(center)
//         ? { lat: center[0], lng: center[1] }
//         : center;
//       if (
//         Number.isFinite(formattedCenter.lat) &&
//         Number.isFinite(formattedCenter.lng)
//       ) {
//         console.log("Panning to:", formattedCenter);
//         map.panTo(formattedCenter);
//       } else {
//         console.error("Coordenadas inválidas:", formattedCenter);
//       }
//     }
//   }, [center, map]);

//   useEffect(() => {
//     if (map && activeAlert) {
//       const { lat, lng } = activeAlert.ubicacion;
//       if (Number.isFinite(lat) && Number.isFinite(lng)) {
//         map.panTo({ lat, lng });
//       } else {
//         console.error(
//           "Coordenadas inválidas en activeAlert:",
//           activeAlert.ubicacion
//         );
//       }
//     }
//   }, [activeAlert, map]);

//   return null;
// };

const animateMarker = (marker, toPosition, duration = 1000) => {
  console.log("Animating marker:", marker);

  // Obtener la posición actual del marcador
  const fromPosition = marker.position;

  // Verificar que 'fromPosition' y 'toPosition' existen
  if (!fromPosition || !toPosition) {
    console.error(
      "fromPosition o toPosition son undefined:",
      fromPosition,
      toPosition
    );
    return;
  }

  // Obtener latitudes y longitudes
  const fromLat =
    typeof fromPosition.lat === "function"
      ? fromPosition.lat()
      : fromPosition.lat;
  const fromLng =
    typeof fromPosition.lng === "function"
      ? fromPosition.lng()
      : fromPosition.lng;
  const toLat =
    typeof toPosition.lat === "function" ? toPosition.lat() : toPosition.lat;
  const toLng =
    typeof toPosition.lng === "function" ? toPosition.lng() : toPosition.lng;

  // Validar que todas las coordenadas sean números
  if (isNaN(fromLat) || isNaN(fromLng) || isNaN(toLat) || isNaN(toLng)) {
    console.error("Coordenadas inválidas:", { fromLat, fromLng, toLat, toLng });
    return;
  }

  let startTime = null;

  const animateStep = (currentTime) => {
    if (!startTime) {
      startTime = currentTime;
    }
    const progress = (currentTime - startTime) / duration;
    if (progress < 1) {
      const deltaLat = fromLat + (toLat - fromLat) * progress;
      const deltaLng = fromLng + (toLng - fromLng) * progress;

      // Crear una nueva instancia de LatLng con los valores interpolados
      const newPosition = new window.google.maps.LatLng(deltaLat, deltaLng);

      // Actualizar la posición del marcador
      marker.position = newPosition;

      requestAnimationFrame(animateStep);
    } else {
      // Asegurar que la posición final es exacta
      const finalPosition = new window.google.maps.LatLng(toLat, toLng);
      marker.position = finalPosition;
    }
  };

  requestAnimationFrame(animateStep);
};

const GoogleMapView = ({
  center,
  alerts,
  activeAlert,
  onClearActiveAlert,
  expandMap,
  onLoadComplete,
  viewMode,
  filters,
  allAlerts
}) => {
  const [userLocation, setUserLocation] = useState(null);
  const mapRef = useRef(null);
  const [selectedGeocercas, setSelectedGeocercas] = useState([]);
  const { geocercas } = useGeocercas();

  const GOOGLE_MAP_ID = "map-monitor";
  const [showTraffic, setShowTraffic] = useState(false);
  const trafficLayer = useRef(null);

  const [selectAllGeocercas, setSelectAllGeocercas] = useState(true);
  const [selectAllVehiculos, setSelectAllVehiculos] = useState(true);
  const [selectedVehiculos, setSelectedVehiculos] = useState([]);
  const [lastSelectedGeocerca, setLastSelectedGeocerca] = useState(null);
  const [vehiculos, setVehiculos] = useState([]);
  //const [activeAlert, setActiveAlert] = useState(null);

  const [prevValidGeocercas, setPrevValidGeocercas] = useState([]);
  const [isMapExpanded, setIsMapExpanded] = useState(false);

  const [selectedVehicleRoute, setSelectedVehicleRoute] = useState(null);
  const [vehicleRouteCoordinates, setVehicleRouteCoordinates] = useState([]);
  const [isRouteVisible, setIsRouteVisible] = useState(false);

  const [activeVehicleId, setActiveVehicleId] = useState(null);
  const [alertCounters, setAlertCounters] = useState({});
  const [alertDataForRoute, setAlertDataForRoute] = useState([]);

  
  // const [allAlerts, setAllAlerts] = useState([]);

 console.log("allAlerts en GoogleMapView:", allAlerts);

 const formatPhoneNumber = (phoneNumber) => {
  // Remover cualquier caracter que no sea número
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");

  // Verificar si tiene 10 dígitos
  if (cleaned.length === 10) {
    return `(${cleaned.slice(0, 3)})${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
  }

  // Si no tiene 10 dígitos, retornar el original o 'No disponible'
  return phoneNumber || "Sin número";
};


useEffect(() => {
  if (!mapRef.current || !activeAlert || !onClearActiveAlert) return;
  
  // Función para manejar el clic en el mapa
  const handleMapClick = () => {
    console.log("Clic en el mapa, cerrando overlay activo");
    onClearActiveAlert();
  };
  
  // Agregar el listener
  const listener = mapRef.current.addListener('click', handleMapClick);
  
  // Limpiar al desmontar o cuando cambian las dependencias
  return () => {
    if (listener) {
      window.google.maps.event.removeListener(listener);
    }
  };
}, [mapRef.current, activeAlert, onClearActiveAlert]);

//   const VehicleRoute = ({ coordinates, isVisible, alertData = [] }) => {
//     const map = useGoogleMap();
//     const polylineRef = useRef(null);
//     const markersRef = useRef([]);
    
//     // Efecto para crear/eliminar la polilínea y marcadores cuando cambian las props
//     useEffect(() => {
//       console.log("VehicleRoute effect - isVisible:", isVisible, "showMarkersAlways:", showMarkersAlways);
      
//       // Función para limpiar todos los elementos
//       const cleanup = () => {
//         // Limpiar polilínea
//         if (polylineRef.current) {
//           try {
//             polylineRef.current.setMap(null);
//           } catch (error) {
//             console.warn("Error limpiando polilínea:", error);
//           }
//           polylineRef.current = null;
//         }
        
//         // Limpiar marcadores
//         markersRef.current.forEach(marker => {
//           if (marker) {
//             try {
//               marker.setMap(null);
//             } catch (error) {
//               console.warn("Error limpiando marcador:", error);
//             }
//           }
//         });
//         markersRef.current = [];
//       };
      
//       // // Si no es visible o no hay datos válidos, limpiar y salir
//       // if (!isVisible || !map || !coordinates || coordinates.length < 2) {
//       //   cleanup();
//       //   return;
//       // }

//        // Si no hay datos válidos, limpiar y salir
//       if (!map || !coordinates || coordinates.length < 2) {
//         cleanup();
//         return;
//       }

//       // Limpiar primero cualquier contenido existente
//       cleanup();

//       // Agrupar puntos cercanos para evitar superposición
//       const groupedPoints = [];
//       const threshold = 0.0001; // Aproximadamente 2 metros en coordenadas
      
//       // Crear grupos de puntos cercanos con información adicional del orden cronológico
//       coordinates.forEach((coord, index) => {
//         const alertInfo = alertData[index];
//         if (!alertInfo) return;
        
//         // Buscar si hay un grupo existente al que este punto pueda pertenecer
//         let added = false;
//         for (const group of groupedPoints) {
//           const mainCoord = group.points[0].coord;
//           // Calcular distancia aproximada
//           if (Math.abs(coord.lat - mainCoord.lat) < threshold && 
//               Math.abs(coord.lng - mainCoord.lng) < threshold) {
//             // Es un punto cercano, añadir al grupo
//             group.points.push({
//               coord, 
//               alertInfo, 
//               index,
//               timestamp: new Date(alertInfo.FechaHora_Evento || alertInfo.fecha || 0).getTime()
//             });
//             added = true;
//             break;
//           }
//         }
        
//         // Si no se añadió a ningún grupo existente, crear un nuevo grupo
//         if (!added) {
//           groupedPoints.push({
//             points: [{
//               coord, 
//               alertInfo, 
//               index,
//               timestamp: new Date(alertInfo.FechaHora_Evento || alertInfo.fecha || 0).getTime()
//             }]
//           });
//         }
//       });

//       // Ordenar los puntos dentro de cada grupo por orden cronológico
//       groupedPoints.forEach(group => {
//         // Ordenar por timestamp (más antiguo primero)
//         group.points.sort((a, b) => a.timestamp - b.timestamp);
//       });

      
//       // Crear la polilínea
//       try {
//         // Limpiar primero cualquier polilínea existente
//         cleanup();
        
//         // Crear la nueva polilínea
//         const polyline = new window.google.maps.Polyline({
//           path: coordinates,
//           geodesic: true,
//           strokeColor: "#FF0000",
//           strokeOpacity: 0.5, // Más sutil
//           strokeWeight: 2,    // Más delgada
//           map: map,
//           icons: [{
//             icon: { 
//               path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
//               scale: 3, // Flecha más pequeña
//               fillColor: '#FF0000',
//               fillOpacity: 0.6,
//               strokeWeight: 1
//             },
//             offset: '100%',
//             repeat: '150px' // Menos frecuente
//           }]
//         });
        
//         polylineRef.current = polyline;
//         console.log("Polilínea creada con éxito");
        
//         // Crear marcadores para los puntos de la ruta con íconos de alerta
//         // coordinates.forEach((coord, index) => {
//         //   // Buscar los datos de alerta correspondientes a esta coordenada
//         //   const alertInfo = alertData[index];
//         //   if (!alertInfo) return; // Si no hay datos de alerta para esta coordenada, no crear marcador
          
//         //   const alertType = alertInfo.alert_type;
//         //   const isLastPoint = index === coordinates.length - 1;
          
//         //   // Crear contenedor para el marcador
//         //   const markerDiv = document.createElement('div');
//         //   markerDiv.className = 'route-point-marker';
//         //   markerDiv.style.position = 'relative';
          
//         //   // Verificar si tenemos un ícono para este tipo de alerta
//         //   const iconUrl = getAlertIcon(alertType);
          
//         //   if (iconUrl) {
//         //     // Crear un marcador con el ícono de la alerta
//         //     markerDiv.innerHTML = `
//         //       <div style="
//         //         position: relative;
//         //         display: flex;
//         //         justify-content: center;
//         //         align-items: center;
//         //       ">
//         //         <div style="
//         //           width: 24px;
//         //           height: 24px;
//         //           border-radius: 50%;
//         //           background-color: white;
//         //           display: flex;
//         //           justify-content: center;
//         //           align-items: center;
//         //           box-shadow: 0 2px 4px rgba(0,0,0,0.3);
//         //           border: 2px solid ${isLastPoint ? '#FF0000' : 'rgba(255,0,0,0.6)'};
//         //           overflow: hidden;
//         //         ">
//         //           <img 
//         //             src="${iconUrl}" 
//         //             alt="${alertType}"
//         //             style="
//         //               width: 16px;
//         //               height: 16px;
//         //               object-fit: contain;
//         //             "
//         //           />
//         //         </div>
//         //       </div>
//         //     `;
//         //   } else {
//         //     // Marcador por defecto si no hay ícono
//         //     markerDiv.innerHTML = `
//         //       <div style="
//         //         width: ${isLastPoint ? '14px' : '10px'};
//         //         height: ${isLastPoint ? '14px' : '10px'};
//         //         background-color: ${isLastPoint ? '#FF0000' : 'rgba(255,0,0,0.6)'};
//         //         border: 2px solid white;
//         //         border-radius: 50%;
//         //         box-shadow: 0 2px 4px rgba(0,0,0,0.3);
//         //       "></div>
//         //     `;
//         //   }
          
//         //   // Crear un tooltip con información sobre la alerta
//         //   const tooltipContent = alertInfo.alerta || alertInfo.nombreAlerta || alertType;
          
//         //   // Crear el marcador con la información de tooltip
//         //   const marker = new window.google.maps.marker.AdvancedMarkerElement({
//         //     position: coord,
//         //     map: map,
//         //     content: markerDiv,
//         //     zIndex: isLastPoint ? 2 : 1,
//         //     title: tooltipContent // Esto añade un tooltip básico
//         //   });
          
//         //   // Añadir evento para mostrar información al hacer hover
//         //   if (window.google.maps.event && marker.addListener) {
            
//         //     marker.addListener('click', () => {
//         //       // Obtener más detalles para el InfoWindow
//         //       const alertDetails = alertInfo || {};
//         //       const fecha = alertDetails.fecha || alertDetails.FechaHora_Evento ? 
//         //         new Date(alertDetails.FechaHora_Evento).toLocaleString('es-MX') : 'Sin fecha';
//         //       const unidad = alertDetails.unidad || 'Sin unidad';
//         //       const alertName = ALERT_TYPE_NAMES[alertInfo.alert_type] || alertInfo.alerta || alertInfo.alert_type || 'Alerta';
//         //       const velocidad = alertDetails.adicional?.velocidad || alertDetails.velocidad || '';
//         //       const direccion = alertDetails.ubicacion?.direccion || '';
              
//         //       // Crear un InfoWindow más informativo
//         //       const infoWindow = new window.google.maps.InfoWindow({
//         //         content: `
//         //           <div style="padding: 12px; max-width: 250px; font-family: Arial, sans-serif;">
//         //             <div style="margin-bottom: 8px; border-bottom: 1px solid #eee; padding-bottom: 5px;">
//         //               <strong style="font-size: 14px; color: #D32F2F;">${alertName}</strong>
//         //             </div>
                    
//         //             <div style="display: flex; flex-direction: column; gap: 4px;">
//         //               <div><strong>Unidad:</strong> ${unidad}</div>
//         //               <div><strong>Fecha:</strong> ${fecha}</div>
//         //               ${velocidad ? `<div><strong>Velocidad:</strong> ${velocidad} km/h</div>` : ''}
//         //               ${direccion ? `<div><strong>Ubicación:</strong> ${direccion}</div>` : ''}
//         //             </div>
//         //           </div>
//         //         `,
//         //         maxWidth: 300,
//         //         pixelOffset: new window.google.maps.Size(0, -10)
//         //       });
              
//         //       infoWindow.open(map, marker);
              
//         //       // Cerrar otros InfoWindows abiertos
//         //       if (window.activeInfoWindow) {
//         //         window.activeInfoWindow.close();
//         //       }
//         //       window.activeInfoWindow = infoWindow;
//         //     });

//         //     // marker.addListener('click', () => {
//         //     //   const infoWindow = new window.google.maps.InfoWindow({
//         //     //     content: `
//         //     //       <div style="padding: 8px; max-width: 200px;">
//         //     //         <strong>${tooltipContent}</strong>
//         //     //         <br>
//         //     //         <span style="font-size: 12px;">${alertInfo.fecha || ''}</span>
//         //     //       </div>
//         //     //     `
//         //     //   });
//         //     //   infoWindow.open(map, marker);
//         //     // });
//         //   }
          
//         //   markersRef.current.push(marker);
//         // });

//         groupedPoints.forEach(group => {
//           const pointsCount = group.points.length;
          
//           group.points.forEach((point, groupIndex) => {
//             const { coord, alertInfo, index } = point;
//             const isLastPoint = index === coordinates.length - 1;
//             const alertType = alertInfo.alert_type;
            
//             // Calcular desplazamiento si hay más de un punto en el grupo
//             let adjustedCoord = {...coord};
//             if (pointsCount > 1) {
//               // Crear un patrón circular alrededor del punto principal
//               const angle = (360 / pointsCount) * groupIndex * (Math.PI / 180);
//               const offsetDistance = 0.00008; // Aproximadamente 8 metros
//               adjustedCoord = {
//                 lat: coord.lat + Math.sin(angle) * offsetDistance,
//                 lng: coord.lng + Math.cos(angle) * offsetDistance
//               };
//             }
            
//             // Crear contenedor para el marcador
//             const markerDiv = document.createElement('div');
//             markerDiv.className = 'route-point-marker';
//             markerDiv.style.position = 'relative';
            
//             // Verificar si tenemos un ícono para este tipo de alerta
//             const iconUrl = getAlertIcon(alertType);
            
//             if (iconUrl) {
//               // Crear un marcador con el ícono de la alerta
//               markerDiv.innerHTML = `
//                 <div style="
//                   position: relative;
//                   display: flex;
//                   justify-content: center;
//                   align-items: center;
//                 ">
//                   <div style="
//                     width: 28px;
//                     height: 28px;
//                     border-radius: 50%;
//                     background-color: white;
//                     display: flex;
//                     justify-content: center;
//                     align-items: center;
//                     box-shadow: 0 2px 4px rgba(0,0,0,0.3);
//                     border: 2px solid ${isLastPoint ? '#FF0000' : 'rgba(255,0,0,0.6)'};
//                     overflow: hidden;
//                   ">
//                     <img 
//                       src="${iconUrl}" 
//                       alt="${alertType}"
//                       style="
//                         width: 18px;
//                         height: 18px;
//                         object-fit: contain;
//                       "
//                     />
//                   </div>
//                   ${pointsCount > 1 ? ''
//                     // `
//                     // <div style="
//                     //   position: absolute;
//                     //   top: -5px;
//                     //   right: -5px;
//                     //   background-color: #FF0000;
//                     //   color: white;
//                     //   border-radius: 50%;
//                     //   width: ${pointsCount > 9 ? '20px' : '16px'};
//                     //   height: 16px;
//                     //   display: flex;
//                     //   justify-content: center;
//                     //   align-items: center;
//                     //   font-size: 10px;
//                     //   font-weight: bold;
//                     //   border: 1px solid white;
//                     // ">${groupIndex + 1}/${pointsCount}</div>
//                   //` 
//                   : ''}
//                 </div>
//               `;
//             } else {
//               // Marcador por defecto si no hay ícono
//               markerDiv.innerHTML = `
//               <div style="
//                 position: relative;
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//               ">
//                 <div style="
//                   width: ${isLastPoint ? '14px' : '10px'};
//                   height: ${isLastPoint ? '14px' : '10px'};
//                   background-color: ${isLastPoint ? '#FF0000' : 'rgba(255,0,0,0.6)'};
//                   border: 2px solid white;
//                   border-radius: 50%;
//                   box-shadow: 0 2px 4px rgba(0,0,0,0.3);
//                 "></div>
//                 ${pointsCount > 1 ? ''
//                 // `
//                 //   <div style="
//                 //     position: absolute;
//                 //     top: -5px;
//                 //     right: -5px;
//                 //     background-color: #FF0000;
//                 //     color: white;
//                 //     border-radius: 50%;
//                 //     width: ${pointsCount > 9 ? '20px' : '16px'};
//                 //     height: 16px;
//                 //     display: flex;
//                 //     justify-content: center;
//                 //     align-items: center;
//                 //     font-size: 10px;
//                 //     font-weight: bold;
//                 //     border: 1px solid white;
//                 //   ">${groupIndex + 1}/${pointsCount}</div>
//                 // `
//                  : ''}
//               </div>
//             `;
//             }
            
//             // Crear el marcador con la posición ajustada
//             const marker = new window.google.maps.marker.AdvancedMarkerElement({
//               position: adjustedCoord,
//               map: map,
//               content: markerDiv,
//               zIndex: isLastPoint ? 2 : 1,
//               title: `${alertInfo.alerta || alertInfo.nombreAlerta || alertType} (${groupIndex + 1}/${pointsCount}) - ${new Date(alertInfo.FechaHora_Evento || alertInfo.fecha || 0).toLocaleTimeString()}`            });
            
//             // Mejorar el evento click con más información
//             if (window.google.maps.event && marker.addListener) {
//              // Modificar la implementación del InfoWindow en el VehicleRoute

// marker.addListener('click', () => {
//   // Obtener más detalles para el InfoWindow
//   const alertDetails = alertInfo || {};
//   const fecha = alertDetails.fecha || alertDetails.FechaHora_Evento ? 
//     new Date(alertDetails.FechaHora_Evento).toLocaleString('es-MX') : 'Sin fecha';
//   const unidad = alertDetails.unidad || 'Sin unidad';
//   const alertName = ALERT_TYPE_NAMES[alertInfo.alert_type] || alertInfo.alerta || alertInfo.alert_type || 'Alerta';
//   const nombreAlerta = alertDetails.nombreAlerta || '';
//   const velocidad = alertDetails.adicional?.velocidad || alertDetails.velocidad || '';
//   const direccion = alertDetails.ubicacion?.direccion || '';
//   const chofer = alertDetails.chofer || 'Sin chofer';
//   const telefono = alertDetails.telefono ? formatPhoneNumber(alertDetails.telefono) : 'Sin número';
//   const mensaje = alertDetails.adicional?.mensaje || 'Sin mensaje';
  
//   // Determinar el color de la barra según el estado
//   let statusColor = '#4CAF50'; // verde por defecto
//   if (alertDetails.estado) {
//     switch (alertDetails.estado.toLowerCase()) {
//       case "rojo":
//         statusColor = '#ff4444';
//         break;
//       case "amarillo":
//         statusColor = '#ff9800';
//         break;
//       default:
//         statusColor = '#4CAF50';
//     }
//   }
  
//   // Crear un InfoWindow con el formato de AlertList
//   const infoWindow = new window.google.maps.InfoWindow({
//     content: `
//       <div style="font-family: 'Roboto', Arial, sans-serif; border-radius: 8px; overflow: hidden; width: 280px;">
//         <!-- Barra superior con hora y nombre del vehículo -->
//         <div style="background-color: ${statusColor}; padding: 6px 10px; display: flex; justify-content: space-between; align-items: center;">
//           <span style="color: white; font-size: 12px;">${fecha.split(' ')[1] || ''}</span>
//           <span style="color: white; font-size: 12px; font-weight: 500; margin-right: 20px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 120px;">${unidad}</span>
//         </div>
        
//         <!-- Contenido principal -->
//         <div style="padding: 10px; background-color: white;">
//           <!-- Tipo de alerta con icono -->
//           <div style="display: flex; align-items: center; width: 100%;">
//             <div style="display: flex; justify-content: center; align-items: center; margin-right: 5px;">
//               <img src="${getAlertIcon(alertInfo.alert_type)}" style="width: 15px; height: 15px;" />
//             </div>
//             <span style="font-size: 12px; color: #333;">${alertName}</span>
//           </div>
          
//           <!-- Nombre de la alerta con sangría -->
//           <div style="display: flex; align-items: center; justify-content: flex-start; width: 100%; margin-top: 4px;">
//             <span style="font-size: 12px; color: #333; padding: 0; margin: 0; margin-left: 20px;">${nombreAlerta}</span>
//           </div>
          
//           <!-- Información de chofer y teléfono -->
//           <div style="display: flex; align-items: center; justify-content: space-between; width: 100%; margin-top: 8px; border-bottom: 1px solid #e0e0e0; padding-bottom: 8px;">
//             <div style="display: flex; flex-direction: column; align-items: flex-start;">
//               <span style="font-size: 12px; color: #333;">${chofer}</span>
//             </div>
//             <div style="display: flex; flex-direction: column; align-items: flex-start;">
//               <span style="font-size: 12px; color: #333;">${telefono}</span>
//             </div>
            
//           </div>
          
//           <!-- Mensaje de alerta -->
//           <div style="display: flex; align-items: flex-start; margin-top: 8px; width: 100%;">
//             <div style="display: flex; align-items: center; margin-right: 5px;">
//               <img src="${WarningIcon || "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'%3E%3Cpath d='M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z'/%3E%3Cpath d='M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z'/%3E%3C/svg%3E"}" style="width: 15px; height: 15px;" />
//             </div>
//             <span style="font-size: 12px; color: #333; flex: 1; word-break: break-word;">
//               ${mensaje}
//             </span>
//           </div>
          
//           <!-- Ubicación con icono -->
//           <div style="display: flex; align-items: flex-start; margin-top: 8px; width: 100%;">
//             <div style="display: flex; align-items: center; margin-right: 5px;">
//               <img src="${LocationIcon || "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'%3E%3Cpath d='M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z'/%3E%3C/svg%3E"}" style="width: 15px; height: 15px;" />
//             </div>
//             <span style="font-size: 12px; color: #333; flex: 1; word-break: break-word;">
//               ${direccion}
//             </span>
//           </div>
          
         
//         </div>
//       </div>
//     `,
//     maxWidth: 300,
//     pixelOffset: new window.google.maps.Size(0, -10)
//   });
  
//   infoWindow.open(map, marker);
  
//   // Cerrar otros InfoWindows abiertos
//   if (window.activeInfoWindow) {
//     window.activeInfoWindow.close();
//   }
//   window.activeInfoWindow = infoWindow;
// });
//             }
            
//             markersRef.current.push(marker);
            
//             // Si hay múltiples puntos en un grupo, dibujar líneas punteadas hacia la posición real
//             if (pointsCount > 1) {
//               const connectorLine = new window.google.maps.Polyline({
//                 path: [adjustedCoord, coord],
//                 geodesic: true,
//                 strokeColor: "#666666",
//                 strokeOpacity: 0.5,
//                 strokeWeight: 1,
//                 map: map,
//                 strokePattern: [
//                   { offset: '0', repeat: '10px', symbol: { path: 'M 0,-1 0,1', strokeOpacity: 1, scale: 2 } }
//                 ]
//               });
//               markersRef.current.push(connectorLine);
//             }
//           });
//         });


//       } catch (error) {
//         console.error("Error creando polilínea o marcadores:", error);
//       }
      
//       // Limpiar al desmontar o cuando cambien las props
//       return cleanup;
//     }, [coordinates, isVisible, map, alertData]);
    
//     // No renderizar nada con React, todo se hace directamente con la API de Maps
//     return null;
//   };

const VehicleRoute = ({ coordinates, isVisible, alertData = [], showMarkersAlways = true }) => {
  const map = useGoogleMap();
  const polylineRef = useRef(null);
  const markersRef = useRef([]);
  
  // Efecto para crear/eliminar la polilínea y marcadores cuando cambian las props
  useEffect(() => {
    console.log("VehicleRoute effect - isVisible:", isVisible, "showMarkersAlways:", showMarkersAlways);
    
    // Función para limpiar todos los elementos
    const cleanup = () => {
      // Limpiar polilínea
      if (polylineRef.current) {
        try {
          polylineRef.current.setMap(null);
        } catch (error) {
          console.warn("Error limpiando polilínea:", error);
        }
        polylineRef.current = null;
      }
      
      // Limpiar marcadores
      markersRef.current.forEach(marker => {
        if (marker) {
          try {
            marker.setMap(null);
          } catch (error) {
            console.warn("Error limpiando marcador:", error);
          }
        }
      });
      markersRef.current = [];
    };
    
    // Si no hay datos válidos o mapa, limpiar y salir
    if (!map || !coordinates || coordinates.length < 2) {
      cleanup();
      return;
    }

    // Limpiar primero cualquier contenido existente
    cleanup();

    // Agrupar puntos cercanos para evitar superposición
    const groupedPoints = [];
    const threshold = 0.0001; // Aproximadamente 2 metros en coordenadas
    
    // Crear grupos de puntos cercanos con información adicional del orden cronológico
    coordinates.forEach((coord, index) => {
      const alertInfo = alertData[index];
      if (!alertInfo) return;
      
      // Buscar si hay un grupo existente al que este punto pueda pertenecer
      let added = false;
      for (const group of groupedPoints) {
        const mainCoord = group.points[0].coord;
        // Calcular distancia aproximada
        if (Math.abs(coord.lat - mainCoord.lat) < threshold && 
            Math.abs(coord.lng - mainCoord.lng) < threshold) {
          // Es un punto cercano, añadir al grupo
          group.points.push({
            coord, 
            alertInfo, 
            index,
            timestamp: new Date(alertInfo.FechaHora_Evento || alertInfo.fecha || 0).getTime()
          });
          added = true;
          break;
        }
      }
      
      // Si no se añadió a ningún grupo existente, crear un nuevo grupo
      if (!added) {
        groupedPoints.push({
          points: [{
            coord, 
            alertInfo, 
            index,
            timestamp: new Date(alertInfo.FechaHora_Evento || alertInfo.fecha || 0).getTime()
          }]
        });
      }
    });

    // Ordenar los puntos dentro de cada grupo por orden cronológico
    groupedPoints.forEach(group => {
      // Ordenar por timestamp (más antiguo primero)
      group.points.sort((a, b) => a.timestamp - b.timestamp);
    });

    // PASO 1: Crear los marcadores SIEMPRE si showMarkersAlways es true
    // o SOLO si isVisible es true en caso contrario
    if (showMarkersAlways || isVisible) {
      groupedPoints.forEach(group => {
        const pointsCount = group.points.length;
        
        group.points.forEach((point, groupIndex) => {
          const { coord, alertInfo, index } = point;
          const isLastPoint = index === coordinates.length - 1;
          const alertType = alertInfo.alert_type;
          
          // Calcular desplazamiento si hay más de un punto en el grupo
          let adjustedCoord = {...coord};
          if (pointsCount > 1) {
            // Crear un patrón circular alrededor del punto principal
            const angle = (360 / pointsCount) * groupIndex * (Math.PI / 180);
            const offsetDistance = 0.00008; // Aproximadamente 8 metros
            adjustedCoord = {
              lat: coord.lat + Math.sin(angle) * offsetDistance,
              lng: coord.lng + Math.cos(angle) * offsetDistance
            };
          }
          
          // Crear contenedor para el marcador
          const markerDiv = document.createElement('div');
          markerDiv.className = 'route-point-marker';
          markerDiv.style.position = 'relative';
          
          // Verificar si tenemos un ícono para este tipo de alerta
          const iconUrl = getAlertIcon(alertType);
          
          if (iconUrl) {
            // Crear un marcador con el ícono de la alerta
            markerDiv.innerHTML = `
              <div style="
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
              ">
                <div style="
                  width: 28px;
                  height: 28px;
                  border-radius: 50%;
                  background-color: white;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
                  border: 2px solid ${isLastPoint ? '#FF0000' : 'rgba(255,0,0,0.6)'};
                  overflow: hidden;
                ">
                  <img 
                    src="${iconUrl}" 
                    alt="${alertType}"
                    style="
                      width: 18px;
                      height: 18px;
                      object-fit: contain;
                    "
                  />
                </div>
              </div>
            `;
          } else {
            // Marcador por defecto si no hay ícono
            markerDiv.innerHTML = `
              <div style="
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
              ">
                <div style="
                  width: ${isLastPoint ? '14px' : '10px'};
                  height: ${isLastPoint ? '14px' : '10px'};
                  background-color: ${isLastPoint ? '#FF0000' : 'rgba(255,0,0,0.6)'};
                  border: 2px solid white;
                  border-radius: 50%;
                  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
                "></div>
              </div>
            `;
          }
          
          // Crear el marcador con la posición ajustada
          const marker = new window.google.maps.marker.AdvancedMarkerElement({
            position: adjustedCoord,
            map: map,
            content: markerDiv,
            zIndex: isLastPoint ? 2 : 1,
            title: `${alertInfo.alerta || alertInfo.nombreAlerta || alertType} (${groupIndex + 1}/${pointsCount}) - ${new Date(alertInfo.FechaHora_Evento || alertInfo.fecha || 0).toLocaleTimeString()}`
          });
          
          // Mejorar el evento click con más información
          if (window.google.maps.event && marker.addListener) {
            marker.addListener('click', () => {
              // Obtener más detalles para el InfoWindow
              const alertDetails = alertInfo || {};
              const fecha = alertDetails.fecha || alertDetails.FechaHora_Evento ? 
                new Date(alertDetails.FechaHora_Evento).toLocaleString('es-MX') : 'Sin fecha';
              const unidad = alertDetails.unidad || 'Sin unidad';
              const alertName = ALERT_TYPE_NAMES[alertInfo.alert_type] || alertInfo.alerta || alertInfo.alert_type || 'Alerta';
              const nombreAlerta = alertDetails.nombreAlerta || '';
              const velocidad = alertDetails.adicional?.velocidad || alertDetails.velocidad || '';
              const direccion = alertDetails.ubicacion?.direccion || '';
              const chofer = alertDetails.chofer || 'Sin chofer';
              const telefono = alertDetails.telefono ? formatPhoneNumber(alertDetails.telefono) : 'Sin número';
              const mensaje = alertDetails.adicional?.mensaje || 'Sin mensaje';
              
              // Determinar el color de la barra según el estado
              let statusColor = '#4CAF50'; // verde por defecto
              if (alertDetails.estado) {
                switch (alertDetails.estado.toLowerCase()) {
                  case "rojo":
                    statusColor = '#ff4444';
                    break;
                  case "amarillo":
                    statusColor = '#ff9800';
                    break;
                  default:
                    statusColor = '#4CAF50';
                }
              }
              
              // Crear un InfoWindow con el formato de AlertList
              const infoWindow = new window.google.maps.InfoWindow({
                content: `
                  <div style="font-family: 'Roboto', Arial, sans-serif; border-radius: 8px; overflow: hidden; width: 280px;">
                    <!-- Barra superior con hora y nombre del vehículo -->
                    <div style="background-color: ${statusColor}; padding: 6px 10px; display: flex; justify-content: space-between; align-items: center;">
                      <span style="color: white; font-size: 12px;">${fecha.split(' ')[1] || ''}</span>
                      <span style="color: white; font-size: 12px; font-weight: 500; margin-right: 20px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 120px;">${unidad}</span>
                    </div>
                    
                    <!-- Contenido principal -->
                    <div style="padding: 10px; background-color: white;">
                      <!-- Tipo de alerta con icono -->
                      <div style="display: flex; align-items: center; width: 100%;">
                        <div style="display: flex; justify-content: center; align-items: center; margin-right: 5px;">
                          <img src="${getAlertIcon(alertInfo.alert_type)}" style="width: 15px; height: 15px;" />
                        </div>
                        <span style="font-size: 12px; color: #333;">${alertName}</span>
                      </div>
                      
                      <!-- Nombre de la alerta con sangría -->
                      <div style="display: flex; align-items: center; justify-content: flex-start; width: 100%; margin-top: 4px;">
                        <span style="font-size: 12px; color: #333; padding: 0; margin: 0; margin-left: 20px;">${nombreAlerta}</span>
                      </div>
                      
                      <!-- Información de chofer y teléfono -->
                      <div style="display: flex; align-items: center; justify-content: space-between; width: 100%; margin-top: 8px; border-bottom: 1px solid #e0e0e0; padding-bottom: 8px;">
                        <div style="display: flex; flex-direction: column; align-items: flex-start;">
                          <span style="font-size: 12px; color: #333;">${chofer}</span>
                        </div>
                        <div style="display: flex; flex-direction: column; align-items: flex-start;">
                          <span style="font-size: 12px; color: #333;">${telefono}</span>
                        </div>
                      </div>
                      
                      <!-- Mensaje de alerta -->
                      <div style="display: flex; align-items: flex-start; margin-top: 8px; width: 100%;">
                        <div style="display: flex; align-items: center; margin-right: 5px;">
                          <img src="${WarningIcon || "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'%3E%3Cpath d='M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z'/%3E%3Cpath d='M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z'/%3E%3C/svg%3E"}" style="width: 15px; height: 15px;" />
                        </div>
                        <span style="font-size: 12px; color: #333; flex: 1; word-break: break-word;">
                          ${mensaje}
                        </span>
                      </div>
                      
                      <!-- Ubicación con icono -->
                      <div style="display: flex; align-items: flex-start; margin-top: 8px; width: 100%;">
                        <div style="display: flex; align-items: center; margin-right: 5px;">
                          <img src="${LocationIcon || "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'%3E%3Cpath d='M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z'/%3E%3C/svg%3E"}" style="width: 15px; height: 15px;" />
                        </div>
                        <span style="font-size: 12px; color: #333; flex: 1; word-break: break-word;">
                          ${direccion}
                        </span>
                      </div>
                    </div>
                  </div>
                `,
                maxWidth: 300,
                pixelOffset: new window.google.maps.Size(0, -10)
              });
              
              infoWindow.open(map, marker);
              
              // Cerrar otros InfoWindows abiertos
              if (window.activeInfoWindow) {
                window.activeInfoWindow.close();
              }
              window.activeInfoWindow = infoWindow;
            });
          }
          
          markersRef.current.push(marker);
          
          // Si hay múltiples puntos en un grupo, dibujar líneas punteadas hacia la posición real
          if (pointsCount > 1) {
            const connectorLine = new window.google.maps.Polyline({
              path: [adjustedCoord, coord],
              geodesic: true,
              strokeColor: "#666666",
              strokeOpacity: 0.5,
              strokeWeight: 1,
              map: map,
              strokePattern: [
                { offset: '0', repeat: '10px', symbol: { path: 'M 0,-1 0,1', strokeOpacity: 1, scale: 2 } }
              ]
            });
            markersRef.current.push(connectorLine);
          }
        });
      });
    }
    
    // PASO 2: Crear la polilínea SÓLO si isVisible es true
    if (isVisible) {
      try {
        const polyline = new window.google.maps.Polyline({
          path: coordinates,
          geodesic: true,
          strokeColor: "#FF0000",
          strokeOpacity: 0.5, // Más sutil
          strokeWeight: 2,    // Más delgada
          map: map,
          icons: [{
            icon: { 
              path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              scale: 3, // Flecha más pequeña
              fillColor: '#FF0000',
              fillOpacity: 0.6,
              strokeWeight: 1
            },
            offset: '100%',
            repeat: '150px' // Menos frecuente
          }]
        });
        
        polylineRef.current = polyline;
        console.log("Polilínea creada con éxito");
      } catch (error) {
        console.error("Error creando polilínea:", error);
      }
    }
    
    // Limpiar al desmontar o cuando cambien las props
    return cleanup;
  }, [coordinates, isVisible, showMarkersAlways, map, alertData]);
  
  // No renderizar nada con React, todo se hace directamente con la API de Maps
  return null;
};


useEffect(() => {
  // Función para manejar la limpieza de rutas
  const handleClearRouteFromEvent = (event) => {
    console.log("Limpiando ruta desde evento externo");
    
    // Usar nuestra función interna que sabemos que funciona
    // Ocultar primero la ruta para que el efecto en VehicleRoute limpie la polilínea
    setIsRouteVisible(false);
    
    // Limpiar todos los estados relacionados con la ruta
    setActiveVehicleId(null);
    setAlertCounters({});
    setVehicleRouteCoordinates([]);
    setSelectedVehicleRoute(null);
    setAlertDataForRoute([]);
  };
  
  // Registrar el listener
  window.addEventListener('clearMapRoute', handleClearRouteFromEvent);
  
  // Limpiar al desmontar el componente
  return () => {
    window.removeEventListener('clearMapRoute', handleClearRouteFromEvent);
  };
}, [setIsRouteVisible, setActiveVehicleId, setAlertCounters, setVehicleRouteCoordinates, setSelectedVehicleRoute, setAlertDataForRoute]);


  const handleClearRoute = () => {
    console.log("Ejecutando handleClearRoute");
    
    // Establecer primero isRouteVisible en false para que el efecto en VehicleRoute
    // se active y limpie la polilínea
    setIsRouteVisible(false);
    
    // Usar un timeout corto para asegurar que los estados se actualicen en el orden correcto
    setTimeout(() => {
      // Limpiar otros estados relacionados
      setActiveVehicleId(null);
      setAlertCounters({});
      setVehicleRouteCoordinates([]);
      setSelectedVehicleRoute(null);
      setAlertDataForRoute([]);
      // Forzar una actualización del mapa
      if (mapRef.current) {
        try {
          // Esta es una técnica que fuerza al mapa a actualizarse
          const currentCenter = mapRef.current.getCenter();
          const currentZoom = mapRef.current.getZoom();
          window.google.maps.event.trigger(mapRef.current, 'resize');
          mapRef.current.setCenter(currentCenter);
          mapRef.current.setZoom(currentZoom);
        } catch (error) {
          console.warn("Error al actualizar el mapa:", error);
        }
      }
      
      console.log("Ruta limpiada completamente");
    }, 100);
  };

  const toggleRouteVisibility = () => {
    // Solo cambiar el estado si tenemos un vehículo seleccionado y coordenadas para la ruta
    if (selectedVehicleRoute && vehicleRouteCoordinates.length > 0) {
      console.log("Cambiando visibilidad de la ruta a:", !isRouteVisible);
      setIsRouteVisible(prev => !prev);
      
      // Si estamos haciendo visible la ruta, asegurar que el mapa muestre todos los puntos
      if (!isRouteVisible && mapRef.current) {
        const bounds = new window.google.maps.LatLngBounds();
        vehicleRouteCoordinates.forEach(coord => bounds.extend(coord));
        
        // Agregar padding a los límites
        mapRef.current.fitBounds(bounds, {
          padding: { top: 50, right: 50, bottom: 50, left: 50 }
        });
      }
    } else {
      console.log("No hay ruta disponible para mostrar");
    }
  };
  
  const ALERT_TYPE_NAMES = {
    // Mapeo según los datos proporcionados
    '2001': 'Entrada de geocerca',
    '2002': 'Salida de geocerca',
    '2003': 'Detenido',
    '2004': 'Botón de pánico',
    '2005': 'Exceso de velocidad',
    '2007': 'Encendido de motor',
    '2008': 'Apagado de motor',
    '2011': 'Acercamiento',
    '62': 'Desconexión de energía',
    '5001': 'Equipo fuera de línea',
    // Añadir otros mapeos según sea necesario
  };
  
  // Puedes también añadir esta función de utilidad para obtener el nombre
  const getAlertTypeName = (alertType) => {
    return ALERT_TYPE_NAMES[alertType] || alertType;
  };

  // Crear componente para mostrar contadores de alertas
  const AlertCountersPanel = ({ counters, activeVehicleId, onClear, alerts }) => {

     const alertTypeToName = useMemo(() => {
    const mapping = {};
    
    if (activeVehicleId) {
      // Filtrar alertas del vehículo seleccionado
      const vehicleAlerts = alerts.filter(a => a.UID_Vehiculo === activeVehicleId);
      
      // Construir el mapeo de tipo_alerta a nombre_alerta
      vehicleAlerts.forEach(alert => {
        if (alert.alert_type) {
          // Usar el nombre predefinido o el de la alerta si está disponible
          mapping[alert.alert_type] = ALERT_TYPE_NAMES[alert.alert_type] || alert.alerta || alert.alert_type;
        }
      });
    }
    
    return mapping;
  }, [activeVehicleId, alerts]);


    if (!activeVehicleId || Object.keys(counters).length === 0) return null;
    
    // Encontrar el nombre de la unidad
    const activeVehicle = alerts.find(a => a.UID_Vehiculo === activeVehicleId);
    const vehicleName = activeVehicle?.unidad || "Vehículo";
    const totalAlertas = Object.values(counters).reduce((a, b) => a + b, 0);
    
    return (
      <Box
        sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          backgroundColor: 'rgba(255, 255, 255, 0.45)', // Más transparente (45% en lugar de 75%)
          backdropFilter: 'blur(3px)', // Menos blur para ver mejor el fondo
          borderRadius: '24px',
          padding: '6px 12px',
          boxShadow: '0 2px 8px rgba(0,0,0,0.1)', // Sombra más sutil
          zIndex: 1000,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          maxWidth: '100%',
          overflow: 'hidden',
          border: '1px solid rgba(255, 255, 255, 0.3)' // Borde sutil para mejor definición
        }}
      >
        <Typography 
          variant="subtitle2" 
          sx={{ 
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            mr: 1,
            color: 'rgba(0, 0, 0, 0.87)' // Text color más oscuro para mantener contraste
          }}
        >
          {vehicleName}: {totalAlertas}
        </Typography>
        
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          flexWrap: 'nowrap',
          gap: 0.5,
          overflow: 'auto',
          '&::-webkit-scrollbar': { display: 'none' },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          flex: 1,
          maxWidth: '60vw'
        }}>
          {Object.entries(counters).map(([alertType, count]) => {
          // Obtener el nombre del tipo de alerta del mapeo o usar directamente el tipo
          const alertName = alertTypeToName[alertType] || getAlertTypeName(alertType) || alertType;
          
          return (
            <Tooltip 
              key={alertType} 
              title={alertName}
            >
              <Box 
                sx={{
                  display: 'flex', 
                  alignItems: 'center', 
                  backgroundColor: 'rgba(224, 224, 224, 0.6)', // Más transparente
                  borderRadius: '16px',
                  padding: '2px 6px',
                  minWidth: 'auto',
                  flexShrink: 0
                }}
              >
                <img 
                  src={getAlertIcon(alertType)} 
                  alt={alertName}
                  style={{ width: 16, height: 16, marginRight: 2 }}
                />
                <Typography 
                  variant="caption" 
                  sx={{ 
                    fontWeight: 'bold',  
                    color: 'primary.main'
                  }}
                >
                  {count}
                </Typography>
              </Box>
            </Tooltip>
          );
        })}
        </Box>
{/* 
        <Tooltip title={isRouteVisible ? "Ocultar ruta" : "Mostrar ruta"}>
        <IconButton 
          size="small" 
          onClick={toggleRouteVisibility}
          sx={{
            backgroundColor: isRouteVisible ? 'rgba(66, 133, 244, 0.2)' : 'rgba(255, 255, 255, 0.2)',
            '&:hover': { 
              backgroundColor: isRouteVisible ? 'rgba(66, 133, 244, 0.3)' : 'rgba(66, 133, 244, 0.15)'
            },
            ml: 0.5,
            padding: '4px',
            flexShrink: 0,
            border: `1px solid ${isRouteVisible ? 'rgba(66, 133, 244, 0.5)' : 'rgba(66, 133, 244, 0.3)'}`
          }}
        >
          <TimelineIcon fontSize="small" sx={{ color: 'rgba(66, 133, 244, 0.8)' }} />
        </IconButton>
      </Tooltip> */}
      
        
        <Tooltip title="Limpiar ruta y contadores">
          <IconButton 
            size="small" 
            onClick={onClear}
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.2)', // Casi transparente
              '&:hover': { 
                backgroundColor: 'rgba(244, 67, 54, 0.15)' // Rojo muy sutil al hover
              },
              ml: 0.5,
              padding: '4px',
              flexShrink: 0,
              border: '1px solid rgba(244, 67, 54, 0.3)' // Borde rojo sutil
            }}
          >
            <ClearIcon fontSize="small" sx={{ color: 'rgba(244, 67, 54, 0.8)' }} /> {/* Icono rojo semi-transparente */}
          </IconButton>
        </Tooltip>
      </Box>
    );
  };


  //console.log('GoogleMapView center:', center);
  const formattedCenter = Array.isArray(center)
    ? { lat: center[0], lng: center[1] }
    : center;

  console.log("GoogleMapView center:", formattedCenter);

  // Reemplazar el useEffect actual para la geolocalización
useEffect(() => {
  // Ubicación predeterminada en caso de error o denegación de permisos
  // Estas coordenadas corresponden al centro de México (ajústalas según sea necesario)
  const defaultLocation = {
    lat: 19.432608,
    lng: -99.133209
  };

  // Siempre establecer una ubicación predeterminada para garantizar que el mapa cargue
  setUserLocation(defaultLocation);

  // Luego intentar obtener la ubicación real del usuario
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // Si el usuario permite el acceso, actualizar con su ubicación real
        setUserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        console.log("Ubicación del usuario obtenida correctamente");
      },
      (error) => {
        // Si hay un error o se deniega el permiso, mantener la ubicación predeterminada
        console.log("No se pudo obtener la ubicación del usuario:", error.message);
        console.log("Usando ubicación predeterminada");
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
    );
  } else {
    // Navegador no soporta geolocalización
    console.log("Este navegador no soporta geolocalización. Usando ubicación predeterminada.");
  }
}, []);

const MapResizer = ({ expandMap, map }) => {
  useEffect(() => {
    if (!map) return;
    
    // Notificar que el mapa se expandió
    setIsMapExpanded(!!expandMap);
    
    // Resto del código de MapResizer
    setTimeout(() => {
      window.google.maps.event.trigger(map, 'resize');
    }, 100);
  }, [map, expandMap]);
  
  return null;
};

const toggleTraffic = () => {
  if (!mapRef.current || !window.google) return;
  
  if (!trafficLayer.current) {
    trafficLayer.current = new window.google.maps.TrafficLayer();
  }
  
  if (showTraffic) {
    trafficLayer.current.setMap(null);
  } else {
    trafficLayer.current.setMap(mapRef.current);
  }
  
  setShowTraffic(!showTraffic);
};

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         setUserLocation({
  //           lat: position.coords.latitude,
  //           lng: position.coords.longitude,
  //         });
  //       },
  //       (error) => console.error("Error getting user location:", error)
  //     );
  //   }
  // }, []);

  // useEffect(() => {
    
  //     const timer = setTimeout(() => {
  //       // Forzar actualización del mapa
  //       window.google?.maps?.event?.trigger(mapRef.current, 'resize');
  //       console.log("Forzando actualización del mapa");
  //     }, 1000);
      
  //     return () => clearTimeout(timer);
    
  // }, []);

  // useEffect(() => {
  //   // Aquí puedes usar el valor de filters.geocercas para filtrar las geocercas en el mapa
  //   const { geocercas } = filters;
  //   console.log("Geocercas seleccionadas:", geocercas);
  //   // Lógica para filtrar las geocercas en el mapa
  // }, [filters.geocercas]);

  // useEffect(() => {
  //   if (geocercas.length > 0) {
  //     setSelectedGeocercas(geocercas.map((g) => g.id));
  //   }
  // }, [geocercas]);

  console.log("🔍 Revisando filters.geocercas:", filters?.geocercas);
  const visibleGeocercas = useMemo(() => {
    console.log("🔍 Revisando filters.geocercas:", filters?.geocercas);
    console.log("Geocercas disponibles:", geocercas);
  
    // Si no hay filtros o no es array, mantener estado previo
    if (!filters?.geocercas || !Array.isArray(filters.geocercas)) {
      console.log("⚠️ filters.geocercas es undefined o no es array, manteniendo selección previa");
      return prevValidGeocercas;
    }
  
    // Si hay undefined, mantener estado previo
    if (filters.geocercas.some(item => item === undefined)) {
      console.log("⚠️ Se encontraron valores undefined, manteniendo selección previa");
      return prevValidGeocercas;
    }
  
    // Si es array válido, actualizar estado previo
    const filteredGeocercas = geocercas.filter((g) => filters.geocercas.includes(g.id));
    setPrevValidGeocercas(filteredGeocercas);
    return filteredGeocercas;
  }, [filters?.geocercas, geocercas]);
  
// const visibleGeocercas = useMemo(() => {
//   console.log("Geocercas disponibles:", geocercas);

//   // Si no hay filtros o no es array, no mostrar nada
//   if (!filters?.geocercas || !Array.isArray(filters.geocercas)) {
//     console.log("⚠️ filters.geocercas es undefined o no es array, ocultando geocercas");
//     return []; 
//   }

//   // Si hay algún undefined, no mostrar nada
//   if (filters.geocercas.some(item => item === undefined)) {
//     console.log("⚠️ Se encontraron valores undefined, ocultando geocercas");
//     return []; 
//   }

//   // Si no hay geocercas seleccionadas, no mostrar nada
//   if (filters.geocercas.length === 0) {
//     console.log("⚠️ No hay geocercas seleccionadas, ocultando todas");
//     return []; 
//   }

//   // Mostrar solo las geocercas seleccionadas
//   console.log("🗺️ Mostrando geocercas seleccionadas:", filters.geocercas);
//   return geocercas.filter((g) => filters.geocercas.includes(g.id));
// }, [filters.geocercas, geocercas]);


  const onLoad = (map) => {
    mapRef.current = map;
    if (onLoadComplete) onLoadComplete("map");
  };

  const mapContainerStyle = {
    height: "100%",
    width: "100%",
    minHeight: "450px",
    borderRadius: "30px",
  };

  // const handleGeocercaSelection = (event) => {
  //   const value = event.target.value;

  //   // Handle "Select All" option
  //   if (value.includes('all')) {
  //     if (selectedGeocercas.length === geocercas.length) {
  //       // If all were selected, deselect all
  //       setSelectedGeocercas([]);
  //     } else {
  //       // Select all
  //       setSelectedGeocercas(geocercas.map(g => g.id));
  //     }
  //   } else {
  //     // Handle individual selections
  //     setSelectedGeocercas(value);

  //     // If all items are now selected, add "all" to selection
  //     if (value.length === geocercas.length) {
  //       setSelectedGeocercas([...value, 'all']);
  //     }
  //   }
  // };

  const handleToggleSelectAllGeocercas = (e) => {
    e.preventDefault();
    setSelectAllGeocercas(!selectAllGeocercas);
    setSelectedGeocercas(selectAllGeocercas ? [] : geocercas.map((g) => g.id));
  };

  const handleToggleSelectAllVehiculos = (e) => {
    e.preventDefault();
    setSelectAllVehiculos(!selectAllVehiculos);
    setSelectedVehiculos(selectAllVehiculos ? [] : vehiculos.map((v) => v.id));
  };

  // 4. Agregar useEffect para cargar vehículos
  useEffect(() => {
    const loadVehiculos = async () => {
      try {
        const response = await vehiculoService.getVehiculos();
        const vehiculosData = response.data || [];
        setVehiculos(vehiculosData);
        setSelectedVehiculos(vehiculosData.map((v) => v.id));
        setSelectAllVehiculos(true);
      } catch (error) {
        console.error("Error cargando vehículos:", error);
      }
    };
    loadVehiculos();
  }, []);

  // const GeofencePointMarker = ({ position, nombre }) => {
  //   const mapRef = useGoogleMap(); // Get map context

  //   useEffect(() => {
  //     if (!mapRef || !window.google) return;

  //     const marker = new window.google.maps.marker.AdvancedMarkerElement({
  //       position,
  //       map: mapRef,
  //       title: nombre,
  //       content: createGeocercaMarker(nombre)
  //     });

  //     return () => {
  //       marker.setMap(null);
  //     };
  //   }, [position, nombre, mapRef]);

  //   return null;
  // };

  // const GeofencePointMarker = ({ position, nombre, radius }) => {
  //   const mapRef = useGoogleMap();

  //   useEffect(() => {
  //     if (!mapRef || !window.google) return;

  //     const element = document.createElement('div');
  //     element.innerHTML = `
  //       <div class="label-container">
  //         <div class="label-content">
  //           <span class="label-icon">📍</span>
  //           <span class="label-text">${nombre}</span>
  //         </div>
  //       </div>
  //     `;

  //     const marker = new window.google.maps.marker.AdvancedMarkerElement({
  //       position,
  //       map: mapRef,
  //       content: element,
  //       zIndex: 2
  //     });

  //     return () => {
  //       marker.setMap(null);
  //     };
  //   }, [position, nombre, mapRef]);

  //   return (
  //     <Circle
  //       center={position}
  //       radius={radius}
  //       options={{
  //         fillColor: '#4285F4',
  //         strokeColor: '#4285F4',
  //         fillOpacity: 0.1,
  //         strokeOpacity: 0.4,
  //         strokeWeight: 2,
  //         zIndex: 1
  //       }}
  //     />
  //   );
  // };

  // const GeofenceLabel = memo(({ position, nombre }) => {
  //   return (
  //     <OverlayView
  //       position={position}
  //       mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
  //       getPixelPositionOffset={(width, height) => ({
  //         x: -(width / 2),
  //         y: -(height / 2)
  //       })}
  //     >
  //       <div
  //         className="label-container"
  //         style={{
  //           position: 'relative',
  //           transform: 'translate3d(0,0,0)',
  //           willChange: 'transform',
  //           transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  //           padding: '4px 8px',
  //           background: '',
  //           borderRadius: '4px',
  //           boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  //           whiteSpace: 'nowrap'
  //         }}
  //       >
  //         <span className="label-icon" style={{ marginRight: '4px' }}>📍</span>
  //         <span className="label-text">{nombre}</span>
  //       </div>
  //     </OverlayView>
  //   );
  // });

  // const OptimizedPolygon = memo(({ paths, options }) => {
  //   const polygonOptions = useMemo(() => ({
  //     ...options,
  //     // Optimizaciones visuales
  //     geodesic: true,
  //     clickable: true,
  //     strokeWeight: 2,
  //     fillOpacity: 0.2,
  //     // Optimización de renderizado
  //     zIndex: 1,
  //     // Mejorar el rendimiento
  //     optimized: true,
  //     // Suavizar bordes
  //     strokeOpacity: 0.8,
  //     // Habilitar aceleración por hardware
  //     clickable: false,
  //     draggable: false
  //   }), [options]);

  //   return (
  //     <Polygon
  //       paths={paths}
  //       options={polygonOptions}
  //     />
  //   );
  // });

  // const GeofencePolygon = memo(({ geocerca }) => {
  //   const paths = useMemo(() =>
  //     geocerca.puntos.puntos.map(coord => ({
  //       lat: Number(coord.latitud),
  //       lng: Number(coord.longitud),
  //     }))
  //   , [geocerca.puntos.puntos]);

  //   const center = useMemo(() => {
  //     if (!window.google || !window.google.maps) return null;

  //     // Crear un objeto LatLngBounds
  //     const bounds = new window.google.maps.LatLngBounds();

  //     // Extender los límites con cada punto del polígono
  //     paths.forEach((path) => {
  //       bounds.extend(path);
  //     });

  //     // Obtener el centro de los límites
  //     const centerPoint = bounds.getCenter();
  //     return centerPoint;
  //   }, [paths]);

  //   if (!center) return null;

  //   return (
  //     <>
  //       <OptimizedPolygon
  //         paths={paths}
  //         options={{
  //           fillColor: '#4285F4',
  //           strokeColor: '#4285F4',
  //           fillOpacity: 0.2,
  //           strokeOpacity: 0.8,
  //           strokeWeight: 2,
  //         }}
  //       />
  //       <GeofenceLabel
  //         position={{ lat: center.lat(), lng: center.lng() }}
  //         nombre={geocerca.nombre}
  //       />
  //     </>
  //   );
  // });

  const GeofenceLabel = memo(({ position, nombre }) => {
    return (
      <OverlayView
        position={position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={(width, height) => ({
          x: 0,
          y: 0,
        })}
      >
        <div
          className="label-container"
          style={{
            position: "relative",
            transform: "translate3d(0,0,0)",
            willChange: "transform",
            transition: "transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
            padding: "4px 8px",
            background: "",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
            whiteSpace: "nowrap",
          }}
        >
          <div className="label-content">
            <span className="label-icon">📍</span>
            <span className="label-text">{nombre}</span>
          </div>
        </div>
      </OverlayView>
    );
  });

  const OptimizedPolygon = memo(({ paths, options }) => {
    const polygonOptions = useMemo(
      () => ({
        ...options,
        geodesic: true,
        clickable: true,
        strokeWeight: 2,
        fillOpacity: 0.2,
        zIndex: 1,
        optimized: true,
        strokeOpacity: 0.8,
        clickable: false,
        draggable: false,
      }),
      [options]
    );

    return <Polygon paths={paths} options={polygonOptions} />;
  });

  const GeofencePolygon = memo(({ geocerca }) => {
    const paths = useMemo(
      () =>
        geocerca.puntos.puntos.map((coord) => ({
          lat: Number(coord.latitud),
          lng: Number(coord.longitud),
        })),
      [geocerca.puntos.puntos]
    );

    const topLeft = useMemo(() => {
      if (!window.google || !window.google.maps) return null;

      const bounds = new window.google.maps.LatLngBounds();
      paths.forEach((path) => bounds.extend(path));

      return bounds.getNorthEast();
    }, [paths]);

    if (!topLeft) return null;

    return (
      <>
        <OptimizedPolygon
          paths={paths}
          options={{
            fillColor: "#4285F4",
            strokeColor: "#4285F4",
            fillOpacity: 0.2,
            strokeOpacity: 0.8,
            strokeWeight: 2,
          }}
        />
        <GeofenceLabel
          position={{
            lat: 0,
            lng: 0,
          }}
          nombre={geocerca.nombre}
        />
      </>
    );
  });

  const GeofencePointMarker = memo(({ position, nombre, radius }) => {
    const mapRef = useGoogleMap();

    useEffect(() => {
      if (!mapRef || !window.google) return;

      const element = document.createElement("div");
      element.innerHTML = `
        <div class="label-container">
          <div class="label-content">
            <span class="label-icon">📍</span>
            <span class="label-text">${nombre}</span>
          </div>
        </div>
      `;

      const marker = new window.google.maps.marker.AdvancedMarkerElement({
        position,
        map: mapRef,
        content: element,
        zIndex: 2,
      });

      return () => {
        marker.setMap(null);
      };
    }, [position, nombre, mapRef]);

    return (
      <Circle
        center={position}
        radius={radius}
        options={{
          fillColor: "#4285F4",
          strokeColor: "#4285F4",
          fillOpacity: 0.1,
          strokeOpacity: 0.4,
          strokeWeight: 2,
          zIndex: 1,
        }}
      />
    );
  });

  const GeofenceRenderer = memo(({ geocercas, selectedGeocercas }) => {
    return (
      <>
        {geocercas
          .filter((geocerca) => selectedGeocercas.includes(geocerca.id))
          .map((geocerca) => {
            if (geocerca.puntos.type === "Polygon") {
              return <GeofencePolygon key={geocerca.id} geocerca={geocerca} />;
            }

            if (geocerca.puntos.type === "Point") {
              const position = {
                lat: Number(geocerca.puntos.puntos[0].latitud),
                lng: Number(geocerca.puntos.puntos[0].longitud),
              };
              return (
                <GeofencePointMarker
                  key={geocerca.id}
                  position={position}
                  nombre={geocerca.nombre}
                  radius={geocerca.radio}
                />
              );
            }

            return null;
          })}
      </>
    );
  });

  const styles = `

   .label-container {
      pointer-events: none;
      user-select: none;
      will-change: transform;
      transform: translate3d(0,0,0);
      backface-visibility: hidden;
      perspective: 1000px;
      transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .label-content{
      display: flex;
      flex-direction: column; /* Alinea los elementos en columna */
      align-items: center; /* Centra horizontalmente */
      text-align: center; /* Asegura que el texto esté centrado */
      gap: 4px; /* Espacio entre el icono y el texto */
    }

    .custom-user-location {
      background: transparent;
    }

    .user-marker-container {
      position: relative;
      width: 40px;
      height: 40px;
    }

    .pulse {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 30px;
      height: 30px;
      background: rgba(66, 133, 244, 0.2);
      border-radius: 50%;
      transform: translate(-50%, -50%);
      animation: pulse 2s infinite;
    }

    .marker {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 1;
    }

    @keyframes pulse {
      0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
      }
      100% {
        transform: translate(-50%, -50%) scale(3);
        opacity: 0;
      }
    }
      
    .custom-alert-icon {
      background: transparent;
    }
    
    .alert-icon {
      position: absolute;
      right: 20px;
      top: 8px;     
      padding: 0px;
      border-radius: 50%;
    }

    .alert-marker-container {
      position: relative;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .alert-pulse {
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      animation: alertPulse 2s infinite;
    }

  .exceso-de-velocidad .alert-pulse {
    background: rgba(255, 0, 0, 0.2);
  }

  .detenido .alert-pulse {
    background: rgba(255, 165, 0, 0.2);
  }

  .encendido-motor .alert-pulse {
    background: rgba(0, 255, 0, 0.2);
  }

  .apagado-de-motor .alert-pulse {
    background: rgba(128, 128, 128, 0.2);
  }

  @keyframes alertPulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }

  .vehicle-marker-container {
    position: relative;
    width: 60px;
    height: 40px;
  }

  .vehicle-icon {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 24px;
  }



  .exceso-de-velocidad {
    background: rgba(255, 0, 0, 0.2);
  }

  .detenido {
    background: rgba(255, 165, 0, 0.2);
  }

  .encendido-motor {
    background: rgba(0, 255, 0, 0.2);
  }

  .apagado-de-motor {
    background: rgba(128, 128, 128, 0.2);
  }

  .puerta-cerrada .alert-pulse {
    background: rgba(0, 0, 255, 0.2);
  }
  
  .puerta-abierta .alert-pulse {
    background: rgba(255, 0, 255, 0.2);
  }
  
  .acercamiento .alert-pulse {
    background: rgba(255, 255, 0, 0.2);
  }
  
  .boton-de-panico .alert-pulse {
    background: rgba(255, 0, 0, 0.4);
  }
  
  .entrada .alert-pulse {
    background: rgba(0, 255, 0, 0.2);
  }
  
  .salida .alert-pulse {
    background: rgba(255, 165, 0, 0.2);
  }

   .gm-style-iw-chr {
            height: 0px;
        }

        .gm-style .gm-style-iw-c {
            padding: 0 !important;
        }

        .gm-style-iw {
            padding: 0 !important;
        }

        .gm-style-iw-d {
            overflow: hidden !important;
            width: 100% !important;
        }

          .alert-container {
    transition: transform 0.1s ease-out;
    -webkit-font-smoothing: antialiased;
  }

 

  /* Optimizaciones para las geocercas */
  .gm-style-iw {
    transform: translate3d(0,0,0);
    backfaceVisibility: hidden;
  }

  /* Optimizaciones para los marcadores */
  .vehicle-marker-container {
    will-change: transform;
    transform: translate3d(0,0,0);
    backfaceVisibility: hidden;
  }

  /* Optimizar animaciones */
  @keyframes fadeIn {
    from { 
      opacity: 0; 
      transform: translate3d(-50%, -90%, 0);
    }
    to { 
      opacity: 1; 
      transform: translate3d(-50%, -100%, 0);
    }
  }

`;

// const handleCloseAlert = () => {
//   console.log("Cerrando alerta");
//   setActiveAlert(null);
// };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: 2,
          p: 2,
        }}
      >
        {/*     
      <FormControl fullWidth>
          <InputLabel>Geocercas Visibles</InputLabel>
          <Select
            multiple
            value={selectedGeocercas}
            onChange={handleGeocercaSelection}
            renderValue={(selected) => {
              // Don't show "all" in the display text
              const displayValues = selected.filter(id => id !== 'all');
              const selectedNames = geocercas
                .filter(g => displayValues.includes(g.id))
                .map(g => g.nombre)
                .join(', ');
              return selectedNames.length > 50 
                ? selectedNames.substring(0, 50) + '...' 
                : selectedNames;
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                  width: 250
                }
              },
              variant: "menu"
            }}
          >
          <MenuItem value="all">
            <Checkbox 
              checked={selectedGeocercas.length === geocercas.length}
              indeterminate={selectedGeocercas.length > 0 && selectedGeocercas.length < geocercas.length}
            />
            <ListItemText 
              primary="Seleccionar Todas" 
              primaryTypographyProps={{
                style: { fontWeight: 'bold' }
              }}
            />
          </MenuItem>
          {geocercas.map((geocerca) => (
            <MenuItem key={geocerca.id} value={geocerca.id}>
              <Checkbox checked={selectedGeocercas.includes(geocerca.id)} />
              <ListItemText primary={geocerca.nombre} />
            </MenuItem>
      ))}
          </Select>
         
        </FormControl> */}

        {/* <Box p={2}>
          {viewMode === "geocercas" ? (
            <Autocomplete
              sx={{ width: "100%" }}
              multiple
              id="geocercas-autocomplete"
              options={geocercas}
              noOptionsText="No hay coincidencias"
              disableCloseOnSelect
              disableListWrap
              getOptionLabel={(option) => option.nombre}
              value={geocercas.filter((g) => selectedGeocercas.includes(g.id))}
              onChange={(event, newValue, reason, details) => {
                switch (reason) {
                  case "clear":
                    setSelectAllGeocercas(false);
                    setSelectedGeocercas([]);
                    break;
                  case "removeOption":
                    const newSelected = newValue.map((g) => g.id);
                    setSelectAllGeocercas(
                      newSelected.length === geocercas.length
                    );
                    setSelectedGeocercas(newSelected);
                    break;
                  case "selectOption":
                    if (details?.option) {
                      setLastSelectedGeocerca(details.option.id);
                    }
                    const selectedIds = newValue.map((g) => g.id);
                    setSelectAllGeocercas(
                      selectedIds.length === geocercas.length
                    );
                    setSelectedGeocercas(selectedIds);
                    break;
                  default:
                    const ids = newValue.map((g) => g.id);
                    setSelectAllGeocercas(ids.length === geocercas.length);
                    setSelectedGeocercas(ids);
                }
              }}
              renderTags={(value) =>
                value.length > 0 ? (
                  <Typography variant="body2">
                    {`${value.length} seleccionados`}
                  </Typography>
                ) : null
              }
              renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props;
                return (
                  <li key={option.id} {...optionProps}>
                    <Checkbox
                      size="small"
                      style={{ marginRight: 8 }}
                      checked={selectedGeocercas.includes(option.id)}
                    />
                    {option.nombre}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Geocercas"
                  placeholder={selectedGeocercas.length === 0 ? "Todas" : ""}
                />
              )}
              groupBy={() => ""}
              renderGroup={(params) => (
                <div key={params.key}>
                  <Box
                    pl={3.5}
                    py={0.5}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    <FormControlLabel
                      onClick={handleToggleSelectAllGeocercas}
                      label="Seleccionar todas"
                      control={
                        <Checkbox
                          size="small"
                          checked={selectAllGeocercas}
                          indeterminate={
                            selectedGeocercas.length > 0 &&
                            selectedGeocercas.length < geocercas.length
                          }
                        />
                      }
                    />
                  </Box>
                  <Divider />
                  {params.children}
                </div>
              )}
            />
          ) : (
            <Autocomplete
              sx={{ width: "100%" }}
              multiple
              id="vehiculos-autocomplete"
              options={vehiculos}
              noOptionsText="No hay coincidencias"
              disableCloseOnSelect
              disableListWrap
              getOptionLabel={(option) =>
                `${option.alias} ${
                  option.no_Economico ? `- ${option.no_Economico}` : ""
                }`
              }
              value={vehiculos.filter((v) => selectedVehiculos.includes(v.id))}
              onChange={(event, newValue, reason) => {
                switch (reason) {
                  case "clear":
                    setSelectAllVehiculos(false);
                    setSelectedVehiculos([]);
                    break;
                  case "removeOption":
                    const newSelected = newValue.map((v) => v.id);
                    setSelectAllVehiculos(
                      newSelected.length === vehiculos.length
                    );
                    setSelectedVehiculos(newSelected);
                    break;
                  case "selectOption":
                    const selectedIds = newValue.map((v) => v.id);
                    setSelectAllVehiculos(
                      selectedIds.length === vehiculos.length
                    );
                    setSelectedVehiculos(selectedIds);
                    break;
                  default:
                    const ids = newValue.map((v) => v.id);
                    setSelectAllVehiculos(ids.length === vehiculos.length);
                    setSelectedVehiculos(ids);
                }
              }}
              renderTags={(value) =>
                value.length > 0 ? (
                  <Typography variant="body2">
                    {`${value.length} seleccionados`}
                  </Typography>
                ) : null
              }
              renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props;
                return (
                  <li key={option.id} {...optionProps}>
                    <Checkbox
                      size="small"
                      style={{ marginRight: 8 }}
                      checked={selectedVehiculos.includes(option.id)}
                    />
                    {`${option.alias} ${
                      option.no_Economico ? `- ${option.no_Economico}` : ""
                    }`}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Vehículos"
                  placeholder={selectedVehiculos.length === 0 ? "Todos" : ""}
                />
              )}
              groupBy={() => ""}
              renderGroup={(params) => (
                <div key={params.key}>
                  <Box
                    pl={3.5}
                    py={0.5}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    <FormControlLabel
                      onClick={handleToggleSelectAllVehiculos}
                      label="Seleccionar todos"
                      control={
                        <Checkbox
                          size="small"
                          checked={selectAllVehiculos}
                          indeterminate={
                            selectedVehiculos.length > 0 &&
                            selectedVehiculos.length < vehiculos.length
                          }
                        />
                      }
                    />
                  </Box>
                  <Divider />
                  {params.children}
                </div>
              )}
            />
          )}
        </Box> */}

        <Box
          sx={{
            flex: 1,
            minHeight: "450px",
            width: "100%",
            position: "relative"
          }}
        >
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={userLocation}
            zoom={13}
            onLoad={onLoad}
            mapId={GOOGLE_MAP_ID}
            options={{
              mapId: GOOGLE_MAP_ID,
              disableDefaultUI: false,
              clickableIcons: false,
              scrollwheel: true,
              gestureHandling: "greedy",
              zoomControl: true,
            }}
            onError={(error) => {
              // onClick={activeAlert ? onClearActiveAlert : undefined}
              console.error("Error loading Google Map:", error);
            }}
          >
            {userLocation && (
              <UserLocationMarker
                position={userLocation}
                map={mapRef.current}
              />
            )}
            {/* <VehicleMarkers alerts={alerts} map={mapRef.current} /> */}
            {/* <VehicleMarkers
              alerts={alerts}
              selectedVehiculos={selectedVehiculos}
              map={mapRef.current}
            /> */}

{/* <VehicleMarkers
  alerts={alerts.filter(a => !activeAlert || a.id !== activeAlert.id)}
  selectedVehiculos={selectedVehiculos}
  map={mapRef.current}
/> */}

{/* {isRouteVisible && vehicleRouteCoordinates.length > 0 && (
    <VehicleRoute 
      key={`route-${isRouteVisible}-${vehicleRouteCoordinates.length}`}
      coordinates={vehicleRouteCoordinates} 
      alertData={alertDataForRoute}
      isVisible={isRouteVisible}
      map={mapRef.current}
    />
  )} */}

{vehicleRouteCoordinates.length > 0 && (
    <VehicleRoute 
      key={`route-${vehicleRouteCoordinates.length}`}
      coordinates={vehicleRouteCoordinates} 
      alertData={alertDataForRoute}
      isVisible={isRouteVisible}
      showMarkersAlways={true}
      map={mapRef.current}
    />
)}
  {console.log("allAlerts in map:", allAlerts)}
    

  <VehicleMarkers
    alerts={alerts.filter(a => !activeAlert || a.UID_Vehiculo !== activeAlert.UID_Vehiculo)}
    allAlerts={allAlerts}
    selectedVehiculos={selectedVehiculos}
    map={mapRef.current}
    setSelectedVehicleRoute={setSelectedVehicleRoute}
    setVehicleRouteCoordinates={setVehicleRouteCoordinates}
    setAlertDataForRoute={setAlertDataForRoute}
    setIsRouteVisible={setIsRouteVisible}
    activeVehicleId={activeVehicleId}
    setActiveVehicleId={setActiveVehicleId}
    setAlertCounters={setAlertCounters}
  />

  {/* Añadir el panel de contadores */}
<AlertCountersPanel 
  counters={alertCounters} 
  activeVehicleId={activeVehicleId} 
  onClear={handleClearRoute} 
  alerts={alerts} 
/>

{/* <VehicleMarkers
  alerts={alerts.filter(a => !activeAlert || a.UID_Vehiculo !== activeAlert.UID_Vehiculo)}
  selectedVehiculos={selectedVehiculos}
  map={mapRef.current}
/> */}

            {/* <GeocercasLayer
          geocercas={geocercas}
          selectedGeocercas={selectedGeocercas}
        /> */}
            <MapViewUpdater center={center} activeAlert={activeAlert} />

            {/* {activeAlert && <ActiveAlertOverlay alert={activeAlert}  />} */}
            {activeAlert && (
        <ActiveAlertOverlay alert={activeAlert} onClose={onClearActiveAlert} />
      )}
            {/* <GeofenceRenderer geocercas={geocercas} selectedGeocercas={selectedGeocercas} /> */}

            {/* {geocercas
              .filter((geocerca) => selectedGeocercas.includes(geocerca.id))
              .map((geocerca) => {
                if (geocerca.puntos.type === "Polygon") {
                  const paths = geocerca.puntos.puntos.map((coord) => ({
                    lat: Number(coord.latitud),
                    lng: Number(coord.longitud),
                  }));

                  return (
                    <RenderPolygon
                      key={geocerca.id}
                      paths={paths}
                      name={geocerca.nombre}
                    />
                  );
                } else if (geocerca.puntos.type === "Point") {
                  const position = {
                    lat: Number(geocerca.puntos.puntos[0].latitud),
                    lng: Number(geocerca.puntos.puntos[0].longitud),
                  };
                  return (
                    <RenderCircle
                      key={geocerca.id}
                      center={position}
                      radius={geocerca.radio}
                      name={geocerca.nombre}
                    />
                  );
                }
                return null;
              })} */}

{visibleGeocercas && Array.isArray(visibleGeocercas) ? (
  visibleGeocercas.map((geocerca) => {
    if (geocerca.puntos.type === "Polygon") {
      const paths = geocerca.puntos.puntos.map((coord) => ({
        lat: Number(coord.latitud),
        lng: Number(coord.longitud),
      }));

      return (
        <RenderPolygon
          key={geocerca.id}
          paths={paths}
          name={geocerca.nombre}
        />
      );
    } else if (geocerca.puntos.type === "Point") {
      const position = {
        lat: Number(geocerca.puntos.puntos[0].latitud),
        lng: Number(geocerca.puntos.puntos[0].longitud),
      };
      return (
        <RenderCircle
          key={geocerca.id}
          center={position}
          radius={geocerca.radio}
          name={geocerca.nombre}
        />
      );
    }
    return null;
  })
) : null}

            <MapResizer expandMap={expandMap} map={mapRef.current} />

            <CenterMapOnGeocerca
              geocercas={geocercas}
              selectedGeocercas={selectedGeocercas}
              map={mapRef.current}
            />

            <MapViewUpdater
              center={userLocation}
              activeAlert={activeAlert}
              map={mapRef.current}
            />
          </GoogleMap>

          <Box
            sx={{
              position: isMapExpanded ? "fixed" : "absolute",
              top: isMapExpanded ? "70px" : "10px",  // Ajustar según necesidad
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: isMapExpanded ? 9999 : 10,     // Mayor z-index cuando expandido
            }}
          >
            <button
              className={`traffic-button ${showTraffic ? 'active' : ''}`}
              onClick={toggleTraffic}
              title={showTraffic ? "Ocultar tráfico" : "Mostrar tráfico"}
            >
              🚦
            </button>
          </Box>

        </Box>
      </Box>

      <style>{styles}</style>
    </>
  );
};

//export default GoogleMapView;
export default React.forwardRef((props, ref) => {
  // Guardar la referencia del mapa cuando se carga
  const onLoad = (map) => {
    // Guardar referencia al mapa de Google
    if (ref) {
      ref.current = {
        map,
        googleMapsRef: map,
        setZoom: (zoom) => map.setZoom(zoom)
      };
    }
    
    // Llamar al onLoad original si existe
    if (props.onLoad) {
      props.onLoad(map);
    }
  };
  
  return <GoogleMapView {...props} onLoad={onLoad} />;
});
