import * as React from "react";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
import { Box, Divider, Grid } from "@mui/material";

import ToggleButton from "@mui/material/ToggleButton";
import { styled } from "@mui/material/styles";
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from "@mui/material/ToggleButtonGroup";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { isEmptyArray } from "formik";
import { confirmDialog } from "../../../components/ConfirmDialog";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  /*diseño seleccionado*/
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  /*diseño no seleccionado*/
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: 0,
    },
}));
//Valores iniciales de la vista
const arrayDays = generateWeek();

//Modulo react principal ----------------------------------------
const HorarioConfigForm = ({
  configuracion,
  actualizarComponente,
  handleGuardarPrincipal,
  idAlerta,
  daysSel,
  setDaysSel,
  nextComponent,
  isEditMode,
  handleClose,
}) => {
  //Estado con los valores del componente
  const [days, setDays] = React.useState(() => []);
  const [checkedSe, setCheckSe] = React.useState(false);
  const [checkedAllSe, setCheckAllSe] = React.useState(false);
  const [checkedAll, setCheckAll] = React.useState(false);
  const [weekHours, setWeekHours] = React.useState([]);
  //Actualizacion de la UI
  React.useEffect(() => {
    // Verificar que days sea un arreglo y no esté vacío
    if (!Array.isArray(days) || days.length === 0) {
      actualizarComponente("horario", []);
      return;
    }
  
    // Lista de días válidos para verificación
    const validDays = ["L", "M", "MI", "J", "V", "S", "D"];
    
    // Formatear el arreglo de días seleccionado con validación
    let arrayData = days
      .filter(item => item && typeof item === 'string' && item.includes('-'))
      .map(item => {
        const parts = item.split("-");
        if (parts.length !== 2) {
          console.error(`Formato inválido para el item: ${item}`);
          return null;
        }
        const [day, hour] = parts;
        if (!day || !validDays.includes(day)) {
          console.error(`Día inválido en el item: ${item}`);
          return null;
        }
        return { day, hour };
      })
      .filter(item => item !== null);
  
    // Agrupa las horas seleccionadas por día
    const groupedDays = {};
    for (const { day, hour } of arrayData) {
      if (!groupedDays[day]) {
        groupedDays[day] = { 
          dia: day, 
          horas: [] 
        };
      }
      // Convertir la hora a número y asegurarse de que sea válida
      const hourNum = parseInt(hour, 10);
      if (!isNaN(hourNum)) {
        // Verificar si la hora ya existe para evitar duplicados
        if (!groupedDays[day].horas.includes(hourNum)) {
          groupedDays[day].horas.push(hourNum);
        }
      }
    }
  
    // Convertir el objeto agrupado a un arreglo
    const groupDays = Object.values(groupedDays);
    
    // Ordenar las horas en cada día
    groupDays.forEach(dayData => {
      if (Array.isArray(dayData.horas)) {
        dayData.horas.sort((a, b) => a - b);
      }
    });
  
    // Actualizar los datos
    console.log("Enviando datos actualizados:", groupDays);
    actualizarComponente("horario", groupDays);
  }, [days]);

  // React.useEffect(() => {
  //   // //Formatear el arreglo de dias seleccionado
  //   // let arrayData = days.map((item, index) => {
  //   //   //obtenemos el dia seleccionado
  //   //   const arrayHour = item.split("-");

  //   //   //retornamos un objeto con el dia y la hora
  //   //   return { day: arrayHour[0], hour: arrayHour[1] };
  //   // });

  //   // //Agrupa las horas seleccionadas por dia
  //   // let groupDays = Object.values(
  //   //   arrayData.reduce((daysOfWeek, { day, hour }) => {
  //   //     if (!daysOfWeek[day]) {
  //   //       daysOfWeek[day] = { dia: day, horas: [] };
  //   //     }

  //   //     daysOfWeek[day].horas.push(parseInt(hour));

  //   //     return daysOfWeek;
  //   //   }, [])
  //   // );

  //   // Formatear el arreglo de días seleccionado
  // let arrayData = days.map((item) => {
  //   const [day, hour] = item.split("-");
  //   return { day, hour };
  // });

  // // Agrupa las horas seleccionadas por día usando {} como acumulador
  // let groupDays = Object.values(
  //   arrayData.reduce((acc, { day, hour }) => {
  //     if (!acc[day]) {
  //       acc[day] = { dia: day, horas: [] };
  //     }
  //     acc[day].horas.push(parseInt(hour, 10));
  //     return acc;
  //   }, {})
  // );

  //   //Aqui va actualizar datos
  //   actualizarComponente("horario", groupDays);
  // }, [days]);

  //Evento para Limpiar el componente cuando el
  //state principal sea eliminado
  React.useEffect(() => {
    //Validamos si el componente fue limpiado
    if (isEmptyArray(configuracion.horario)) {
      //refrescamos el state si es necesario
      if (!isEmptyArray(days)) {
        setCheckAll((e) => {
          return false;
        });

        setCheckSe((e) => {
          return false;
        });

        setCheckAllSe((e) => {
          return false;
        });

        setDaysSel((prevDays) => {
          return [];
        });
        setDays((prevDays) => {
          return [];
        });
      }
    } else {
      //Si el componente esta vacio procedemos a cargar la informacion
      if (isEmptyArray(days)) {
        //Cargamos la data recuperada del API
        console.log("recuperando datos del back");
        console.log(configuracion.horario);

        //Precargamos la UI con el horario
        setDays((prevDays) => {
          //Nuevo origen par el componente
          let newDays = [];

          //validamos los dias que tenia seleccionado
          configuracion.horario.map((item, index) => {
            //Obtenemos la informacion del dia
            const { dia } = item;

            //Cargamos las horas que tenia seleccionado
            item.horas.map((subitem, index_subitem) => {
              //obtenemos el dia selecionado
              newDays.push(`${dia.toUpperCase()}-${subitem}`);

              return subitem;
            });

            return item;
          });

          //retona los dias precargados del usuaario
          return newDays;
        });
      }
    }
  }, [configuracion.horario]);

  //Evento click del boton horas
  const handleButtonHour = (hour_selected) => {
    const [day, hour] = hour_selected.split("-");
    let hourInt = parseInt(hour);
    let start;
    let end;
    let rangeHours;
    setWeekHours((prevWeekHours) => {
      // Verificar si ya existe un registro para el día seleccionado
      const existingDay = prevWeekHours.find((w) => w.day === day);
      if (existingDay) {
        // Actualiza el registro existente
        // Si startHour es diferente de 0 se calcula el rango de horas
        if (existingDay.startHour !== 0) {
          start = Math.min(existingDay.startHour, hourInt);
          end = Math.max(existingDay.startHour, hourInt);
          rangeHours = Array.from(
            { length: end - start + 1 },
            (_, i) => `${day}-${start + i}`
          );
          setDays((prevDays) => {
            if (prevDays.includes(hour_selected)) {
              const matchDay = prevDays.filter(
                (hourID) => hourID !== hour_selected
              );
              return matchDay;
            } else {
              return [...new Set([...prevDays, ...rangeHours])];
            }
          });
          //Actualiza startHour del dia seleccionado
          return prevWeekHours.map((w) =>
            w.day === day ? { ...w, startHour: 0 } : w
          );
        } else {
          // Si startHour es 0 se coloca hourInt como hora inicial
          setDays((prevDays) => {
            if (prevDays.includes(hour_selected)) {
              const matchDay = prevDays.filter(
                (hourID) => hourID !== hour_selected
              );
              return matchDay;
            } else {
              return [...prevDays, hour_selected];
            }
          });
          //Actualiza startHour del dia seleccionado
          return prevWeekHours.map((w) =>
            w.day === day ? { ...w, startHour: hourInt } : w
          );
        }
      } else {
        // Agrega un nuevo registro para el día
        setDays((prevDays) => {
          if (prevDays.includes(hour_selected)) {
            const matchDay = prevDays.filter(
              (hourID) => hourID !== hour_selected
            );
            hourInt = 0;
            return matchDay;
          } else {
            return [...prevDays, hour_selected];
          }
        });
        setDaysSel([...daysSel, day]);

        return [
          ...prevWeekHours,
          { day: day, startHour: hourInt === 0 ? 1 : hourInt },
        ];
      }
    });
    //Actualiza el origen de datos del plugin
    // setDays((prevDays) => {
    //   if (prevDays.includes(hour_selected)) {
    //     //Eliminamos el horario seleccionado
    //     const newDays = prevDays.filter((hourID) => hourID !== hour_selected);
    //     //retornamos la nueva lista
    //     return newDays;
    //   } else {

    //     // Si no hay una última hora seleccionada, agregar la actual y establecerla como última seleccionada
    //     // if (!lastSelectedHour || lastSelectedHour.day !== day) {
    //     //   setLastSelectedHour({ day, hour: hourInt });
    //     //   return [...prevDays, hour_selected];
    //     // }

    //     // Si hay una última hora seleccionada en el mismo día, calcular el rango y seleccionar todas las horas en ese rango
    //     // if (lastSelectedHour.day === day) {
    //     //   const start = Math.min(lastSelectedHour.hour, hourInt);
    //     //   const end = Math.max(lastSelectedHour.hour, hourInt);
    //     //   const rangeHours = Array.from(
    //     //     { length: end - start + 1 },
    //     //     (_, i) => `${day}-${start + i}`
    //     //   );
    //     //   setLastSelectedHour({ day, hour: hourInt });
    //     //   return [...new Set([...prevDays, ...rangeHours])];
    //     // }

    //     // Si la nueva hora está en un día diferente, simplemente agregarla
    //     // setLastSelectedHour({ day, hour: hourInt });

    //     //Agrega el nuevo elemento seleccionado
    //     const newDays = [...prevDays, hour_selected];
    //     //retornamos la nueva lista
    //     return newDays;
    //   }
    // });
  };
  
  React.useEffect(() => {
    console.log(weekHours);
  }, [weekHours]);

  //Evento para validar si el dia tiene horas seleccionadas
  const hasHour = (pref) => {
    //retorna si en las 24 horas se encuentra alguna seleccionada
    for (let index = 0; index < 24; index++) {
      //Filtrado por dia
      if (days.includes(`${pref}-${index}`)) {
        //retorna dia seleccionado ?
        return true;
      }
    }

    //no se encontro ningun dia seleccionado
    return false;
  };

  //Evento para Aplicar todas las horas, a todos los dias
  const handleChangeCheckAll = (event) => {
    //actualizamos el estado del check
    setCheckAll((e) => {
      return event.target.checked;
    });

    //Valida si el evento esta seleccionado
    if (event.target.checked) {
      //Actualiza el origen de datos del plugin
      setDays((prevDays) => {
        let newDays = [];

        arrayDays.map((item, index) => {
          item.hours.map((subitem, index_subitem) => {
            newDays.push(`${item.pref}-${subitem}`);

            return subitem;
          });

          return item;
        });

        return newDays;
      });
    } else {
      //Actualiza el origen de datos del plugin
      setDays((prevDays) => {
        return [];
      });
      setDaysSel((prevDays) => {
        return [];
      });
      setWeekHours([]);
      setCheckSe(false);
      setCheckAllSe(false);
    }
  };

  //Evento para aplicar todas las horas, a dias seleccionados
  const handleChangeCheckAllSe = (event) => {
    //actualizamos el estado del check
    setCheckAllSe((e) => {
      return event.target.checked;
    });

    //Valida si el evento esta seleccionado
    if (event.target.checked) {
      //Actualiza el origen de datos del plugin
      setDays((prevDays) => {
        let newDays = [];

        arrayDays.map((item, index) => {
          if (hasHour(item.pref) || itemExist(item) >= 0) {
            item.hours.map((subitem, index_subitem) => {
              newDays.push(`${item.pref}-${subitem}`);
              return subitem;
            });
          }
          return item;
        });
        return newDays;
      });
    } else {
      //Actualiza el origen de datos del plugin
      setDays((prevDays) => {
        return [];
      });
      setDaysSel((prevDays) => {
        return [];
      });
      setWeekHours([]);
      setCheckSe(false);
    }
  };

  const handleChangeCheckSe = (event) => {
    // Actualizamos el estado del check
    setCheckSe(() => event.target.checked);
  
    // Valida si el evento está seleccionado
    if (event.target.checked) {
      // Actualiza el origen de datos del plugin
      setDays((prevDays) => {
        let newDays = [];
        let layoutHours = [];
        let chargeLayout = false;
  
        if (Array.isArray(arrayDays)) {
          arrayDays.forEach((item) => {
            // Procede a cargar la plantilla inicial
            if (!chargeLayout && hasHour(item.pref)) {
              if (Array.isArray(item.hours)) {
                // Procede a buscar días seleccionados
                item.hours.forEach((subitem) => {
                  if (prevDays.includes(`${item.pref}-${subitem}`)) {
                    layoutHours.push(subitem);
                  }
                });
              }
              // Plantilla cargada correctamente
              chargeLayout = true;
            }
  
            if (chargeLayout && hasHour(item.pref) && Array.isArray(item.hours)) {
              layoutHours.forEach((subitem) => {
                newDays.push(`${item.pref}-${subitem}`);
              });
            }
          });
        }
  
        if (Array.isArray(daysSel)) {
          daysSel.forEach((item) => {
            if (itemExist(item) >= 0 && Array.isArray(item.hours)) {
              // Procede a buscar días seleccionados
              item.hours.forEach((subitem) => {
                if (prevDays.includes(`${item.pref}-${subitem}`)) {
                  layoutHours.push(subitem);
                }
              });
              // Plantilla cargada correctamente
              chargeLayout = true;
            }
  
            if (itemExist(item) >= 0) {
              layoutHours.forEach((subitem) => {
                newDays.push(`${item.pref}-${subitem}`);
              });
            }
          });
        }
        // Filtra para evitar retornar undefined o null
        return newDays.filter((day) => day !== undefined && day !== null);
      });
    } else {
      // Actualiza el origen de datos del plugin
      setDays([]);
      setDaysSel([]);
      setWeekHours([]);
    }
  };

  //Evento para el check horario de carga (del dia indicado)
  const handleChangeCheckSeg = (event) => {
    //actualizamos el estado del check
    setCheckSe((e) => {
      return event.target.checked;
    });

    //Valida si el evento esta seleccionado
    if (event.target.checked) {
      //Actualiza el origen de datos del plugin
      setDays((prevDays) => {
        let newDays = [];
        let layoutHours = [];
        let chargeLayout = false;

        if (Array.isArray(arrayDays)) {
          arrayDays.map((item, index) => {
            //Procede a cargar la plantilla inicial
            if (chargeLayout === false && hasHour(item.pref) === true) {
              if (Array.isArray(item.hours)) {
                //procede a buscar dias seleccionados
                item.hours.map((subitem, subitem_index) => {
                  if (prevDays.includes(`${item.pref}-${subitem}`)) {
                    layoutHours.push(subitem);
                  }
                  return subitem;
                });
              }
              //Plantilla cargada correctamente
              chargeLayout = true;
            }

            if (
              chargeLayout === true &&
              hasHour(item.pref) === true &&
              Array.isArray(item.hours)
            ) {
              layoutHours.map((subitem, subitem_index) => {
                newDays.push(`${item.pref}-${subitem}`);
                return subitem;
              });
            }
            return item;
          });
        }

        if (Array.isArray(daysSel)) {
          daysSel.map((item, index) => {
            //Procede a cargar la plantilla inicial
            if (itemExist(item) && Array.isArray(item.hours)) {
              //procede a buscar dias seleccionados
              item.hours.map((subitem, subitem_index) => {
                if (prevDays.includes(`${item.pref}-${subitem}`)) {
                  layoutHours.push(subitem);
                }

                return subitem;
              });

              //Plantilla cargada correctamente
              chargeLayout = true;
            }

            if (itemExist(item)) {
              layoutHours.map((subitem, subitem_index) => {
                newDays.push(`${item.pref}-${subitem}`);
                return subitem;
              });
            }
            return item;
          });
        }
        return newDays;
      });
    } else {
      //Actualiza el origen de datos del plugin
      setDays((prevDays) => {
        return [];
      });
      setDaysSel((prevDays) => {
        return [];
      });
      setWeekHours([]);
    }
  };

  const itemExist = (day) =>
    daysSel?.findIndex((daySel) => daySel.id === day.id);

  const handleSelect = async (day) => {
    if (hasHour(day.pref)) {
      const isConfirmed = await confirmDialog({
        title: `Confirmación para desmarcar día.`,
        message: "¿Estás seguro de quitar el día y sus horas?",
      });
      if (isConfirmed) {
        setDays((prevDays) => {
          const dayPref = `${generateWeek()[day.id].pref}-`;
          return prevDays.filter((daySel) => !daySel.includes(dayPref));
        });
        setDaysSel((prev) => prev.filter((daySel) => daySel.id !== day.id));
      }
    } else if (itemExist(day) >= 0 && !hasHour(day.pref)) {
      setDaysSel((prev) => prev.filter((daySel) => daySel.id !== day.id));
    } else {
      setDaysSel([...daysSel, day]);
    }
  };

  const guardarAlerta = async () => {
    const flag = await handleGuardarPrincipal("horario");
    if (!isEditMode) nextComponent(4);
    if (flag && isEditMode) handleClose();
  };

  //-- Retorna la vista del componente horario ------------------------------------
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "stretch",
          alignContent: "space-between",
        }}
        overflow={"auto"}
      >
        <Box
          pr={2}
          pb={2.5}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-around"}
        >
          <Box pl={1} pb={1}>
            <Typography variant="subtitle1" gutterBottom>
              Dias
            </Typography>
          </Box>
          <Grid
            pl={1}
            pb={1}
            container
            display={"flex"}
            flexDirection={"column"}
            width={44}
            spacing={1}
          >
            {arrayDays.map((item, index) => {
              return (
                <Grid item>
                  <Button
                    border={1}
                    borderRadius={1}
                    borderColor={"lightgray"}
                    key={index}
                    variant="contained"
                    type="button"
                    color={
                      itemExist(item) >= 0 || hasHour(item.pref)
                        ? "primary"
                        : "default"
                    }
                    sx={{ minWidth: 41, width: 41, height: 41 }}
                    onClick={() => handleSelect(item)}
                  >
                    {item.Name}
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </Box>

        <Box ml={1} py={1}>
          <Divider
            orientation="vertical"
            sx={{
              borderBottomWidth: 2,
              borderColor: "lightgray",
              marginBottom: 2,
            }}
          />
        </Box>

        <Box
          pb={2}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-around"}
        >
          <Box ml={2}>
            <Typography variant="subtitle1" gutterBottom>
              Horas
            </Typography>
          </Box>
          <Box ml={1.5} overflow={"auto"}>
            {arrayDays.map((item, index) => {
              return (
                <>
                  <StyledToggleButtonGroup
                    value={days}
                    aria-label="day"
                    sx={{ width: "100%" }}
                  >
                    {item.hours.map((hour, index_hour) => {
                      return (
                        <ToggleButton
                          sx={{
                            height: 41,
                            flex: 1,
                          }}
                          onClick={() => {
                            handleButtonHour(`${item.pref}-${hour}`);
                          }}
                          value={`${item.pref}-${hour}`}
                          aria-label={`${item.pref}-${hour}`}
                        >
                          {hour.toString().length === 1
                            ? `0${hour}`
                            : hour.toString()}
                        </ToggleButton>
                      );
                    })}
                  </StyledToggleButtonGroup>
                  <Divider
                    orientation="horizontal"
                    sx={{
                      borderBottomWidth: 2,
                      borderColor: "lightgray",
                    }}
                  />
                </>
              );
            })}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "stretch",
          alignContent: "space-between",
        }}
        overflow={"auto"}
      >
        <Box
          flexGrow={1}
          paddingX={2}
          borderRadius={1}
          bgcolor={(theme) => theme.palette.background.paper}
        >
          <FormGroup>
            <FormControlLabel
              control={<Checkbox />}
              checked={checkedSe}
              onChange={handleChangeCheckSe}
              label="Aplicar mismo horario de la alerta (del día indicado) a todos los días seleccionados."
            />
            <FormControlLabel
              control={<Checkbox />}
              checked={checkedAllSe}
              onChange={handleChangeCheckAllSe}
              label="Aplicar todas las horas, a días seleccionados."
            />
            <FormControlLabel
              control={<Checkbox />}
              checked={checkedAll}
              onChange={handleChangeCheckAll}
              label="Aplicar todas las horas, a todos los días."
            />
          </FormGroup>
        </Box>
        <Box
          ml={1}
          pt={1}
          width={150}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-around"}
        >
          <Box display={"flex"} flexGrow={1}></Box>
          {isEditMode && (
            <Box alignSelf={"flex-end"}>
              <Button
                variant="contained"
                color="primary"
                type="button"
                endIcon={!isEditMode ? <NavigateNextIcon /> : <SaveIcon />}
                onClick={guardarAlerta}
                disabled={idAlerta ? "" : "true"}
                sx={{
                  height: "25px",
                }}
              >
                {!isEditMode ? "Siguiente" : "Guardar"}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

//Exporta el modulo para la plantilla
export default HorarioConfigForm;

// ---------------------- funciones ------------------------------

//Funcion para generar el arreglo de horas
function createHours() {
  let hours = [];

  for (let index = 0; index < 24; index++) {
    hours.push(index);
  }

  return hours;
}

//Funcion para generar el arreglo de dias
function generateWeek() {
  let days = [];

  for (let index = 0; index < 7; index++) {
    switch (index) {
      case 0:
        days.push({
          id: index,
          pref: "L",
          Name: "LU",
          hours: createHours(),
        });
        break;

      case 1:
        days.push({
          id: index,
          pref: "M",
          Name: "MA",
          hours: createHours(),
        });
        break;

      case 2:
        days.push({
          id: index,
          pref: "MI",
          Name: "MI",
          hours: createHours(),
        });
        break;

      case 3:
        days.push({
          id: index,
          pref: "J",
          Name: "JU",
          hours: createHours(),
        });
        break;

      case 4:
        days.push({
          id: index,
          pref: "V",
          Name: "VI",
          hours: createHours(),
        });
        break;

      case 5:
        days.push({
          id: index,
          pref: "S",
          Name: "SA",
          hours: createHours(),
        });
        break;

      case 6:
        days.push({
          id: index,
          pref: "D",
          Name: "DO",
          hours: createHours(),
        });
        break;

      default:
        days.push({
          id: 0,
          pref: "NA",
          Name: "NA",
          hours: [],
        });
        break;
    }
  }

  return days;
}
