import { createTheme } from '@mui/material/styles';

const baseTheme = {
  typography: {
    fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontWeight: 700, // Bold para títulos importantes
    },
    h2: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontWeight: 700, // Bold para títulos importantes
    },
    h3: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontWeight: 700, // Bold para títulos importantes
    },
    h4: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontWeight: 500, // Medium para subtítulos
    },
    h5: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontWeight: 500, // Medium para subtítulos
    },
    h6: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontWeight: 500, // Medium para subtítulos
    },
    subtitle1: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontWeight: 500, // Medium
    },
    subtitle2: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontWeight: 500, // Medium
    },
    body1: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontWeight: 500, // Medium para texto general
    },
    body2: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontWeight: 300, // Light para texto menos importante
    },
    button: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontWeight: 500, // Medium
      textTransform: 'none',
    },
    caption: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontWeight: 300, // Light para elementos menos importantes
    },
    overline: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontWeight: 300, // Light para elementos menos importantes
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    // Componentes de entrada y formularios
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 400, // Regular para mejor legibilidad en campos de entrada
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 400,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 400,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 400,
          fontSize: '0.875rem',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 500, // Medium para etiquetas
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 300, // Light para textos de ayuda
        },
      },
    },
    
    // Componentes de Autocomplete
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 400,
          fontSize: '0.875rem',
        },
        listbox: {
          '& .MuiAutocomplete-option': {
            fontSize: '0.875rem',
            padding: '6px 16px',
          },
        },
        tag: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 400,
          fontSize: '0.75rem',
        },
        input: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 400,
        },
      },
    },
    
    // Componentes de Tablas
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 400,
          fontSize: '0.875rem',
        },
        head: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 500, // Medium para encabezados de tabla
          fontSize: '0.875rem',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 500,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 400,
          fontSize: '0.875rem',
        },
        displayedRows: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 400,
          fontSize: '0.875rem',
        },
      },
    },
    
    // Componentes de navegación
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 400,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 500,
          fontSize: '0.875rem',
        },
      },
    },
    
    // Componentes de diálogo y notificaciones
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 700, // Bold para títulos de diálogos
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 400,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 400,
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 500,
        },
      },
    },
    
    // Componentes de listas
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 400,
        },
        secondary: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 300, // Light para texto secundario
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 500, // Medium para encabezados de listas
          fontSize: '0.875rem',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 400,
        },
      },

   
    },

    MuiCssBaseline: {
      styleOverrides: {
        html: {
          scrollBehavior: 'smooth',
        },
        '*::-webkit-scrollbar': {
          width: '8px',
          height: '8px',
        },
        '*::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: '#babac0',
          borderRadius: '8px',
        },
        '*::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#a0a0a5',
        },
        '*::-webkit-scrollbar-corner': {
          backgroundColor: 'transparent',
        },
      },
    },
    
    // Otros componentes comunes
    MuiChip: {
      styleOverrides: {
        label: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 400,
          fontSize: '0.75rem',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 400,
          fontSize: '0.75rem',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 500,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 500, // Medium para botones
        },
        text: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 500,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
          fontWeight: 500,
        },
      },
    },
  },
};

export const lightTheme = createTheme({
  ...baseTheme,
  palette: {
    mode: 'light',
    primary: {
      main: '#ea0000',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#000000',
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
    text: {
      primary: '#000000',
      secondary: 'rgba(0, 0, 0, 0.7)',
    },
  },
  components: {
    ...baseTheme.components,
    MuiCssBaseline: {
      styleOverrides: {
        ...baseTheme.components.MuiCssBaseline.styleOverrides,
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          borderRadius: '8px',
        },
        '*::-webkit-scrollbar-thumb:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    mode: 'dark',
    primary: {
      main: '#ea0000',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ffffff',
    },
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
    text: {
      primary: '#ffffff',
      secondary: 'rgba(255, 255, 255, 0.7)',
    },
  },
  components: {
    ...baseTheme.components,
    MuiCssBaseline: {
      styleOverrides: {
        ...baseTheme.components.MuiCssBaseline.styleOverrides,
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          borderRadius: '8px',
        },
        '*::-webkit-scrollbar-thumb:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
        },
      },
    },
  },

});
// import { createTheme } from '@mui/material/styles';

// const baseTheme = {
//   typography: {
//     fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
//     button: {
//       textTransform: 'none',
//     },
//   },
//   shape: {
//     borderRadius: 8,
//   },
// };

// export const lightTheme = createTheme({
//   ...baseTheme,
//   palette: {
//     mode: 'light',
//     primary: {
//       main: '#ea0000',
//       contrastText: '#ffffff',
//     },
//     secondary: {
//       main: '#000000',
//     },
//     background: {
//       default: '#f5f5f5',
//       paper: '#ffffff',
//     },
//     text: {
//       primary: '#000000',
//       secondary: 'rgba(0, 0, 0, 0.7)',
//     },
//   },
// });

// export const darkTheme = createTheme({
//   ...baseTheme,
//   palette: {
//     mode: 'dark',
//     primary: {
//       main: '#ea0000',
//       contrastText: '#ffffff',
//     },
//     secondary: {
//       main: '#ffffff',
//     },
//     background: {
//       default: '#121212',
//       paper: '#1e1e1e',
//     },
//     text: {
//       primary: '#ffffff',
//       secondary: 'rgba(255, 255, 255, 0.7)',
//     },
//   },
// });