import React, { Suspense, useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CssBaseline, CircularProgress } from "@mui/material";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import { Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import { AuthContext } from "./contexts/AuthContext";
import PrivateRoute from "./components/PrivateRoute";
import { ThemeProvider } from "./contexts/ThemeContext";
import { menuService } from "./services/apiService";
import MainLayout from "./layouts/MainLayout";
import Monitor from "./pages/monitor";
import { LoadScript } from "@react-google-maps/api";
import { LoadingProvider } from "./contexts/LoadingContext";
import LoadingOverlay from "./components/LoadingOverlay";
import MonitorStandalone from "./pages/monitor/MonitorStandalone";
import AddVehicleForm from './pages/vehiculo';
import ListaAlerta from "./pages/listado_alertas";
import "react-toastify/dist/ReactToastify.css";
import MainComponent from "./pages/report_v2/maincomponent";
import Test from "./pages/test/sonidos";
import { SoundProvider } from "./utils/soundcontext";
import MessageBalancePage from './pages/mensajeria/MessageBalancePage';
import ThirdPartyLogin from './pages/ThirdPartyLogin';
const libraries = ["drawing", "places", "marker", "geometry", "visualization"];


function App() {
  const { autenticado } = useContext(AuthContext);
  const [menuItems, setMenuItems] = useState([]);
  const [loadingMenu, setLoadingMenu] = useState(false);

  useEffect(() => {
    const fetchMenu = async () => {
      if (!autenticado) return;
      setLoadingMenu(true);

      //setLoadingMessage('Cargando menú...');
      try {
        const response = await menuService.getMenu();
        if (response.statusCode === 200) {
          setMenuItems(response.data);
        }
      } catch (error) {
        console.error("Error fetching menu:", error);
      } finally {
        setLoadingMenu(false);
        //setIsLoading(false);
      }
    };
    fetchMenu();
  }, [autenticado]);

  const generateRoutes = () => {
    if (!menuItems || menuItems.length === 0) return null;

    // if (item._Controller === 'report_v2' && item._Action === 'maincomponent') {
    //   return (
    //     <Route
    //       key={item._id}
    //       path={`/pages/${item._Controller}/${item._Action}`}
    //       element={
    //         <MainComponent configId={item.configId} />
    //       }
    //     />
    //   );
    // }

    return menuItems.map((item) => {

      if (item.nombre === 'Reporte Alertas' || item.nombre === 'Reporte Omisiones') {
        console.log('Setting up route for Reporte Alertas with configId');
        return (
          <Route
            key={item.id}
            //path={`/pages/${item.controller.toLowerCase()}/${item.action.toLowerCase()}`}
              path={`/pages/${item.controller.toLowerCase()}/${item.action.toLowerCase()}/:configId`}

            element={
              <PrivateRoute>
                {/* <MainComponent configId="a7458b28-61a5-4eaf-99f1-a1b4aaab7d38" /> */}
                <MainComponent />
              </PrivateRoute>
            }
          />
        );
      }

      const Component = React.lazy(() =>
        import(
          `./pages/${item.controller.toLowerCase()}/${item.action.toLowerCase()}`
        ).catch(() => import("./pages/NotFound"))
      );

      const path = `/pages/${item.controller.toLowerCase()}/${item.action.toLowerCase()}`;
      console.log(`Generating route for path: ${path}`);

      return (
        <Route
          key={item.id}
          path={path}
          element={
            <PrivateRoute>
              <Suspense fallback={<div>Loading...</div>}>
                <Component />
              </Suspense>
            </PrivateRoute>
          }
        />
      );


    });


  };

  if (loadingMenu) {
    //return <loadin />;
  }

  return (
    <LoadingProvider>
      <LoadingOverlay />
      <SoundProvider>
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          libraries={libraries}
          language="es"
          loadingElement={<LoadingOverlay />}
        >
          <Router>
            <Routes>
              {/* Rutas públicas */}
              <Route path="/login" element={<Login />} />
              <Route path="/message-balance" element={<MessageBalancePage />} />
              <Route path="/third-party-login" element={<ThirdPartyLogin />} />
              <Route
                path="/monitor-standalone"
                element={
                  <PrivateRoute>
                    <MonitorStandalone />
                  </PrivateRoute>
                }
              />

              {/* Rutas privadas */}
              {autenticado ? (
                <Route
                  path="/*"
                  element={
                    <MainLayout menuData={menuItems}>
                      <Routes>
                        <Route
                          path="/"
                          element={
                            <PrivateRoute>
                              {/* <Home /> */}
                              <Monitor />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/monitor"
                          element={
                            <PrivateRoute>
                              <Monitor />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/vehiculos"
                          element={
                            <PrivateRoute>
                              <AddVehicleForm />
                            </PrivateRoute>
                          }
                        />  
                        <Route
                          path="/lista-alertas"
                          element={
                            <PrivateRoute>
                              <ListaAlerta />
                            </PrivateRoute>
                          }
                        />     

                        <Route
                          path="/test"
                          element={
                            <PrivateRoute>
                              <Test />
                            </PrivateRoute>
                          }
                        /> 

                        {generateRoutes()}
                        {/* Ruta comodín para rutas no encontradas */}
                        <Route path="*" element={<Navigate to="/" />} />
                      </Routes>
                    </MainLayout>
                  }
                />
              ) : (
                // Redirigir a login si no está autenticado y la ruta no coincide
                <Route path="*" element={<Navigate to="/login" replace />} />
              )}
            </Routes>
          </Router>
        </LoadScript>
      </SoundProvider>
    </LoadingProvider>
  
  );
}
export default App;
