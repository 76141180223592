import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  useMemo,
} from "react";
import {
  GoogleMap,
  DrawingManager,
  Marker,
  Circle,
  Polygon,
} from "@react-google-maps/api";

const GoogleMapComponent_v1 = ({
  center,
  zoom,
  userLocation,
  geocercas,
  onCreated,
  onEdited,
  onDeleted,
  onMoved,
  drawnItemsRef,
  mapRef,
  renderGeocercas,
  handleTypeChange,
  currentOverlay,
  setCurrentOverlay,
  drawingManager,
  setDrawingManager,
  //setOverlayMap,
  isFirstLoad,
  setIsFirstLoad,
  onLoad,
}) => {
  const GOOGLE_MAP_ID = "geocercas-omisiones";
  const markersRef = useRef([]);
  //const mapRef = useRef(null);
  const [overlayMap, setOverlayMap] = useState({});

  const formattedUserLocation = useMemo(() => {
    if (Array.isArray(userLocation) && userLocation.length === 2) {
      const lat = parseFloat(userLocation[0]);
      const lng = parseFloat(userLocation[1]);
      if (!isNaN(lat) && !isNaN(lng)) {
        return { lat, lng };
      }
    }
    return null;
  }, [userLocation]);

  const formattedCenter = useMemo(() => {
    if (Array.isArray(center) && center.length === 2) {
      const lat = parseFloat(center[0]);
      const lng = parseFloat(center[1]);
      if (!isNaN(lat) && !isNaN(lng)) {
        return { lat, lng };
      }
    }
    return { lat: 21.1619, lng: -86.8515 }; // Default center
  }, [center]);

  const updateOverlayMap = useCallback((geocercaId, overlay) => {
    setOverlayMap((prev) => ({
      ...prev,
      [geocercaId]: overlay,
    }));
  }, []);
  //   return (
  //     location &&
  //     typeof location.lat === 'number' &&
  //     typeof location.lng === 'number' &&
  //     !isNaN(location.lat) &&
  //     !isNaN(location.lng)
  //   );
  // };

  const isValidLocation = useCallback((location) => {
    return (
      location &&
      typeof location.lat === "number" &&
      typeof location.lng === "number" &&
      !isNaN(location.lat) &&
      !isNaN(location.lng)
    );
  }, []);

  useEffect(() => {
    if (!isValidLocation(formattedUserLocation)) {
      console.warn(
        "formattedUserLocation tiene un formato inválido:",
        formattedUserLocation
      );
      return;
    }

    if (mapRef.current) {
      const homeIcon = document.createElement("div");
      homeIcon.innerHTML = `
        <div class="user-marker-container" style="
          position: relative;
          width: 24px;
          height: 24px;
        ">
          <div class="pulse" style="
            position: absolute;
            top: 50%;
            left: 50%;
            width: 12px;
            height: 12px;
            background: rgba(66, 133, 244, 0.5);
            border-radius: 50%;
            transform: translate(-50%, -50%);
            animation: pulse 2s infinite;
          "></div>
          <div style="
            font-size: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
          ">
            🏠
          </div>
        </div>
      `;
      const userMarker = new window.google.maps.marker.AdvancedMarkerElement({
        position: formattedUserLocation,
        map: mapRef.current,
        content: homeIcon,
        title: "Tu ubicación actual",
      });
      markersRef.current.push(userMarker);
    }
  }, [formattedUserLocation, isValidLocation, mapRef]);

  useEffect(() => {
    if (mapRef.current && isValidLocation(formattedCenter)) {
      mapRef.current.setCenter(formattedCenter);
      mapRef.current.setZoom(zoom);
    }
  }, [formattedCenter, zoom, isValidLocation, mapRef]);

  useEffect(() => {
    //if (!mapRef.current) return;

    let overlays = [];

    console.log("🚀 Renderizando geocercas...");
    console.log("geocercas:", geocercas);
    console.log("formattedUserLocation:", formattedUserLocation);
    console.log("mapRef:", mapRef);

    // Renderizar Marcador de Usuario
    if (isValidLocation(formattedUserLocation) && mapRef.current) {
      const homeIcon = document.createElement("div");
      homeIcon.innerHTML = `
        <div class="user-marker-container" style="
          position: relative;
          width: 24px;
          height: 24px;
        ">
          <div class="pulse" style="
            position: absolute;
            top: 50%;
            left: 50%;
            width: 12px;
            height: 12px;
            background: rgba(66, 133, 244, 0.5);
            border-radius: 50%;
            transform: translate(-50%, -50%);
            animation: pulse 2s infinite;
          "></div>
          <div style="
            font-size: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
          ">
            🏠
          </div>
        </div>
      `;

      const userMarker = new window.google.maps.marker.AdvancedMarkerElement({
        position: formattedUserLocation,
        map: mapRef.current,
        content: homeIcon,
        title: "Tu ubicación actual",
      });

      overlays.push(userMarker);
    } else {
      console.warn("userLocation tiene un formato inválido:", userLocation);
    }

    // Renderizar Geocercas
    if (geocercas?.length > 0 && mapRef.current) {
      geocercas.forEach((geocerca) => {
        if (geocerca.puntos.type === "Point") {
          const position = {
            lat: Number(geocerca.puntos.puntos[0].latitud),
            lng: Number(geocerca.puntos.puntos[0].longitud),
          };

          const circle = new window.google.maps.Circle({
            strokeColor: "#4285F4",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#4285F4",
            fillOpacity: 0.2,
            map: mapRef.current,
            center: position,
            radius: geocerca.radio,
          });

          const label = new window.google.maps.marker.AdvancedMarkerElement({
            position: position,
            map: mapRef.current,
            content: document.createElement("div"),
          });

          label.content.innerHTML = `
            <div class="label-container">
              <div class="label-content">
                <span class="label-icon">📍</span>
                <span class="label-text">${geocerca.nombre}</span>
              </div>
            </div>
          `;

          overlays.push(circle, label);

          updateOverlayMap(geocerca.id, circle);
          markersRef.current = [...markersRef.current, circle, label];
        } else if (geocerca.puntos.type === "Polygon") {
          const polygonCoords = geocerca.puntos.puntos.map((pt) => ({
            lat: Number(pt.latitud),
            lng: Number(pt.longitud),
          }));

          const polygon = new window.google.maps.Polygon({
            paths: polygonCoords,
            fillColor: "#4285F4",
            fillOpacity: 0.2,
            strokeColor: "#4285F4",
            strokeOpacity: 1,
            strokeWeight: 2,
            map: mapRef.current,
          });

          const bounds = new window.google.maps.LatLngBounds();
          polygonCoords.forEach((coord) => bounds.extend(coord));
          const center = bounds.getCenter();

          const label = new window.google.maps.marker.AdvancedMarkerElement({
            position: center,
            map: mapRef.current,
            content: document.createElement("div"),
          });

          label.content.innerHTML = `
            <div class="label-container">
              <div class="label-content">
                <span class="label-icon">📍</span>
                <span class="label-text">${geocerca.nombre}</span>
              </div>
            </div>
          `;

          overlays.push(polygon, label);

          updateOverlayMap(geocerca.id, polygon);
          markersRef.current = [...markersRef.current, polygon, label];
        } else {
          console.warn(`Tipo de geocerca desconocido: ${geocerca.puntos.type}`);
        }
      });
    }

    return () => {
      overlays.forEach((overlay) => overlay.setMap(null));
    };
    //}, [formattedUserLocation, mapRef, userLocation, geocercas]);
  }, [
    formattedUserLocation,
    geocercas,
    isValidLocation,
    updateOverlayMap,
    mapRef,
    userLocation,
  ]);

  useEffect(() => {
    if (mapRef.current) {
      console.log("🚀 Mapa Google cargado (useEffect)");
      onLoad?.();
    }
  }, [onLoad, mapRef]);

  // useEffect(() => {
  //   if (mapRef.current) {
  //     mapRef.current.panTo(formattedUserLocation);
  //     mapRef.current.setZoom(zoom);
  //   }
  // }, [formattedUserLocation, zoom]);

  //const handleOverlayComplete = (e) => {
  const handleOverlayComplete = useCallback((e) => {
    const overlay = e.overlay;
    setCurrentOverlay(overlay);

    if (e.type === "circle") {
      const center = overlay.getCenter();
      const radius = overlay.getRadius();
      onCreated({
        layerType: "circle",
        layer: {
          getLatLng: () => center,
          getRadius: () => radius,
        },
      });
    } else if (e.type === "polygon") {
      const path = overlay
        .getPath()
        .getArray()
        .map((latLng) => ({
          lat: latLng.lat(),
          lng: latLng.lng(),
        }));
      onCreated({
        layerType: "polygon",
        layer: {
          getLatLngs: () => path,
        },
      });
    }

    //};
  }, []);

  return (
    <GoogleMap
      mapId={GOOGLE_MAP_ID}
      mapContainerStyle={{ height: "400px", width: "100%" }}
      center={formattedCenter}
      zoom={zoom}
      options={{
        mapId: GOOGLE_MAP_ID,
        disableDefaultUI: false,
        clickableIcons: false,
        scrollwheel: true,
        gestureHandling: "greedy",
        zoomControl: true,
      }}
      onLoad={(map) => {
        mapRef.current = map;
        onLoad?.();
      }}
    >
      {/* {renderGeocercasConEtiquetas()} */}
      <DrawingManager
        onOverlayComplete={(e) => {
          handleOverlayComplete(e);
        }}
        options={{
          drawingControl: true,
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_RIGHT,
            drawingModes: ["circle", "polygon"],
          },
          circleOptions: {
            fillColor: "#4285F4",
            fillOpacity: 0.2,
            strokeWeight: 2,
            clickable: false,
            editable: true,
            zIndex: 1,
          },
          polygonOptions: {
            fillColor: "#4285F4",
            fillOpacity: 0.2,
            strokeWeight: 2,
            clickable: false,
            editable: true,
            zIndex: 1,
          },
        }}
      />
    </GoogleMap>
  );
};

export default GoogleMapComponent_v1;
