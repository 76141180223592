import React, { useState, useEffect,useRef } from "react";
import {
  Box,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  DialogContent, // Contenido principal del modal
  DialogContentText, // Texto dentro del modal
  Divider,
} from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  alertaService,
  tiposGeocercasService,
} from "../../services/apiService";
import SearchForm from "./SearchForm";
import TableModule from "./TableModule";
import NuevaAlertaForm from "../alertas/components/NuevaAlertaForm";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
//import { Grid } from "@mui/material";
import Grid from "@mui/material/Grid2";
//Modulo listado de alertas
const Index = () => {

  const componentsRefs = useRef([]);
    const [errorAlertaNombre,setErrorAlertaNombre]=useState(false);
    const [errorVehiculoDistancia,setErrorVehiculoDistancia]=useState(false);
    const [errorExcesoVeces,setErrorExcesoVeces]=useState(false);
    const [errorExcesoVelocidad,setErrorExcesoVelocidad]=useState(false);

  const [alerts, setAlerts] = useState([]);
  const [alertaId, setAlertaId] = useState(""); // ID de la alerta
  const [editMode, setEditMode] = useState(false); // Nuevo estado para controlar el modo edición
  const [configuracion, setConfiguracion] = useState({
    nombre: "",
    tipo_alerta: {},
    geocerca: [],
    vehiculo: [],
    vehiculo_compare: [],
    contactos: [],
    riesgo: "",
    tiempo: 0,
    veces: 0,
    repetir: 1,
    velocidad_movimiento: 0,
    distancia_vehiculos: 0,
    tipo_marcado: 0,
    url_sonido: "",
    horario: [],
  });

  const [selectedRows, setSelectedRows] = useState([]); // Almacena las filas seleccionadas
  const [showConfirmDialog, setShowConfirmDialog] = useState(false); // Controla el mensaje de confirmación

  // Método para actualizar el JSON principal con los datos de los componentes
  const actualizarComponente = (clave, datos) => {
    setConfiguracion((prev) => ({
      ...prev,
      [clave]: datos,
    })); // Solo actualiza la clave correspondiente
  };

    // Método para pasar al componente indicado en el currentIndex
    const firstComponent = (currentIndex) => {
      const nextElement = componentsRefs.current[currentIndex];
      if (nextElement) {
        nextElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    };

  // Método para guardar toda la alerta (creación o edición al api)
  const handleGuardarPrincipal = async () => {
    let jsonCompleto = {
      id: alertaId || "",
      ...configuracion, // Usamos todo el estado como base
    };
    try {
      if (!alertaId) {
        let errors = [];

       
        //si el Tipo de Alerta es 'Acercamiento', verificar que la geocerca seleccionada sea de tipo base
        if (configuracion.nombre.length === 0) {
          errors.push("alerta-nombre");
          toast.error("Escribe el NOMBRE de la Alerta");
        }

        if (configuracion.riesgo.length === 0) {
          errors.push("riesgo");
          toast.error("Seleccionar un riesgo");
        }

        if (configuracion.url_sonido.length === 0) {
          errors.push("sonido");
          toast.error("Seleccionar un sonido");
        }

        if (configuracion.tipo_alerta.nombre === "Acercamiento") {
          if (configuracion.distancia_vehiculos === 0) {
            errors.push("vehiculo-distancia");
            toast.error("La distancia tiene que ser mayor a 0");
          }
        }

        if (configuracion.tipo_alerta.nombre === "Exceso de velocidad") {
          if (configuracion.veces === 0) {
            errors.push("exceso-veces");
            toast.error("Veces tiene que ser mayor a 0");
          }

          if (configuracion.velocidad_movimiento === 0) {
            errors.push("exceso-velocidad");
            toast.error("Velocidad tiene que ser mayor a 0");
          }
        }

        //if (errors.length > 0) return;

        if (errors.length > 0) {
          if (errors[0] === "alerta-nombre") {
            firstComponent(0);
            setErrorAlertaNombre(true);
            return;
          }

          if (errors[0] === "vehiculo-distancia") {
            firstComponent(0);
            setErrorVehiculoDistancia(true);
            return;
          }

          if (errors[0] === "exceso-veces") {
            firstComponent(0);
            setErrorExcesoVeces(true);
            return;
          }

          if (errors[0] === "exceso-velocidad") {
            firstComponent(0);
            setErrorExcesoVelocidad(true);
            return;
          }

          if (
            errors[0] === "geocerca-acercamiento" ||
            errors[0] === "geocerca-tipoMarcado"
          ) {
            firstComponent(1);
            return;
          }
          if (errors[0] === "vehiculo-comparar" || errors[0] === "vehiculo") {
            firstComponent(2);
            return;
          }
          if (errors[0] === "horario") {
            firstComponent(3);
            return;
          }
          /*if (errors[0] === "contactos") {
            firstComponent(4);
            return;
          }*/
        }

        // Crear una nueva alerta
        const response = await alertaService.createAlerta(jsonCompleto);
        if (response && response.statusCode === 200) {
          setAlertaId(response.data.id);
          toast.success("Alerta creada exitosamente");
          loadAlerts();
        } else {
          toast.error(response?.message || "Error al crear alerta");
        }
      } else {
        let errors = [];
        //si el Tipo de Alerta es 'Acercamiento', verificar que la geocerca seleccionada sea de tipo base
        if (configuracion.nombre.length === 0) {
          errors.push("alerta-nombre");
          toast.error("Escribe el NOMBRE de la Alerta");
        }

        if (configuracion.riesgo.length === 0) {
          errors.push("riesgo");
          toast.error("Seleccionar un riesgo");
        }

        if (configuracion.url_sonido.length === 0) {
          errors.push("sonido");
          toast.error("Seleccionar un sonido");
        }

        if (configuracion.tipo_alerta.nombre === "Acercamiento") {
          if (configuracion.distancia_vehiculos === 0) {
            errors.push("vehiculo-distancia");
            toast.error("La distancia tiene que ser mayor a 0");
          }
        }

        if (configuracion.tipo_alerta.nombre === "Exceso de velocidad") {
          if (configuracion.veces === 0) {
            errors.push("exceso-veces");
            toast.error("Veces tiene que ser mayor a 0");
          }

          if (configuracion.velocidad_movimiento === 0) {
            errors.push("exceso-velocidad");
            toast.error("Velocidad tiene que ser mayor a 0");
          }
        }

        if (errors.length > 0) return;

        // Actualizar la alerta existente
        const response = await alertaService.updateAlerta(jsonCompleto);
        if (response && response.statusCode === 200) {
          toast.success("Alerta Actualizada exitosamente");
          resetForm();
          loadAlerts();
        } else {
          toast.error(response?.message || "Error al actualizar alerta");
        }
      }
    } catch (error) {
      console.error("Error al guardar alerta:", error);
      toast.error("Error al guardar la alerta");
    }
  };

  // Método para restablecer el formulario
  const resetForm = () => {
    setAlertaId(""); // Restablece el ID de la alerta
    setEditMode(false); // Salir del modo edición
    setConfiguracion({
      nombre: "",
      tipo_alerta: {},
      geocerca: [],
      vehiculo: [],
      vehiculo_compare: [],
      contactos: [],
      riesgo: "",
      tiempo: 0,
      veces: 0,
      repetir: 1,
      velocidad_movimiento: 0,
      distancia_vehiculos: 0,
      tipo_marcado: 0,
      url_sonido: "",
      horario: [],
    }); // Restaura el estado inicial
  };

  const handleEditarAlerta = (id) => {
    if (id) {
      loadAlertaById(id); // Llama solo si hay un ID válido
    } else {
      console.warn("Intento de edición sin seleccionar una alerta.");
    }
  };

  // Método para cargar los datos de una alerta seleccionada
  const loadAlertaById = async (id) => {
    if (!id) {
      console.warn("No se seleccionó ningún ID para cargar la alerta.");
      return; // Si no hay un ID, no hace nada
    }
    try {
      const filtros = { id: id };
      const response = await alertaService.getAlertList(filtros);
      if (response && response.statusCode === 200) {
        setConfiguracion(response.data[0]); // Cargar los datos en el estado de configuración
        setAlertaId(id); // Establecer el ID de la alerta
        setEditMode(true); // Activar el modo edición
      } else {
        toast.error("Error al cargar los datos de la alerta seleccionada");
      }
    } catch (error) {
      console.error("Error al cargar la alerta:", error);
      toast.error("Error al cargar la alerta seleccionada");
    }
  };

  const defaultFilters = {
    inicio: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(), // Inicio del día (00:00:00)
    fin: new Date(new Date().setHours(23, 59, 59, 999)).toISOString(), // Fin del día (23:59:59)
    activo: true, // Solo alertas activas
  };

  //Metodo para Listar todos las alertas
  const loadAlerts = async (filtros = defaultFilters) => {
    try {
      const response = await alertaService.getAlertList(filtros);
      if (response && response.statusCode === 200) {
        setAlerts(response.data);
      }
    } catch (error) {
      console.error("Error al cargar alertas:", error);
    }
  };

  // Método para eliminar alertas seleccionadas
  const confirmDelete = () => {
    setShowConfirmDialog(false); // Cierra el diálogo
    const idsToDelete = selectedRows.map((row) => row.id);
    handleDelete(idsToDelete); // Llama a la función de eliminación
  };

  const handleDelete = async (ids) => {
    const config = {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      containerId: "listado-alertas"
    };
    try {

      

      const response = await alertaService.deleteAlert({ ids }); // Ajusta el payload según tu API
      if (response.statusCode === 200) {
        toast.success("Alertas eliminadas exitosamente",config);
        setSelectedRows([]); // Limpia las filas seleccionadas
        // Recargar alertas con los filtros completos
        await loadAlerts(defaultFilters);
      } else {
        toast.error("Error al eliminar alertas",config);
      }
    } catch (error) {
      console.error("Error eliminando alertas:", error);
      toast.error("Error al eliminar alertas",config);
    }
  };

  //Init del modulo
  useEffect(() => {
    loadAlerts();
  }, []);

  //Retonrna la UI
  return (<>
    <Paper sx={{ px: 2, pb: 1, mr: 0.3, minHeight: "100vh" }}>
      <Box
        sx={{
          px: 2,
          pb: 1,
          pt: 3,
          width: "100%",
          height: "100%",
          overflow: "auto",
        }}
      >
        <Typography
          variant="h6"
          component="h1"
          gutterBottom
          sx={{ fontWeight: "bold" }}
        >
          Listado de Alertas
        </Typography>
        <Divider
          sx={{
            borderBottomWidth: 1,
            borderColor: "red",
            marginY: 2,
          }}
        />
        <form width="100%" height="100%">
          <Grid container spacing={2}>
            <Grid item size={{ xs: 12 }}>
              {editMode && (
                <NuevaAlertaForm
                  idAlerta={alertaId}
                  configuracion={configuracion}
                  setConfiguracion={setConfiguracion}
                  handleGuardarPrincipal={handleGuardarPrincipal}
                  resetForm={resetForm}
                  viewNew={false}
                  viewEdit={true}
                  errorAlertaNombre={errorAlertaNombre}
                  errorVehiculoDistancia={errorVehiculoDistancia}
                  errorExcesoVeces={errorExcesoVeces}
                  errorExcesoVelocidad={errorExcesoVelocidad}
                />
              )}
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <TableModule
                alertList={alerts}
                onSelectionChange={(rows) => setSelectedRows(rows)}
                handleEditarAlerta={handleEditarAlerta}
                setShowConfirmDialog={setShowConfirmDialog}
                selectedRows={selectedRows}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
     
      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
      >
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que deseas guardar los cambios realizados?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmDialog(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={confirmDelete} color="secondary" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

    

    </Paper>

<ToastContainer 
  enableMultiContainer
  containerId="listado-alertas"
  position="bottom-left"
  autoClose={1000}
  hideProgressBar={false}
  closeOnClick
  pauseOnHover
  draggable
  limit={3}
/> </>
  );
};
export default Index;

// return (
//   <LocalizationProvider dateAdapter={AdapterDateFns}>
//      <Paper sx={{ p: 2, width: "100%" }}>

//       {/* <Box sx={{ padding: 3 ,  width: "100%"}}> */}
//       <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
//         <Typography variant="h4" component="h1" gutterBottom>
//           Listado de Alertas
//         </Typography>
//         <Divider sx={{ my: 2, borderColor: "red" }} />

//         <form>
//           <Grid container spacing={2}>
//             {/* Mostrar el formulario de búsqueda o el de edición */}
//             <Grid item xs={12}>
//             {editMode ? (
//     <NuevaAlertaForm
//       idAlerta={alertaId}
//       configuracion={configuracion}
//       setConfiguracion={setConfiguracion}
//       handleGuardarPrincipal={handleGuardarPrincipal}
//       resetForm={resetForm}
//       viewNew={false}
//     />
//   ) : null}

//             </Grid>
//             <Grid item xs={12}>
//               <TableModule
//                 alertList={alerts}
//                 onSelectionChange={(rows) => setSelectedRows(rows)}
//                 handleEditarAlerta={handleEditarAlerta}
//                 setShowConfirmDialog={setShowConfirmDialog}
//                 selectedRows={selectedRows}
//               />
//             </Grid>
//           </Grid>
//         </form>

//               </Box>
//               <ToastContainer />
//           </Paper>
//           <Dialog
//               open={showConfirmDialog}
//               onClose={() => setShowConfirmDialog(false)}
//               >
//               <DialogTitle>Confirmar Eliminación</DialogTitle>
//               <DialogContent>
//                 <DialogContentText>
//                   ¿Estás seguro de que deseas guardar los cambios realizados?
//                 </DialogContentText>
//               </DialogContent>
//               <DialogActions>
//                   <Button onClick={() => setShowConfirmDialog(false)} color="primary">
//                   Cancelar
//                   </Button>
//                   <Button onClick={confirmDelete}  color="secondary" autoFocus>
//                   Eliminar
//                   </Button>
//               </DialogActions>
//           </Dialog>
//       </LocalizationProvider>

//   );
