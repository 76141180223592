// Monitor.js
import React, { useState, useEffect,useCallback,useRef } from "react";
import {
  Container,
  CssBaseline,
  Typography,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
  AppBar,
  Toolbar,
  Paper,
  Menu,
  MenuItem,
  Divider,Tooltip
} from "@mui/material";
import {
  FilterList,
  List as ListIcon,
  Map as MapIcon,
  HelpOutline as HelpOutlineIcon,
} from "@mui/icons-material";

import FilterBar from "../monitor/FilterBar";
import AlertList from "../monitor/AlertList";
import MapView from "../monitor/MapView";
import GoogleMapView from "../monitor/GoogleMapView";
// import FilterListIcon from '@mui/icons-material/FilterList';
//import { SoundProvider } from "../../utils/soundcontext";
import { useSound } from "../../utils/soundcontext";
import { 
  VolumeUp, 
  VolumeOff, 
  VolumeDown, 
  StopCircle as StopIcon
} from "@mui/icons-material";
import Slider from '@mui/material/Slider';

import {
  FilterList as FilterListIcon,
  FilterListOff as FilterListOffIcon,
} from "@mui/icons-material";

import { motion, AnimatePresence } from "framer-motion";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useLoading } from "../../contexts/LoadingContext";
import { OpenInNew as OpenInNewIcon } from "@mui/icons-material";
import Joyride, { STATUS } from "react-joyride";
import ClonedAlertOverlay from "../monitor/ClonedAlertOverlay";
import { MapOutlined as SwitchMapIcon } from "@mui/icons-material";
import { DirectionsCar, Place } from "@mui/icons-material"; // Agregar imports
import { userService,messageService } from "../../services/apiService";

import DeleteIcon from '@mui/icons-material/Delete';

import { wsService } from "../../services/webSocketService";

import MessageBalance from './MessageBalance';

const sharedIconButtonStyle = {
  position: "absolute",
  top: 10,
  backgroundColor: (theme) => theme.palette.background.paper,
  "&:hover": {
    backgroundColor: (theme) => theme.palette.action.hover,
  },
};

const Monitor = () => {
  const [showAlertList, setShowAlertList] = useState(true);
  const [showFilterBar, setShowFilterBar] = useState(true);
  const [expandMap, setExpandMap] = useState(false);
  const [mapCenter, setMapCenter] = useState([21.1619, -86.8515]);
  const [alerts, setAlerts] = useState([]);
  const [activeAlert, setActiveAlert] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [runTour, setRunTour] = useState(false);
  const [clonedAlert, setClonedAlert] = useState(null);
  const [tourAlertId, setTourAlertId] = useState(null);
  const { setIsLoading, setLoadingMessage } = useLoading();
  const [mapProvider, setMapProvider] = useState("google");
  const [mapMenuAnchor, setMapMenuAnchor] = useState(null);
  const [viewMode, setViewMode] = useState("geocercas");
  const [allAlerts, setAllAlerts] = useState([]);
  const [filters, setFilters] = useState({
    horaInicial: null,
    horaFinal: null,
    vehiculo: "",
    tiposAlerta: "",
    //geocercas: "",
    geocercas: [],
    riesgos: []
  });

  const [balanceData, setBalanceData] = useState(null);
  const [selectedVehicleRoute, setSelectedVehicleRoute] = useState(null);
  const [vehicleRouteCoordinates, setVehicleRouteCoordinates] = useState([]);
  const [isRouteVisible, setIsRouteVisible] = useState(false);
  const [activeVehicleId, setActiveVehicleId] = useState(null);
  const [alertCounters, setAlertCounters] = useState({});
  const [alertDataForRoute, setAlertDataForRoute] = useState([]);



  const [componentsLoaded, setComponentsLoaded] = useState({
    map: false,
    alerts: false,
  });

  const [soundMenuAnchor, setSoundMenuAnchor] = useState(null);
  const { isMuted, toggleMute, stopAllSounds, volume, changeVolume, activeAudios } = useSound();


  const mapRef = useRef(null);

// Función para limpiar la ruta activa
// const handleClearRoute = () => {
//   console.log("Ejecutando handleClearRoute - Limpiando toda la información de ruta");
  
//   // Ocultar primero la ruta para que el efecto en VehicleRoute limpie la polilínea
//   setIsRouteVisible(false);
  
//   // Limpiar todos los estados relacionados con la ruta
//   setActiveVehicleId(null);
//   setAlertCounters({});
//   setVehicleRouteCoordinates([]);
//   setSelectedVehicleRoute(null);
//   setAlertDataForRoute([]);
  
//   // Forzar una actualización del mapa si es necesario
//   if (mapRef.current && mapRef.current.googleMapsRef) {
//     try {
//       console.log("Actualizando vista del mapa");
//       // Si hay métodos específicos para actualizar el mapa, se llamarían aquí
//     } catch (error) {
//       console.warn("Error al actualizar el mapa:", error);
//     }
//   }
// };


  // Sound menu handlers
  const handleSoundMenuOpen = (event) => {
    setSoundMenuAnchor(event.currentTarget);
  };

  const handleSoundMenuClose = () => {
    setSoundMenuAnchor(null);
  };

  const handleVolumeChange = (event, newValue) => {
    changeVolume(newValue);
  };


  const handleFilterChange = useCallback((newFilters) => {
    //console.log("Nuevos filtros recibidos:", newFilters);
    if (newFilters && Array.isArray(newFilters.geocercas) && Array.isArray(newFilters.riesgos)) {
      setFilters(newFilters);
    }
  }, []);
  
  // const handleFilterChange = useCallback((newFilters) => {
  //   console.log("Nuevos filtros recibidos:", newFilters);
  //   if (newFilters && Array.isArray(newFilters.geocercas)) {
  //     setFilters(newFilters);
  //   }
  // }, []);

  const toggleAlertList = () => setShowAlertList(!showAlertList);
  const toggleFilterBar = () => setShowFilterBar(!showFilterBar);
  const toggleExpandMap = () => setExpandMap(!expandMap);
 



  const filterBarRef = useRef(null);

  // useEffect(() => {
  //   // Si allAlerts está vacío pero hay alertas disponibles, inicializarlo
  //   if (allAlerts.length === 0 && Object.values(alerts).length > 0) {
  //     console.log("Inicializando allAlerts con alertas existentes");
  //     setAllAlerts(Object.values(alerts));
  //   }
  // }, [alerts, allAlerts]);

  // useEffect(() => {
  //   // Register handler for BalanceConsumption messages
  //   wsService.registerMessageHandler("BalanceConsumption", (data) => {
  //     console.log("BalanceConsumption received:", data);
  //     setBalanceData(data.data); // Store the balance data in state
  //   });
    
  //   return () => {
  //     // Cleanup if needed
  //   };
  // }, []);

  // useEffect(() => {
  //   // Función para cargar los datos de balance
  //   const loadMessageBalance = async () => {
  //     try {
  //       const response = await messageService.getMessageBalance();
  //       if (response && response.success) {
  //         setBalanceData(response.data);
  //       } else {
  //         // Si hay error, establecer valores por defecto
  //         setBalanceData({
  //           MensajeMailDisponible: 0,
  //           MensajeMailUtilizado: 0,
  //           MensajeWatsDisponible: 0,
  //           MensajeWatsUtilizado: 0,
  //           MensajetextoDisponible: 0,
  //           MensajetextoUtilizado: 0,
  //           MensajeVozDisponible: 0,
  //           MensajeVozUtilizado: 0
  //         });
  //       }
  //     } catch (error) {
  //       console.error("Error al cargar balance de mensajes:", error);
  //       // Establecer valores por defecto en caso de error
  //       setBalanceData({
  //         MensajeMailDisponible: 0,
  //         MensajeMailUtilizado: 0,
  //         MensajeWatsDisponible: 0,
  //         MensajeWatsUtilizado: 0,
  //         MensajetextoDisponible: 0,
  //         MensajetextoUtilizado: 0,
  //         MensajeVozDisponible: 0,
  //         MensajeVozUtilizado: 0
  //       });
  //     }
  //   };

  //   // Cargar datos al montar el componente
  //   loadMessageBalance();

  //   // Register handler for WebSocket BalanceConsumption messages
  //   wsService.registerMessageHandler("BalanceConsumption", (data) => {
  //     console.log("BalanceConsumption received:", data);
  //     setBalanceData(data.data); // Update balance data from WebSocket
  //   });
    
  //   return () => {
  //     // Cleanup if needed
  //     //wsService.unregisterMessageHandler("BalanceConsumption");
  //     if (wsService && typeof wsService.unregisterMessageHandler === 'function') {
  //       wsService.unregisterMessageHandler("BalanceConsumption");
  //     } 
  //     // Si no existe, verificar si hay una alternativa como removeListener
  //     else if (wsService && typeof wsService.removeListener === 'function') {
  //       wsService.removeListener("BalanceConsumption", messageHandler);
  //     }
  //     // Si no hay ninguna alternativa, simplemente imprimir advertencia
  //     else {
  //       console.warn("No se pudo eliminar el handler de WebSocket al desmontar el componente");
  //     }
      
  //   };
  // }, []);


  useEffect(() => {
    // Función para cargar los datos de balance
    const loadMessageBalance = async () => {
      try {
        //const response = await messageService.getMessageBalance();
        const response = await messageService.getMessageBalance("summary");
        if (response && response.success) {
          setBalanceData(response.data);
        } else {
          // Si hay error, establecer valores por defecto
          setBalanceData({
            MensajeMailDisponible: 0,
            MensajeMailUtilizado: 0,
            MensajeWatsDisponible: 0,
            MensajeWatsUtilizado: 0,
            MensajetextoDisponible: 0,
            MensajetextoUtilizado: 0,
            MensajeVozDisponible: 0,
            MensajeVozUtilizado: 0
          });
        }
      } catch (error) {
        console.error("Error al cargar balance de mensajes:", error);
        // Establecer valores por defecto en caso de error
        setBalanceData({
          MensajeMailDisponible: 0,
          MensajeMailUtilizado: 0,
          MensajeWatsDisponible: 0,
          MensajeWatsUtilizado: 0,
          MensajetextoDisponible: 0,
          MensajetextoUtilizado: 0,
          MensajeVozDisponible: 0,
          MensajeVozUtilizado: 0
        });
      }
    };
  
    // Cargar datos al montar el componente
    loadMessageBalance();
  
    // Define the handler function before registering it
    const balanceConsumptionHandler = (data) => {
      console.log("BalanceConsumption received:", data);
      setBalanceData(data.data); // Update balance data from WebSocket
    };
  
    // Register handler for WebSocket BalanceConsumption messages
    wsService.registerMessageHandler("BalanceConsumption", balanceConsumptionHandler);
    
    return () => {
      // Cleanup if needed
      if (wsService && typeof wsService.unregisterMessageHandler === 'function') {
        wsService.unregisterMessageHandler("BalanceConsumption");
      } 
      // Si no existe, verificar si hay una alternativa como removeListener
      else if (wsService && typeof wsService.removeListener === 'function') {
        wsService.removeListener("BalanceConsumption", balanceConsumptionHandler);
      }
      // Si no hay ninguna alternativa, simplemente imprimir advertencia
      else {
        console.warn("No se pudo eliminar el handler de WebSocket al desmontar el componente");
      }
    };
  }, []);



  const handleAlertsUpdate = useCallback((newAlerts) => {
    if (!newAlerts || newAlerts.length === 0) return;
    
    console.log(`Recibidas ${newAlerts.length} alertas desde AlertList`);
    
    // Usar Promise para asegurar que esto se ejecuta después del ciclo de renderizado
    Promise.resolve().then(() => {
      setAllAlerts(prevAlerts => {
        // Crear un conjunto para verificación rápida de IDs existentes
        const existingIds = new Set(prevAlerts.map(alert => alert.id));
        
        // Array para nuevas alertas
        const updatedAlerts = [...prevAlerts];
        
        // Añadir solo alertas nuevas o actualizar existentes
        newAlerts.forEach(alert => {
          if (!alert || !alert.id) return;
          
          if (existingIds.has(alert.id)) {
            // Actualizar alerta existente
            const index = updatedAlerts.findIndex(a => a.id === alert.id);
            if (index >= 0) {
              updatedAlerts[index] = alert;
            }
          } else {
            // Añadir nueva alerta
            updatedAlerts.push(alert);
          }
        });
        
        console.log(`allAlerts actualizado: ${updatedAlerts.length} alertas totales`);
        return updatedAlerts;
      });
    });
  }, []);


  const resetAllFilters = () => {
    if (filterBarRef.current) {
      filterBarRef.current.resetFilters();
    }
  };
  const handleComponentLoad = (component) => {
    setComponentsLoaded((prev) => {
      const newState = { ...prev, [component]: true };

      // Si todos los componentes están cargados
      if (Object.values(newState).every((loaded) => loaded)) {
        setIsLoading(false);
      }

      return newState;
    });
  };

  useEffect(() => {
    setIsLoading(true);
    setLoadingMessage("Cargando módulo de monitoreo...");
  }, []);

  useEffect(() => {
    const isNewUser = localStorage.getItem("nuevo_usuario") === "1";
    if (isNewUser) {
      startTour();
      // Reset the new user flag after starting tour
      // localStorage.setItem('nuevo_usuario', '0');
      userService.firsttimeoff().catch((error) => {
        console.error("Error updating new user status:", error);
      });
      localStorage.setItem("nuevo_usuario", "0");
    }
  }, []); // Empty dependency array means this runs once on mount

  // const handleMapIconClick = (location, alert) => {
  //   setMapCenter([location.lat, location.lng]);
  //   setActiveAlert(alert);
  // };

  const handleMapIconClick = (location, alert) => {
    // Limpiar primero cualquier información de ruta anterior

    //handleClearRoute();

    // if (mapRef.current && mapRef.current.googleMapsRef) {
    //   // Si existe una instancia de GoogleMapView, llamar a su método handleClearRoute
    //   console.log("Limpiando ruta activa antes de mostrar nueva ubicación");
      
    //   // Limpieza completa del estado de rutas
    //   if (setSelectedVehicleRoute) setSelectedVehicleRoute(null);
    //   if (setVehicleRouteCoordinates) setVehicleRouteCoordinates([]);
    //   if (setAlertDataForRoute) setAlertDataForRoute([]);
    //   if (setIsRouteVisible) setIsRouteVisible(false);
    //   if (setActiveVehicleId) setActiveVehicleId(null);
    //   if (setAlertCounters) setAlertCounters({});
    // }
    
    // Ahora centramos el mapa en la nueva ubicación
    setMapCenter([location.lat, location.lng]);
    setActiveAlert(alert);
    
    // Aseguramos un zoom adecuado para ver el detalle
    if (mapRef.current) {
      setTimeout(() => {
        if (mapRef.current.setZoom) {
          mapRef.current.setZoom(16);
        }
      }, 100);
    }
  };

  const handleClearActiveAlert = () => {
    setActiveAlert(null);
  };

  const handleMapMenuOpen = (event) => {
    setMapMenuAnchor(event.currentTarget);
  };

  const handleMapMenuClose = () => {
    setMapMenuAnchor(null);
  };

  const handleMapProviderChange = (provider) => {
    setMapProvider(provider);
    handleMapMenuClose();
  };

  const handleNewAlert = (alert) => {
    setAlerts((prevAlerts) => {
      const newAlerts = {
        ...prevAlerts,
        [alert.UID_Vehiculo]: alert,
      };

      // Mantener la alerta del tour si existe
      if (clonedAlert) {
        newAlerts[clonedAlert.UID_Vehiculo] = clonedAlert;
      }

      return newAlerts;
    });
  };

  const windowConfig = (()=>{
    const width = window.innerWidth * 0.8;
    const height = window.innerHeight * 0.8;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;
    return {width, height, left, top};
  })

  const handleDetach = () => {
    const {width, height, left, top} = windowConfig;
    // const width = window.innerWidth * 0.8;
    // const height = window.innerHeight * 0.8;
    // const left = (window.screen.width - width) / 2;
    // const top = (window.screen.height - height) / 2;
    const newWindow = window.open(
      "/monitor-standalone",
      "MonitorWindow",
      `width=${width},
       height=${height},
       left=${left},
       top=${top},
       menubar=no,
       toolbar=no,
       location=no,
       status=no`
    );

    // Opcional: Establecer comunicación entre ventanas
    window.addEventListener("message", (event) => {
      if (event.data.type === "ALERT_UPDATE") {
        // Manejar actualizaciones de alertas
        handleNewAlert(event.data.alert);
      }
    });
  };

  const startTour = () => {
    setMapProvider("leaflet");

    if (Object.keys(alerts).length === 0) {
      // Crear una alerta dummy si no hay alertas
      const sampleAlert = {
        UID_Vehiculo: "tour-vehicle", // Asegúrate de usar 'UID_Vehiculo' en lugar de 'id'
        unidad: "Unidad Dummy",
        chofer: "Chofer Dummy",
        telefono: "1234567890",
        fecha: new Date().toLocaleString(),
        alerta: "Alerta Dummy",
        ubicacion: {
          lat: 21.1619,
          lng: -86.8515,
          direccion: "Dirección Dummy",
        },
        adicional: { mensaje: "Mensaje Dummy" },
        estado: "verde",
      };
      //setAlerts({ [dummyAlert.UID_Vehiculo]: dummyAlert });
      setAlerts((prevAlerts) => ({
        ...prevAlerts,
        [sampleAlert.UID_Vehiculo]: sampleAlert,
      }));

      setClonedAlert(sampleAlert); // Establecer la alerta clonada
      setTourAlertId(sampleAlert.UID_Vehiculo);
    } else {
      // Clonar la primera alerta
      const firstKey = Object.keys(alerts)[0];
      const firstAlert = alerts[firstKey];
      const clonedAlert = {
        ...firstAlert,
        UID_Vehiculo: `${firstAlert.UID_Vehiculo}-clone`,
      };
      setAlerts((prevAlerts) => ({
        ...prevAlerts,
        [clonedAlert.UID_Vehiculo]: clonedAlert,
      }));
      setClonedAlert(clonedAlert); // Establecer la alerta clonada
      setTourAlertId(clonedAlert.UID_Vehiculo);
      setMapCenter([clonedAlert.ubicacion.lat, clonedAlert.ubicacion.lng]);
    }
    setRunTour(true);
  };

  const steps = [
    {
      target: ".detach-button",
      content:
        "Puedes abrir el monitor en una nueva ventana para tener mejor visibilidad.",
      disableBeacon: true,
    },
    {
      target: ".filter-bar-button",
      content:
        "Aquí puedes filtrar las alertas por fecha, unidad y tipo de alerta.",
      disableBeacon: true,
    },
    {
      target: ".expand-map",
      content: "Puedes expandir el mapa.",
      disableBeacon: true,
    },
    {
      target: ".Switch-MapIcon",
      content: "Cambiar de proveedor de mapa.",
      disableBeacon: true,
    },
    {
      target: ".alert-list",
      content:
        "Cada alerta muestra información importante como el color en los bordes izquierdo y superior que nos dicen la importancia de la misma.",
      disableBeacon: true,
    },
    {
      target: ".alert-iconoalert",
      content: "Icono que nos muestra el tipo de alerta.",
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: ".alert-unidad",
      content: "Esta es la unidad involucrada en la alerta.",
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: ".alert-chofer",
      content: "Este es el chofer asignado a la unidad.",
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: ".alert-telefono",
      content: "Este es el numero de telefono en que se generó la alerta.",
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: ".alert-fecha",
      content: "Esta es la fecha y hora en que se generó la alerta.",
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: ".alert-mapredirect",
      content:
        "Boton para ir al mapa y nos muestre el lugar exacto de la alerta.",
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: ".alert-alerta",
      content: "Este es el evento que se genera.",
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: ".alert-mensaje",
      content: "Este es el mensaje detallado de la alerta.",
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: ".alert-direccion",
      content: "Esta es la dirección donde se generó la alerta.",
      placement: "bottom",
      disableBeacon: true,
    },

    {
      target: ".map-view",
      content:
        "El mapa muestra la ubicación de todas las alertas en tiempo real.",
      disableBeacon: true,
    },
  ];

  return (
  
      <Paper
        sx={{
          p: 3,
          mr: 0.3,
          minHeight: "100vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              flexGrow: 1,
              fontWeight: "bold",
              color: (theme) => theme.palette.text.primary,
              py: 0,
              mb: 0, // Remove bottom margin
            }}
          >
            Monitor de Alertas en Tiempo Real
          </Typography>

          {balanceData && <MessageBalance balanceData={balanceData} />}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* Vehiculos/Geocercas */}
            {/* <IconButton
              onClick={() =>
                setViewMode((prev) =>
                  prev === "geocercas" ? "vehiculos" : "geocercas"
                )
              }
              title={
                viewMode === "geocercas"
                  ? "Cambiar a vista de vehículos"
                  : "Cambiar a vista de geocercas"
              }
            >
              {viewMode === "geocercas" ? <DirectionsCar /> : <Place />}
            </IconButton> */}

            {/* Expandir mapa */}
            {/* <IconButton
              onClick={toggleExpandMap}
              title="Expandir mapa"
              className="expand-map"
            >
              {expandMap ? <ListIcon /> : <MapIcon />}
            </IconButton> */}

            {/* Ocultar/Mostrar filtros */}
            {/* <IconButton
              onClick={toggleFilterBar}
              title={showFilterBar ? "Ocultar filtros" : "Mostrar filtros"}
              className="filter-bar-button"
            >
              {showFilterBar ? <FilterListOffIcon /> : <FilterListIcon />}
            </IconButton> */}

            {/* New Reset Filters button */}
           
                <IconButton
                 title="Limpiar filtros"
                  onClick={resetAllFilters}
                 /*  sx={{
                    ...sharedIconButtonStyle,
                    backgroundColor: "white",
                    marginRight: 1,
                    '&:hover': {
                      backgroundColor: 'rgba(25, 118, 210, 0.04)',
                    }
                  }} */
                >
                  <DeleteIcon />
                </IconButton>
         

            {/* Menu */}
            <Menu
              anchorEl={mapMenuAnchor}
              open={Boolean(mapMenuAnchor)}
              onClose={handleMapMenuClose}
              PaperProps={{
                sx: {
                  backgroundColor: (theme) => theme.palette.background.paper,
                  color: (theme) => theme.palette.text.primary,
                },
              }}
            >
              <MenuItem
                onClick={() => handleMapProviderChange("leaflet")}
                selected={mapProvider === "leaflet"}
              >
                OpenStreetMap
              </MenuItem>
              <MenuItem
                onClick={() => handleMapProviderChange("google")}
                selected={mapProvider === "google"}
              >
                Google Maps
              </MenuItem>
            </Menu>


            {/* Menu for sound controls */}
          <Menu
            anchorEl={soundMenuAnchor}
            open={Boolean(soundMenuAnchor)}
            onClose={handleSoundMenuClose}
            PaperProps={{
              sx: {
                backgroundColor: (theme) => theme.palette.background.paper,
                color: (theme) => theme.palette.text.primary,
                width: 200,
              },
            }}
          >
            <MenuItem onClick={toggleMute}>
              {isMuted ? "Activar sonido" : "Silenciar"}
            </MenuItem>
            <MenuItem>
              <Typography id="volume-slider" gutterBottom>
                Volumen
              </Typography>
              <Slider
                value={volume}
                onChange={handleVolumeChange}
                aria-labelledby="volume-slider"
                step={0.1}
                min={0}
                max={1}
                disabled={isMuted}
              />
            </MenuItem>
            <MenuItem onClick={stopAllSounds}>
              Detener sonidos {activeAudios > 0 ? `(${activeAudios})` : ''}
            </MenuItem>
          </Menu>


            {/* Cambiar proveedor de mapas */}
            <IconButton
              onClick={handleMapMenuOpen}
              title="Cambiar proveedor de mapas"
              className="Switch-MapIcon"
            >
              <SwitchMapIcon />
            </IconButton>

            {/* Controles de sonido */}
          <IconButton
            onClick={handleSoundMenuOpen}
            title="Controles de sonido"
            className="sound-control-button"
          >
            {isMuted ? <VolumeOff /> : activeAudios > 0 ? <VolumeUp color="primary" /> : <VolumeUp />}
          </IconButton>

            {/* Abrir en nueva ventana */}
            <IconButton
              onClick={handleDetach}
              title="Abrir en nueva ventana"
              className="detach-button"
            >
              <OpenInNewIcon />
            </IconButton>

            {/* Ayuda */}
            <IconButton onClick={startTour} title="Ayuda">
              <HelpOutlineIcon />
            </IconButton>
          </Box>
        </Box>

        <Divider sx={{ borderBottomWidth: 1, borderColor: "red" }} />

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Joyride
            steps={steps}
            run={runTour}
            continuous
            showSkipButton
            disableScrolling={true}
            disableBeacon={true}
            styles={{
              options: {
                zIndex: 10000,
              },
            }}
            locale={{
              back: "Atrás",
              close: "Cerrar",
              last: "Finalizar",
              next: "Siguiente",
              skip: "Saltar",
            }}
            callback={(data) => {
              const { index, status, type } = data;
              if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
                setRunTour(false);
                setTourAlertId(null);

                setAlerts((prevAlerts) => {
                  const newAlerts = { ...prevAlerts };
                  if (clonedAlert) {
                    delete newAlerts[clonedAlert.UID_Vehiculo];
                  }
                  return newAlerts;
                });
                setClonedAlert(null);
              }

              if (type === "step:before") {
                const currentStep = steps[index];
                if (currentStep.target === ".alert-mapredirect") {
                  if (clonedAlert) {
                    setMapCenter([
                      clonedAlert.ubicacion.lat,
                      clonedAlert.ubicacion.lng,
                    ]);
                    setActiveAlert(clonedAlert);
                    setTimeout(() => {
                      setActiveAlert(null);
                    }, 5000);
                  }
                } else if (currentStep.target.startsWith(".alert-")) {
                }
              }

              // if (type === 'step:after') {
              //   const currentStep = steps[index];
              //   if (currentStep.target === '.alert-mapredirect') {
              //     if (clonedAlert) {
              //       setTimeout(() => {
              //         setActiveAlert(null);
              //       }, 5000);
              //     }
              //   } else if (currentStep.target.startsWith('.alert-')) {
              //   }
              // }
            }}
          />
          <AnimatePresence>
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Box
                sx={{
                  maxHeight: "100vh",
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                }}
              >
                {/* <Paper elevation={3} sx={{ p: 1, mb: 2 }}> */}
                <Paper
                  sx={{
                    // height: '100%',
                    // display: 'flex',
                    // flexDirection: 'column',
                    // overflow: 'hidden'
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    height:"100vh",
                  }}
                >
                  <CssBaseline />

                  {/* {showFilterBar && 
                    <FilterBar 
                    onFilter={setFilters}
                    />
                    } */}

                  {/* Filtros */}
                  <AnimatePresence>
                    {showFilterBar && (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                      >
                        <FilterBar onFilter={handleFilterChange} 
                        //onResetFilters={() => {}} // Optional callback when filters are reset from FilterBar
                        ref={filterBarRef}
                         />
                      </motion.div>
                    )}
                  </AnimatePresence>

                  {/* Contenedor de las Cards y del Mapa */}
                  <Box
                    display="flex"
                    flexDirection={isMobile ? "column" : "row"}
                    alignItems="stretch"
                    sx={{
                      width: "100%",
                      height: "100vh",
                      overflow: "hidden",
                      // pt: 0,
                    }}
                  >
                    {/* Contenedor de las Cards */}
                    <Box
                      flex={expandMap ? 0 : 1}
                      sx={{
                        display: expandMap ? "none" : "block",
                        height: "100%", // Añadir altura completa
                        //minHeight: '600px' // Altura mínima para mantener consistencia
                        overflow: "hidden",
                      }}
                      // sx={{ display: expandMap ? 'none' : 'block' }}
                      className="alert-list"
                    >
                      <AlertList
                        onMapIconClick={handleMapIconClick}
                        onNewAlert={handleNewAlert}
                        onAlertsUpdate={handleAlertsUpdate}
                        onLoadComplete={() => handleComponentLoad("alerts")}
                        tourAlertId={tourAlertId}
                        filters={filters}
                      />
                      {clonedAlert && (
                        <ClonedAlertOverlay
                          alert={clonedAlert}
                          onClose={() => setClonedAlert(null)}
                          className="ClonedAlertOverlay"
                        />
                      )}
                    </Box>
                    {/* Contenedor de los Mapas */}
                    <Box
                      flex={expandMap ? 1 : 2.2}
                      ml={showAlertList && !expandMap ? 2 : 0}
                      style={{
                        position: "relative",
                        height: "100%",
                        transition: "all 0.3s ease",
                      }}
                      sx={{
                        width: "100%",
                        height: "calc(100vh -200px)", // Añadir altura completa
                        //minHeight: 'calc(100vh - 120px)',  // Altura mínima para mantener consistencia
                        overflow: "hidden",
                      }}
                      className="map-view"
                    >
                      {mapProvider === "leaflet" ? (
                        <MapView
                          center={mapCenter} //
                          alerts={Object.values(alerts)}
                          activeAlert={activeAlert} //
                          onClearActiveAlert={handleClearActiveAlert}
                          expandMap={expandMap}
                          onLoadComplete={() => handleComponentLoad("map")}
                          viewMode={viewMode}
                          allAlerts={allAlerts}
                        />
                      ) : (
                        <GoogleMapView
                          center={mapCenter}
                          alerts={Object.values(alerts)}
                          activeAlert={activeAlert}
                          onClearActiveAlert={handleClearActiveAlert}
                          expandMap={expandMap}
                          onLoadComplete={() => handleComponentLoad("map")}
                          viewMode={viewMode}
                          filters={filters}
                          allAlerts={allAlerts}

                          selectedVehicleRoute={selectedVehicleRoute}
                          setSelectedVehicleRoute={setSelectedVehicleRoute}
                          vehicleRouteCoordinates={vehicleRouteCoordinates}
                          setVehicleRouteCoordinates={setVehicleRouteCoordinates}
                          isRouteVisible={isRouteVisible}
                          setIsRouteVisible={setIsRouteVisible}
                          activeVehicleId={activeVehicleId}
                          setActiveVehicleId={setActiveVehicleId}
                          alertCounters={alertCounters}
                          setAlertCounters={setAlertCounters}
                          alertDataForRoute={alertDataForRoute}
                          setAlertDataForRoute={setAlertDataForRoute}
                          //onMapReset={handleClearRoute}

                        />
                      )}
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </motion.div>
          </AnimatePresence>
        </LocalizationProvider>
      </Paper>
    
  );
};

export default Monitor;
