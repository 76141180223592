import {
  Autocomplete,
  Checkbox,
  TextField,
  Grid,
  FormControlLabel,
  Divider,
  Box,
  IconButton,

  FormControl,
  InputLabel,
  Select,
  MenuItem,

} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

import { useEffect, useState } from "react";
import { isEmptyArray } from "formik";
import { vehiculoService,groupService } from "../../../services/apiService";
import ImageContainer from "./ImageContainer";
import notFoundVehicle from "../../../assets/not-found/empty-space-vehiculos.png";
import { confirmDialog } from "../../../components/ConfirmDialog";
import NextButton from "./NextButton";

export default function ListaVehiculo({
  configuracion,
  actualizarComponente,
  handleGuardarPrincipal,
  idAlerta,
  nextComponent,
  isEditMode,
  handleClose,
  errorVehiculo,
  actualizarErrores
}) {
  /*Lista de Vehiculos*/
  const [vehicles, setVehicles] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [selectedGroup, setSelectedGroup] = useState('');
  // const [selectedGroup, setSelectedGroup] = useState('');


  // const loadVehiculos = async () => {
  //   try {
  //     const response = await vehiculoService.getVehiculos("", 1);
  //     console.log("Vehiculos", response);
  //     if (response.statusCode === 200) {
  //       setVehicles(response.data);

  //       setFilteredVehicles(response.data);
  //     }
  //   } catch (error) {
  //     console.error("Error cargando vehículos:", error);
  //   }
  // };

  const loadVehiculos = async () => {
    try {
      const response = await vehiculoService.getVehiculos("", 1);
      console.log("Vehiculos", response);
      if (response.statusCode === 200) {
        setVehicles(response.data);
        
        // Apply initial filtering if a group is already selected
        if (configuracion.grupoId) {
          const filtered = response.data.filter(
            vehicle => vehicle.grupo_uid === configuracion.grupoId
          );
          setFilteredVehicles(filtered);
        } else {
          setFilteredVehicles(response.data);
        }
      }
    } catch (error) {
      console.error("Error cargando vehículos:", error);
      setVehicles([]);
      setFilteredVehicles([]);
    }
  };


  // Handle group change
  const handleGroupChange = (event) => {
    const groupId = event.target.value;
    setSelectedGroup(groupId);
    actualizarComponente("grupoId", groupId);
  };
  
  

  // const handleToggleSelectAll = () => {
  //   setSelectAll((prev) => {
  //     if (!prev) setSelectedVehicles([...vehicles]);
  //     else setSelectedVehicles([]);
  //     return !prev;
  //   });
  // };

  const handleToggleSelectAll = () => {
    setSelectAll((prev) => {
      if (!prev) {
        // Select all filtered vehicles (not all vehicles)
        setSelectedVehicles([...filteredVehicles]);
      }
      else setSelectedVehicles([]);
      return !prev;
    });
  };


  const handleAgregarVehiculo = async () => {
    const isConfirmed = await confirmDialog({
      title: "Crear vehículo",
      message:
        "Será redireccionado a Quro para crear el vehículo, ¿Está seguro de realizar esta acción?",
      confirmLabel: "Aceptar",
      cancelLabel: "Cancelar",
    });
    if (!isConfirmed) return;
  };

  const handleRemoveVehiculo = (id) => {
    setSelectedVehicles((prevSelected) =>
      prevSelected.filter((vehicle) => vehicle.id !== id)
    );
    if (selectedVehicles.length === 0) setSelectAll(false);
  };

  useEffect(() => {
    loadVehiculos();
  }, []);


  useEffect(() => {
    if (configuracion.tipo_alerta.nombre !== "Acercamiento") {
      const selectedVehicleIds = selectedVehicles.map((vehicle) => vehicle.id);
      actualizarComponente("vehiculo", selectedVehicleIds);
      
      // Si selecciona al menos un vehículo, eliminar el error
      if (selectedVehicleIds.length > 0 && errorVehiculo) {
        actualizarErrores("errorVehiculo", false);
      }
    } else {
      const selectedVehicleIds = selectedVehicles.map((vehicle) => vehicle.id);
      actualizarComponente("vehiculo", selectedVehicleIds);
      actualizarComponente("vehiculo_compare", selectedVehicleIds);
      
      // Si selecciona al menos dos vehículos, eliminar el error en modo acercamiento
      if (selectedVehicleIds.length >= 2 && errorVehiculo) {
        actualizarErrores("errorVehiculo", false);
      }
    }
  }, [selectedVehicles]);


  // useEffect(() => {
  //   if (configuracion.tipo_alerta.nombre !== "Acercamiento") {
  //     const selectedVehicleIds = selectedVehicles.map((vehicle) => vehicle.id);
  //     actualizarComponente("vehiculo", selectedVehicleIds);
  //   } else {
  //     const selectedVehicleIds = selectedVehicles.map((vehicle) => vehicle.id);
  //     actualizarComponente("vehiculo", selectedVehicleIds);
  //     actualizarComponente("vehiculo_compare", selectedVehicleIds);
  //   }
  // }, [selectedVehicles]);

  useEffect(() => {
    if (configuracion.grupoId) {
      setSelectedGroup(configuracion.grupoId);
    }
  }, [configuracion.grupoId]);

  useEffect(() => {
    if (vehicles.length > 0) {
      cargarSeleccionados();
    }
  }, [vehicles, configuracion.vehiculo, configuracion.vehiculo_compare, configuracion.grupoId]);

  // useEffect(() => {
  //   if (vehicles.length > 0) {
  //     if (configuracion.grupoId) {
  //       // Filter vehicles by group_uid
  //       const filtered = vehicles.filter(
  //         vehicle => vehicle.grupo_uid === configuracion.grupoId
  //       );
  //       setFilteredVehicles(filtered);
        
  //       // Update selected vehicles if some are no longer in the filtered list
  //       if (selectedVehicles.length > 0) {
  //         const updatedSelection = selectedVehicles.filter(
  //           selected => filtered.some(v => v.id === selected.id)
  //         );
          
  //         if (updatedSelection.length !== selectedVehicles.length) {
  //           setSelectedVehicles(updatedSelection);
  //           setSelectAll(updatedSelection.length === filtered.length && filtered.length > 0);
  //         }
  //       }
  //     } else {
  //       // If no group selected, show all vehicles
  //       setFilteredVehicles(vehicles);
  //     }
  //   }
  // }, [configuracion.grupoId, vehicles]);

  useEffect(() => {
    if (vehicles.length > 0) {
      if (configuracion.grupoId) {
        // Filter vehicles by group_uid
        const filtered = vehicles.filter(
          vehicle => vehicle.grupo_uid === configuracion.grupoId
        );
        setFilteredVehicles(filtered);
        
        // Update selected vehicles if some are no longer in the filtered list
        if (selectedVehicles.length > 0) {
          const updatedSelection = selectedVehicles.filter(
            selected => filtered.some(v => v.id === selected.id)
          );
          
          if (updatedSelection.length !== selectedVehicles.length) {
            setSelectedVehicles(updatedSelection);
            setSelectAll(updatedSelection.length === filtered.length && filtered.length > 0);
          }
        }
      } else {
        // If no group selected, show all vehicles
        setFilteredVehicles(vehicles);
      }
    }
  }, [configuracion.grupoId, vehicles, selectedVehicles]);

  // Update the selected group state when configuration changes
  useEffect(() => {
    if (configuracion.grupoId) {
      setSelectedGroup(configuracion.grupoId);
    }
  }, [configuracion.grupoId]);
  
  // useEffect(() => {
  //   if (vehicles.length > 0) {
  //     if (selectedGroup) {
  //       // Filter vehicles that belong to the selected group
  //       const groupVehicles = vehicles.filter(vehicle => vehicle.grupo_uid === selectedGroup);
  //       setFilteredVehicles(groupVehicles);
        
  //       // If all vehicles are selected and we filter, update selection to only include filtered vehicles
  //       if (selectAll) {
  //         setSelectedVehicles(prev => 
  //           prev.filter(vehicle => groupVehicles.some(v => v.id === vehicle.id))
  //         );
  //         setSelectAll(groupVehicles.length > 0 && 
  //           selectedVehicles.length === groupVehicles.length);
  //       }
  //     } else {
  //       // If no group selected, show all vehicles
  //       setFilteredVehicles(vehicles);
  //     }
  //   }
  // }, [selectedGroup, vehicles]);


  // useEffect(() => {
  //   if (vehicles.length > 0) {
  //     cargarSeleccionados();
  //   }
  // }, [vehicles, configuracion.vehiculo, configuracion.vehiculo_compare]);

  const cargarSeleccionados = async () => {
    //Validamos si el componente fue limpiado
    if (isEmptyArray(configuracion.vehiculo)) {
      //refrescamos el state si es necesario
      if (!isEmptyArray(selectedVehicles)) {
        setSelectedVehicles([]);
        setSelectAll(false);
        loadVehiculos();
      }
    } else {
      // cuando se obtiene configuración
      // Comparamos si las seleccionadas actuales coinciden con las de la bd
      const selectedFromConfig = configuracion.vehiculo
        .map((vehicle_id) => {
          const vehicle_id_str = vehicle_id.toString();

          //Buscamos los vehiculos seleccionados
          const matchingVehicle = vehicles.find(
            (vehicle) => vehicle.id.toString() === vehicle_id_str
          );

          //Valida si encontro vehiculos selecciono
          if (matchingVehicle) {
            return {
              ...matchingVehicle,
            };
          }
          return null;
        })
        .filter((item) => item !== null);
      // Verificamos si el estado actual ya tiene seleccionadas
      const areEqual =
        selectedVehicles.length === selectedFromConfig.length &&
        selectedVehicles.every(
          (sel, index) => sel.id === selectedFromConfig[index].id
        );

      if (!areEqual) {
        setSelectedVehicles(selectedFromConfig); // Actualizamos solo si es necesario
        // Notificamos al componente padre si el estado cambia
        actualizarComponente(
          "vehiculo",
          selectedFromConfig.map((item) => {
            return `${item.id}`;
          })
        );
      }
    }

     // Handle group selection
     if (configuracion.grupoId && configuracion.grupoId !== selectedGroup) {
      setSelectedGroup(configuracion.grupoId);
    }


  };

  

  return (
    <>
      <Box
        height={310}
        sx={{
          display: "flex",
          alignItems: "stretch",
          alignContent: "space-between",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          flexBasis="25%" // En lugar de width
          flexGrow={0}    // Evitar que crezca más allá del 60%
          flexShrink={0}  // Evitar que se encoja
          pt={1}
          gap={2}
        >




          <Box display={"flex"} alignItems={"center"} gap={1} width={"100%"}>
            <Box flexGrow={1}>
              <Autocomplete
                id="checkboxes-vehiculo"
                multiple
                noOptionsText={"No hay coincidencias"}
                disableCloseOnSelect
                disableListWrap
                size="small"
                fullWidth
                // options={vehicles}
                options={filteredVehicles} 
                getOptionLabel={(option) =>
                  `${option.alias} - ${option.no_Economico}`
                }
                // value={selectedVehicles}
                // value={selectedVehicles.map((selected) =>
                //   vehicles.find((vehiculo) => vehiculo.id === selected.id)
                // )}
                value={selectedVehicles.map((selected) =>
                  filteredVehicles.find((vehiculo) => vehiculo.id === selected.id) || selected
                ).filter(Boolean)}
                onChange={(_e, value, reason) => {
                  if (
                    reason === "removeOption" &&
                    selectedVehicles.length > 0
                  ) {
                    setSelectedVehicles(selectedVehicles);
                    return;
                  }
                  switch (reason) {
                    case "clear":
                      setSelectAll(false);
                      setSelectedVehicles([]);
                      break;
                    case "removeOption":
                      setSelectAll(false);
                      setSelectedVehicles(value);
                      break;
                    // case "selectOption":
                    //   if (value.length === vehicles.length) setSelectAll(true);
                    //   setSelectedVehicles(value);
                    //   break;
                    case "selectOption":
                      // Check if all filtered vehicles are selected
                      if (value.length === filteredVehicles.length) {
                        setSelectAll(true);
                      }
                      setSelectedVehicles(value);
                      break;
                    default:
                      setSelectedVehicles(value);
                  }
                }}
                defaultChecked
                renderOption={(props, option, { selected }) => {
                  const { key, ...optionProps } = props;
                  return (
                    <li key={option.id} {...optionProps}>
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={selected}
                        onChange={() => {
                          const isSelected = selectedVehicles.some(
                            (v) => v.id === option.id
                          );
                          setSelectedVehicles(
                            isSelected
                              ? selectedVehicles.filter(
                                  (v) => v.id !== option.id
                                )
                              : [...selectedVehicles, option]
                          );
                        }}
                      />
                      {`${option.alias} - ${option.no_Economico}`}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Vehículos"
                    placeholder="Selecciona un vehículo"
                    error={errorVehiculo} // Aplicar el error cuando errorVehiculo es true
                    helperText={errorVehiculo && (configuracion.tipo_alerta.nombre === "Acercamiento" 
                      ? "Selecciona al menos 2 vehículos" 
                      : "Selecciona al menos un vehículo")}
                    // label="Vehículos"
                    // placeholder="Selecciona un vehículo"
                  />
                )}
                renderTags={(selectedOptions) => {
                  return <></>;
                }}
                groupBy={() => ""}
                renderGroup={(params) => (
                  <div key={params.key}>
                    <>
                      <Box
                        onMouseDown={(e) => e.preventDefault()} // prevent blur
                        pl={3.5}
                        py={0.5}
                      >
                        <FormControlLabel
                          onClick={(e) => {
                            e.preventDefault(); // prevent blur
                            handleToggleSelectAll();
                          }}
                          label="Seleccionar todos"
                          control={
                            <Checkbox
                              id="select-all-checkbox"
                              checked={selectAll}
                            />
                          }
                        />
                      </Box>
                      <Divider />
                    </>
                    {params.children}
                  </div>
                )}
              />
            </Box>
          </Box>
        </Box>

        <Box marginLeft={2} display="flex">
          <Divider
            orientation="vertical"
            //flexItem
            sx={{
              borderRightWidth: 1,
              borderColor: "red",
            }}
          />
        </Box>

        <Box 
        marginLeft={2} pt={1} flexBasis="75%" flexGrow={0} flexShrink={0}
         >
  {selectedVehicles.length > 0 ? (
    <>
      <Grid
        container
        alignContent="flex-start"
        display={"flex"}
        direction={"column"}
        wrap="wrap"
        height={300}
        sx={{
          overflowX: vehicles.length >= 5 ? "auto" : "visible",
          overflowY: "auto", // Añadimos scroll vertical si hay muchos elementos
        }}
        gap={1} // Reducido de 2 a 1 para disminuir el espacio entre elementos
      >
        {selectedVehicles.map((vehiculo) => (
          <Grid
            item
            key={vehiculo.uuid + vehiculo.no_Economico}
            p={1} // Reducido el padding de 2 a 1
            width={"30%"}
            border={1}
            borderRadius={"8px"}
            borderColor={"grey.100"}
            sx={{ 
              position: "relative",
              minHeight: "36px", // Altura mínima más compacta
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              size="small"
              aria-label="close"
              onClick={() => handleRemoveVehiculo(vehiculo.id)}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                padding: "2px", // Reducir el padding del botón
              }}
            >
              <CancelIcon fontSize="small" />
            </IconButton>
            <Box
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                display: "block",
                width: "calc(100% - 24px)", // Ajustado para dar espacio al botón
                fontSize: "0.875rem", // Texto más pequeño (14px)
                pr: 3, // Padding derecho para evitar solapar con el botón
              }}
            >
              {`${vehiculo.alias} - ${vehiculo.no_Economico}`}
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  ) : (
    <>
      <ImageContainer src={notFoundVehicle} />
    </>
  )}
</Box>
        {/* <Box marginLeft={2} flexGrow={1} pt={1}>
          {selectedVehicles.length > 0 ? (
            <>
              <Grid
                container
                alignContent="flex-start"
                display={"flex"}
                direction={"column"}
                wrap="wrap"
                height={300}
                sx={{
                  overflowX: vehicles.length >= 5 ? "auto" : "visible",
                }}
                gap={2}
              >
                {selectedVehicles.map((vehiculo) => (
                  <Grid
                    item
                    key={vehiculo.uuid + vehiculo.no_Economico}
                    p={2}
                    width={"48%"}
                    // height={"20%"}
                    border={1}
                    borderRadius={"8px"}
                    borderColor={"grey.100"}
                    sx={{ position: "relative" }}
                  >
                    <IconButton
                      size="small"
                      aria-label="close"
                      onClick={() => handleRemoveVehiculo(vehiculo.id)}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0, // Posiciona el botón en la esquina superior derecha
                      }}
                    >
                      <CancelIcon fontSize="small" />
                    </IconButton>
                    <Box
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        display: "block",
                        width: "100%", // Asegura que respete el ancho del contenedor
                      }}
                    >
                      {`${vehiculo.alias} - ${vehiculo.no_Economico}`}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            <>
              <ImageContainer src={notFoundVehicle} />
            </>
          )}
        </Box> */}
      </Box>
      {isEditMode && (
        <NextButton
          handleGuardarPrincipal={handleGuardarPrincipal}
          isEditMode={true}
          nextComponent={nextComponent}
          idAlerta={idAlerta}
          handleClose={handleClose}
          ModalName={
            configuracion.tipo_alerta.nombre === "Acercamiento"
              ? "vehiculo-comparar"
              : "vehiculo"
          }
        />
      )}
    </>
  );
}
