import React, { useState, useEffect, useContext } from 'react';
import { 
  Container, 
  Box, 
  Paper, 
  Typography, 
  TextField, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  CircularProgress,
  Alert,
  Button,
  Snackbar,
  InputAdornment,useTheme,useMediaQuery
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { messageService, authService, messageAuthService } from '../../services/apiService';
import { useLoading } from '../../contexts/LoadingContext';
import { 
  Email as EmailIcon, 
  WhatsApp as WhatsAppIcon, 
  Sms as SmsIcon,
  RecordVoiceOver as VoiceIcon,
  AttachMoney as MoneyIcon,
  Inventory as InventoryIcon
} from '@mui/icons-material';
import { decrypt } from "../../utils/encryption";

const MessageBalancePage = () => {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { setIsLoading, setLoadingMessage } = useLoading(); 

  const [balanceData, setBalanceData] = useState([]);
  const [loading, setLoading] = useState(true); // Comenzar con loading true
  const [dataLoaded, setDataLoaded] = useState(false); // Nuevo estado para rastrear si los datos han sido cargados
  const [error, setError] = useState(null);
  const [editedQuantities, setEditedQuantities] = useState({});
  const [notificationTypes, setNotificationTypes] = useState([]);

  const [editedValues, setEditedValues] = useState({});
  const [calculationSource, setCalculationSource] = useState({});

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info'
  });
  
  const location = useLocation();
  const navigate = useNavigate();
  const { login, autenticado } = useContext(AuthContext);

  const loadAllData = async (specificCompanyId = null) => {
    try {
      setIsLoading(true);
      setLoading(true);
      setLoadingMessage('Cargando datos de mensajería...');
      
      // Cargar balance y tipos de notificación en paralelo
      const [balanceResponse, typesResponse] = await Promise.all([
        messageService.getMessageBalance("default", specificCompanyId),
        messageService.getNotificationTypes()
      ]);
      
      // Procesar resultados del balance
      if (balanceResponse.success) {
        setBalanceData(balanceResponse.data);
      } else {
        setError("Error al cargar datos de balance");
        console.error("Error en respuesta de balance:", balanceResponse);
      }
      
      // Procesar resultados de tipos de notificación
      if (typesResponse && typesResponse.success) {
        setNotificationTypes(typesResponse.data || []);
      } else {
        console.error("Error en respuesta de tipos de notificación:", typesResponse);
      }
      
      // Marcar que los datos han sido cargados
      //setDataLoaded(true);
    } catch (error) {
      console.error("Error al cargar datos:", error);
      setError("Error al cargar datos: " + (error.message || "Error desconocido"));
    } finally {
      setIsLoading(false);
      setLoading(false);
    }
  };


  // Extract login parameters from URL query string
  useEffect(() => {
    const authenticateFromUrl = async () => {
      try {
        setIsLoading(true);
        setLoading(true);
        setLoadingMessage('Autenticando...');
        //setDataLoaded(false); 
        const searchParams = new URLSearchParams(location.search);
        const combinedUsername = searchParams.get("username");
        const encryptedPassword = searchParams.get("password");
        const targetCompanyId = searchParams.get("companyId");

        if (!combinedUsername || !encryptedPassword) {
          setError("Faltan parámetros de autenticación en la URL");
          setIsLoading(false);
          setLoading(false);
          return;
        }
    
        // Parse combinedUsername
        let userid = "";
        let username = "";
        
        if (combinedUsername.includes('@')) {
          const parts = combinedUsername.split('@');
          userid = parts[0];
          username = parts[1];
        } else {
          setError("Formato de usuario incorrecto");
          setIsLoading(false);
          setLoading(false);
          return;
        }
        
        const password = decrypt(encryptedPassword);
    
        // If user is not authenticated, try to login
        if (!autenticado) {
          setLoadingMessage('Iniciando sesión...');
          const credentials = {
            usuario: `${userid}@${username}`,
            password: password,
          };
          
          const response = await authService.login(credentials);
          
          if (response.statusCode === 200) {
            // Login with AuthContext to set all required states
            const { 
              token, nombre, uuid_empresa, uuid_usuario, 
              nuevo_usuario,  uid_externo, user, 
              grupos, razon_social, nombre_comercial,id_externo
            } = response.data;
            
            await login(
              token,
              nombre,
              uuid_empresa,
              uuid_usuario,
              nuevo_usuario,
              encryptedPassword,
              uid_externo,
              user,
              grupos,
              razon_social,
              nombre_comercial,id_externo
            );
            
            // Get message API token using direct credentials first
            await messageAuthService.login({
              userId: userid,
              username: username,
              password: password
            });
            
            // Now load data after authentication is complete
            // await loadMessageBalance();
            // await loadNotificationTypes();
            setLoadingMessage('Cargando datos de mensajería...');
            await loadAllData(targetCompanyId);
          } else {
            setError("Error de autenticación: " + (response.message || "Credenciales inválidas"));
          }
        } else {
          // User already authenticated, just load the data
          // await loadMessageBalance(targetCompanyId);
          // await loadNotificationTypes();
          setLoadingMessage('Cargando datos de mensajería...');
          await loadAllData(targetCompanyId);
        }
      } catch (error) {
        console.error("Error en autenticación:", error);
        setError("Error al autenticar: " + (error.message || "Error desconocido"));
      } finally {
        setIsLoading(false);
        setLoading(false);
      }
    };

    authenticateFromUrl();
  }, [location.search, autenticado]);

  const loadMessageBalance = async (specificCompanyId = null) => {
    try {
      const response = await messageService.getMessageBalance("default", specificCompanyId);
      
      // if (response && response.success) {
      //   setBalanceData(response.data || []);
        
      //   // Initialize edited quantities with current values
      //   const initialQuantities = {};
      //   response.data.forEach(item => {
      //     initialQuantities[item.notificationTypeId._id] = item.cantidad;
      //   });
      // The data is already processed by messageService, so use it directly
    if (response.success) {
      // Use response.data directly since it's already in the expected format
      setBalanceData(response.data);
        //setEditedQuantities(initialQuantities);
      } else {
        setError("Error al cargar datos de balance");
      }
    } catch (error) {
      console.error("Error al cargar balance de mensajes:", error);
      setError("Error al cargar balance de mensajes: " + (error.message || "Error desconocido"));
    } finally {
      setLoading(false);
    }
  };

  const loadNotificationTypes = async () => {
    try {
      const response = await messageService.getNotificationTypes();
      if (response && response.success) {
        setNotificationTypes(response.data || []);
      }
    } catch (error) {
      console.error("Error al cargar tipos de notificación:", error);
    }
  };

  const handleQuantityChange = (typeId, value) => {
    // Ensure the value is a positive integer
    const numValue = parseInt(value, 10);
    
    if (isNaN(numValue) || numValue < 0) {
      return;
    }
    
    setEditedQuantities(prev => ({
      ...prev,
      [typeId]: numValue
    }));
  };

 const handleValueChange = (typeId, field, value) => {
    // Asegurar que el valor sea numérico
    const numValue = parseFloat(value) || 0;
    
    // Actualizar el campo específico
    setEditedValues(prev => ({
      ...prev,
      [typeId]: {
        ...(prev[typeId] || {}),
        [field]: numValue
      }
    }));
    
    // Registrar qué campo fue modificado para los cálculos
    setCalculationSource(prev => ({
      ...prev,
      [typeId]: field
    }));
    
    // Obtener valor actual o del estado
    const currentValues = editedValues[typeId] || {};
    const balanceItem = balanceData.find(item => item.notificationTypeId._id === typeId);
    
    // Para los cálculos, usamos los valores editados o los originales
    let cantidad = currentValues.cantidad !== undefined ? currentValues.cantidad : (balanceItem?.cantidad || 0);
    let costoUnitario = currentValues.costo_unitario !== undefined ? currentValues.costo_unitario : (balanceItem?.costo_unitario || 0);
    let costoTotal = currentValues.costo_total !== undefined ? currentValues.costo_total : (balanceItem?.costo_total || 0);
    
    // Según qué campo se modificó, calcular los otros
    if (field === 'cantidad') {
      costoTotal = numValue * costoUnitario;
      
      // Actualizar campo derivado
      setEditedValues(prev => ({
        ...prev,
        [typeId]: {
          ...(prev[typeId] || {}),
          cantidad: numValue,
          costo_total: costoTotal
        }
      }));
    } 
    else if (field === 'costo_unitario') {
      costoTotal = cantidad * numValue;
      
      // Actualizar campo derivado
      setEditedValues(prev => ({
        ...prev,
        [typeId]: {
          ...(prev[typeId] || {}),
          costo_unitario: numValue,
          costo_total: costoTotal
        }
      }));
    } 
    else if (field === 'costo_total') {
      // Si hay costo unitario, calcular cantidad
      if (costoUnitario > 0) {
        cantidad = Math.floor(numValue / costoUnitario);
        
        // Actualizar campo derivado
        setEditedValues(prev => ({
          ...prev,
          [typeId]: {
            ...(prev[typeId] || {}),
            costo_total: numValue,
            cantidad: cantidad
          }
        }));
      } else {
        // Si no hay costo unitario, solo actualizar costo total
        setEditedValues(prev => ({
          ...prev,
          [typeId]: {
            ...(prev[typeId] || {}),
            costo_total: numValue
          }
        }));
      }
    }
  };

  const handleSaveChanges = async () => {
    try {
      setIsLoading(true);
      setLoading(true);
      setLoadingMessage('Guardando cambios...');

      const searchParams = new URLSearchParams(location.search);
    const targetCompanyId = searchParams.get("companyId");
      
      // Preparar actualizaciones para paquetes existentes y nuevos paquetes
      const updatePromises = [];
      
      // Procesar las ediciones
      for (const [typeId, values] of Object.entries(editedValues)) {
        // Encontrar el paquete existente
        const existingPackage = balanceData.find(item => 
          item.notificationTypeId._id === typeId
        );
        
        // Si existe, actualizar
        if (existingPackage) {
          // Verificar si hay cambios
          let hasChanges = false;
          for (const [field, value] of Object.entries(values)) {
            if (existingPackage[field] !== value) {
              hasChanges = true;
              break;
            }
          }
          
          if (hasChanges) {
            updatePromises.push(
              messageService.updateNotificationPackage(existingPackage._id, values)
            );
          }
        }
        // Si no existe, crear nuevo paquete
        else {
          // Obtener información del tipo de notificación
          const typeInfo = notificationTypes.find(t => t._id === typeId);
          if (!typeInfo) continue;
          
          // Crear nuevo paquete
          const newPackage = {
            notificationTypeId: typeId,
            companyId: targetCompanyId || localStorage.getItem("uuid_empresa"), 
            cantidad: Math.floor(values.cantidad || 0),
            costo_unitario: values.costo_unitario || 0,
            fecha_inicio: new Date().toISOString(),
            fecha_fin: new Date(new Date().getFullYear() + 1, 11, 31).toISOString(),
            estatus: 'activo'
          };
          
          updatePromises.push(
            messageService.createNotificationPackage(newPackage)
          );
        }
      }
      
      if (updatePromises.length === 0) {
        setSnackbar({
          open: true,
          message: "No hay cambios para guardar",
          severity: "info"
        });
        setIsLoading(false);
        setLoading(false);
        return;
      }
      
      // Esperar a que todas las actualizaciones se completen
      await Promise.all(updatePromises);
      
      // Recargar datos de balance
      await loadAllData(targetCompanyId);
      
      // Limpiar ediciones
      setEditedValues({});
      setCalculationSource({});
      
      setSnackbar({
        open: true,
        message: "Cambios guardados correctamente",
        severity: "success"
      });
      
    } catch (error) {
      console.error("Error al guardar cambios:", error);
      setSnackbar({
        open: true,
        message: "Error al guardar cambios: " + (error.message || "Error desconocido"),
        severity: "error"
      });
    } finally {
      setIsLoading(false);
      setLoading(false);
    }
  };

  const getIconForType = (type) => {
    const typeName = type.toLowerCase();
    
    if (typeName.includes('email') || typeName.includes('correo')) {
      return <EmailIcon />;
    } else if (typeName.includes('whatsapp') || typeName.includes('wats')) {
      return <WhatsAppIcon />;
    } else if (typeName.includes('sms') || typeName.includes('texto')) {
      return <SmsIcon />;
    } else if (typeName.includes('voz')) {
      return <VoiceIcon />;
    }
    
    return null;
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({
      ...prev,
      open: false
    }));
  };

  // Function to find a balance item by type name
  const findBalanceItemByTypeName = (typeName) => {
    return balanceData.find(item => 
      item.notificationTypeId.nombre.toLowerCase() === typeName.toLowerCase()
    );
  };

  // Function to render rows for known types even if not in balance data
  // const getKnownTypeRow = (typeName, icon) => {
  //   const balanceItem = findBalanceItemByTypeName(typeName);
  //   const typeInfo = notificationTypes.find(t => t.nombre.toLowerCase() === typeName.toLowerCase());
    
  //   const typeId = balanceItem?.notificationTypeId?._id || typeInfo?._id || `temp-${typeName}`;
    
  //   // Usar valores editados o valores originales
  //   const editedTypeValues = editedValues[typeId] || {};
    
  //   const cantidad = editedTypeValues.cantidad !== undefined ? 
  //     editedTypeValues.cantidad : (balanceItem?.cantidad || 0);
    
  //   const costoUnitario = editedTypeValues.costo_unitario !== undefined ? 
  //     editedTypeValues.costo_unitario : (balanceItem?.costo_unitario || 0);
    
  //   const costoTotal = editedTypeValues.costo_total !== undefined ? 
  //     editedTypeValues.costo_total : (balanceItem?.costo_total || 0);
    
  //   const utilizadas = balanceItem?.utilizadas || 0;
  //   const disponibles = balanceItem?.disponibles || 0;
    
  //   return (
  //     <TableRow key={typeId}>
  //       <TableCell>
  //         <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
  //           {icon}
  //           <Typography variant="body1">{typeName}</Typography>
  //         </Box>
  //       </TableCell>
  //       <TableCell>
  //         <TextField
  //           type="number"
  //           value={cantidad}
  //           onChange={(e) => handleValueChange(typeId, 'cantidad', e.target.value)}
  //           inputProps={{ min: 0, step: 1 }}
  //           variant="outlined"
  //           size="small"
  //         />
  //       </TableCell>
  //       <TableCell>
  //         <TextField
  //           type="number"
  //           value={costoUnitario}
  //           onChange={(e) => handleValueChange(typeId, 'costo_unitario', e.target.value)}
  //           inputProps={{ min: 0, step: 0.01 }}
  //           variant="outlined"
  //           size="small"
  //           InputProps={{
  //             startAdornment: <InputAdornment position="start">$</InputAdornment>,
  //           }}
  //         />
  //       </TableCell>
  //       <TableCell>
  //         <TextField
  //           type="number"
  //           value={costoTotal}
  //           onChange={(e) => handleValueChange(typeId, 'costo_total', e.target.value)}
  //           inputProps={{ min: 0, step: 0.01 }}
  //           variant="outlined"
  //           size="small"
  //           InputProps={{
  //             startAdornment: <InputAdornment position="start">$</InputAdornment>,
  //           }}
  //         />
  //       </TableCell>
  //       <TableCell>{utilizadas}</TableCell>
  //       <TableCell>{disponibles}</TableCell>
  //     </TableRow>
  //   );
  // };

  const getKnownTypeRow = (typeName, icon) => {
    const balanceItem = findBalanceItemByTypeName(typeName);
    const typeInfo = notificationTypes.find(t => t.nombre.toLowerCase() === typeName.toLowerCase());
    
    const typeId = balanceItem?.notificationTypeId?._id || typeInfo?._id || `temp-${typeName}`;
    
    // Usar valores editados o valores originales
    const editedTypeValues = editedValues[typeId] || {};
    
    const cantidad = editedTypeValues.cantidad !== undefined ? 
      editedTypeValues.cantidad : (balanceItem?.cantidad || 0);
    
    const costoUnitario = editedTypeValues.costo_unitario !== undefined ? 
      editedTypeValues.costo_unitario : (balanceItem?.costo_unitario || 0);
    
    const costoTotal = editedTypeValues.costo_total !== undefined ? 
      editedTypeValues.costo_total : (balanceItem?.costo_total || 0);
    
    const utilizadas = balanceItem?.utilizadas || 0;
    const disponibles = balanceItem?.disponibles || 0;
    
    return (
      <TableRow key={typeId}>
        <TableCell size="small" sx={{ px: isSmallScreen ? 1 : 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            {icon}
            {!isSmallScreen && <Typography variant="body2">{typeName}</Typography>}
          </Box>
        </TableCell>
        <TableCell size="small" sx={{ px: isSmallScreen ? 1 : 2 }}>
          <TextField
            type="number"
            value={cantidad}
            onChange={(e) => handleValueChange(typeId, 'cantidad', e.target.value)}
            inputProps={{ min: 0, step: 1 }}
            variant="outlined"
            size="small"
            sx={{ 
              width: isSmallScreen ? '60px' : '80px',
              '& .MuiInputBase-input': {
                padding: isSmallScreen ? '6px 4px' : '8px 12px',
                fontSize: isSmallScreen ? '0.75rem' : '0.875rem'
              }
            }}
          />
        </TableCell>
        <TableCell size="small" sx={{ px: isSmallScreen ? 1 : 2 }}>
          <TextField
            type="number"
            value={costoUnitario}
            onChange={(e) => handleValueChange(typeId, 'costo_unitario', e.target.value)}
            inputProps={{ min: 0, step: 0.01 }}
            variant="outlined"
            size="small"
            sx={{ 
              width: isSmallScreen ? '70px' : '100px',
              '& .MuiInputBase-input': {
                padding: isSmallScreen ? '6px 4px' : '8px 12px',
                fontSize: isSmallScreen ? '0.75rem' : '0.875rem'
              }
            }}
            InputProps={{
              startAdornment: isSmallScreen ? null : <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </TableCell>
        <TableCell size="small" sx={{ px: isSmallScreen ? 1 : 2 }}>
          <TextField
            type="number"
            value={costoTotal}
            onChange={(e) => handleValueChange(typeId, 'costo_total', e.target.value)}
            inputProps={{ min: 0, step: 0.01 }}
            variant="outlined"
            size="small"
            sx={{ 
              width: isSmallScreen ? '70px' : '100px',
              '& .MuiInputBase-input': {
                padding: isSmallScreen ? '6px 4px' : '8px 12px',
                fontSize: isSmallScreen ? '0.75rem' : '0.875rem'
              }
            }}
            InputProps={{
              startAdornment: isSmallScreen ? null : <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </TableCell>
        <TableCell size="small" sx={{ px: isSmallScreen ? 1 : 2 }}>
          <Typography variant="body2">{utilizadas}</Typography>
        </TableCell>
        <TableCell size="small" sx={{ px: isSmallScreen ? 1 : 2 }}>
          <Typography variant="body2">{disponibles}</Typography>
        </TableCell>
      </TableRow>
    );
  };
  // Define known message types with their icons
  const knownTypes = [
    { name: 'SMS', icon: <SmsIcon color="warning" /> },
    { name: 'WhatsApp', icon: <WhatsAppIcon color="success" /> },
    { name: 'Email', icon: <EmailIcon color="primary" /> },
    { name: 'Voz', icon: <VoiceIcon color="error" /> }
  ];

  // if (loading) {
  //   return (
  //     <Container>
  //       <Box 
  //         display="flex" 
  //         justifyContent="center" 
  //         alignItems="center" 
  //         minHeight="80vh"
  //       >
  //         <CircularProgress />
  //         <Typography variant="h6" ml={2}>
  //           Cargando datos de mensajería...
  //         </Typography>
  //       </Box>
  //     </Container>
  //   );
  // }

  if (error) {
    return (
      <Container>
        <Box my={4}>
          <Alert severity="error">{error}</Alert>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth={false} sx={{ px: isSmallScreen ? 1 : 2 }}>
      <Paper sx={{ p: isSmallScreen ? 2 : 4, my: 2 }} elevation={3}>
        <Typography 
          variant={isSmallScreen ? "h5" : "h4"} 
          component="h1" 
          gutterBottom
          sx={{ mb: isSmallScreen ? 1 : 2 }}
        >
          Administrador de mensajes
        </Typography>
        
        <TableContainer sx={{ overflowX: 'auto' }}>
          <Table size={isSmallScreen ? "small" : "medium"} sx={{ minWidth: 500 }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ px: isSmallScreen ? 1 : 2 }}>
                  <Typography variant={isSmallScreen ? "caption" : "subtitle1"} fontWeight="bold">
                    {isSmallScreen ? "Tipo" : "Tipo de Mensaje"}
                  </Typography>
                </TableCell>
                <TableCell sx={{ px: isSmallScreen ? 1 : 2 }}>
                  <Typography variant={isSmallScreen ? "caption" : "subtitle1"} fontWeight="bold">
                    {isSmallScreen ? <InventoryIcon fontSize="small" /> : "Cantidad"}
                  </Typography>
                </TableCell>
                <TableCell sx={{ px: isSmallScreen ? 1 : 2 }}>
                  <Typography variant={isSmallScreen ? "caption" : "subtitle1"} fontWeight="bold">
                    {isSmallScreen ? "C.Unit." : "Costo Unitario"}
                  </Typography>
                </TableCell>
                <TableCell sx={{ px: isSmallScreen ? 1 : 2 }}>
                  <Typography variant={isSmallScreen ? "caption" : "subtitle1"} fontWeight="bold">
                    {isSmallScreen ? "C.Total" : "Costo Total"}
                  </Typography>
                </TableCell>
                <TableCell sx={{ px: isSmallScreen ? 1 : 2 }}>
                  <Typography variant={isSmallScreen ? "caption" : "subtitle1"} fontWeight="bold">
                    {isSmallScreen ? "Usados" : "Utilizados"}
                  </Typography>
                </TableCell>
                <TableCell sx={{ px: isSmallScreen ? 1 : 2 }}>
                  <Typography variant={isSmallScreen ? "caption" : "subtitle1"} fontWeight="bold">
                    {isSmallScreen ? "Rest." : "Restantes"}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {knownTypes.map(type => 
                getKnownTypeRow(type.name, type.icon)
              )}
              
              {/* Modificar el mapeo de otros tipos para incluir nuevos campos */}
              {balanceData.filter(item => 
                !knownTypes.some(type => 
                  type.name.toLowerCase() === item.notificationTypeId.nombre.toLowerCase()
                )
              ).map(item => {
                const typeId = item.notificationTypeId._id;
                const editedTypeValues = editedValues[typeId] || {};
                
                const cantidad = editedTypeValues.cantidad !== undefined ? 
                  editedTypeValues.cantidad : item.cantidad;
                
                const costoUnitario = editedTypeValues.costo_unitario !== undefined ? 
                  editedTypeValues.costo_unitario : item.costo_unitario;
                
                const costoTotal = editedTypeValues.costo_total !== undefined ? 
                  editedTypeValues.costo_total : item.costo_total;
                
                return (
                  <TableRow key={typeId}>
                    <TableCell size="small" sx={{ px: isSmallScreen ? 1 : 2 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        {getIconForType(item.notificationTypeId.nombre)}
                        {!isSmallScreen && <Typography variant="body2">{item.notificationTypeId.nombre}</Typography>}
                      </Box>
                    </TableCell>
                    <TableCell size="small" sx={{ px: isSmallScreen ? 1 : 2 }}>
                      <TextField
                        type="number"
                        value={cantidad} 
                        onChange={(e) => handleValueChange(typeId, 'cantidad', e.target.value)}
                        inputProps={{ min: 0, step: 1 }}
                        variant="outlined"
                        size="small"
                        sx={{ 
                          width: isSmallScreen ? '60px' : '80px',
                          '& .MuiInputBase-input': {
                            padding: isSmallScreen ? '6px 4px' : '8px 12px',
                            fontSize: isSmallScreen ? '0.75rem' : '0.875rem'
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell size="small" sx={{ px: isSmallScreen ? 1 : 2 }}>
                      <TextField
                        type="number"
                        value={costoUnitario}
                        onChange={(e) => handleValueChange(typeId, 'costo_unitario', e.target.value)}
                        inputProps={{ min: 0, step: 0.01 }}
                        variant="outlined"
                        size="small"
                        sx={{ 
                          width: isSmallScreen ? '70px' : '100px',
                          '& .MuiInputBase-input': {
                            padding: isSmallScreen ? '6px 4px' : '8px 12px',
                            fontSize: isSmallScreen ? '0.75rem' : '0.875rem'
                          }
                        }}
                        InputProps={{
                          startAdornment: isSmallScreen ? null : <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </TableCell>
                    <TableCell size="small" sx={{ px: isSmallScreen ? 1 : 2 }}>
                      <TextField
                        type="number"
                        value={costoTotal}
                        onChange={(e) => handleValueChange(typeId, 'costo_total', e.target.value)}
                        inputProps={{ min: 0, step: 0.01 }}
                        variant="outlined"
                        size="small"
                        sx={{ 
                          width: isSmallScreen ? '70px' : '100px',
                          '& .MuiInputBase-input': {
                            padding: isSmallScreen ? '6px 4px' : '8px 12px',
                            fontSize: isSmallScreen ? '0.75rem' : '0.875rem'
                          }
                        }}
                        InputProps={{
                          startAdornment: isSmallScreen ? null : <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </TableCell>
                    <TableCell size="small" sx={{ px: isSmallScreen ? 1 : 2 }}>
                      <Typography variant="body2">{item.utilizadas}</Typography>
                    </TableCell>
                    <TableCell size="small" sx={{ px: isSmallScreen ? 1 : 2 }}>
                      <Typography variant="body2">{item.disponibles}</Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        
        <Box mt={isSmallScreen ? 2 : 3} display="flex" justifyContent="flex-end">
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleSaveChanges}
            disabled={loading}
            size={isSmallScreen ? "small" : "medium"}
          >
            {loading ? 'Guardando...' : 'Guardar Cambios'}
          </Button>
        </Box>
      </Paper>
      
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default MessageBalancePage;