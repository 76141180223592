import React from 'react';
import { 
  Box, 
  Typography, 
  Tooltip,
  useTheme
} from '@mui/material';
import { 
  Email as EmailIcon, 
  WhatsApp as WhatsAppIcon, 
  RecordVoiceOver as VoiceIcon, 
  Sms as SmsIcon
} from '@mui/icons-material';

const MessageBalance = ({ balanceData }) => {
  const theme = useTheme();

  const defaultData = {
    MensajeMailDisponible: 0,
    MensajeMailUtilizado: 0,
    MensajeWatsDisponible: 0,
    MensajeWatsUtilizado: 0,
    MensajetextoDisponible: 0,
    MensajetextoUtilizado: 0,
    MensajeVozDisponible: 0,
    MensajeVozUtilizado: 0
  };

  const data = balanceData || defaultData;
  
  // Define message types with their icons and data keys
  const messageTypes = [
    { 
      name: "Email", 
      icon: <EmailIcon />, 
      used: data.MensajeMailUtilizado || 0,
      total: data.MensajeMailDisponible || 0,
      color: theme.palette.primary.main
    },
    { 
      name: "WhatsApp", 
      icon: <WhatsAppIcon />, 
      used: data.MensajeWatsUtilizado || 0,
      total: data.MensajeWatsDisponible || 0,
      color: theme.palette.success.main
    },
    { 
      name: "SMS", 
      icon: <SmsIcon />, 
      used: data.MensajetextoUtilizado || 0,
      total: data.MensajetextoDisponible || 0,
      color: theme.palette.warning.main
    },
    { 
      name: "Voz", 
      icon: <VoiceIcon />, 
      used: data.MensajeVozUtilizado || 0,
      total: data.MensajeVozDisponible || 0,
      color: theme.palette.error.main
    }
  ];

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 2,
        flexWrap: 'wrap',
        justifyContent: 'center',
        mx: 2
      }}
    >
      {messageTypes.map((type) => (
        <Tooltip 
          key={type.name} 
          title={`${type.name}: ${type.used}/${type.total} utilizados`}
          arrow
        >
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 0.8,
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1.5,
            px: 1.2,
            py: 0.7,
            backgroundColor: 'background.paper',
            boxShadow: 1
          }}>
            <Box sx={{ color: type.color }}>
              {type.icon}
            </Box>
            <Typography 
              variant="body2" 
              fontWeight="medium"
              sx={{ fontSize: '0.9rem' }}
            >
              {type.used}/{type.total}
            </Typography>
          </Box>
        </Tooltip>
      ))}
    </Box>
  );
};

export default MessageBalance;