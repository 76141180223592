import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  TextField,
  Grid,
  Box,
  IconButton,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useState, useRef, useCallback, useEffect } from "react";
import { confirmDialog } from "../../../components/ConfirmDialog";
import MapComponent from "../MapComponent";

import { Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import L from "leaflet";
import { Circle, Polygon } from "react-leaflet";
import { useLoading } from "../../../contexts/LoadingContext";
import {
  geocercaService,
  ClasificacionesGeocercasService,
  tiposGeocercasService,
  gruposGeocercasService,
  loginQuro,
  geocercaQuroService,
} from "../../../services/apiService";

export default function GeocercaModal({ open, onClose }) {
  // Estado para obtener el token de quro
  const [tokenQuro, setTokenQuro] = useState([]);
  const loadToken = async () => {
    try {
      const response = await loginQuro.getToken();
      const collection = response?.data?.collection;
      const json = JSON.parse(collection);
      if (json.Token) {
        setTokenQuro(json.Token);
      } else {
        console.error("Error al obtener el token");
      }
    } catch (error) {
      console.error(error);
    }
  };
  //Estados para guardar los datos de la api
  const [clasificacionesGeocercas, setClasificacionesGeocercas] = useState([]);
  const [tiposGeocercas, setTiposGeocercas] = useState([]);
  const [gruposGeocercas, setGruposGeocercas] = useState([]);

  const loadClasificacionesGeocercas = async () => {
    try {
      const response =
        await ClasificacionesGeocercasService.getClasificacionesGeocercas();
      if (response.statusCode === 200) {
        setClasificacionesGeocercas(response.data);
      } else {
        console.error(
          "❌ Error al cargar las clasificaciones de geocercas:",
          response
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const clasificacionesOrdenadas = clasificacionesGeocercas.sort((a, b) => {
    if (a.nombre === "N/A") return -1; // N/A al principio
    if (b.nombre === "N/A") return 1;
    return a.nombre.localeCompare(b.nombre); // Orden alfabético para el resto
  });

  const loadTiposGeocercas = async () => {
    try {
      const response = await tiposGeocercasService.getTiposGeocercas("", "", 1);
      if (response.statusCode === 200) {
        setTiposGeocercas(response.data);
      } else {
        console.error("❌ Error al cargar los tipos de geocercas:", response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const tiposOrdenados = tiposGeocercas.sort((a, b) => {
    // Ordena Alfabeticamente los tipos
    return a.nombre.localeCompare(b.nombre);
  });

  /*Grupos de Geocercas*/
  const loadGruposGeocercas = async () => {
    try {
      const responseLogQuro = await loginQuro.getToken();
      const collection = responseLogQuro?.data?.collection;
      const json = JSON.parse(collection);

      const response = await gruposGeocercasService.getGruposGeocercas(
        json.Token
      );

      if (response.data.success) {
        const collectionGrupos = JSON.parse(response.data.collection);

        const gruposOrdenados = collectionGrupos.sort((a, b) => {
          return a.Nombre.localeCompare(b.Nombre);
        });

        setGruposGeocercas(gruposOrdenados);
      } else {
        console.error("❌ Error al cargar los grupos de geocercas:", response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Estados del formulario
  const [nombre, setNombre] = useState("");
  const [forma, setForma] = useState();
  const [grupo, setGrupo] = useState("");
  const [clasificacion, setClasificacion] = useState("");
  const [tipo, setTipo] = useState("");
  const [subclasificacion, setSubclasificacion] = useState("");
  const [radio, setRadio] = useState("");

  //Estados para el mapa leaflet
  const [geocercas, setGeocercas] = useState([]); // Geocercas disponibles
  const [center, setCenter] = useState([21.1619, -86.8515]); // Default center
  const [zoom, setZoom] = useState(13);
  const [userLocation, setUserLocation] = useState(null); // To store user's location
  const [selectedGeocercas, setSelectedGeocercas] = useState([]);
  const drawnItemsRef = useRef(new L.FeatureGroup()); // Referencia para capas dibujadas
  const googleMapRef = useRef(null);
  const leafletMapRef = useRef(null); // Referencia del mapa
  const [useGoogleMaps, setUseGoogleMaps] = useState(false); // New state for map toggle
  const [isFirstMapLoad, setIsFirstMapLoad] = useState(true);
  const [selectedGeocercaId, setSelectedGeocercaId] = useState(null);
  const { setIsLoading, setLoadingMessage } = useLoading();
  const [puntos, setPuntos] = useState([]);
  const [direccion, setDireccion] = useState("");
  const [componentsLoaded, setComponentsLoaded] = useState({
    //map: false,
    geocercas: false,
  });
  const [currentOverlay, setCurrentOverlay] = useState(null);

  const loadGeocercas = useCallback(async () => {
    setIsLoading(true);
    setLoadingMessage("Cargando geocercas...");
    try {
      const response = await geocercaService.getGeocercas("");
      if (response.statusCode === 200) {
        const geocercasActivas = response.data.filter(
          (geocerca) => geocerca.status === 1
        );

        //setGeocercas(response.data);
        setGeocercas(geocercasActivas);
        console.log("🚀 Geocercas cargadas:", response.data);

        // NUEVO:  Seleccionar todas las geocercas por defecto en el select geocercas visibles
        setSelectedGeocercas(geocercasActivas.map((geocerca) => geocerca.id));

        handleComponentLoad("geocercas");
        console.log("🚀 handleComponentLoad para geocercas llamado");
      } else {
        console.error("❌ Error al cargar geocercas:", response);
        setIsLoading(false); // Ocultar loading en caso de error
      }
    } catch (error) {
      console.error("❌ Error cargando geocercas:", error);
      setIsLoading(false); // Ocultar loading en caso de error
    }
  });

  const handleComponentLoad = (component) => {
    setComponentsLoaded((prev) => {
      const newState = { ...prev, [component]: true };
      console.log(`🚀 Componentes cargados:`, newState);
      if (Object.values(newState).every((loaded) => loaded)) {
        console.log("🚀 Todos los componentes cargados, ocultando loading");
        setIsLoading(false);
      }

      return newState;
    });
  };

  const fetchAddress = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`
      );
      const data = await response.json();
      setDireccion(data.display_name || "Dirección no encontrada");
    } catch (error) {
      console.error("Error fetching address:", error);
      setDireccion("Error al obtener la dirección");
    }
  };

  // Filtra las geocercas visibles en función de la selección
  const geocercasVisibles = geocercas.filter((geocerca) =>
    selectedGeocercas.includes(geocerca.id)
  );

  const _onCreated = (e) => {
    const { layerType, layer } = e;

    if (useGoogleMaps) {
      // Lógica para Google Maps
      if (layerType === "circle") {
        const center = layer.getCenter();
        const radius = Math.round(layer.getRadius()); //layer.getRadius();
        setCenter([center.lat(), center.lng()]);
        setRadio(radius);
        //setTipo("Point");
        fetchAddress(center.lat(), center.lng());
      } else if (layerType === "polygon") {
        const path = layer
          .getPath()
          .getArray()
          .map((point, index) => ({
            Latitud: point.lat(),
            Longitud: point.lng(),
            Order: index + 1, // Asigna el índice + 1 para que comience desde 1
          }));
        setPuntos(path);
        console.log("******* Puntos *******");
        console.log(puntos);
        console.log("******* Puntos *******");
        //setTipo("Polygon");
        setDireccion("");
      }
    } else {
      // Lógica para Leaflet
      drawnItemsRef.current.clearLayers();
      drawnItemsRef.current.addLayer(layer);

      if (layerType === "circle") {
        const { lat, lng } = layer.getLatLng();
        const radius = Math.round(layer.getRadius()); //layer.getRadius();
        setCenter([lat, lng]);
        setRadio(radius);
        //setTipo("Point");
        fetchAddress(lat, lng);
      } else if (layerType === "polygon") {
        const latlngs = layer
          .getLatLngs()[0]
          .map((point) => [point.lat, point.lng]);
        setPuntos(latlngs);
        //setTipo("Polygon");
        setDireccion("");
      }
    }
  };

  const _onEdited = (e) => {
    e.layers.eachLayer((layer) => {
      if (layer instanceof L.Circle) {
        const { lat, lng } = layer.getLatLng();
        const radius = layer.getRadius();
        setCenter([lat, lng]);
        setRadio(radius);
        setTipo("Point");
        fetchAddress(lat, lng);
      } else if (layer instanceof L.Polygon) {
        const latlngs = layer.getLatLngs()[0].map((point, index) => ({
          Latitud: point.lat(),
          Longitud: point.lng(),
          Order: index + 1,
        }));
        setPuntos(latlngs);
        //setTipo("Polygon");
        setDireccion("");
      }
    });
  };

  const _onDeleted = () => {
    setPuntos([]);
    setCenter(null);
    setRadio(0);
    setDireccion("");
  };

  const _onMoved = (e) => {
    const layer = e.layer;
    if (layer instanceof L.Circle) {
      const { lat, lng } = layer.getLatLng();
      const radius = layer.getRadius();
      setCenter([lat, lng]);
      setRadio(radius);
      fetchAddress(lat, lng);
    } else if (layer instanceof L.Polygon) {
      const latlngs = layer
        .getLatLngs()[0]
        .map((point) => [point.lat, point.lng]);
      setPuntos(latlngs);
    }
  };

  const renderGeocercas = () => {
    return geocercas.map((geocerca) => {
      if (geocerca.puntos.type === "Polygon") {
        const points = geocerca.puntos.puntos.map((p) => [
          p.latitud,
          p.longitud,
        ]);
        return (
          <Polygon
            key={geocerca.id}
            positions={points}
            pathOptions={{
              color: "#1E90FF",
              fillColor: "#1E90FF",
              fillOpacity: 0.2,
            }}
          >
            <Popup>{geocerca.nombre}</Popup>
          </Polygon>
        );
      } else if (geocerca.puntos.type === "Point") {
        const center = [
          geocerca.puntos.puntos[0].latitud,
          geocerca.puntos.puntos[0].longitud,
        ];
        return (
          <Circle
            key={geocerca.id}
            center={center}
            radius={geocerca.radio}
            pathOptions={{
              color: "#1E90FF",
              fillColor: "#1E90FF",
              fillOpacity: 0.2,
            }}
          >
            <Popup>{geocerca.nombre}</Popup>
          </Circle>
        );
      }
      return null;
    });
  };

  const handleMapLoad = () => {
    //console.log('🚀 handleMapLoad llamado, mapRef:', leafletMapRef.current);
    //if (leafletMapRef.current) {
    handleComponentLoad("map");
    //} else {
    //console.error('❌ Mapa no inicializado en handleMapLoad');
    //}
  };

  const handleClose = async () => {
    const isConfirmed = await confirmDialog({
      title: "Confirmar cancelación.",
      message:
        "¿Estas seguro de que deseas cancelar la operación? Los cambios realizados no se guardarán.",
      confirmLabel: "Sí, cancelar",
      cancelLabel: "No",
    });
    if (!isConfirmed) return;
    setForma(null);
    setGrupo("");
    setClasificacion("");
    setTipo("");
    setSubclasificacion("");
    onClose();
  };

  const handleGuardar = useCallback(async () => {
    try {
      console.log(puntos);
      // const login = await loginQuro.getToken();
      // const collection = login?.data?.collection;
      // const json = JSON.parse(collection);
      // if (!json.Token) {
      //   console.error("Error al obtener el Token");
      //   return;
      // }
      // const uid_externoUsuario = localStorage.getItem("uid_externoUsuario");
      // const uid_externoEmpresa = localStorage.getItem("uid_externo");
      // const nuevaGeocerca = {
      //   Token: json.Token,
      //   UserUid: uid_externoUsuario,
      //   GrupoUid: grupo,
      //   ClasificacionUid: clasificacion,
      //   Tipofuncion: "",
      //   EmpresaUid: uid_externoEmpresa,
      //   Nombre: nombre,
      //   CenterLongitud: center[1],
      //   CenterLatitud: center[0],
      //   NoSucursal: "",
      //   Direccion: "",
      //   Points: [
      //     {
      //       Latitud: "",
      //       Longitud: "",
      //       Order: "",
      //     },
      //   ],
      //   Horario: {
      //     EmpresaUid: uid_externoEmpresa,
      //     UserUid: uid_externoUsuario,
      //     Todos: "",
      //     Lunes: "",
      //     Martes: "",
      //     Miercoles: "",
      //     Jueves: "",
      //     Viernes: "",
      //     Sabado: "",
      //     Domingo: "",
      //   },
      // };
      // const response = await geocercaQuroService.crearGeocerca(nuevaGeocerca);
      // console.log(response);
    } catch (error) {
      console.error(error);
    }
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "nombre") {
      setNombre(value);
    }
    if (name === "forma") {
      console.log(value);
      setForma(value);
    }
    if (name === "grupo") {
      setGrupo(value);
    }
    if (name === "clasificacion") {
      setClasificacion(value);
    }
    if (name === "subclasificacion") {
      setSubclasificacion(value);
    }
    if (name === "tipo") {
      setTipo(value);
    }
    if (name === "radio") {
      setRadio(value);
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = Number(position.coords.latitude);
          const lng = Number(position.coords.longitude);
          if (Number.isFinite(lat) && Number.isFinite(lng)) {
            setUserLocation([lat, lng]);
          } else {
            console.error(
              "Coordenadas inválidas en geolocalización:",
              position
            );
          }
        },
        (error) => {
          console.error("Error obteniendo la ubicación del usuario:", error);
        }
      );
    }
  }, []);

  useEffect(() => {
    //setIsLoading(true);
    loadGeocercas();
    loadClasificacionesGeocercas();
    loadTiposGeocercas();
    loadGruposGeocercas();
  }, []);

  useEffect(() => {
    if (
      useGoogleMaps &&
      currentOverlay &&
      currentOverlay instanceof window.google.maps.Circle
    ) {
      const handleRadiusChange = () => {
        setRadio(currentOverlay.getRadius());
      };

      window.google.maps.event.addListener(
        currentOverlay,
        "radius_changed",
        handleRadiusChange
      );

      return () => {
        window.google.maps.event.removeListener(
          currentOverlay,
          "radius_changed",
          handleRadiusChange
        );
      };
    }
  }, [useGoogleMaps, currentOverlay]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Agregar Geocerca
        {/* Botón de cierre */}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
        >
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} marginTop={1}>
          {/*Forma*/}
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" size="small" fullWidth>
              <TextField
                select
                label="Forma"
                name="forma"
                variant="outlined"
                fullWidth
                size="small"
                value={forma}
                onChange={handleChange}
              >
                <MenuItem value={1}>Polígono</MenuItem>
                <MenuItem value={2}>Círculo (Cliente) </MenuItem>
                <MenuItem value={3}>Círculo (Referencia)</MenuItem>
              </TextField>
            </FormControl>
          </Grid>
          {forma && (
            <>
              {/*Nombre*/}
              <Grid item xs={12} md={4}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <TextField
                    label="Nombre"
                    name="nombre"
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={nombre}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              {/*Grupo*/}
              <Grid item xs={12} md={4}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <TextField
                    select
                    label="Grupo"
                    name="grupo"
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={grupo}
                    onChange={handleChange}
                  >
                    {gruposGeocercas.map((grupo) => (
                      <MenuItem key={grupo.GrupoUid} value={grupo.GrupoUid}>
                        {grupo.Nombre}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              {forma === 1 ? (
                <>
                  {/*Clasificacion*/}
                  <Grid item xs={12} md={4}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <TextField
                        select
                        label="Clasificación"
                        name="clasificacion"
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={clasificacion}
                        onChange={handleChange}
                      >
                        {clasificacionesOrdenadas.map((clasificacion) => (
                          <MenuItem
                            key={clasificacion.id}
                            value={clasificacion.id}
                          >
                            {clasificacion.nombre}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  {/*Tipo*/}
                  <Grid item xs={12} md={4}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <TextField
                        select
                        label="Tipo"
                        name="tipo"
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={tipo}
                        onChange={handleChange}
                      >
                        {tiposOrdenados.map((tipo) => (
                          <MenuItem key={tipo.id} value={tipo.id}>
                            {tipo.nombre}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                </>
              ) : forma === 2 ? (
                <>
                  {/*Clasificacion*/}
                  <Grid item xs={12} md={4}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <TextField
                        select
                        label="Clasificación"
                        name="clasificacion"
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={clasificacion}
                        onChange={handleChange}
                      >
                        {clasificacionesOrdenadas.map((clasificacion) => (
                          <MenuItem
                            key={clasificacion.id}
                            value={clasificacion.id}
                          >
                            {clasificacion.nombre}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  {/*Subclasificacion*/}
                  <Grid item xs={12} md={4}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <TextField
                        select
                        label="Subclasificación"
                        name="subclasificacion"
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={subclasificacion}
                        onChange={handleChange}
                      >
                        <MenuItem value={0}>Subclasificacion</MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>
                  {/*Radio*/}
                  <Grid item xs={12} md={4}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <TextField
                        label="Radio"
                        name="radio"
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={radio}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Grid>
                </>
              ) : (
                <>
                  {/*Radio*/}
                  <Grid item xs={12} md={4}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <TextField
                        label="Radio"
                        name="radio"
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={radio}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
            </>
          )}
          {/*Mapa*/}
          <Grid item xs={12} md={12} sm={12}>
            <MapComponent
              center={center}
              zoom={zoom}
              userLocation={userLocation}
              geocercas={[]} // Sin geocercas iniciales
              onCreated={_onCreated}
              onEdited={_onEdited}
              onDeleted={_onDeleted}
              drawnItemsRef={drawnItemsRef}
              renderGeocercas={renderGeocercas}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
            paddingRight: 1.5,
            paddingBottom: 2,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            endIcon={<SaveIcon />}
            onClick={handleGuardar}
          >
            Agregar
          </Button>
          <Button
            variant="contained"
            color="primary"
            endIcon={<CancelIcon />}
            onClick={handleClose}
          >
            Cancelar
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
