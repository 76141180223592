// MapShapes.js
import React, { useEffect } from "react";
import { useGoogleMap } from "@react-google-maps/api";
import { useTheme } from "@mui/material/styles";

export const RenderCircle = ({ center, radius, name }) => {
  const map = useGoogleMap();
  const theme = useTheme();

  useEffect(() => {
    if (!map) return;

    const circle = new window.google.maps.Circle({
      strokeColor: "#4285F4",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#4285F4",
      fillOpacity: 0.2,
      map,
      center,
      radius,
    });

    const newCenter = [center.lat, center.lng];

    const label = new window.google.maps.marker.AdvancedMarkerElement({
      position: center,
      map,
      content: createLabel(name, "Circle", theme),
      collisionBehavior: "REQUIRED",
    });

    return () => {
      circle.setMap(null);
      label.setMap(null);
    };
  }, [map, center, radius, name, theme]);

  return null;
};

export const RenderPolygon = ({ paths, name }) => {
  const map = useGoogleMap();
  const theme = useTheme();

  useEffect(() => {
    if (!map) return;
    if (!Array.isArray(paths)) {
      console.error("paths debe ser un arreglo");
      return;
    }

    const polygon = new window.google.maps.Polygon({
      paths,
      fillColor: "#4285F4",
      fillOpacity: 0.2,
      strokeColor: "#4285F4",
      strokeOpacity: 1,
      strokeWeight: 2,
      map,
    });

    const bounds = new window.google.maps.LatLngBounds();
    paths.forEach((coord) => bounds.extend(coord));
    const center = bounds.getCenter();

    const label = new window.google.maps.marker.AdvancedMarkerElement({
      position: center,
      map,
      content: createLabel(name, "Polygon", theme),
      collisionBehavior: "REQUIRED",
    });

    return () => {
      polygon.setMap(null);
      label.setMap(null);
    };
  }, [map, paths, name, theme]);

  return null;
};

const createLabel = (name, type, theme) => {
  const markerColor =
    type === "Polygon"
      ? theme.palette.primary.main // Color for polygons
      : theme.palette.secondary.main; // Color for points/circles

  const div = document.createElement("div");
  div.innerHTML = `
    <div class="marker-container">
      <div style="
        width: 30px;
        height: 45px;
        position: relative;
        filter: drop-shadow(0 1px 4px rgba(0,0,0,0.3));
      ">
        <div style="
          width: 100%;
          height: 100%;
          background-color: ${markerColor};
          clip-path: path('M15 0C6.716 0 0 6.716 0 15c0 8.284 15 30 15 30s15-21.716 15-30c0-8.284-6.716-15-15-15zm0 23c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z');
          display: flex;
          justify-content: center;
          align-items: center;
        ">
          <div style="
            width: 12px;
            height: 12px;
            background: white;
            border-radius: 50%;
            margin-top: -5px;
          "></div>
        </div>
      </div>
      <div class="label-container">
        <span class="label-text">${name}</span>
      </div>
    </div>
  `;
  return div;
};
