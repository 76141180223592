import {
  Autocomplete,
  Checkbox,
  TextField,
  Grid,
  FormControlLabel,
  Divider,
  Box,
  Button,
  IconButton,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  geocercaService,
  ClasificacionesGeocercasService,
  tiposGeocercasService,
} from "../../../services/apiService";
import { forwardRef, useEffect, useState } from "react";
import { isEmptyArray } from "formik";
import ImageContainer from "./ImageContainer";
import notFoundGeocerca from "../../../assets/not-found/empty-space-geocercas.png";
import GeocercaModal from "../../geocerca/components/GeocercaModal";
import NextButton from "./NextButton";

const ListaGeocercas = forwardRef(
  ({
    configuracion,
    actualizarComponente,
    handleGuardarPrincipal,
    idAlerta,
    nextComponent,
    isEditMode,
    handleClose,
  }) => {
    // const [isModalOpen, setIsModalOpen] = useState(false);
    // const openModal = () => setIsModalOpen(true);
    // const closeModal = () => setIsModalOpen(false);

    /*Lista de Geocercas*/
    const [geocercas, setGeocercas] = useState([]);

    /*Tipos de Geocercas*/
    const [tiposGeocercas, setTiposGeocercas] = useState([]);
    const [selectedTipo, setSelectedTipo] = useState();

    const loadGeocercas = async () => {
      try {
        const response = await geocercaService.getGeocercas("");
        if (response.statusCode === 200) {
          setGeocercas(
            response.data.filter((geocerca) => geocerca.status === 1)
          );
        } else {
          console.error("❌ Error al cargar geocercas:", response);
        }
      } catch (error) {
        console.error("❌ Error cargando geocercas:", error);
      }
    };
    const [selectedGeocercas, setSelectedGeocercas] = useState([]);

    /*Geocercas filtradas*/
    const [filteredGeocercas, setFilteredGeocercas] = useState(geocercas);
    const [selectAll, setSelectAll] = useState(false);
    
    

    /*Clasificaciones de Geocercas*/
    const [clasificacionesGeocercas, setClasificacionesGeocercas] = useState(
      []
    );



    const [selectedClasificacion, setSelectedClasificacion] = useState();
    const loadClasificacionesGeocercas = async () => {
      try {
        const response =
          await ClasificacionesGeocercasService.getClasificacionesGeocercas();
        if (response.statusCode === 200) {
          setClasificacionesGeocercas(response.data);
        } else {
          console.error(
            "❌ Error al cargar las clasificaciones de geocercas:",
            response
          );
        }
      } catch (error) {
        console.error(error);
      }
    };



    useEffect(() => {
      let filtered = geocercas;

      // First apply existing filters
      if (selectedClasificacion) {
        if (selectedClasificacion.nombre !== "N/A") {
          filtered = filtered.filter(
            (geocerca) =>
              geocerca.clasificacion_uid === selectedClasificacion.uiD_Externo
          );
        }
      }

      if (selectedTipo) {
        filtered = filtered.filter(
          (geocerca) => geocerca.tipogeocerca_uid === selectedTipo.uiD_Externo
        );
      }
      
      // Then filter by group if one is selected in configuracion
      if (configuracion.grupoId) {
        filtered = filtered.filter(
          (geocerca) => geocerca.grupo_uid === configuracion.grupoId
        );
      }

      setFilteredGeocercas(filtered);
    }, [selectedClasificacion, selectedTipo, geocercas, configuracion.grupoId]);
    




    const clasificacionesOrdenadas = clasificacionesGeocercas.sort((a, b) => {
      if (a.nombre === "N/A") return -1; // N/A al principio
      if (b.nombre === "N/A") return 1;
      return a.nombre.localeCompare(b.nombre); // Orden alfabético para el resto
    });

    
    const loadTiposGeocercas = async () => {
      try {
        const response = await tiposGeocercasService.getTiposGeocercas(
          "",
          "",
          1
        );
        if (response.statusCode === 200) {
          setTiposGeocercas(response.data);
        } else {
          console.error("❌ Error al cargar los tipos de geocercas:", response);
        }
      } catch (error) {
        console.error(error);
      }
    };
    const tiposOrdenados = tiposGeocercas.sort((a, b) => {
      // Ordena Alfabeticamente los tipos
      return a.nombre.localeCompare(b.nombre);
    });

    const handleSelect = (geocerca) => {
      setSelectedGeocercas(geocerca);
    };

    const handleToggleSelectAll = () => {
      setSelectAll((prev) => {
        if (!prev) setSelectedGeocercas([...geocercas]);
        else setSelectedGeocercas([]);
        return !prev;
      });
    };

    const handleRemoveGeocerca = (id) => {
      setSelectedGeocercas((prevSelected) =>
        prevSelected.filter((geocerca) => geocerca.id !== id)
      );
      if (selectedGeocercas.length === 0) setSelectAll(false);
    };

    /*Lista de Geocercas*/
    function isEnableButton() {
      return selectedGeocercas.length > 0 && idAlerta !== "";
    }

    useEffect(() => {
      loadGeocercas();
      loadClasificacionesGeocercas();
      loadTiposGeocercas();
    }, []);

    useEffect(() => {
      actualizarComponente(
        "geocerca",
        selectedGeocercas.map((item) => {
          return {
            _id: `${item.id}`,
            idTipoGeocerca: item.tipogeocerca_uid,
            type: item.puntos.type,
          };
        })
      );
    }, [selectedGeocercas]);

    useEffect(() => {
      let filtered = geocercas;

      if (selectedClasificacion) {
        if (selectedClasificacion.nombre !== "N/A") {
          console.log(selectedClasificacion);
          filtered = filtered.filter(
            (geocerca) =>
              geocerca.clasificacion_uid === selectedClasificacion.uiD_Externo
          );
        }
      }

      if (selectedTipo) {
        console.log(selectedTipo);
        filtered = filtered.filter(
          (geocerca) => geocerca.tipogeocerca_uid === selectedTipo.uiD_Externo
        );
      }

      setFilteredGeocercas(filtered);
    }, [selectedClasificacion, selectedTipo, geocercas]);

    useEffect(() => {
      //Validamos si el componente fue limpiado
      if (isEmptyArray(configuracion.geocerca)) {
        //refrescamos el state si es necesario
        if (!isEmptyArray(selectedGeocercas)) {
          setSelectedGeocercas([]);
          setSelectAll(false);
        }
      } else if (geocercas.length > 0) {
        // const selectedFromConfig = configuracion.geocerca.map((configGeocerca) => { //antiguo
        //   const matchingGeocerca = geocercas.find(
        //     (geocerca) => geocerca.id === configGeocerca._id
        //   );
        //   if (matchingGeocerca) {
        //     return {
        //       ...matchingGeocerca,
        //       type: configGeocerca.type,
        //     };
        //   }
        //   return null;
        // }).filter((item) => item !== null);

        // cuando se obtiene configuración
        // Comparamos si las geocercas seleccionadas actuales coinciden con las de la configuración
        const selectedFromConfig = configuracion.geocerca
          .map((configGeocerca) => {
            const matchingGeocerca = geocercas.find(
              (geocerca) => geocerca.id === configGeocerca._id
            );
            return matchingGeocerca
              ? { ...matchingGeocerca, type: configGeocerca.type }
              : null;
          })
          .filter((item) => item !== null);

        console.log("selecteds", selectedFromConfig);

        // Verificamos si el estado actual ya tiene las geocercas seleccionadas
        const areEqual =
          selectedGeocercas.length === selectedFromConfig.length &&
          selectedGeocercas.every(
            (sel, index) => sel.id === selectedFromConfig[index].id
          );

        if (!areEqual) {
          setSelectedGeocercas(selectedFromConfig); // Actualizamos solo si es necesario

          // Notificamos al componente padre si el estado cambia
          actualizarComponente(
            "geocerca",
            selectedFromConfig.map((item) => ({
              _id: item.id,
              type: item.type,
            }))
          );
        }
      }
    }, [configuracion.geocerca, geocercas]);

    return (
      <>
        <Box
          height={310}
          sx={{
            display: "flex",
            alignItems: "stretch",
            alignContent: "space-between",
          }}
        >
          <Box
           display={"flex"}
           flexDirection={"column"}
           flexBasis="25%" // En lugar de width
           flexGrow={0}    // Evitar que crezca más allá del 60%
           flexShrink={0}  // Evitar que se encoja
           pt={1}
           gap={2}
          >
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="clasificaciones-geocerca"
                    options={clasificacionesOrdenadas}
                    noOptionsText={"No se cargaron las clasificaciones"}
                    disableListWrap
                    size="small"
                    filterSelectedOptions={false}
                    renderInput={(params) => (
                      <TextField {...params} label="Clasificaciones" />
                    )}
                    getOptionLabel={(option) => option.nombre}
                    renderOption={(props, option, { selected }) => {
                      const { key, ...optionProps } = props;
                      return (
                        <li key={key} {...optionProps}>
                          {option.nombre}
                        </li>
                      );
                    }}
                    onChange={(_e, value, reason) =>
                      setSelectedClasificacion(value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="tipos-geocerca"
                    options={tiposOrdenados}
                    noOptionsText={"No se cargaron los tipos"}
                    disableListWrap
                    size="small"
                    filterSelectedOptions={false}
                    renderInput={(params) => (
                      <TextField {...params} label="Tipos" />
                    )}
                    getOptionLabel={(option) => option.nombre}
                    renderOption={(props, option, { selected }) => {
                      const { key, ...optionProps } = props;
                      return (
                        <li key={key} {...optionProps}>
                          {option.nombre}
                        </li>
                      );
                    }}
                    onChange={(_e, value, reason) => setSelectedTipo(value)}
                  />
                </Grid>
              </Grid>
            </>
            <Box display={"flex"} alignItems={"center"} gap={1} width={"100%"}>
              <Box flexGrow={1}>
                <Autocomplete
                  id="checkboxes-geocerca"
                  multiple
                  options={filteredGeocercas}
                  noOptionsText={"No hay coincidencias"}
                  disableCloseOnSelect
                  disableListWrap
                  size="small"
                  fullWidth
                  filterSelectedOptions={false}
                  // value={selectedGeocercas} //antiguo
                  value={selectedGeocercas.map(
                    (selected) =>
                      filteredGeocercas.find(
                        (geocerca) => geocerca.id === selected.id
                      ) || selected
                  )}
                  defaultValue={selectedGeocercas}
                  onChange={(_e, value, reason) => {
                    if (
                      reason === "removeOption" &&
                      selectedGeocercas.length > 0
                    ) {
                      setSelectedGeocercas(selectedGeocercas);
                      return;
                    }
                    switch (reason) {
                      case "clear":
                        setSelectAll(false);
                        setSelectedGeocercas([]);
                        break;
                      case "removeOption":
                        setSelectAll(false);
                        setSelectedGeocercas(value);
                        break;
                      case "selectOption":
                        if (value.length === geocercas.length)
                          setSelectAll(true);
                        setSelectedGeocercas(value);
                        break;
                      default:
                        setSelectedGeocercas(value);
                    }
                  }}
                  getOptionLabel={(option) => option.nombre}
                  renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={key} {...optionProps}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                          onChange={() => {
                            const isSelected = selectedGeocercas.some(
                              (g) => g.id === option.id
                            );
                            setSelectedGeocercas(
                              isSelected
                                ? selectedGeocercas.filter(
                                    (g) => g.id !== option.id
                                  )
                                : [...selectedGeocercas, option]
                            );
                          }}
                        />
                        {option.nombre}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Geocercas"
                      placeholder="Selecciona una geocerca"
                    />
                  )}
                  renderTags={(selectedOptions) => {
                    return <></>;
                  }}
                  groupBy={() => ""}
                  renderGroup={(params) => (
                    <div key={params.key}>
                      <>
                        <Box
                          onMouseDown={(e) => e.preventDefault()} // prevent blur
                          pl={3.5}
                          py={0.5}
                        >
                          <FormControlLabel
                            onClick={(e) => {
                              e.preventDefault(); // prevent blur
                              handleToggleSelectAll();
                            }}
                            label="Seleccionar todos"
                            control={
                              <Checkbox
                                id="select-all-checkbox"
                                checked={selectAll}
                              />
                            }
                          />
                        </Box>
                        <Divider />
                      </>
                      {params.children}
                    </div>
                  )}
                />
              </Box>
              {/* <Button
                variant="contained"
                sx={{
                  marginLeft: 2,
                  // width: 55,
                  // height: 55,
                  bgcolor: "#ea0000",
                  color: "#ffffff",
                  // padding: "12px",
                  fontSize: "1.1rem",
                  fontWeight: 600,
                  "&:hover": {
                    bgcolor: "#000000",
                  },
                  "&.Mui-disabled": {
                    bgcolor: "grey.600",
                    color: "#ffffff",
                  },
                }}
                onClick={openModal}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                  }}
                >
                  <AddCircleIcon />
                </Box>
              </Button> */}
            </Box>
          </Box>

          <Box marginLeft={2} display="flex">
            <Divider
              orientation="vertical"
              sx={{ borderRightWidth: 1, borderColor: "red" }}
            />
          </Box>


          <Box 
          marginLeft={2} pt={1} flexBasis="75%" flexGrow={0} flexShrink={0}
          >
  {selectedGeocercas.length > 0 ? (
    <>
      <Grid
        container
        alignContent="flex-start"
        display={"flex"}
        direction={"column"}
        wrap="wrap"
        height={300}
        sx={{
          overflowX: geocercas.length >= 5 ? "auto" : "visible",
          overflowY: "auto", // Añadimos scroll vertical para muchos elementos
        }}
        gap={1} // Espacio reducido entre elementos
      >
        {selectedGeocercas.map((geocerca) => (
          <Grid
            item
            key={geocerca.id}
            p={1} // Padding reducido
            width={"30%"} // Ancho optimizado
            border={1}
            borderRadius={"8px"}
            borderColor={"grey.100"}
            sx={{ 
              position: "relative",
              minHeight: "36px", // Altura mínima compacta
              display: "flex", 
              alignItems: "center",
            }}
          >
            <IconButton
              size="small"
              aria-label="close"
              onClick={() => handleRemoveGeocerca(geocerca.id)}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                padding: "2px", // Padding reducido del botón
              }}
            >
              <CancelIcon fontSize="small" />
            </IconButton>
            <Box
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                display: "block",
                width: "calc(100% - 24px)", // Espacio para el botón
                fontSize: "0.875rem", // Texto más pequeño (14px)
                pr: 3, // Padding derecho para no solapar con el botón
              }}
            >
              {geocerca.nombre}
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  ) : (
    <>
      <ImageContainer src={notFoundGeocerca} />
    </>
  )}
</Box>

          {/* <Box marginLeft={2} flexGrow={1} pt={1}>
            {selectedGeocercas.length > 0 ? (
              <>
                <Grid
                  container
                  alignContent="flex-start"
                  display={"flex"}
                  direction={"column"}
                  wrap="wrap"
                  height={300}
                  sx={{
                    overflowX: geocercas.length >= 5 ? "auto" : "visible",
                  }}
                  gap={2}
                >
                  {selectedGeocercas.map((geocerca) => (
                    <Grid
                      item
                      key={geocerca.id}
                      p={2}
                      width={"48%"}
                      // height={"20%"}
                      border={1}
                      borderRadius={"8px"}
                      borderColor={"grey.100"}
                      sx={{ position: "relative" }}
                    >
                      <IconButton
                        size="small"
                        aria-label="close"
                        onClick={() => handleRemoveGeocerca(geocerca.id)}
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0, // Posiciona el botón en la esquina superior derecha
                        }}
                      >
                        <CancelIcon fontSize="small" />
                      </IconButton>
                      {geocerca.nombre}
                    </Grid>
                  ))}
                </Grid>
              </>
            ) : (
              <>
                <ImageContainer src={notFoundGeocerca} />
              </>
            )}
          </Box> */}
        </Box>
        {isEditMode && (
          <NextButton
            handleGuardarPrincipal={handleGuardarPrincipal}
            isEditMode={true}
            nextComponent={nextComponent}
            idAlerta={idAlerta}
            handleClose={handleClose}
            ModalName={"geocerca"}
          />
        )}
        {/* <GeocercaModal open={isModalOpen} onClose={closeModal} /> */}
      </>
    );
  }
);
export default ListaGeocercas;
