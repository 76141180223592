// LeafletMapComponent.js

import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, FeatureGroup,useMap } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import L from 'leaflet';
import { Circle, Polygon } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import CenterMapOnGeocerca from "./CenterMapOnGeocerca";
import { useTheme } from '@mui/material/styles';

const LeafletMapComponent = ({
  center,
  zoom,
  userLocation,
  geocercas,
  onCreated,
  onEdited,
  onDeleted,
  onMoved,
  drawnItemsRef = null,
  mapRef = { current: null }, // Default value for mapRef,
  renderGeocercas,
  isFirstLoad,
  setIsFirstLoad,
  onLoad,
  leafletMapRef,
  selectedGeocercaId, // Recibimos la geocerca seleccionada
}) => {

  const theme = useTheme();

  console.log('mapRef en leafletmap', mapRef.current);
  //onLoad?.();
  useEffect(() => {
    if (mapRef.current) {
      console.log('🚀 Mapa Leaflet cargado (useEffect)');
      onLoad?.();
    }
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setView(center, zoom);
    }
  }, [center, zoom]);
  
 
  const MapViewUpdater = () => {
    const map = useMap();
    useEffect(() => {
      if (userLocation && isFirstLoad) {
        map.setView(userLocation, zoom);
        setIsFirstLoad(false);
      }
    }, [userLocation, zoom, map, isFirstLoad]);
    return null;
  };

  const userLocationIcon = L.divIcon({
    className: 'custom-user-location',
    html: `
       <div class="user-marker-container">
          <div class="pulse"></div>
          <div style="
            font-size: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
          ">
            🏠
          </div>
        </div>
    `,
    iconSize: [40, 40],
    iconAnchor: [20, 40],
  });


  const renderGeocercasConEtiquetas = () => {
    return geocercas.map((geocerca, index) => {

      const markerColor = geocerca.puntos.type === 'Polygon' 
        ? theme.palette.primary.main  // #ea0000 (red)
        : theme.palette.secondary.main; // #000000 (black)


      if (geocerca.puntos.type === 'Polygon') {
        const coordinates = geocerca.puntos.puntos.map(pt => [pt.latitud, pt.longitud]);

        // Calcular el centro del polígono
        const latitudes = coordinates.map(coord => coord[0]);
        const longitudes = coordinates.map(coord => coord[1]);
        const centerLat = (Math.min(...latitudes) + Math.max(...latitudes)) / 2;
        const centerLng = (Math.min(...longitudes) + Math.max(...longitudes)) / 2;
        const centro = [centerLat, centerLng];

        return (
          <React.Fragment key={index}>
            <Polygon
              positions={coordinates}
              pathOptions={{ color: '#4285F4', fillColor: '#4285F4', fillOpacity: 0.2 }}
            />


<Marker
  position={centro}
  icon={L.divIcon({
    className: 'marker-with-label',
    html: `
    <div class="marker-container">
      <div style="
        width: 30px;
        height: 45px;
        position: relative;
        filter: drop-shadow(0 1px 4px rgba(0,0,0,0.3));
      ">
        <div style="
          width: 100%;
          height: 100%;
          background-color: ${markerColor};
          clip-path: path('M15 0C6.716 0 0 6.716 0 15c0 8.284 15 30 15 30s15-21.716 15-30c0-8.284-6.716-15-15-15zm0 23c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z');
          display: flex;
          justify-content: center;
          align-items: center;
        ">
          <div style="
            width: 12px;
            height: 12px;
            background: white;
            border-radius: 50%;
            margin-top: -5px;
          "></div>
        </div>
      </div>
      <div class="label-container">
        <span class="label-text">${geocerca.nombre}</span>
      </div>
    </div>
  `,
    iconSize: [25, 45],     // Increased size
    iconAnchor: [12.5, 45], // Centered anchor point
  })}
/>
            {/* <Marker
              position={centro}
              icon={L.divIcon({
                className: 'marker-with-label',
                html: `
                  <div class="marker-container">
                    <img 
                      src="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png"
                      style="width: 25px; height: 41px;"
                    />
                    <div class="label-container">
                      <div class="label-content">                    
                        <span class="label-text">${geocerca.nombre}</span>
                      </div>
                    </div>
                  </div>
                `,
                iconSize: [25, 55],  // Height increased to accommodate label
                iconAnchor: [12, 41],
              })}
            /> */}

          </React.Fragment>
        );
      } else if (geocerca.puntos.type === 'Point') {
        //const centro = [geocerca.puntos.latitud, geocerca.puntos.longitud];
        //console.log('geocerca.puntos:', geocerca);
        const punto = geocerca.puntos.puntos[0];
        const centro = [Number(punto.latitud), Number(punto.longitud)];
        return (
          <React.Fragment key={index}>
            <Circle
              center={centro}
              radius={geocerca.radio}
              pathOptions={{ color: '#4285F4', fillColor: '#4285F4', fillOpacity: 0.2 }}
            />

            <Marker
              position={centro}
              icon={L.divIcon({
                className: 'marker-with-label',
                html: `
                  <div class="marker-container">
                    <div style="
                      width: 30px;
                      height: 45px;
                      position: relative;
                      filter: drop-shadow(0 1px 4px rgba(0,0,0,0.3));
                    ">
                      <div style="
                        width: 100%;
                        height: 100%;
                        background-color: ${markerColor};
                        clip-path: path('M15 0C6.716 0 0 6.716 0 15c0 8.284 15 30 15 30s15-21.716 15-30c0-8.284-6.716-15-15-15zm0 23c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z');
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      ">
                        <div style="
                          width: 12px;
                          height: 12px;
                          background: white;
                          border-radius: 50%;
                          margin-top: -5px;
                        "></div>
                      </div>
                    </div>
                    <div class="label-container">
                      <span class="label-text">${geocerca.nombre}</span>
                    </div>
                  </div>
                `,
                iconSize: [25, 45],     // Increased size
                iconAnchor: [12.5, 45], // Centered anchor point
              })}
            />
            
            {/* <Marker
              position={centro}
              icon={L.divIcon({
                className: 'marker-with-label',
                html: `
                  <div class="marker-container">
                     <img 
                      src="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-black.png"
                      style="width: 25px; height: 41px;"
                    />
                    <div class="label-container">
                      <div class="label-content">                    
                        <span class="label-text">${geocerca.nombre}</span>
                      </div>
                    </div>
                  </div>
                `,
                iconSize: [25, 55],  // Height increased to accommodate label
                iconAnchor: [12, 41],
              })}
            /> */}

          </React.Fragment>
        );
      } else {
        return null;
      }
    });
  };


  const styles = `

    .marker-container {
    display: flex;
    flex-direction: column;
    align-items: center;    
  }

  .label-container {
    background: transparent;
    padding: 0px 0px;
    border-radius: 3px;
    border: 0px solid #ccc;
    margin-top: -8px;
    white-space: nowrap;
  }

  .label-text {
    font-size: 14px;
     font-weight: 500;
    color: #333;
  }

  .custom-user-location {
    background: transparent;
  }

  .user-marker-container {
    position: relative;
    width: 40px;
    height: 40px;
  }

  .pulse {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 30px;
    height: 30px;
    background: rgba(66, 133, 244, 0.2);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: pulse 2s infinite;
  }

  .marker {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
  }

  @keyframes pulse {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(3);
      opacity: 0;
    }
  }
`;

  return (
    <>
    <MapContainer
      center={center}
      zoom={zoom}
      style={{ height: '400px', width: '100%' }}
      whenCreated={(map) => {
        console.log('Entrando en whenCreated');
        if (mapRef) mapRef.current = map; // Asignar el mapa si mapRef está definido
          console.log('🚀 Mapa Leaflet creado');
          onLoad?.(); // Llama a onLoad aquí
      }
    }
    >
      <MapViewUpdater />
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <CenterMapOnGeocerca
        geocercas={geocercas}
        selectedGeocercaId={selectedGeocercaId} // Pasamos la geocerca seleccionada
      />
      {userLocation && (
       <Marker position={userLocation} icon={userLocationIcon}>
          <Popup>Tu ubicación actual</Popup>
        </Marker>
      )}
      {/* {renderGeocercas()} */}
      {renderGeocercasConEtiquetas()}

      {drawnItemsRef?.current && (
      <FeatureGroup ref={drawnItemsRef}>
        <EditControl
          position="topright"
          onDrawStart={() => console.log('Draw Start')}
          onCreated={onCreated}
          onEdited={onEdited}
          onDeleted={onDeleted}
          onMoved={onMoved}
          draw={{
            rectangle: false,
            polyline: false,
            circlemarker: false,
            marker: false,
            circle: {
              shapeOptions: {
                color: '#1E90FF',
                fillColor: '#1E90FF',
                fillOpacity: 0.2
              },
              metric: true,
              feet: false,
              nautic: false,
              showRadius: true,
              radius: 100
            },
            polygon: {
              shapeOptions: {
                color: '#1E90FF',
                fillColor: '#1E90FF',
                fillOpacity: 0.2
              },
              allowIntersection: false
            },
          }}
          edit={{
            featureGroup: drawnItemsRef.current,
            edit: {
              moveMarkers: true,
              selectedPathOptions: {
                maintainColor: true,
                moveMarkers: true
              }
            },
            remove: true,
          }}
        />
      </FeatureGroup>
      )}
    </MapContainer>

    <style>{styles}</style>

    </>
  );
};

export default LeafletMapComponent;