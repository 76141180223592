// MapView.js
import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Circle,
  Polygon,
  LayersControl,
  useMap,
} from "react-leaflet";
import { Box } from "@mui/material";
import { geocercaService } from "../../services/apiService";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { injectPopupStyles } from "../../utils/popupStyles";
import { createVehicleMarkerHTML } from "../../utils/markerUtils";
import { createPopupContent } from "../../utils/popupUtils";
import vehicleIcon from "../../assets/svg_images/svg_vehiculo.svg";
import "./MapView.css";
import { vehiculoService, eventoService } from "../../services/apiService";
import { useTheme } from "@mui/material/styles";

const activeIcon = (alert) => {
  // Lo de abajo estaba entre la etiqueta style y el div
  // <div style="position: absolute; top: 0px; right: 0px;z-index:10;">
  //   <button class="close-button clear-alert-button">&times;</button>
  // </div>

  // ${createPopupContent(alert)}
  return new L.DivIcon({
    html: `
      <div class="alert-container" style="
        position: relative; 
        width: auto; 
        height: auto; 
        display: inline-block;        
        transform: translate(-50%, -100%);
        animation: fadeIn 0.3s ease-in-out;
      ">
        <style>
         
        </style>
      
        
        <div class="alert-icons2">
          <img src="${vehicleIcon}" class='vehicle-icon_2' style="width: 32px; height: 32px;"/>       
        </div>
      </div>
    `,
    iconAnchor: [10, -35],
    className: "",
  });
};

const createCustomMarker = (alert) => {
  const markerDiv = document.createElement("div");
  markerDiv.className = "vehicle-marker-container";
  markerDiv.innerHTML = createVehicleMarkerHTML(alert);

  return L.divIcon({
    className: "custom-vehicle-marker",
    html: markerDiv,
    iconSize: [60, 40],
    iconAnchor: [30, 20],
  });
};

// Función de animación
const animateMarker = (marker, toLatLng, duration = 1000) => {
  const fromLatLng = marker.getLatLng();
  const to = L.latLng(toLatLng);
  const fromLat = fromLatLng.lat;
  const fromLng = fromLatLng.lng;
  const toLatVal = to.lat;
  const toLngVal = to.lng;
  const start = performance.now();

  const animate = (currentTime) => {
    const elapsed = currentTime - start;
    const progress = Math.min(elapsed / duration, 1);

    const currentLat = fromLat + (toLatVal - fromLat) * progress;
    const currentLng = fromLng + (toLngVal - fromLng) * progress;
    marker.setLatLng([currentLat, currentLng]);

    if (progress < 1) {
      requestAnimationFrame(animate);
    } /*else {
      bounceMarker(marker); 
    }*/
  };

  requestAnimationFrame(animate);
};

const MapResizer = ({ expandMap }) => {
  const map = useMap();

  useEffect(() => {
    // Dar tiempo a que se complete la animación de expansión
    setTimeout(() => {
      map.invalidateSize();
      // Forzar un re-render del mapa
      window.dispatchEvent(new Event("resize"));
    }, 400); // Ajusta este valor según la duración de tu animación
  }, [expandMap, map]);

  return null;
};

// const MapResizer = ({ expandMap }) => {
//   const map = useMap();

//   useEffect(() => {
//     // Pequeño retraso para asegurar que la transición CSS se complete
//     const timer = setTimeout(() => {
//       map.invalidateSize();
//     }, 300);

//     return () => clearTimeout(timer);
//   }, [expandMap, map]);

//   return null;
// };

const VehicleMarkers = ({ alerts = [], selectedVehiculos = [] }) => {
  const map = useMap();
  const markersRef = useRef({});
  const latestVehiclePositions = useRef({});

  useEffect(() => {
    console.log("--- VehicleMarkers useEffect Triggered ---");
    console.log("Alerts recibidos en VehicleMarkers:", alerts);
    console.log(
      "Vehículos seleccionados en VehicleMarkers:",
      selectedVehiculos
    );

    // Resetear las posiciones de vehículos actuales
    latestVehiclePositions.current = {};

    const filteredAlerts = alerts.filter((alert) => {
      console.log("--- Evaluando Alerta ---");
      console.log("ID de Alerta:", alert.id);
      console.log("UID_Vehiculo de Alerta:", alert.UID_Vehiculo);
      console.log("Vehículos Seleccionados:", selectedVehiculos);
      const isIncluded =
        alert.UID_Vehiculo && selectedVehiculos.includes(alert.UID_Vehiculo);
      console.log(
        `¿UID_Vehiculo "${alert.UID_Vehiculo}" está en selectedVehiculos?`,
        isIncluded
      );
      if (!alert.UID_Vehiculo) {
        console.warn(
          `Alerta con ID "${alert.id}" no tiene definido UID_Vehiculo.`
        );
      }
      return isIncluded;
    });

    console.log(
      "Vehículos seleccionados en VehicleMarkers:",
      selectedVehiculos
    );
    console.log("Alertas totales en VehicleMarkers:", alerts);
    console.log("Alerts filtrados en VehicleMarkers:", filteredAlerts);

    // Actualizar solo las últimas posiciones de cada vehículo
    // filteredAlerts.forEach(alert => {
    //   latestVehiclePositions.current[alert.UID_Vehiculo] = alert;
    // });

    filteredAlerts
      .slice()
      .reverse()
      .forEach((alert) => {
        const vehicleId = alert.unidad;

        // Only set if not already set (to keep the latest position)
        if (!latestVehiclePositions.current[vehicleId]) {
          latestVehiclePositions.current[vehicleId] = alert;
        }
      });

    // Procesar solo las últimas posiciones
    Object.entries(latestVehiclePositions.current).forEach(
      ([vehicleId, alert]) => {
        if (!alert?.ubicacion?.lat || !alert?.ubicacion?.lng) {
          console.warn(
            `Alerta con ID "${alert.id}" tiene ubicación incompleta.`
          );
          return;
        }

        const position = [alert.ubicacion.lat, alert.ubicacion.lng];

        try {
          if (markersRef.current[vehicleId]) {
            // Actualizar marcador existente
            const marker = markersRef.current[vehicleId];

            animateMarker(marker, position, 1000); // Duración de 1 segundo

            marker.setIcon(createCustomMarker(alert));
            marker.getPopup().setContent(createPopupContent(alert));

            console.log(`Marcador actualizado para Vehículo ID: ${vehicleId}`);
          } else {
            // Crear nuevo marcador
            const marker = L.marker(position, {
              icon: createCustomMarker(alert),
            });

            marker.bindPopup(createPopupContent(alert), {
              className: "custom-popup",
            });

            marker.addTo(map);
            markersRef.current[vehicleId] = marker;
            console.log(`Marcador creado para Vehículo ID: ${vehicleId}`);
          }
        } catch (error) {
          console.error("Error al crear/actualizar marcador:", error);
        }
      }
    );

    // Limpiar marcadores de vehículos que ya no están en las últimas posiciones
    Object.entries(markersRef.current).forEach(([id, marker]) => {
      console.log(`Verificando si eliminar marcador para Vehículo ID: ${id}`);
      if (!latestVehiclePositions.current[id]) {
        console.log(`Eliminando marcador para Vehículo ID: ${id}`);
        marker.remove();
        delete markersRef.current[id];
        console.log(`Marcador removido para Vehículo ID: ${id}`);
      } else {
        console.log(`Marcador para Vehículo ID: ${id} se mantiene`);
      }
    });
  }, [alerts, selectedVehiculos, map]);

  return null;
};

function CenterMapOnGeocerca({ geocercas, selectedGeocercas, lastSelected }) {
  const map = useMap();

  useEffect(() => {
    if (lastSelected) {
      const selectedGeocerca = geocercas.find((g) => g.id === lastSelected);
      if (selectedGeocerca) {
        let center, zoom;

        if (selectedGeocerca.puntos.type === "Point") {
          center = [
            selectedGeocerca.puntos.puntos[0].latitud,
            selectedGeocerca.puntos.puntos[0].longitud,
          ];
          zoom = Math.max(15, map.getZoom()); // Zoom para círculos
        } else {
          // Para polígonos, calcular el centro
          const points = selectedGeocerca.puntos.puntos;
          const lats = points.map((p) => Number(p.latitud));
          const lngs = points.map((p) => Number(p.longitud));

          center = [
            (Math.min(...lats) + Math.max(...lats)) / 2,
            (Math.min(...lngs) + Math.max(...lngs)) / 2,
          ];

          // Calcular zoom basado en el tamaño del polígono
          const bounds = L.latLngBounds(
            points.map((p) => [p.latitud, p.longitud])
          );
          map.fitBounds(bounds, { padding: [50, 50] });
          return; // Salimos temprano ya que fitBounds maneja el zoom
        }

        map.setView(center, zoom, {
          animate: true,
          duration: 1,
        });
      }
    }
  }, [lastSelected, geocercas, map]);

  return null;
}

// function CenterMapOnGeocerca({ geocercas, selectedGeocercas }) {
//   const map = useMap();

//   useEffect(() => {
//     if (selectedGeocercas.length === 1) {
//       const selectedGeocerca = geocercas.find(g => g.id === selectedGeocercas[0]);
//       if (selectedGeocerca) {
//         let center;
//         if (selectedGeocerca.puntos.type === 'Point') {
//           center = [
//             selectedGeocerca.puntos.puntos[0].latitud,
//             selectedGeocerca.puntos.puntos[0].longitud
//           ];
//         } else {
//           // Para polígonos, calcular el centro
//           const points = selectedGeocerca.puntos.puntos;
//           const lat = points.reduce((sum, p) => sum + Number(p.latitud), 0) / points.length;
//           const lng = points.reduce((sum, p) => sum + Number(p.longitud), 0) / points.length;
//           center = [lat, lng];
//         }
//         map.setView(center, 15); // Zoom level 15 for mejor vista
//       }
//     }
//   }, [selectedGeocercas, geocercas, map]);

//   return null;
// }

// Componente para actualizar la vista del mapa
function MapViewUpdater({ center, activeAlert }) {
  const map = useMap();
  useEffect(() => {
    if (center) {
      map.setView(center, 13);
    }
  }, [center, map]);

  useEffect(() => {
    if (activeAlert) {
      const alertLatLng = L.latLng(
        activeAlert.ubicacion.lat,
        activeAlert.ubicacion.lng
      );

      // Calcular un offset hacia abajo para el centro
      const offset = -0.002; // Ajusta este valor según necesites
      const adjustedLatLng = L.latLng(
        alertLatLng.lat - offset,
        alertLatLng.lng
      );

      // Centrar el mapa con el offset
      map.setView(adjustedLatLng, 16, {
        animate: true,
        duration: 1,
      });
    }
  }, [activeAlert, map]);

  return null;
}

const CustomListbox = React.forwardRef((props, ref) => {
  const { children, ownerState, ...other } = props; // Excluir ownerState
  return (
    <ul
      {...other}
      ref={ref}
      style={{
        listStyle: "none", // Eliminar viñetas
        padding: 0, // Ajustar padding
        margin: 0, // Ajustar margin
        maxHeight: 200, // Mantener altura máxima
        overflow: "auto", // Habilitar scroll
      }}
    >
      {children}
    </ul>
  );
});

const MapView = ({
  center,
  alerts,
  activeAlert,
  onClearActiveAlert,
  expandMap,
  onLoadComplete,
  viewMode,
}) => {
  const [geocercas, setGeocercas] = useState([]);
  const [selectedGeocercas, setSelectedGeocercas] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [initialCenter, setInitialCenter] = useState([21.1619, -86.8515]);
  const [isLocationReady, setIsLocationReady] = useState(false);
  const [lastSelectedGeocerca, setLastSelectedGeocerca] = useState(null);

  const [vehiculos, setVehiculos] = useState([]);
  const [selectedVehiculos, setSelectedVehiculos] = useState([]);

  const [selectAllGeocercas, setSelectAllGeocercas] = useState(true);
  const [selectAllVehiculos, setSelectAllVehiculos] = useState(true);

  const theme = useTheme();
  const createLabelIcon = (text, type) => {
    const markerColor =
      type === "Polygon"
        ? theme.palette.primary.main // Color para polígonos
        : theme.palette.secondary.main; // Color para puntos

    return L.divIcon({
      className: "marker-with-label",
      html: `
        <div class="marker-container">
          <div style="
            width: 30px;
            height: 45px;
            position: relative;
            filter: drop-shadow(0 1px 4px rgba(0,0,0,0.3));
          ">
            <div style="
              width: 100%;
              height: 100%;
              background-color: ${markerColor};
              clip-path: path('M15 0C6.716 0 0 6.716 0 15c0 8.284 15 30 15 30s15-21.716 15-30c0-8.284-6.716-15-15-15zm0 23c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z');
              display: flex;
              justify-content: center;
              align-items: center;
            ">
              <div style="
                width: 12px;
                height: 12px;
                background: white;
                border-radius: 50%;
                margin-top: -5px;
              "></div>
            </div>
          </div>
          <div class="label-container">
            <span class="label-text">${text}</span>
          </div>
        </div>
      `,
      iconSize: [25, 45],
      iconAnchor: [12.5, 45],
    });
  };

  // Handlers para seleccionar todo
  const handleToggleSelectAllGeocercas = (e) => {
    e.preventDefault();
    setSelectAllGeocercas(!selectAllGeocercas);
    setSelectedGeocercas(selectAllGeocercas ? [] : geocercas.map((g) => g.id));
  };

  const handleToggleSelectAllVehiculos = (e) => {
    e.preventDefault();
    setSelectAllVehiculos(!selectAllVehiculos);
    setSelectedVehiculos(selectAllVehiculos ? [] : vehiculos.map((v) => v.id));
  };

  useEffect(() => {
    injectPopupStyles();
  }, []);

  useEffect(() => {
    // Simular tiempo de carga del mapa
    const timer = setTimeout(() => {
      onLoadComplete?.();
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    window.clearActiveAlert = onClearActiveAlert;
  }, [onClearActiveAlert]);

  useEffect(() => {
    const loadVehiculos = async () => {
      try {
        const response = await vehiculoService.getVehiculos();
        const vehiculosData = response.data || [];
        setVehiculos(vehiculosData);
        // Inicializar con todos seleccionados usando 'id'
        setSelectedVehiculos(vehiculosData.map((v) => v.id));
        setSelectAllVehiculos(true);
        console.log(
          "Vehículos cargados y seleccionados:",
          vehiculosData.map((v) => v.id)
        );
      } catch (error) {
        console.error("Error cargando vehículos:", error);
      }
    };
    loadVehiculos();
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation([latitude, longitude]);
          setInitialCenter([latitude, longitude]);
          setIsLocationReady(true);
        },
        (error) => {
          console.error("Error getting location:", error);
          setIsLocationReady(true);
        }
      );
    } else {
      setIsLocationReady(true);
    }
  }, []);

  // useEffect(() => {
  //   // Load geocercas
  //   const loadGeocercas = async () => {
  //     try {
  //       const response = await geocercaService.getGeocercas();
  //       if (response.statusCode === 200) {
  //         setGeocercas(response.data);
  //         setSelectedGeocercas(response.data.map((g) => g.id));
  //       }
  //     } catch (error) {
  //       console.error("Error loading geocercas:", error);
  //     }
  //   };
  //   loadGeocercas();
  // }, []);

  const handleSelectAllGeocercas = (checked) => {
    if (checked) {
      setSelectedGeocercas(geocercas.map((g) => g.id));
    } else {
      setSelectedGeocercas([]);
    }
  };

  const handleSelectAllVehiculos = (checked) => {
    if (checked) {
      setSelectedVehiculos(vehiculos.map((v) => v.id));
    } else {
      setSelectedVehiculos([]);
    }
  };

  const userLocationIcon = L.divIcon({
    className: "custom-user-location",
    html: `
       <div class="user-marker-container">
          <div class="pulse"></div>
          <div style="
            font-size: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
          ">
            🏠
          </div>
        </div>
    `,
    iconSize: [40, 40],
    iconAnchor: [20, 40],
  });

  const getPolygonCenter = (points) => {
    const latitudes = points.map((p) => Number(p.latitud));
    const longitudes = points.map((p) => Number(p.longitud));
    const centerLat = (Math.min(...latitudes) + Math.max(...latitudes)) / 2;
    const centerLng = (Math.min(...longitudes) + Math.max(...longitudes)) / 2;
    return [centerLat, centerLng];
  };

  return (
    <>
      {/* <Paper elevation={3}> */}
      <Box
        bgcolor="background.paper"
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          transition: "all 0.3s ease", // Añadir transición suave
          flex: 1, // Asegurar que el contenedor crece
          position: "relative", // Importante para el posicionamiento
          // overflowY: 'hidden',
          // overflowX: 'hidden',
          gap: 2,
          p: 2,
        }}
      >
        {/* <Box p={2}>
          {viewMode === "geocercas" ? (
            <Autocomplete
              sx={{ width: "100%" }}
              multiple
              id="geocercas-autocomplete"
              options={geocercas}
              noOptionsText="No hay coincidencias"
              disableCloseOnSelect
              disableListWrap
              getOptionLabel={(option) => option.nombre}
              value={geocercas.filter((g) => selectedGeocercas.includes(g.id))}
              onChange={(event, newValue, reason, details) => {
                switch (reason) {
                  case "clear":
                    setSelectAllGeocercas(false);
                    setSelectedGeocercas([]);
                    break;
                  case "removeOption":
                    const newSelected = newValue.map((g) => g.id);
                    setSelectAllGeocercas(
                      newSelected.length === geocercas.length
                    );
                    setSelectedGeocercas(newSelected);
                    break;
                  case "selectOption":
                    if (details?.option) {
                      setLastSelectedGeocerca(details.option.id);
                    }
                    const selectedIds = newValue.map((g) => g.id);
                    setSelectAllGeocercas(
                      selectedIds.length === geocercas.length
                    );
                    setSelectedGeocercas(selectedIds);
                    break;
                  default:
                    const ids = newValue.map((g) => g.id);
                    setSelectAllGeocercas(ids.length === geocercas.length);
                    setSelectedGeocercas(ids);
                }
              }}
              renderTags={(value) =>
                value.length > 0 ? (
                  <Typography variant="body2">
                    {`${value.length} seleccionados`}
                  </Typography>
                ) : null
              }
              renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props;
                return (
                  <li key={option.id} {...optionProps}>
                    <Checkbox
                      size="small"
                      style={{ marginRight: 8 }}
                      checked={selectedGeocercas.includes(option.id)}
                    />
                    {option.nombre}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Geocercas"
                  placeholder={selectedGeocercas.length === 0 ? "Todas" : ""}
                />
              )}
              groupBy={() => ""}
              renderGroup={(params) => (
                <div key={params.key}>
                  <Box
                    pl={3.5}
                    py={0.5}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    <FormControlLabel
                      onClick={handleToggleSelectAllGeocercas}
                      label="Seleccionar todas"
                      control={
                        <Checkbox
                          size="small"
                          checked={selectAllGeocercas}
                          indeterminate={
                            selectedGeocercas.length > 0 &&
                            selectedGeocercas.length < geocercas.length
                          }
                        />
                      }
                    />
                  </Box>
                  <Divider />
                  {params.children}
                </div>
              )}
            />
          ) : (
            <Autocomplete
              sx={{ width: "100%" }}
              multiple
              id="vehiculos-autocomplete"
              options={vehiculos}
              noOptionsText="No hay coincidencias"
              disableCloseOnSelect
              disableListWrap
              getOptionLabel={(option) =>
                `${option.alias} ${
                  option.no_Economico ? `- ${option.no_Economico}` : ""
                }`
              }
              value={vehiculos.filter((v) => selectedVehiculos.includes(v.id))}
              onChange={(event, newValue, reason) => {
                switch (reason) {
                  case "clear":
                    setSelectAllVehiculos(false);
                    setSelectedVehiculos([]);
                    break;
                  case "removeOption":
                    const newSelected = newValue.map((v) => v.id);
                    setSelectAllVehiculos(
                      newSelected.length === vehiculos.length
                    );
                    setSelectedVehiculos(newSelected);
                    break;
                  case "selectOption":
                    const selectedIds = newValue.map((v) => v.id);
                    setSelectAllVehiculos(
                      selectedIds.length === vehiculos.length
                    );
                    setSelectedVehiculos(selectedIds);
                    break;
                  default:
                    const ids = newValue.map((v) => v.id);
                    setSelectAllVehiculos(ids.length === vehiculos.length);
                    setSelectedVehiculos(ids);
                }
              }}
              renderTags={(value) =>
                value.length > 0 ? (
                  <Typography variant="body2">
                    {`${value.length} seleccionados`}
                  </Typography>
                ) : null
              }
              renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props;
                return (
                  <li key={option.id} {...optionProps}>
                    <Checkbox
                      size="small"
                      style={{ marginRight: 8 }}
                      checked={selectedVehiculos.includes(option.id)}
                    />
                    {`${option.alias} ${
                      option.no_Economico ? `- ${option.no_Economico}` : ""
                    }`}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Vehículos"
                  placeholder={selectedVehiculos.length === 0 ? "Todos" : ""}
                />
              )}
              groupBy={() => ""}
              renderGroup={(params) => (
                <div key={params.key}>
                  <Box
                    pl={3.5}
                    py={0.5}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    <FormControlLabel
                      onClick={handleToggleSelectAllVehiculos}
                      label="Seleccionar todos"
                      control={
                        <Checkbox
                          size="small"
                          checked={selectAllVehiculos}
                          indeterminate={
                            selectedVehiculos.length > 0 &&
                            selectedVehiculos.length < vehiculos.length
                          }
                        />
                      }
                    />
                  </Box>
                  <Divider />
                  {params.children}
                </div>
              )}
            />
          )}
        </Box> */}

        {isLocationReady && (
          <MapContainer
            center={center || initialCenter}
            zoom={13}
            style={{
              height: "100%",
              width: "100%",
              flex: 1,
              minHeight: "450px", // Agregar esto para evitar que el mapa se colapse
              borderRadius: "30px",
            }}
          >
            <MapViewUpdater
              center={userLocation || initialCenter}
              activeAlert={activeAlert}
            />

            <MapResizer expandMap={expandMap} />

            {/* <VehicleMarkers alerts={alerts} /> */}
            {/* <VehicleMarkers 
              alerts={alerts.filter(alert => {
                console.log('Alert UID:', alert.UID_Vehiculo);
                console.log('Selected Vehiculos:', selectedVehiculos);
                return selectedVehiculos.includes(alert.UID_Vehiculo);
              })} 
            /> */}

            <VehicleMarkers
              alerts={alerts}
              selectedVehiculos={selectedVehiculos}
            />

            {/* <VehicleMarkers 
              alerts={alerts.filter(alert => 
                selectedVehiculos.includes(alert.UID_Vehiculo)
              )} 
            /> */}

            <CenterMapOnGeocerca
              geocercas={geocercas}
              selectedGeocercas={selectedGeocercas}
              lastSelected={lastSelectedGeocerca}
            />

            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution="&copy; OpenStreetMap contributors"
            />

            {userLocation && (
              <Marker position={userLocation} icon={userLocationIcon}>
                <Popup>Tu ubicación actual</Popup>
              </Marker>
            )}

            {activeAlert && (
              <Marker
                position={[
                  activeAlert.ubicacion.lat,
                  activeAlert.ubicacion.lng,
                ]}
                icon={activeIcon(activeAlert)}
                eventHandlers={{
                  click: onClearActiveAlert,
                }}
              ></Marker>
            )}

            <LayersControl position="topright">
              {geocercas.map(
                (geocerca) =>
                  selectedGeocercas.includes(geocerca.id) && (
                    <LayersControl.Overlay
                      key={geocerca.id}
                      checked
                      name={geocerca.nombre}
                    >
                      <>
                        {geocerca.puntos.type === "Point" ? (
                          <>
                            <Circle
                              center={[
                                geocerca.puntos.puntos[0].latitud,
                                geocerca.puntos.puntos[0].longitud,
                              ]}
                              radius={geocerca.radio}
                              pathOptions={{ color: "#1E90FF" }}
                            >
                              <Popup>{geocerca.nombre}</Popup>
                            </Circle>

                            <Marker
                              position={[
                                geocerca.puntos.puntos[0].latitud,
                                geocerca.puntos.puntos[0].longitud,
                              ]}
                              icon={createLabelIcon(geocerca.nombre, "Point")}
                              zIndexOffset={1000}
                            />
                          </>
                        ) : (
                          <>
                            <Polygon
                              positions={geocerca.puntos.puntos.map((p) => [
                                p.latitud,
                                p.longitud,
                              ])}
                              pathOptions={{ color: "#1E90FF" }}
                            >
                              <Popup>{geocerca.nombre}</Popup>
                            </Polygon>

                            <Marker
                              position={getPolygonCenter(
                                geocerca.puntos.puntos
                              )}
                              icon={createLabelIcon(geocerca.nombre, "Polygon")}
                              zIndexOffset={1000}
                            />
                          </>
                        )}
                      </>
                    </LayersControl.Overlay>
                  )
              )}
            </LayersControl>
          </MapContainer>
        )}
      </Box>
      {/* </Paper> */}
    </>
  );
};

export default MapView;
