// contexts/AuthContext.js
import React, { createContext, useState, useEffect } from "react";
import { empresaService } from "../services/apiService";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [autenticado, setAutenticado] = useState(() => {
    return localStorage.getItem("auth") === "true";
  });

  // Añadir estado para el nombre de usuario
  const [userName, setUserName] = useState(() => {
    return localStorage.getItem("userName") || "";
  });

  const [razonSocial, setRazonSocial] = useState(() => {
    return localStorage.getItem("razon_social") || "";
  });
  
  const [nombreComercial, setNombreComercial] = useState(() => {
    return localStorage.getItem("nombre_comercial") || "";
  });

  const [uidExterno, setUidExterno] = useState(() => {
    return localStorage.getItem("uid_externo") || "";
  });

  const [passwordQuro, setPasswordQuro] = useState(() => {
    return localStorage.getItem("password_quro") || "";
  });
  const [uidExternoUsuario, setUidExternoUsuario] = useState(() => {
    return localStorage.getItem("uid_externoUsuario") || "";
  });
  const [user, setUser] = useState(() => {
    return localStorage.getItem("user") || "";
  });

  const [id_externo, setid_externo] = useState(() => {
    return localStorage.getItem("id_externo") || "";
  });

  

  const [groups, setGroups] = useState(() => {
    try {
      const storedGroups = localStorage.getItem("groups");
      return storedGroups ? JSON.parse(storedGroups) : [];
    } catch (error) {
      console.error("Error parsing stored groups:", error);
      return [];
    }
  });

  useEffect(() => {
    if (autenticado) {
      localStorage.setItem("auth", "true");
    } else {
      localStorage.removeItem("auth");
      localStorage.removeItem("userName");
      localStorage.removeItem("token");
      localStorage.removeItem("uuid_empresa");
      localStorage.removeItem("uuid_usuario");
      localStorage.removeItem("uid_externo");
      localStorage.removeItem("password_quro");
      localStorage.removeItem("uid_externoUsuario");
      localStorage.removeItem("user");
      localStorage.removeItem("groups"); 
      localStorage.removeItem("razon_social");
      localStorage.removeItem("nombre_comercial");
    }
  }, [autenticado]);

  const login = async (
    token,
    nombre,
    uuid_empresa,
    uuid_usuario,
    nuevo_usuario,
    password_quro,
    uid_externo,
    user,
    groupsData = [],
     razon_social = "",
    nombre_comercial = "",
     id_externo = ""
  ) => {
    setAutenticado(true);
    setUserName(nombre);

    setPasswordQuro(password_quro);
    setUidExternoUsuario(uid_externo);
    setUser(user);

    setRazonSocial(razon_social);
    setNombreComercial(nombre_comercial);
    setid_externo(id_externo);
    localStorage.setItem("auth", "true");
    localStorage.setItem("token", token);
    localStorage.setItem("userName", nombre);
    localStorage.setItem("uuid_empresa", uuid_empresa);
    localStorage.setItem("uuid_usuario", uuid_usuario);
    localStorage.setItem("nuevo_usuario", nuevo_usuario);
    localStorage.setItem("password_quro", password_quro);
    localStorage.setItem("uid_externoUsuario", uid_externo);
    localStorage.setItem("user", user);
    localStorage.setItem("razon_social", razon_social);
    localStorage.setItem("nombre_comercial", nombre_comercial);
    localStorage.setItem("id_externo", id_externo);


   

    if (groupsData && groupsData.length > 0) {
      console.log("Storing groups in login:", groupsData);
      localStorage.setItem("groups", JSON.stringify(groupsData));
      setGroups(groupsData);
    }


    //console.log("nuevo_usuario:", nuevo_usuario);

    try {
      const empresaData = await empresaService.getEmpresas(uuid_empresa);
      console.log("empresaData:", empresaData);
      if (empresaData && empresaData.data[0].uiD_Externo) {
        console.log(
          "empresaData.UID_Externo:",
          empresaData.data[0].uiD_Externo
        );
        localStorage.setItem("uid_externo", empresaData.data[0].uiD_Externo);
        setUidExterno(empresaData.data[0].uiD_Externo);
      }
    } catch (error) {
      console.error("Error fetching empresa UID_Externo:", error);
    }
  };

  const logout = () => {
    setAutenticado(false);
    setUserName("");
    setUserName("");
    setGroups([]);

    localStorage.removeItem("auth");
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    localStorage.removeItem("uuid_empresa");
    localStorage.removeItem("uuid_usuario");
    localStorage.removeItem("uid_externo");
    localStorage.removeItem("password_quro");
    localStorage.removeItem("uid_externoUsuario");
    localStorage.removeItem("user");
    localStorage.removeItem("razon_social");
    localStorage.removeItem("id_externo");
    localStorage.removeItem("nombre_comercial");
  };

  return (
    <AuthContext.Provider
      value={{
        autenticado,
        setAutenticado,
        userName,
        razonSocial,
        nombreComercial,
        setUserName,
        uidExterno,
        passwordQuro,
        setPasswordQuro,
        uidExternoUsuario,
        setUidExternoUsuario,
        user,
        setUser,
        groups, // Expose groups to components
        setGroups, // Allow components to update groups
        id_externo, 
        setid_externo, 
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
