import React, { useState, useEffect, useCallback, forwardRef, useImperativeHandle } from "react";
import {
  TextField,
  IconButton,
  Paper,
  Button,
  Typography,
  Box,
  InputLabel,
  Select,
  OutlinedInput,
  ListItemText,
  FormControl,
  MenuItem,
  InputAdornment,
  Divider,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { FilterList } from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';
import {
  vehiculoService,
  eventoService,
  clasificacionService,
  ClasificacionEventoService,
  geocercaService,
} from "../../services/apiService";
import { motion } from "framer-motion";
import { Checkbox } from "@mui/material";

import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AccessTimeIcon from '@mui/icons-material/AccessTime';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FilterBar = forwardRef(({ onFilter, onResetFilters }, ref) => {
  const [vehiculos, setVehiculos] = useState([]);
  const [geocercas, setGeocercas] = useState([]);
  const [eventos, setEventos] = useState([]);
  const [clasificacionEventos, setClasificacionEventos] = useState([]);
  const [eventosFiltrados, setEventosFiltrados] = useState([]);
  const [selectedVehiculos, setSelectedVehiculos] = useState([]);
  const [selectedGeocercas, setSelectedGeocercas] = useState([]);
  const [selectedEventos, setSelectedEventos] = useState([]);
  const [selectedRiesgos, setSelectedRiesgos] = useState([]);
  
  // Search states
  const [vehiculosSearch, setVehiculosSearch] = useState("");
  const [geocercasSearch, setGeocercasSearch] = useState("");
  const [eventosSearch, setEventosSearch] = useState("");
  const [riesgosSearch, setRiesgosSearch] = useState("");
  
  // Lista de riesgos disponibles
  const riesgos = [
    { id: 'Alto', nombre: 'Alto' },
    { id: 'Medio', nombre: 'Medio' },
    { id: 'Bajo', nombre: 'Bajo' },
  ];

  const initialHoraInicial = new Date();
  initialHoraInicial.setHours(0, 0, 0, 0);

  const initialHoraFinal = new Date();
  initialHoraFinal.setHours(23, 59, 59, 999);

  const [horaInicial, setHoraInicial] = useState(initialHoraInicial);
  const [horaFinal, setHoraFinal] = useState(initialHoraFinal);


  const presetTimeRanges = [
    { label: "Todo el día", inicial: "00:00", final: "23:59" },
    { label: "Mañana", inicial: "06:00", final: "12:00" },
    { label: "Tarde", inicial: "12:00", final: "18:00" },
    { label: "Noche", inicial: "18:00", final: "23:59" },
  ];
  
  const setTimeFromString = (timeStr) => {
    const [hours, minutes] = timeStr.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
  };
  
  const selectPresetTimeRange = (preset) => {
    setHoraInicial(setTimeFromString(preset.inicial));
    setHoraFinal(setTimeFromString(preset.final));
  };

  
  const formatTimeForInput = (date) => {
    return date.toTimeString().slice(0, 5); // Formato HH:MM
  };
  
  const parseTimeInput = (timeStr) => {
    const [hours, minutes] = timeStr.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
  };


  // Exponemos la función resetFilters para que pueda ser llamada desde el componente padre
  useImperativeHandle(ref, () => ({
    resetFilters
  }));

  const resetFilters = () => {
    setSelectedVehiculos([]);
    setSelectedEventos([]);
    setSelectedRiesgos([]);
    setSelectedGeocercas([]);
    setEventosFiltrados([]);
    setHoraInicial(initialHoraInicial);
    setHoraFinal(initialHoraFinal);
    
    // Clear searches
    setVehiculosSearch("");
    setGeocercasSearch("");
    setEventosSearch("");
    setRiesgosSearch("");
    
    // If reset function exists in parent, call it
    if (onResetFilters) {
      onResetFilters();
    }
  };

  // Data fetching
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [vehiculosResult, eventosResult, geocercasResult] =
          await Promise.all([
            vehiculoService.getVehiculos(),
            eventoService.getEventos(),
            geocercaService.getGeocercas("", "", "", "1"),
          ]);
        setVehiculos(vehiculosResult.data || []);
        setEventos(eventosResult.data || []);
        setGeocercas(geocercasResult.data || []);
      } catch (error) {
        console.error("Error al cargar datos:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    filtroClasificacionEventos();
    onFilter({
      horaInicial,
      horaFinal,
      vehiculos: selectedVehiculos.map((v) => v.id),
      tiposAlerta: selectedEventos.map((e) => e.id),
      geocercas: selectedGeocercas.map((g) => g.id),
      riesgos: selectedRiesgos.map((r) => r.id),
    });
  }, [
    horaInicial,
    horaFinal,
    selectedVehiculos,
    selectedEventos,
    selectedGeocercas,
    selectedRiesgos,
    onFilter,
  ]);

  // Other functions
  const filtroClasificacionEventos = async () => {
    // Implementation preserved
  };

  useEffect(() => {
    if (clasificacionEventos.length > 0) {
      const filtrados = eventos.filter((evento) =>
        clasificacionEventos.includes(evento.id)
      );
      setEventosFiltrados(filtrados);
    } else {
      setEventosFiltrados([]);
    }
  }, [clasificacionEventos, eventos]);

  const handleHoraInicialChange = useCallback((newValue) => {
    setHoraInicial(newValue);
  }, []);

  const handleHoraFinalChange = useCallback((newValue) => {
    setHoraFinal(newValue);
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    
    if (name === "vehiculos") {
      const selectedObjects = value.map(id => 
        vehiculos.find(vehiculo => vehiculo.id === id)
      ).filter(Boolean);
      setSelectedVehiculos(selectedObjects);
    }
    
    if (name === "geocercas") {
      const selectedObjects = value.map(id => 
        geocercas.find(geocerca => geocerca.id === id)
      ).filter(Boolean);
      setSelectedGeocercas(selectedObjects);
    }
    
    if (name === "eventos") {
      const selectedObjects = value.map(id => 
        eventos.find(evento => evento.id === id)
      ).filter(Boolean);
      setSelectedEventos(selectedObjects);
    }

    if (name === "riesgos") {
      const selectedObjects = value.map(id => 
        riesgos.find(riesgo => riesgo.id === id)
      ).filter(Boolean);
      setSelectedRiesgos(selectedObjects);
    }
  };

  // Funciones para gestionar los filtros de búsqueda
  const getFilteredItems = (items, searchText, propertyName) => {
    if (!searchText) return items;
    return items.filter(item => 
      (item[propertyName] || "").toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const getFilteredVehiculos = () => getFilteredItems(vehiculos, vehiculosSearch, "alias");
  const getFilteredGeocercas = () => getFilteredItems(geocercas, geocercasSearch, "nombre");
  const getFilteredEventos = () => getFilteredItems(eventos, eventosSearch, "nombre");
  const getFilteredRiesgos = () => getFilteredItems(riesgos, riesgosSearch, "nombre");

  // Funciones para seleccionar/deseleccionar todos
  const handleSelectAll = (items, setItems, e) => {
    // Guardar la posición del scroll
    const menuElement = e?.currentTarget?.closest('.MuiPaper-root');
    const scrollPosition = menuElement?.scrollTop || 0;
    
    setItems(items);
    
    // Restaurar la posición del scroll después de la actualización
    if (menuElement) {
      setTimeout(() => {
        menuElement.scrollTop = scrollPosition;
      }, 0);
    }
  };
  
  const handleDeselectAll = (setItems) => {
    setItems([]);
  };

  // Estilos consistentes para los botones
  const buttonStyles = {
    selectButton: {
      variant: "contained",
      color: "primary",
      sx: { py: 0.5 } 
    },
    deselectButton: {
      variant: "contained",
      color: "primary",
      sx: { py: 0.5 } 
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        mb: 0,
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      }}
      component={motion.div}
      initial={{ y: -20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
    >
      <Grid
        container
        spacing={2}
        alignItems="flex-end"
        columns={{ xs: 12, sm: 12, md: 12 }}
      >

<Grid size={{ xs: 12, sm: 6, md: 4 }}>
  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
    {/* Selector de hora inicial actualizado */}
    <TextField
      label="Desde"
      type="time"
      value={formatTimeForInput(horaInicial)}
      onChange={(e) => {
        const timeStr = e.target.value;
        setHoraInicial(parseTimeInput(timeStr));
      }}
      size="small"
      fullWidth
      slotProps={{
        inputLabel: { 
          shrink: true 
        },
        htmlInput: {
          step: 300, // 5 min
          style: { 
            // Estilos para mejorar visibilidad del icono en modo oscuro
            color: 'inherit'
          }
        },
      }}
      sx={{
        // Aplica estilos al icono del reloj para que sea visible en modo oscuro
        '& input::-webkit-calendar-picker-indicator': {
          filter: theme => theme.palette.mode === 'dark' ? 'invert(100%)' : 'none'
        }
      }}
    />
    
    <Typography variant="body2">a</Typography>
    
    {/* Selector de hora final actualizado */}
    <TextField
      label="Hasta"
      type="time"
      value={formatTimeForInput(horaFinal)}
      onChange={(e) => {
        const timeStr = e.target.value;
        setHoraFinal(parseTimeInput(timeStr));
      }}
      size="small"
      fullWidth
      slotProps={{
        inputLabel: { 
          shrink: true 
        },
        htmlInput: {
          step: 300, // 5 min
          style: { 
            color: 'inherit' 
          }
        },
      }}
      sx={{
        '& input::-webkit-calendar-picker-indicator': {
          filter: theme => theme.palette.mode === 'dark' ? 'invert(100%)' : 'none'
        }
      }}
    />
  </Box>
</Grid>

{/* <Grid size={{ xs: 12, sm: 6, md: 4 }}>
  <Box sx={{ 
    border: '1px solid rgba(0, 0, 0, 0.23)', 
    borderRadius: '4px', 
    p: 1.5, 
    display: 'flex', 
    flexDirection: 'column',
    gap: 1
  }}>
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
      <AccessTimeIcon color="primary" sx={{ mr: 1 }} />
      <Typography variant="body2" fontWeight="500">Rango de horas</Typography>
    </Box>
    
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      <TimePicker
        ampm={false}
        value={horaInicial}
        onChange={handleHoraInicialChange}
        views={['hours', 'minutes']}
        slotProps={{
          textField: {
            size: 'small',
            fullWidth: true,
            inputProps: { style: { textAlign: 'center' } }
          }
        }}
      />
      <Typography variant="body2">a</Typography>
      <TimePicker
        ampm={false}
        value={horaFinal}
        onChange={handleHoraFinalChange}
        views={['hours', 'minutes']}
        slotProps={{
          textField: {
            size: 'small',
            fullWidth: true,
            inputProps: { style: { textAlign: 'center' } }
          }
        }}
      />
    </Box>
    
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 0.5 }}>
      {presetTimeRanges.map((preset) => (
        <Button
          key={preset.label}
          size="small"
          variant="outlined"
          onClick={() => selectPresetTimeRange(preset)}
          sx={{ fontSize: '0.75rem', py: 0.25 }}
        >
          {preset.label}
        </Button>
      ))}
    </Box>
  </Box>
</Grid> */}


        {/* <Grid size={{ xs: 12, sm: 6, md: 2 }}>
          <DesktopTimePicker
            label="Hora Inicial"
            value={horaInicial}
            onChange={handleHoraInicialChange}
            views={["hours", "minutes"]}
            ampm={false}
            minutesStep={1}
            secondsStep={1}
            disablePortal
            inputFormat="HH:mm"
            mask="__:__"
            slotProps={{
              textField: {
                variant: "outlined",
                size: "small",
                sx: { width: "100%" },
                inputProps: { autoComplete: "off" },
              },
            }}
          />
        </Grid>

        <Grid size={{ xs: 12, sm: 6, md: 2 }}>
          <DesktopTimePicker
            label="Hora Final"
            value={horaFinal}
            onChange={handleHoraFinalChange}
            views={["hours", "minutes"]}
            ampm={false}
            minutesStep={1}
            secondsStep={1}
            disablePortal
            inputFormat="HH:mm"
            mask="__:__"
            slotProps={{
              textField: {
                variant: "outlined",
                size: "small",
                sx: { width: "100%" },
                inputProps: { autoComplete: "off" },
              },
            }}
          />
        </Grid> */}

        {/* Vehículos Select */}
        <Grid size={{ xs: 12, sm: 6, md: 2 }}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel id="select-vehiculos-label">Vehículos</InputLabel>
            <Select
              labelId="select-vehiculos-label"
              id="select-vehiculos"
              name="vehiculos"
              multiple
              value={selectedVehiculos.map(v => v.id)}
              onChange={handleChange}
              input={<OutlinedInput label="Vehículos" />}
              renderValue={(selected) => 
                selected.length > 0 ? `${selected.length} seleccionados` : ''}
              MenuProps={MenuProps}
              onOpen={() => setVehiculosSearch("")}
            >
             <Box
              sx={{
                position: 'sticky', 
                top: 0, 
                bgcolor: (theme) => theme.palette.mode === 'dark' ? '#121212' : 'white', 
                zIndex: 2, 
                padding: '8px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
              }}
            >
                <TextField
                  autoFocus
                  placeholder="Buscar vehículo..."
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={vehiculosSearch}
                  onChange={(e) => setVehiculosSearch(e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                  onKeyDown={(e) => e.stopPropagation()}                
                  onMouseDown={(e) => e.stopPropagation()}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                    onMouseDown: (e) => e.stopPropagation(),
                    onClick: (e) => e.stopPropagation(),
                  }}
                />
              </Box>
              
              <Box
  sx={{
    position: 'sticky', 
    top: '56px', 
    bgcolor: (theme) => theme.palette.mode === 'dark' ? '#121212' : 'white', 
    zIndex: 1, 
    padding: '0 8px 8px 8px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  }}
>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', my: 1 }}>
                  <Button 
                    size="small" 
                    {...buttonStyles.selectButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSelectAll(getFilteredVehiculos(), setSelectedVehiculos, e);
                    }}
                  >
                    Seleccionar todos
                  </Button>
                  <Button 
                    size="small"
                    {...buttonStyles.deselectButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeselectAll(setSelectedVehiculos);
                    }}
                  >
                    Deseleccionar
                  </Button>
                </Box>
                <Divider />
                </Box>
              
              {getFilteredVehiculos().map((vehiculo) => (
                <MenuItem
                  key={vehiculo.id}
                  value={vehiculo.id}
                  sx={{ height: "30px" }}
                >
                  <Checkbox
                    size="small"
                    checked={selectedVehiculos.some(v => v.id === vehiculo.id)}
                  />
                  <ListItemText primary={vehiculo.alias}
                   primaryTypographyProps={{
                    variant: "body2"  // Usar una variante de tipografía definida en el tema
                  }} 
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Geocercas Select */}
        <Grid size={{ xs: 12, sm: 6, md: 2 }}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel id="select-geocercas-label">Geocercas</InputLabel>
            <Select
              labelId="select-geocercas-label"
              id="select-geocercas"
              name="geocercas"
              multiple
              value={selectedGeocercas.map(g => g.id)}
              onChange={handleChange}
              input={<OutlinedInput label="Geocercas" />}
              renderValue={(selected) => 
                selected.length > 0 ? `${selected.length} seleccionados` : ''}
              MenuProps={MenuProps}
              onOpen={() => setGeocercasSearch("")}
            >
              <Box
  sx={{
    position: 'sticky', 
    top: 0, 
    bgcolor: (theme) => theme.palette.mode === 'dark' ? '#121212' : 'white', 
    zIndex: 2, 
    padding: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
  }}
>
                <TextField
                  autoFocus
                  placeholder="Buscar geocerca..."
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={geocercasSearch}
                  onChange={(e) => setGeocercasSearch(e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                  onKeyDown={(e) => e.stopPropagation()}                
                  onMouseDown={(e) => e.stopPropagation()}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                    onMouseDown: (e) => e.stopPropagation(),
                    onClick: (e) => e.stopPropagation(),
                  }}
                />
             </Box>
              
             <Box
  sx={{
    position: 'sticky', 
    top: '56px', 
    bgcolor: (theme) => theme.palette.mode === 'dark' ? '#121212' : 'white', 
    zIndex: 1, 
    padding: '0 8px 8px 8px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  }}
>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', my: 1 }}>
                  <Button 
                    size="small" 
                    {...buttonStyles.selectButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSelectAll(getFilteredGeocercas(), setSelectedGeocercas, e);
                    }}
                  >
                    Seleccionar todos
                  </Button>
                  <Button 
                    size="small" 
                    {...buttonStyles.deselectButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeselectAll(setSelectedGeocercas);
                    }}
                  >
                    Deseleccionar
                  </Button>
                </Box>
                <Divider />
                </Box>
              
              {getFilteredGeocercas().map((geocerca) => (
                <MenuItem
                  key={geocerca.id}
                  value={geocerca.id}
                  sx={{ height: "30px" }}
                >
                  <Checkbox
                    size="small"
                    checked={selectedGeocercas.some(g => g.id === geocerca.id)}
                  />
                  <ListItemText primary={geocerca.nombre}  primaryTypographyProps={{
      variant: "body2"  // Usar una variante de tipografía definida en el tema
    }} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Tipos de Alerta Select */}
        <Grid size={{ xs: 12, sm: 6, md: 2 }}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel id="select-eventos-label">Tipos de Alerta</InputLabel>
            <Select
              labelId="select-eventos-label"
              id="select-eventos"
              name="eventos"
              multiple
              value={selectedEventos.map(e => e.id)}
              onChange={handleChange}
              input={<OutlinedInput label="Tipos de Alerta" />}
              renderValue={(selected) => 
                selected.length > 0 ? `${selected.length} seleccionados` : ''}
              MenuProps={MenuProps}
              onOpen={() => setEventosSearch("")}
            >
              <Box
  sx={{
    position: 'sticky', 
    top: 0, 
    bgcolor: (theme) => theme.palette.mode === 'dark' ? '#121212' : 'white', 
    zIndex: 2, 
    padding: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
  }}
>
                <TextField
                  autoFocus
                  placeholder="Buscar alerta..."
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={eventosSearch}
                  onChange={(e) => setEventosSearch(e.target.value)}
                  onKeyDown={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                  onMouseDown={(e) => e.stopPropagation()}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                    onMouseDown: (e) => e.stopPropagation(),
                    onClick: (e) => e.stopPropagation(),
                  }}
                />
         </Box>
              
         <Box
  sx={{
    position: 'sticky', 
    top: '56px', 
    bgcolor: (theme) => theme.palette.mode === 'dark' ? '#121212' : 'white', 
    zIndex: 1, 
    padding: '0 8px 8px 8px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  }}
>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', my: 1 }}>
                  <Button 
                    size="small" 
                    {...buttonStyles.selectButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSelectAll(getFilteredEventos(), setSelectedEventos, e);
                    }}
                  >
                    Seleccionar todos
                  </Button>
                  <Button 
                    size="small" 
                    {...buttonStyles.deselectButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeselectAll(setSelectedEventos);
                    }}
                  >
                    Deseleccionar
                  </Button>
                </Box>
                <Divider />
                </Box>
              
              {getFilteredEventos().map((evento) => (
                <MenuItem
                  key={evento.id}
                  value={evento.id}
                  sx={{ height: "30px" }}
                >
                  <Checkbox
                    size="small"
                    checked={selectedEventos.some(e => e.id === evento.id)}
                  />
                  <ListItemText primary={evento.nombre}  primaryTypographyProps={{
      variant: "body2"  // Usar una variante de tipografía definida en el tema
    }} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Nivel de Riesgo Select */}
        <Grid size={{ xs: 12, sm: 6, md: 2 }}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel id="select-riesgos-label">Nivel de Riesgo</InputLabel>
            <Select
              labelId="select-riesgos-label"
              id="select-riesgos"
              name="riesgos"
              multiple
              value={selectedRiesgos.map(r => r.id)}
              onChange={handleChange}
              input={<OutlinedInput label="Nivel de Riesgo" />}
              renderValue={(selected) => 
                selected.length > 0 ? `${selected.length} seleccionados` : ''}
              MenuProps={MenuProps}
              onOpen={() => setRiesgosSearch("")}
            >
              <Box
  sx={{
    position: 'sticky', 
    top: 0, 
    bgcolor: (theme) => theme.palette.mode === 'dark' ? '#121212' : 'white', 
    zIndex: 2, 
    padding: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
  }}
>
                <TextField
                  autoFocus
                  placeholder="Buscar nivel..."
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={riesgosSearch}
                  onChange={(e) => setRiesgosSearch(e.target.value)}
                  onKeyDown={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                  onMouseDown={(e) => e.stopPropagation()}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                    onMouseDown: (e) => e.stopPropagation(),
                    onClick: (e) => e.stopPropagation(),
                  }}
                />
           </Box>
              
              <Box
  sx={{
    position: 'sticky', 
    top: '56px', 
    bgcolor: (theme) => theme.palette.mode === 'dark' ? '#121212' : 'white', 
    zIndex: 1, 
    padding: '0 8px 8px 8px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  }}
>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', my: 1 }}>
                  <Button 
                    size="small" 
                    {...buttonStyles.selectButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSelectAll(getFilteredRiesgos(), setSelectedRiesgos, e);
                    }}
                  >
                    Seleccionar todos
                  </Button>
                  <Button 
                    size="small" 
                    {...buttonStyles.deselectButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeselectAll(setSelectedRiesgos);
                    }}
                  >
                    Deseleccionar
                  </Button>
                  </Box>
                <Divider />
                </Box>
              
              {getFilteredRiesgos().map((riesgo) => (
                <MenuItem
                  key={riesgo.id}
                  value={riesgo.id}
                  sx={{ height: "30px" }}
                >
                  <Checkbox
                    size="small"
                    checked={selectedRiesgos.some(r => r.id === riesgo.id)}
                  />
                  <ListItemText primary={riesgo.nombre}  primaryTypographyProps={{
      variant: "body2"  // Usar una variante de tipografía definida en el tema
    }} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  );
});

export default FilterBar;

// // FilterBar.js
// import React, { useState, useEffect, useCallback } from "react";
// import {
//   TextField,
//   IconButton,
//   Paper,
//   Button,
//   Autocomplete,
//   Typography,
//   Box,
//   InputLabel,
//   Select,
//   OutlinedInput,
//   ListItemText,
//   FormControl,
//   MenuItem,
// } from "@mui/material";
// import Grid from "@mui/material/Grid2";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
// import { FilterList, Clear } from "@mui/icons-material";
// import {
//   vehiculoService,
//   eventoService,
//   clasificacionService,
//   ClasificacionEventoService,
//   geocercaService,
// } from "../../services/apiService";
// import { motion } from "framer-motion";
// import { Clear as ClearIcon } from "@mui/icons-material";
// import { Tooltip } from "@mui/material";
// import { Checkbox } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };
// const FilterBar = ({ onFilter }) => {
//   const [vehiculos, setVehiculos] = useState([]);
//   // const [clasificaciones, setClasificaciones] = useState([]);
//   const [geocercas, setGeocercas] = useState([]);
//   const [eventos, setEventos] = useState([]);
//   const [clasificacionEventos, setClasificacionEventos] = useState([]);
//   const [eventosFiltrados, setEventosFiltrados] = useState([]);
//   const [selectedVehiculos, setSelectedVehiculos] = useState([]);
//   const [selectedGeocercas, setSelectedGeocercas] = useState([]);
//   const [selectedEventos, setSelectedEventos] = useState([]);

//   const [selectedRiesgos, setSelectedRiesgos] = useState([]);

//   // Lista de riesgos disponibles
//   const riesgos = [
//     { id: 'Alto', nombre: 'Alto' },
//     { id: 'Medio', nombre: 'Medio' },
//     { id: 'Bajo', nombre: 'Bajo' },
//   ];

//   const initialHoraInicial = new Date();
//   initialHoraInicial.setHours(0, 0, 0, 0);

//   const initialHoraFinal = new Date();
//   initialHoraFinal.setHours(23, 59, 59, 999);

//   const [horaInicial, setHoraInicial] = useState(initialHoraInicial);
//   const [horaFinal, setHoraFinal] = useState(initialHoraFinal);

//   const resetFilters = () => {
//     setSelectedVehiculos([]);
//     setSelectedEventos([]);
//     setSelectedRiesgos([]);
//     // setSelectedClasificacion({});
//     setSelectedGeocercas([]);
//     setEventosFiltrados([]);
//     setHoraInicial(initialHoraInicial);
//     setHoraFinal(initialHoraFinal);
//   };

//   const CustomListbox = React.forwardRef((props, ref) => {
//     const { children, ownerState, ...other } = props; // Excluir ownerState
//     return (
//       <ul
//         {...other}
//         ref={ref}
//         style={{
//           listStyle: "none", // Eliminar viñetas
//           padding: 0, // Ajustar padding
//           margin: 0, // Ajustar margin
//           maxHeight: 200, // Mantener altura máxima
//           overflow: "auto", // Habilitar scroll
//         }}
//       >
//         {children}
//       </ul>
//     );
//   });

//   const handleSelectAllVehiculos = (checked) => {
//     setSelectedVehiculos(checked ? vehiculos : []);
//   };

//   const handleSelectAllEventos = (checked) => {
//     setSelectedEventos(checked ? eventos : []);
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const [vehiculosResult, eventosResult, geocercasResult] =
//           await Promise.all([
//             vehiculoService.getVehiculos(),
//             eventoService.getEventos(),
//             // clasificacionService.getClasificaciones("", 1),
//             geocercaService.getGeocercas("", "", "", "1"),
//           ]);
//         setVehiculos(vehiculosResult.data || []);
//         setEventos(eventosResult.data || []);
//         // setClasificaciones(clasificacionesResult.data || []);
//         setGeocercas(geocercasResult.data || []);
//       } catch (error) {
//         console.error("Error al cargar datos:", error);
//       }
//     };

//     fetchData();
//   }, []);

//   const filtroClasificacionEventos = async () => {
//     // if (selectedClasificacion?.id) {
//     //   const id = selectedClasificacion.id;
//     //   const response = await ClasificacionEventoService.FiltrarAsignaciones(id);
//     //   if (response.statusCode === 200) {
//     //     // Extraer y aplanar los eventos del array data
//     //     const filtroEventos = response.data.flatMap((item) => item.eventos);
//     //     // Actualizar el estado con los eventos
//     //     setClasificacionEventos(filtroEventos);
//     //   }
//     // }
//   };

//   useEffect(() => {
//     //ClasificacionEventoService.FiltrarAsignaciones(selectedClasificacion.id);
//     filtroClasificacionEventos();
//     onFilter({
//       horaInicial,
//       horaFinal,
//       vehiculos: selectedVehiculos.map((v) => v.id),
//       tiposAlerta: selectedEventos.map((e) => e.id),
//       // clasificaciones: selectedClasificacion?.id || null,
//       geocercas: selectedGeocercas.map((g) => g.id),
//       riesgos: selectedRiesgos.map((r) => r.id),
//     });
//   }, [
//     horaInicial,
//     horaFinal,
//     selectedVehiculos,
//     selectedEventos,
//     // selectedClasificacion,
//     selectedGeocercas,
//     selectedRiesgos,
//     onFilter,
//   ]);

//   useEffect(() => {
//     if (clasificacionEventos.length > 0) {
//       const filtrados = eventos.filter((evento) =>
//         clasificacionEventos.includes(evento.id)
//       );
//       setEventosFiltrados(filtrados);
//     } else {
//       setEventosFiltrados([]);
//     }
//   }, [clasificacionEventos, eventos]);

//   const handleHoraInicialChange = useCallback((newValue) => {
//     setHoraInicial(newValue);
//   }, []);

//   const handleHoraFinalChange = useCallback((newValue) => {
//     setHoraFinal(newValue);
//   }, []);

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     if (name === "vehiculos") {
//       // Convertir los IDs seleccionados a objetos completos
//       const selectedObjects = value.map(id => 
//         vehiculos.find(vehiculo => vehiculo.id === id)
//       ).filter(Boolean);
//       setSelectedVehiculos(selectedObjects);
//     }
//     if (name === "geocercas") {

//       const selectedObjects = value.map(id => 
//         geocercas.find(geocercas => geocercas.id === id)
//       ).filter(Boolean);
//       setSelectedGeocercas(selectedObjects);

//       // console.log(value);
//       // setSelectedGeocercas(value);
//     }
//     if (name === "eventos") {
//       const selectedObjects = value.map(id => 
//         eventos.find(eventos => eventos.id === id)
//       ).filter(Boolean);
//       setSelectedEventos(selectedObjects);

//       //setSelectedEventos(value);
//     }

//     if (name === "riesgos") {
//       const selectedObjects = value.map(id => 
//         riesgos.find(riesgo => riesgo.id === id)
//       ).filter(Boolean);
//       setSelectedRiesgos(selectedObjects);
//     }

//   };

//   // const handleGeocercaSelection = (event) => {
//   //   const { value } = event.target;
//   //   const selected = typeof value === "string" ? value.split(",") : value;
//   //   setSelectedGeocercas(selected);
//   //   onFilter((prev) => ({
//   //     ...prev,
//   //     geocercas: selected,
//   //   }));
//   // };

//   // const handleGeocercaSelection = (event) => {
//   //   const { value } = event.target;
//   //   if (value.includes("all")) {
//   //     setSelectedGeocercas(geocercas.map((g) => g.id));
//   //   } else {
//   //     setSelectedGeocercas(value);
//   //   }
//   // };

//   // const handleGeocercaSelection = (event) => {
//   //   const { value } = event.target;
//   //   // Si se selecciona "all", asignar todas las geocercas
//   //   if (value.includes("all")) {
//   //     setSelectedGeocercas(geocercas.map((g) => g.id));
//   //   } else {
//   //     setSelectedGeocercas(value); // Solo las escogidas
//   //   }
//   //   onFilter((prev) => ({ ...prev, geocercas: value }));
//   // };

//   const handleGeocercaSelection = (event) => {
//     const { value } = event.target;
//     const selected = typeof value === "string" ? value.split(",") : value;
//     console.log("Geocercas seleccionadas (raw):", value);
//     console.log("Geocercas seleccionadas (processed):", selected);

//     if (Array.isArray(selected)) {
//       setSelectedGeocercas(selected);
//       // Enviar el objeto completo en lugar de una función
//       onFilter({
//         horaInicial,
//         horaFinal,
//         vehiculos: selectedVehiculos,
//         tiposAlerta: selectedEventos,
//         geocercas: selected,
//       });
//     }
//   };


//   // const handleChange = (event) => {
//   //   const { name, value } = event.target;
//   //   if (name === "vehiculos") {
//   //     // Convertir los IDs seleccionados a objetos completos
//   //     const selectedObjects = value.map(id => 
//   //       vehiculos.find(vehiculo => vehiculo.id === id)
//   //     ).filter(Boolean); // filter(Boolean) elimina los undefined
//   //     setSelectedVehiculos(selectedObjects);
//   //   }
//   //   
//   // };

//   return (
//     <Paper
//       // elevation={3}
//       // sx={{ p: 2, mb: 4 }}
//       elevation={0} // Change from 3 to 0 to remove shadow
//       sx={{
//         p: 2,
//         mb: 0, // Change from 4 to 0 to remove bottom margin
//         // Optional: add custom border if needed
//         borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
//       }}
//       component={motion.div}
//       initial={{ y: -20, opacity: 0 }}
//       animate={{ y: 0, opacity: 1 }}
//     >
//       <Grid
//         container
//         spacing={2}
//         alignItems="flex-end"
//         columns={{ xs: 12, sm: 12, md: 12 }}
//       >
//         <Grid size={{ xs: 12, sm: 6, md: 2 }}>
//           <DesktopTimePicker
//             label="Hora Inicial"
//             value={horaInicial}
//             onChange={handleHoraInicialChange}
//             views={["hours", "minutes"]}
//             ampm={false}
//             minutesStep={1}
//             secondsStep={1}
//             disablePortal
//             inputFormat="HH:mm"
//             mask="__:__"
//             slotProps={{
//               textField: {
//                 variant: "outlined",
//                 size: "small",
//                 sx: { width: "100%" },
//                 inputProps: { autoComplete: "off" },
//               },
//             }}
//             // renderInput={(params) => (
//             //   <TextField
//             //     {...params}
//             //     fullWidth
//             //     variant="standard"
//             //     inputProps={{
//             //       ...params.inputProps,
//             //       autoComplete: 'off',
//             //     }}
//             //   />
//             // )}
//           />
//         </Grid>
//         <Grid size={{ xs: 12, sm: 6, md: 2 }}>
//           <DesktopTimePicker
//             label="Hora Final"
//             value={horaFinal}
//             onChange={handleHoraFinalChange}
//             views={["hours", "minutes"]}
//             ampm={false}
//             minutesStep={1}
//             secondsStep={1}
//             disablePortal
//             inputFormat="HH:mm"
//             mask="__:__"
//             slotProps={{
//               textField: {
//                 variant: "outlined",
//                 size: "small",
//                 sx: { width: "100%" },
//                 inputProps: { autoComplete: "off" },
//               },
//             }}
//             // renderInput={(params) => (
//             //   <TextField
//             //     {...params}
//             //     fullWidth
//             //     variant="standard"
//             //     inputProps={{
//             //       ...params.inputProps,
//             //       autoComplete: 'off',
//             //     }}
//             //   />
//             // )}
//           />
//         </Grid>

//         <Grid size={{ xs: 12, sm: 6, md: 2 }}>

//         <FormControl variant="outlined" size="small" fullWidth>
//           <InputLabel id="select-vehiculos-label">Vehículos</InputLabel>
//           <Select
//             labelId="select-vehiculos-label"
//             id="select-vehiculos"
//             name="vehiculos"
//             multiple
//             value={selectedVehiculos.map(v => v.id)} // Mapear solo los IDs para el value
//             onChange={handleChange}
//             input={<OutlinedInput label="Vehículos" />}
//             renderValue={(selected) => 
//               selected.length > 0 ? `${selected.length} seleccionados` : ''
//             }
//             MenuProps={{
//               ...MenuProps,
//               PaperProps: {
//                 ...MenuProps.PaperProps,
//                 style: {
//                   ...MenuProps.PaperProps.style,
//                   maxHeight: 300
//                 }
//               }
//             }}
//           >
//             {vehiculos.map((vehiculo) => (
//               <MenuItem
//                 key={vehiculo.id}
//                 value={vehiculo.id}
//                 sx={{
//                   height: "30px",
//                 }}
//               >
//                 <Box
//                   sx={{
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "flex-start",
//                   }}
//                 >
//                   <Checkbox
//                     checked={selectedVehiculos.some(v => v.id === vehiculo.id)}
//                     sx={{ mr: 1, "& .MuiSvgIcon-root": { fontSize: 15 } }}
//                   />
//                   <ListItemText primary={vehiculo.alias} />
//                 </Box>
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>         
          
//         </Grid>
//         {/* <Grid size={{ xs: 12, sm: 6, md: 2 }}>
//           <Autocomplete
//             sx={{ width: "100%" }}
//             id="clasificaciones-autocomplete"
//             options={clasificaciones}
//             getOptionLabel={(option) => option.nombre || ""}
//             value={selectedClasificacion}
//             onChange={(e, newValue) => setSelectedClasificacion(newValue)}
//             renderOption={(props, option, { selected }) => (
//               <li {...props} key={option.id}>
//                 <Checkbox size="small" checked={selected} sx={{ mr: 1 }} />
//                 {option.nombre}
//               </li>
//             )}
//             renderInput={(params) => (
//               <TextField
//                 {...params}
//                 variant="outlined"
//                 size="small"
//                 label="Clasificaciones"
//                 placeholder="Clasificación"
//                 sx={{ width: "100%" }}
//               />
//             )}
//             slots={{
//               listbox: CustomListbox,
//             }}
//           />
//         </Grid> */}

//         <Grid size={{ xs: 12, sm: 6, md: 2 }}>
//         <FormControl variant="outlined" size="small" fullWidth>
         
//           <InputLabel id="select-geocercas-label">Geocercas</InputLabel>

//           <Select

// // labelId="select-eventos-label"
// // id="select-eventos"
// // name="eventos"
// // multiple
// //value={selectedEventos}
// // value={selectedEventos.map(v => v.id)}
// // onChange={handleChange}
// input={<OutlinedInput label="geocercas-nombre" />}


//           labelId="select-geocercas-label"
//           id="select-geocercas"
//           name="geocercas"
//             multiple
//            value={selectedGeocercas.map(v => v.id)}
//            onChange={handleChange}
//             //value={selectedGeocercas}
//             //onChange={handleGeocercaSelection}
//             renderValue={(selected) =>
//               //selected
//                 // .map(
//                 //   (id) => eventos.find((evento) => evento.id === id)?.nombre
//                 // )
//                 // .join(", ")
//                 selected.length > 0 ? `${selected.length} seleccionados` : ''
//             }

//             // renderValue={(selected) => {
//             //   //selected.length > 0 ? `${selected.length} seleccionados` : ''
//             //   const displayValues = selected.filter((id) => id !== "all");
//             //   const selectedNames = geocercas
//             //     .filter((g) => displayValues.includes(g.id))
//             //     .map((g) => g.nombre)
//             //     .join(", ");
//             //   return selectedNames.length > 50
//             //     ? selectedNames.substring(0, 50) + "..."
//             //     : selectedNames;
//             // }}
//             MenuProps={{
//               ...MenuProps,
//               PaperProps: {
//                 ...MenuProps.PaperProps,
//                 style: {
//                   ...MenuProps.PaperProps.style,
//                   maxHeight: 300
//                 }
//               }
//             }}
//           >
//             {geocercas.map((geocerca) => (
//               <MenuItem key={geocerca.id} value={geocerca.id} sx={{
//                 height: "30px",
//               }}>                        

//                 <Box
//                     sx={{
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "flex-start",
//                     }}
//                   >
//                     <Checkbox
//                       //checked={selectedEventos.includes(evento.id)}
//                       checked={selectedGeocercas.some(v => v.id === geocerca.id)}
//                       sx={{ mr: 1, "& .MuiSvgIcon-root": { fontSize: 15 } }}
//                     />
//                     <ListItemText primary={geocerca.nombre} />
//                   </Box>

//                 {/* <Box
//                 sx={{
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "flex-start",
//                 }}
//               >
//                 <Checkbox
//                   checked={selectedGeocercas.indexOf(geocerca.id) > -1}
//                   sx={{ mr: 1, "& .MuiSvgIcon-root": { fontSize: 15 } }}
//                 />
//                    <ListItemText primary={geocerca.nombre} />
//               </Box> */}


//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
          
//         </Grid>       

//         <Grid size={{ xs: 12, sm: 6, md: 2 }}>
//           <FormControl variant="outlined" size="small" fullWidth>
//             <InputLabel id="select-eventos-label">Tipos de Alerta</InputLabel>
//             <Select
//               labelId="select-eventos-label"
//               id="select-eventos"
//               name="eventos"
//               multiple
//               //value={selectedEventos}
//               value={selectedEventos.map(v => v.id)}
//               onChange={handleChange}
//               input={<OutlinedInput label="eventos-nombre" />}
//               renderValue={(selected) =>
//                 //selected
//                   // .map(
//                   //   (id) => eventos.find((evento) => evento.id === id)?.nombre
//                   // )
//                   // .join(", ")
//                   selected.length > 0 ? `${selected.length} seleccionados` : ''
//               }
//               MenuProps={{
//                 ...MenuProps,
//                 PaperProps: {
//                   ...MenuProps.PaperProps,
//                   style: {
//                     ...MenuProps.PaperProps.style,
//                     maxHeight: 300
//                   }
//                 }
//               }}
//             >
//               {eventos.map((evento) => (
//                 <MenuItem
//                   key={evento.id}
//                   value={evento.id}
//                   sx={{
//                     height: "30px",
//                   }}
//                 >
//                   <Box
//                     sx={{
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "flex-start",
//                     }}
//                   >
//                     <Checkbox
//                       //checked={selectedEventos.includes(evento.id)}
//                       checked={selectedEventos.some(v => v.id === evento.id)}
//                       sx={{ mr: 1, "& .MuiSvgIcon-root": { fontSize: 15 } }}
//                     />
//                     <ListItemText primary={evento.nombre} />
//                   </Box>
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//           {/* <Autocomplete
//             sx={{ width: "100%" }}
//             multiple
//             disableCloseOnSelect
//             disableListWrap
//             id="eventos-autocomplete"
//             options={eventosFiltrados.length > 0 ? eventosFiltrados : eventos}
//             getOptionLabel={(option) => option.nombre}
//             value={selectedEventos}
//             onChange={(event, newValue) => setSelectedEventos(newValue)}
//             renderTags={(value, getTagProps) =>
//               value.length > 0 ? (
//                 <Typography
//                   key={getTagProps({ index: 0 }).key} // Asignar 'key' directamente
//                   variant="body2"
//                 >
//                   {`${value.length} seleccionados`}
//                 </Typography>
//               ) : null
//             }
//             renderOption={(props, option, { selected }) => (
//               <li
//                 {...props}
//                 key={option.id}
//                 style={{ display: "flex", alignItems: "center" }}
//               >
//                 <Checkbox
//                   checked={selected}
//                   sx={{ mr: 1, "& .MuiSvgIcon-root": { fontSize: 15 } }}
//                 />
//                 {option.nombre}
//               </li>
//             )}
//             renderInput={(params) => (
//               <TextField
//                 {...params}
//                 variant="outlined"
//                 size="small"
//                 label="Tipos de Alerta"
//                 placeholder={selectedEventos.length === 0 ? "Todos" : ""}
//               />
//             )}
//             slots={{
//               listbox: CustomListbox, // Reemplaza ListboxComponent
//             }}
//             slotProps={{
//               listbox: {
//                 style: { maxHeight: 200, overflow: "auto" }, // Reemplaza ListboxProps
//               },
//             }}
//           /> */}
//         </Grid>

//         {/* Filtro de Riesgos */}
//         <Grid size={{ xs: 12, sm: 6, md: 2 }}>
//           <FormControl variant="outlined" size="small" fullWidth>
//             <InputLabel id="select-riesgos-label">Nivel de Riesgo</InputLabel>
//             <Select
//               labelId="select-riesgos-label"
//               id="select-riesgos"
//               name="riesgos"
//               multiple
//               value={selectedRiesgos.map(r => r.id)}
//               onChange={handleChange}
//               input={<OutlinedInput label="Nivel de Riesgo" />}
//               renderValue={(selected) =>
//                 selected.length > 0 ? `${selected.length} seleccionados` : ''
//               }
//               MenuProps={{
//                 ...MenuProps,
//                 PaperProps: {
//                   ...MenuProps.PaperProps,
//                   style: {
//                     ...MenuProps.PaperProps.style,
//                     maxHeight: 300
//                   }
//                 }
//               }}
//             >
//               {riesgos.map((riesgo) => (
//                 <MenuItem
//                   key={riesgo.id}
//                   value={riesgo.id}
//                   sx={{
//                     height: "30px",
//                   }}
//                 >
//                   <Box
//                     sx={{
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "flex-start",
//                     }}
//                   >
//                     <Checkbox
//                       checked={selectedRiesgos.some(r => r.id === riesgo.id)}
//                       sx={{ mr: 1, "& .MuiSvgIcon-root": { fontSize: 15 } }}
//                     />
//                     <ListItemText primary={riesgo.nombre} />
//                   </Box>
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         </Grid>

//          <Grid
//           size={{ xs: 12, sm: 6, md: 2 }}
//           sx={{ display: "flex", justifyContent: "center" }}
//         >
//           <Tooltip title="Limpiar filtros">
//             <IconButton
//               onClick={resetFilters}
//               color="primary"
//               size="medium"
//               sx={{
//                 border: "1px solid rgba(0, 0, 0, 0.23)",
//                 borderRadius: "4px",
//                 "&:hover": {
//                   backgroundColor: "rgba(25, 118, 210, 0.04)",
//                 },
//               }}
//             >
//               <DeleteIcon />
//             </IconButton>
//           </Tooltip>
//         </Grid> 
//       </Grid>
//     </Paper>
//   );
// };

// export default FilterBar;
