import React from "react";
import { Box, Button } from "@mui/material";

// Iconos
import SaveIcon from "@mui/icons-material/Save";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function NextButton({
  handleGuardarPrincipal,
  isEditMode,
  nextComponent,
  index,
  idAlerta,
  handleClose,
  ModalName
}) {
  const handleSave = async () => {
    const flag = await handleGuardarPrincipal(ModalName);
    if (flag && !isEditMode) {
      nextComponent(index);
    }
    if(flag && isEditMode) handleClose()
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        pr: 4,
      }}
    >
      <Button
        variant="contained"
        endIcon={!isEditMode ? <NavigateNextIcon /> : <SaveIcon />}
        color="primary"
        type="button"
        onClick={handleSave}
        sx={{
          height: "25px",
        }}
      >
        {!isEditMode ? "Siguiente" : "Guardar"}
      </Button>
    </Box>
  );
}
