import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, DrawingManager } from '@react-google-maps/api';

const GoogleMapComponent = ({
  center,
  zoom,
  userLocation,
  geocercas,
  onCreated,
  onEdited,
  onDeleted,
  onMoved,
  drawnItemsRef,
  mapRef,
  renderGeocercas,
  handleTypeChange,
  currentOverlay,
  setCurrentOverlay,
  drawingManager,
  setDrawingManager,
  setOverlayMap,
  isFirstLoad,
  setIsFirstLoad,
  onLoad
}) => {
  const markersRef = useRef([]);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const GOOGLE_MAP_ID = 'geocercas-omisiones';
  const formattedCenter = Array.isArray(center) 
    ? { lat: parseFloat(center[0]), lng: parseFloat(center[1]) }
    : center;

  useEffect(() => {
    if (!isMapLoaded ) return;

    try {
      markersRef.current.forEach(marker => marker.setMap(null));
      markersRef.current = [];

      if (userLocation) {
        createUserMarker(userLocation, mapRef.current);
      }

      if (geocercas?.length > 0) {
        geocercas.forEach(geocerca => {
          if (geocerca.puntos.type === 'Point') {
            const position = {
              lat: Number(geocerca.puntos.puntos[0].latitud),
              lng: Number(geocerca.puntos.puntos[0].longitud)
            };

            const circle = new window.google.maps.Circle({
              strokeColor: '#4285F4',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: '#4285F4',
              fillOpacity: 0.2,
              map: mapRef.current,
              center: position,
              radius: geocerca.radio
            });

            const label = new window.google.maps.marker.AdvancedMarkerElement({
              position: position,
              map: mapRef.current,
              content: document.createElement('div'),
            });

            label.content.innerHTML = `
              <div class="label-container">
                <div class="label-content">
                  <span class="label-icon">📍</span>
                  <span class="label-text">${geocerca.nombre}</span>
                </div>
              </div>
            `;

            setOverlayMap(prev => ({
              ...prev,
              [geocerca.id]: circle,
            }));
            markersRef.current.push(circle);
            markersRef.current.push(label);

          } else if (geocerca.puntos.type === 'Polygon') {
            const polygonCoords = geocerca.puntos.puntos.map(pt => ({
              lat: Number(pt.latitud),
              lng: Number(pt.longitud),
            }));

            const polygon = new window.google.maps.Polygon({
              paths: polygonCoords,
              fillColor: '#4285F4',
              fillOpacity: 0.2,
              strokeColor: '#4285F4',
              strokeOpacity: 1,
              strokeWeight: 2,
              map: mapRef.current
            });

            const bounds = new window.google.maps.LatLngBounds();
            polygonCoords.forEach(coord => bounds.extend(coord));
            const center = bounds.getCenter();

            const label = new window.google.maps.marker.AdvancedMarkerElement({
              position: center,
              map: mapRef.current,
              content: document.createElement('div'),
            });

            label.content.innerHTML = `
              <div class="label-container">
                <div class="label-content">
                  <span class="label-icon">📍</span>
                  <span class="label-text">${geocerca.nombre}</span>
                </div>
              </div>
            `;

            setOverlayMap(prev => ({
              ...prev,
              [geocerca.id]: polygon,
            }));
            markersRef.current.push(polygon);
            markersRef.current.push(label);
          }
        });
      }
    } catch (error) {
      console.error('Error renderizando geocercas:', error);
    }
  }, [isMapLoaded, geocercas]);

  useEffect(() => {
    if (drawingManager) {
      window.google.maps.event.addListener(drawingManager, 'drawingmode_changed', () => {
        if (currentOverlay) {
          currentOverlay.setMap(null);
          setCurrentOverlay(null);
        }

        markersRef.current.forEach(marker => {
          if (marker.editable) {
            marker.setMap(null);
          }
        });
      });

      window.google.maps.event.addListener(drawingManager, 'overlaycomplete', (e) => {
        const overlay = e.overlay;
        const type = e.type;

        if (currentOverlay) {
          currentOverlay.setMap(null);
          setCurrentOverlay(null);
        }

        markersRef.current.forEach(marker => {
          if (marker.editable) {
            marker.setMap(null);
          }
        });

        setCurrentOverlay(overlay);

        if (type === window.google.maps.drawing.OverlayType.CIRCLE) {
          const center = overlay.getCenter();
          const radius = overlay.getRadius();
          onCreated({
            layerType: 'circle',
            layer: overlay,
          });

          setOverlayMap(prev => ({
            ...prev,
            [geocercas.length + 1]: overlay,
          }));
        } else if (type === window.google.maps.drawing.OverlayType.POLYGON) {
          const path = overlay.getPath();
          onCreated({
            layerType: 'polygon',
            layer: overlay,
          });

          setOverlayMap(prev => ({
            ...prev,
            [geocercas.length + 1]: overlay,
          }));
        }

        drawingManager.setDrawingMode(null);
      });
    }
  }, [drawingManager, currentOverlay, geocercas.length, setOverlayMap, onCreated]);

  const initializeMap = (map) => {
    mapRef.current = map;
    setIsMapLoaded(true);
    onLoad?.(); 
      


    const drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          window.google.maps.drawing.OverlayType.CIRCLE,
          window.google.maps.drawing.OverlayType.POLYGON,
        ],
      },
      circleOptions: {
        fillColor: '#1E90FF',
        fillOpacity: 0.2,
        strokeColor: '#1E90FF',
        editable: true,
        draggable: true,
      },
      polygonOptions: {
        fillColor: '#1E90FF',
        fillOpacity: 0.2,
        strokeColor: '#1E90FF',
        editable: true,
        draggable: true,
      },
    });

    drawingManager.setMap(map);
    setDrawingManager(drawingManager);

    window.google.maps.event.addListener(drawingManager, 'drawingmode_changed', () => {
      if (currentOverlay) {
        currentOverlay.setMap(null);
        setCurrentOverlay(null);
      }

      if (drawingManager.getDrawingMode() !== null) {
        markersRef.current.forEach(marker => {
          if (marker.editable) {
            marker.setMap(null);
          }
        });
      }
    });

    window.google.maps.event.addListener(drawingManager, 'overlaycomplete', (e) => {
      const overlay = e.overlay;
      const type = e.type;

      if (currentOverlay) {
        currentOverlay.setMap(null);
        setCurrentOverlay(null);
      }

      markersRef.current.forEach(marker => {
        if (marker.editable) {
          marker.setMap(null);
        }
      });

      setCurrentOverlay(overlay);

      if (type === window.google.maps.drawing.OverlayType.CIRCLE) {
        const center = overlay.getCenter();
        const radius = overlay.getRadius();
        onCreated({
          layerType: 'circle',
          layer: overlay,
        });

        setOverlayMap(prev => ({
          ...prev,
          [geocercas.length + 1]: overlay,
        }));
      } else if (type === window.google.maps.drawing.OverlayType.POLYGON) {
        const path = overlay.getPath();
        onCreated({
          layerType: 'polygon',
          layer: overlay,
        });

        setOverlayMap(prev => ({
          ...prev,
          [geocercas.length + 1]: overlay,
        }));
      }

      drawingManager.setDrawingMode(null);
    });

    // if (userLocation) {
    //   createUserMarker(userLocation, mapRef.current);
    // }

  };

  const createUserMarker = (location, map) => {
    try {
      markersRef.current.forEach(marker => marker.setMap(null));
      markersRef.current = [];

      const position = { lat: parseFloat(location[0]), lng: parseFloat(location[1]) };

      const homeIcon = document.createElement('div');
      homeIcon.innerHTML = `
        <div class="user-marker-container">
          <div class="pulse"></div>
          <div style="
            font-size: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
          ">
            🏠
          </div>
        </div>
      `;

      const marker = new window.google.maps.marker.AdvancedMarkerElement({
        position: position,
        map: map,
        content: homeIcon,
        title: 'Tu ubicación actual'
      });

      if (isFirstLoad) {
        map.setCenter(position);
        map.setZoom(15);
        setIsFirstLoad(false);
      }

      markersRef.current.push(marker);

      return marker;
    } catch (error) {
      console.error('Error creando marcador de usuario:', error);
      return null;
    }
  };

  const clearCurrentOverlay = () => {
    if (currentOverlay) {
      currentOverlay.setMap(null);
      setCurrentOverlay(null);
    }
  };

  const styles = `
    .custom-user-location {
      background: transparent;
    }

    .user-marker-container {
      position: relative;
      width: 40px;
      height: 40px;
    }

    .pulse {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 30px;
      height: 30px;
      background: rgba(66, 133, 244, 0.2);
      border-radius: 50%;
      transform: translate(-50%, -50%);
      animation: pulse 2s infinite;
    }

    .marker {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 1;
    }

    @keyframes pulse {
      0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
      }
      100% {
        transform: translate(-50%, -50%) scale(3);
        opacity: 0;
      }
    }
  `;

  return (
    <>
      <div>
        <GoogleMap
          center={formattedCenter}
          zoom={zoom}
          mapId={GOOGLE_MAP_ID}
          options={{
            mapId: GOOGLE_MAP_ID,
            disableDefaultUI: false,
            clickableIcons: false,
            scrollwheel: true,
            gestureHandling: 'greedy',
            zoomControl: true,
          }}
          mapContainerStyle={{ height: '400px', width: '100%' }}
          onLoad={initializeMap}
          onError={(error) => {
            console.error('Error loading Google Map:', error);
          }}
        />
        <button onClick={clearCurrentOverlay}>Cancelar Dibujo</button>
      </div>
      <style>{styles}</style>
    </>
  );
};

export default GoogleMapComponent;