import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Typography,
  Box,
  Paper,
  useTheme,
  Button,
  Stepper,
  Step,
  StepLabel,
  Alert,
  Snackbar,
  CircularProgress
} from "@mui/material";
import { motion } from "framer-motion";
import { styled } from "@mui/material/styles";
import { AuthContext } from "../contexts/AuthContext";
import { useLoading } from "../contexts/LoadingContext";
import { authService } from "../services/apiService";
import { thirdPartyService } from "../services/thirdPartyService";
//import {   encrypt } from "../utils/encryption";
import Logo from "../assets/logo_login.png";

import { decrypt,encrypt } from "../utils/encryption";

const ThirdPartyLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { login, setAutenticado } = useContext(AuthContext);
  const { setIsLoading, setLoadingMessage } = useLoading();
  
  // Estados para el proceso
  const [activeStep, setActiveStep] = useState(0);
  const [companyStatus, setCompanyStatus] = useState({});
  const [userStatus, setUserStatus] = useState({});
  const [processingError, setProcessingError] = useState(null);
  const [processStatus, setProcessStatus] = useState("");
  const [processDetails, setProcessDetails] = useState("");
  const [processComplete, setProcessComplete] = useState(false);
  
  // Parámetros de URL
  const [params, setParams] = useState({
    uid_empresa_externo: "",
    idusuario: "",
    usuario: "",
    password: ""
  });
  
  // Estado para snackbars
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info"
  });

  const handleCloseSnackbar = () => {
    setSnackbar({...snackbar, open: false});
  };

  // Configurar pasos del proceso
  const steps = [
    'Verificar empresa',
    'Sincronizar empresa',
    'Verificar usuario',
    'Sincronizar usuario',
    'Iniciar sesión'
  ];

  // Obtener parámetros de la URL al cargar el componente
  useEffect(() => {
    const parseParams = () => {
      try {
        const searchParams = new URLSearchParams(location.search);
        const uid_empresa_externo = searchParams.get("uid_empresa_externo");
        const idusuario = searchParams.get("idusuario");
        const usuario = searchParams.get("username");
        const encryptedPassword = searchParams.get("password");
        
        if (!uid_empresa_externo || !usuario || !encryptedPassword) {
          setProcessingError("Faltan parámetros requeridos en la URL");
          return false;
        }
        
        const decryptedPassword = decrypt(encryptedPassword);
        
        // Guardar los parámetros en el estado (para uso futuro)
        setParams({
          uid_empresa_externo,
          idusuario,
          usuario,
          password: decryptedPassword
        });
        
        // Pasar los parámetros directamente en lugar de depender del estado
        startAuthProcess({
          uid_empresa_externo,
          idusuario,
          usuario,
          password: decryptedPassword
        });
        
        return true;
      } catch (error) {
        console.error("Error al procesar parámetros:", error);
        setProcessingError("Error al procesar los parámetros de la URL");
        return false;
      }
    };
    
    parseParams();
  }, [location.search]);
  

  // Función para iniciar el proceso completo de autenticación
  const startAuthProcess = async (urlParams) => {
    setIsLoading(true);
    setLoadingMessage("Iniciando proceso de autenticación...");
    
    try {
      // Paso 1: Verificar empresa
      await verifyCompany(urlParams);
    } catch (error) {
      console.error("Error en el proceso de autenticación:", error);
      setProcessingError("Error en el proceso de autenticación");
      setIsLoading(false);
    }
  };

  // Paso 1: Verificar empresa
  const verifyCompany = async (urlParams) => {
    setActiveStep(0);
    setProcessStatus("Verificando existencia de empresa...");
    
    try {
      console.log("Verificando empresa con UID:", urlParams.uid_empresa_externo);
      const companyData = await thirdPartyService.checkCompany(urlParams.uid_empresa_externo);
      setCompanyStatus(companyData);
      
      if (!companyData.success) {
        setProcessingError("Error al verificar la empresa");
        setIsLoading(false);
        return;
      }
      
      if (!companyData.existsInMySQL) {
        setProcessingError("La empresa no existe en el sistema");
        setIsLoading(false);
        return;
      }
      
      // Continuar con el proceso
      if (!companyData.existsInMongo || companyData.hasInconsistencies) {
        // Necesita sincronización
        await syncCompany(urlParams);
      } else {
        // La empresa existe y está sincronizada, pasar a verificación de usuario
        setActiveStep(2);
        await verifyUser(urlParams);
      }
    } catch (error) {
      console.error("Error al verificar empresa:", error);
      setProcessingError("Error al verificar la empresa");
      setIsLoading(false);
    }
  };
  

  // Paso 2: Sincronizar empresa si es necesario
  const syncCompany = async (urlParams) => {
    setActiveStep(1);
    setProcessStatus("Sincronizando datos de empresa...");
    
    try {
      const syncResult = await thirdPartyService.syncCompany(urlParams.uid_empresa_externo);
       
      if (!syncResult.success || !syncResult.existsInMongo) {
        setProcessingError("Error al sincronizar la empresa");
        setIsLoading(false);
        return;
      }
      
      setProcessDetails("Empresa sincronizada correctamente");
      
      // Continuar con verificación de usuario
      setActiveStep(2);
      await verifyUser(urlParams);
    } catch (error) {
      console.error("Error al sincronizar empresa:", error);
      setProcessingError("Error al sincronizar la empresa");
      setIsLoading(false);
    }
  };

  // Paso 3: Verificar usuario
  const verifyUser = async (urlParams) => {
    setActiveStep(2);
    setProcessStatus("Verificando datos de usuario...");
    
    try {
        const userData = await thirdPartyService.verifyUser(urlParams.usuario, urlParams.password);
        setUserStatus(userData);
      
      if (!userData.success) {
        setProcessingError("Error al verificar el usuario");
        setIsLoading(false);
        return;
      }
      
      if (!userData.existsInMySQL || !userData.authenticated) {
        setProcessingError("Credenciales de usuario inválidas");
        setIsLoading(false);
        return;
      }
      
      // Continuar con el proceso
      if (!userData.existsInMongo || userData.hasInconsistencies) {
        // Necesita sincronización
        await syncUser(urlParams);
      } else {
        // El usuario existe y está sincronizado, pasar a inicio de sesión
        setActiveStep(4);
        await performLogin(urlParams);
      }
    } catch (error) {
      console.error("Error al verificar usuario:", error);
      setProcessingError("Error al verificar el usuario");
      setIsLoading(false);
    }
  };

  // Paso 4: Sincronizar usuario si es necesario
  const syncUser = async (urlParams) => {
    setActiveStep(3);
    setProcessStatus("Sincronizando datos de usuario...");
    
    try {
      const syncResult = await thirdPartyService.syncUser(urlParams.usuario, urlParams.password);
      
      if (!syncResult.success) {
        setProcessingError("Error al sincronizar el usuario");
        setIsLoading(false);
        return;
      }
      
      setProcessDetails("Usuario sincronizado correctamente");
      
      // Continuar con inicio de sesión
      setActiveStep(4);
      await performLogin(urlParams);
    } catch (error) {
      console.error("Error al sincronizar usuario:", error);
      setProcessingError("Error al sincronizar el usuario");
      setIsLoading(false);
    }
  };

  // Paso 5: Realizar inicio de sesión
  const performLogin = async (urlParams) => {
    setActiveStep(4);
    setProcessStatus("Iniciando sesión...");
    
    try {
        const credentials = {
            usuario: urlParams.usuario,
            password: urlParams.password,
          };
      
      const response = await authService.login(credentials);
      
      if (response.statusCode === 200) {
        // Login with AuthContext to set all required states
        const { 
          token, nombre, uuid_empresa, uuid_usuario, 
          nuevo_usuario, uid_externo, user, 
          grupos, razon_social, nombre_comercial, id_externo 
        } = response.data;
        
        await login(
          token,
          nombre,
          uuid_empresa,
          uuid_usuario,
          nuevo_usuario,
          encrypt(urlParams.password), // Usar el password decriptado como password_quro
          uid_externo,
          user,
          grupos,
          razon_social,
          nombre_comercial,
          id_externo
        );
        
        setAutenticado(true);
        setProcessStatus("Inicio de sesión exitoso");
        setProcessComplete(true);
        
        // Mostrar mensaje de éxito
        setSnackbar({
          open: true,
          message: "Autenticación exitosa, redirigiendo...",
          severity: "success"
        });
        
        setTimeout(() => {
          navigate("/");
        }, 1500);
      } else {
        setProcessingError("Error de autenticación: " + (response.message || "Credenciales inválidas"));
      }
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
      setProcessingError("Error al iniciar sesión");
    } finally {
      setIsLoading(false);
    }
  };

  // Estilos
  const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: theme.shape.borderRadius * 3,
    background:
      theme.palette.mode === "dark"
        ? "linear-gradient(135deg, rgba(30, 30, 30, 0.95) 0%, rgba(30, 30, 30, 0.98) 100%)"
        : "linear-gradient(135deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.98) 100%)",
    backdropFilter: "blur(20px)",
    boxShadow:
      theme.palette.mode === "dark"
        ? "0 8px 32px rgba(0, 0, 0, 0.3)"
        : "0 8px 32px rgba(234, 0, 0, 0.1)",
    position: "relative",
    overflow: "hidden",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      height: "5px",
      background: "linear-gradient(90deg, #ea0000, #000000, #ea0000)",
      backgroundSize: "200% 100%",
      animation: "gradient 15s ease infinite",
    },
  }));

  const BackgroundPattern = styled(Box)(({ theme }) => ({
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
    opacity: 0.02,
    background: `
      linear-gradient(45deg, ${
        theme.palette.mode === "dark" ? "#ffffff" : "#000000"
      } 25%, transparent 25%),
      linear-gradient(-45deg, ${
        theme.palette.mode === "dark" ? "#ffffff" : "#000000"
      } 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, ${
        theme.palette.mode === "dark" ? "#ffffff" : "#000000"
      } 75%),
      linear-gradient(-45deg, transparent 75%, ${
        theme.palette.mode === "dark" ? "#ffffff" : "#000000"
      } 75%)
    `,
    backgroundSize: "20px 20px",
    backgroundPosition: "0 0, 0 10px, 10px -10px, -10px 0px",
  }));

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: theme.palette.background.default,
      }}
    >
      <BackgroundPattern />
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        style={{ width: "100%", maxWidth: "600px", margin: "0 auto" }}
      >
        <StyledPaper>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: 4,
            }}
          >
            <Box
              component="img"
              src={Logo}
              alt="Logo de Omisiones"
              sx={{
                height: { xs: 60, sm: 80 },
                mr: 1,
                filter: "drop-shadow(2px 4px 6px rgba(0,0,0,0.1))",
              }}
            />
          </Box>

          <Typography
            variant="h5"
            align="center"
            sx={{
              color: theme.palette.text.secondary,
              fontWeight: 500,
              mb: 1,
            }}
          >
            Autenticación de Aplicación Externa
          </Typography>

          <Typography
            variant="body1"
            align="center"
            sx={{
              color: theme.palette.text.secondary,
              mb: 4,
            }}
          >
            Procesando su solicitud de inicio de sesión
          </Typography>

          <Box sx={{ width: '100%', mb: 4 }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          <Box sx={{ 
            width: '100%', 
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
            mb: 3,
            minHeight: '100px'
          }}>
            {processStatus && (
              <Typography variant="subtitle1" color="text.secondary">
                {processStatus}
              </Typography>
            )}
            
            {processDetails && (
              <Typography variant="body2" color="text.secondary">
                {processDetails}
              </Typography>
            )}
            
            {processingError ? (
              <Alert severity="error" sx={{ width: '100%' }}>
                {processingError}
              </Alert>
            ) : !processComplete && (
              <CircularProgress size={40} sx={{ my: 2 }} />
            )}
            
            {processComplete && (
              <Alert severity="success" sx={{ width: '100%' }}>
                Autenticación exitosa, redirigiendo...
              </Alert>
            )}
          </Box>

          {processingError && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/login")}
              sx={{
                mt: 2,
                bgcolor: "#ea0000",
                color: "#ffffff",
                "&:hover": {
                  bgcolor: "#000000",
                },
              }}
            >
              Ir a inicio de sesión manual
            </Button>
          )}
        </StyledPaper>
      </motion.div>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          variant="filled"
          sx={{ 
            width: '100%',
            boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
            '& .MuiAlert-icon': {
              fontSize: '1.2rem'
            }
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ThirdPartyLogin;