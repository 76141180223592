import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  IconButton,
  ListItemButton,
  Collapse,
  Avatar,
  useTheme,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  Map as MapIcon,
  Circle as CircleIcon,
} from "@mui/icons-material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import { motion, AnimatePresence } from "framer-motion";
import { wsService } from "../../services/webSocketService";
import { getAlertIcon, getTextEmoji } from "../../utils/alertUtils";
import WarningIcon from "../../assets/warning.svg";
import LocationIcon from "../../assets/location.svg";
import carmap from "../../assets/svg_images/car_.svg";

import { VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { useSound } from "../../utils/soundcontext";
import { Snackbar, Alert } from '@mui/material';


// const COLLAPSED_HEIGHT = 130; // Adjust this value
// const EXPANDED_HEIGHT = 230; // Adjust this value based on the expanded item content
const TRANSITION_DURATION = 1000; // en ms
const EASING = "cubic-bezier(0.4, 0, 0.2, 1)";


const transformWebSocketData = async (wsData) => {
  try {
    const eventDate = new Date(wsData.FechaHora_Evento);

    const lat1 = wsData?.latitud || 0;
    const lng1 = wsData?.longitud || 0;

    const lat = parseFloat(lat1);
    const lng = parseFloat(lng1);

    if (Number.isNaN(lat) || Number.isNaN(lng)) {
      throw new Error("Coordenadas inválidas");
    }

    const direccion = wsData?.Direccion || "No disponible";
    //  (lat && lng) ?
    //   await getAddressFromCoordinates(lat, lng) :
    //   'Dirección no disponible';
    // fecha: eventDate.toLocaleString('es-MX', {
    //   timeZone: 'America/Mexico_City',
    //   year: 'numeric',
    //   month: '2-digit',
    //   day: '2-digit',
    //   hour: '2-digit',
    //   minute: '2-digit',
    //   second: '2-digit',
    //   hour12: true
    // }),
    return {
      id: wsData.IdUnico,
      nombreAlerta: wsData.NombreAlerta,
      FechaHora_Evento: wsData.FechaHora_Evento,
      fecha: eventDate.toLocaleString("es-MX", {
      timeZone: "America/Mexico_City",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      }),
      UID_Vehiculo: wsData.UID_Vehiculo,
      unidad: wsData.Alias || wsData.NoEconomico,
      chofer: wsData.chofer,
      telefono: wsData.Telefono,
      alerta: wsData.Nombre_TipoAlerta,
      estado: determinarEstado(wsData.Riesgo),
      geocerca: wsData.Nombre_Geocerca,
      ubicacion: {
      lat,
      lng,
      direccion,
      },
      adicional: {
      placas: wsData.Placas,
      velocidad: wsData.velocidadOriginal,
      odometro: wsData.odometroOriginal,
      empresa: wsData.Nombre_Empresa,
      mensaje: wsData.message || wsData.Message || "Sin mensaje",
      },
      cog: wsData.cog,
      UID_TipoAlerta: wsData.UID_TipoAlerta,
      alert_type: wsData.Tipo_Alerta,
      IconoVehiculo: wsData.IconoVehiculo,
      Color: wsData.Color,
      Sonido: wsData.Sonido,
      grupoid: wsData.grupoid,
    };

    // console.log("Datos transformados:", wsData);
  } catch (error) {
    console.error("Error transformando datos:", error);
    return null;
  }
};

const determinarEstado = (riesgo) => {
  switch (riesgo) {
    case "Alto":
      return "rojo";
    case "Medio":
      return "amarillo";
    case "Bajo":
      return "verde";
    default:
      return "verde";
  }
};

const getStatusColor = (estado) => {
  switch (estado.toLowerCase()) {
    case "rojo":
      return "#ff4444";
    case "amarillo":
      return "#ff9800";
    default:
      return "#4CAF50";
  }
};

const formatPhoneNumber = (phoneNumber) => {
  // Remover cualquier caracter que no sea número
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");

  // Verificar si tiene 10 dígitos
  if (cleaned.length === 10) {
    return `(${cleaned.slice(0, 3)})${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
  }

  // Si no tiene 10 dígitos, retornar el original o 'No disponible'
  return phoneNumber || "Sin número";
};

const removeEmojis = (text) => {
  return text.replace(
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|[\uD83C-\uDBFF\uDC00-\uDFFF]|[\u0023-\u0039]\uFE0F?\u20E3|[\u3297\u3299]|[\u303D\u3030]|[\u24C2]|[\u25AA-\u25AB]|[\u25B6]|[\u25C0]|[\u25FB-\u25FE]|[\u00A9\u00AE]|[\u2122\u2139]|[\u0020-\u0039]\uFE0F|[\u2194-\u21AA]|[\u2B05-\u2B07]|[\u2934\u2935]|[\u2B1B\u2B1C]|[\u2B50]|[\u231A\u231B]|[\u23E9-\u23F3]|[\u23F8-\u23FA]|[\u25AA-\u25AB]|[\u25B6]|[\u25C0]|[\u25FB-\u25FE]|[\u00A9\u00AE]|[\u2122\u2139]|[\u0020-\u0039]\uFE0F|[\u2194-\u21AA]|[\u2B05-\u2B07]|[\u2934\u2935]|[\u2B1B\u2B1C]|[\u2B50]|[\u231A\u231B]|[\u23E9-\u23F3]|[\u23F8-\u23FA]|⚡)/g,
    ""
  );
};

const sortByTime = (alerts) => {
  return [...alerts].sort((a, b) => {
    const dateA = new Date(a.FechaHora_Evento);
    const dateB = new Date(b.FechaHora_Evento);
    return dateB - dateA;
  });
};

const AlertList = ({
  onMapIconClick,
  onNewAlert,
  onLoadComplete,
  tourAlertId,
  filters,
  onAlertsUpdate,
}) => {
  const [wsStatus, setWsStatus] = useState("disconnected");
  const [alerts, setAlerts] = useState([]);
  const [altura, setAltura] = useState(0);
  const [filteredAlerts, setFilteredAlerts] = useState([]);

  const [openAlertIds, setOpenAlertIds] = useState([]);

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userGroups, setUserGroups] = useState([]);
  const userGroupsRef = useRef([]);
  const theme = useTheme();

  /*const [itemHeights, setItemHeights] = useState({});
  const itemHeightsRef = useRef({});*/

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const collapsedHeight = isSmallScreen ? 110 : 130;
  const expandedHeight = isSmallScreen ? 190 : 230;
  const listRef = useRef(null);
  const containerRef = useRef(null);
  const prevAlertsLengthRef = useRef(filteredAlerts.length);
  const { playSound } = useSound();


  useEffect(() => {
    console.log("userGroups state updated:", userGroups);
  }, [userGroups]);


  useEffect(() => {
    try {
      const storedGroups = localStorage.getItem("groups");
      if (storedGroups) {
        const groups = JSON.parse(storedGroups);
        const groupIds = groups.map(group => group.grupo_id || group.GrupoUid);
        setUserGroups(groupIds);
        userGroupsRef.current = groupIds;  // Store in ref too
        console.log("User's assigned groups:", groupIds);
      }
    } catch (error) {
      console.error("Error loading user groups:", error);
      setUserGroups([]);
      userGroupsRef.current = [];  // Reset ref on error
    }
  }, []);

  const handleNewAlert = useCallback(
    (rawData) => {
      const { type, data } = rawData;
      console.log("Procesando mensaje tipo:", type);
      
      if (type === "history") {
        const processHistoricalAlerts = async () => {
          //console.log("Procesando histórico inicial...");
          const transformedAlerts = await Promise.all(
            data.map((alert) => transformWebSocketData(alert))
          );
      
          const validAlerts = transformedAlerts.filter(
            (alert) => alert !== null
          );
          
          // Apply user group filtering to historical alerts
          const filteredByGroup = userGroupsRef.current.length === 0 
          ? validAlerts 
          : validAlerts.filter(alert => userGroupsRef.current.includes(alert.grupoid));
           
          const sortedAlerts = sortByTime(filteredByGroup);
      
          console.log("Alertas históricas procesadas:", sortedAlerts.length);
          if (userGroupsRef.length > 0) {
            console.log(`Filtradas por grupos de usuario: ${userGroupsRef.join(', ')}`);
          }
      
          setAlerts(sortedAlerts);
          setFilteredAlerts(sortedAlerts);
      
          if (listRef.current) {
            setTimeout(() => {
              if(listRef.current){
                listRef.current.resetAfterIndex(0);
              }
            }, 0);
          }
      
          sortedAlerts
            .slice()
            .reverse()
            .forEach((alert) => onNewAlert(alert));
        };
      
        processHistoricalAlerts();
        return;
      }

      // if (type === "history") {
      //   const processHistoricalAlerts = async () => {
      //     //console.log("Procesando histórico inicial...");
      //     const transformedAlerts = await Promise.all(
      //       data.map((alert) => transformWebSocketData(alert))
      //     );

      //     const validAlerts = transformedAlerts.filter(
      //       (alert) => alert !== null
      //     );
      //     const sortedAlerts = sortByTime(validAlerts);

      //     //console.log("Alertas históricas procesadas:", sortedAlerts.length);

      //     setAlerts(sortedAlerts);
      //     setFilteredAlerts(sortedAlerts);

      //     if (listRef.current) {
      //       setTimeout(() => {
      //         if(listRef.current){
      //           listRef.current.resetAfterIndex(0);
      //         }
      //       }, 0);
      //     }

      //     //sortedAlerts.forEach(alert => onNewAlert(alert));
      //     sortedAlerts
      //       .slice()
      //       .reverse()
      //       .forEach((alert) => onNewAlert(alert));
      //   };

      //   processHistoricalAlerts();
      //   return;
      // }

      const processAlert = async (alert) => {
        const transformedAlert = await transformWebSocketData(alert);
        if (transformedAlert) {

          const belongsToUserGroups = 
          // If userGroups is empty, show all alerts (fallback for backward compatibility)
          userGroupsRef.current.length === 0 || 
          // Otherwise, only show alerts from user's groups
          userGroupsRef.current.includes(transformedAlert.grupoid);


          if (belongsToUserGroups) {
            // Existing code for processing the alert...
            if (transformedAlert.Sonido) {
              // Validar que el valor de Sonido sea una cadena no vacía y con una extensión de audio válida
              const validAudioExtensions = ['mp3', 'wav', 'ogg', 'aac', 'm4a', 'flac'];
              const soundValue = transformedAlert.Sonido.toString().trim();
              
              if (soundValue && soundValue !== "") {
                // Verificar si tiene una extensión de archivo reconocida
                const hasValidExtension = validAudioExtensions.some(ext => 
                  soundValue.toLowerCase().endsWith('.' + ext)
                );
                
                if (hasValidExtension) {
                  playSound(soundValue);
                } else if (soundValue.startsWith('http') || soundValue.startsWith('/')) {
                  // Si es una URL o ruta absoluta, intentar reproducirla de todos modos
                  playSound(soundValue);
                } else {
                  // Si es solo un identificador o nombre sin extensión, asumimos mp3
                  playSound(`${soundValue}.mp3`);
                }
              }
            }
      
            setAlerts((prev) => {
              const newAlerts = [transformedAlert, ...prev];
              return newAlerts;
            });
      
            setFilteredAlerts((prevFiltered) => {
              const newFiltered = [transformedAlert, ...prevFiltered];
              return sortByTime(newFiltered);
            });
      
            if (listRef.current) {
              setTimeout(() => {
                listRef.current.resetAfterIndex(0, false);
              }, 0);
            }
      
            onNewAlert(transformedAlert);
          } else {
            console.log(`Alert from group ${transformedAlert.grupoid} filtered out - not in user's assigned groups`);
          }


          


          // setAlerts((prev) => {
          //   const newAlerts = [transformedAlert, ...prev];
          //   return newAlerts;
          // });

          // setFilteredAlerts((prevFiltered) => {
          //   const newFiltered = [transformedAlert, ...prevFiltered];
          //   return sortByTime(newFiltered);
          // });

          // if (listRef.current) {
          //   setTimeout(() => {
          //     listRef.current.resetAfterIndex(0, false);
          //   }, 0);
          // }

          // onNewAlert(transformedAlert);
        }
      };

      if (type === "update") {
        if (Array.isArray(data)) {
          data.forEach(processAlert);
        } else if (data) {
          processAlert(data);
        }
      }
    },
    [onNewAlert,playSound]
  );

  useEffect(() => {
    setAltura(window.screen.height);
    let isSubscribed = true;

    const handleStatus = (status) => {
      if (!isSubscribed) return;

      if (status === "connected") {
        onLoadComplete?.();
      }
      //console.log("WebSocket status:", status);
      setWsStatus(status);
    };

    wsService.onStatusChange = handleStatus;
    wsService.connect(handleNewAlert);

    return () => {
      isSubscribed = false;
      wsService.disconnect();
    };
  }, []);

  useEffect(() => {
    const applyFilters = () => {
      const { horaInicial, horaFinal, vehiculos, tiposAlerta, geocercas,riesgos } =
        filters;

      const toSeconds = (time) => {
        if (!time) return null;
        return (
          time.getHours() * 3600 + time.getMinutes() * 60 + time.getSeconds()
        );
      };

      const inicialSeconds = horaInicial ? toSeconds(horaInicial) : null;
      const finalSeconds = horaFinal ? toSeconds(horaFinal) : null;

      const filtered = sortByTime(
        alerts.filter((alert) => {
          //alerts.filter((alert) => {
          const [alertH, alertM, alertS] = alert.fecha.split(":").map(Number);
          const alertTotalSeconds = alertH * 3600 + alertM * 60 + alertS;

          const vehiculoMatch =
            vehiculos?.length > 0
              ? vehiculos.includes(alert.UID_Vehiculo)
              : true;

          const alertaMatch =
            tiposAlerta?.length > 0
              ? tiposAlerta.includes(alert.UID_TipoAlerta)
              : true;


                   
        // Nuevo filtro de riesgos
        const riesgoMatch =
        riesgos?.length > 0
          ? riesgos.includes(alert.estado === "rojo" ? "Alto" : 
                            alert.estado === "amarillo" ? "Medio" : "Bajo")
          : true;


          let horaMatch = true;
          if (inicialSeconds !== null && finalSeconds !== null) {
            horaMatch =
              alertTotalSeconds >= inicialSeconds &&
              alertTotalSeconds <= finalSeconds;
          }

          return vehiculoMatch && alertaMatch && horaMatch && riesgoMatch;
        })
      );

      setFilteredAlerts(filtered);
    };

    applyFilters();
  }, [alerts, filters]);

  useEffect(() => {
    if (onAlertsUpdate && alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        console.log("AlertList enviando TODAS las alertas:", alerts.length);
        // Enviar una copia profunda para evitar problemas de referencia
        const alertsCopy = JSON.parse(JSON.stringify(alerts));
        onAlertsUpdate(alertsCopy);
      }, 200); // Mayor tiempo para asegurar que todas las alertas están procesadas
      
      return () => clearTimeout(timeoutId);
    }
  }, [alerts, onAlertsUpdate]);

  const filteredAlertsMemo = useMemo(() => filteredAlerts, [filteredAlerts]);

  // const handleCollapseClick = useCallback((id) => {
  //   setOpenAlertIds(prevOpenAlertIds => {
  //     const isOpen = prevOpenAlertIds.includes(id);
  //     const newOpenAlertIds = isOpen
  //       ? prevOpenAlertIds.filter(openId => openId !== id)
  //       : [...prevOpenAlertIds, id];

  //     const index = filteredAlerts.findIndex(alert => alert.id === id);

  //     if (listRef.current) {
  //       const currentOffset = listRef.current.state.scrollOffset;

  //       // Usar setTimeout para dar tiempo a la animación
  //       setTimeout(() => {
  //         listRef.current.resetAfterIndex(index, false);
  //         listRef.current.scrollTo(currentOffset);
  //       }, 50);
  //     }

  //     return newOpenAlertIds;
  //   });
  // }, [filteredAlerts]);

  const handleCollapseClick = useCallback(
    (id) => {
      setOpenAlertIds((prevOpenAlertIds) => {
        const newOpenAlertIds = prevOpenAlertIds.includes(id)
          ? prevOpenAlertIds.filter((openId) => openId !== id)
          : [...prevOpenAlertIds, id];

        const index = filteredAlerts.findIndex((alert) => alert.id === id);
        if (listRef.current) {
          listRef.current.resetAfterIndex(index);
        }

        return newOpenAlertIds;
      });
    },
    [filteredAlerts]
  );
  const MARGIN_BOTTOM = 16;

  // const getItemSize = useCallback(
  //   (index) => {
  //     const alert = filteredAlerts[index];
  //     const isOpen = openAlertIds.includes(alert.id);
  //     //return isOpen ? EXPANDED_HEIGHT : COLLAPSED_HEIGHT;
  //     return (isOpen ? EXPANDED_HEIGHT : COLLAPSED_HEIGHT); //+ MARGIN_BOTTOM;

  //   },
  //   [filteredAlerts, openAlertIds]
  // );

  const itemHeightsRef = useRef({}); // Guarda la altura de cada ítem

  const getItemSize = useCallback((index) => {
    // const alert = filteredAlerts[index];
    // const isOpen = openAlertIds.includes(alert.id);
    return itemHeightsRef.current[index] + 10 || 80; // Alto predeterminado
  }, []);

  // const getItemSize = useCallback(
  //   (index) => {
  //     const alert = filteredAlerts[index];
  //     const isOpen = openAlertIds.includes(alert.id);
  //     return isOpen ? expandedHeight : collapsedHeight;
  //   },
  //   [filteredAlerts, openAlertIds, collapsedHeight, expandedHeight]
  // );

  useEffect(() => {
    if (
      listRef.current &&
      prevAlertsLengthRef.current !== filteredAlerts.length
    ) {
      const scrollOffset = listRef.current.state.scrollOffset;
      listRef.current.resetAfterIndex(0, false);
      listRef.current.scrollTo(scrollOffset);
      prevAlertsLengthRef.current = filteredAlerts.length;
    }
  }, [filteredAlerts]);

  // listener para el cambio de tamaño de ventana y resetear la lista
  useEffect(() => {
    const handleResize = () => {
      if (listRef.current) {
        listRef.current.resetAfterIndex(0, true);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      setAltura(containerRef.current.clientHeight);
    }
    const handleResize = () => {
      if (containerRef.current) {
        setAltura(containerRef.current.clientHeight);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const updateItemSize = useCallback((index, size) => {
    itemHeightsRef.current[index] = size;
    if (listRef.current) {
      listRef.current.resetAfterIndex(index, true);
    }
  }, []);

  const RenderRow = React.memo(({ index, style, data }) => {
    const rowRef = useRef(null);

    const alert = data[index];
    const isOpen = openAlertIds.includes(alert.id);
    const borderColor = getStatusColor(alert.estado);
    const isInTour = alert.id === tourAlertId;

    React.useEffect(() => {
      //console.log(alert);
      if (rowRef.current) {
        const measured = rowRef.current.offsetHeight;
        if (measured !== itemHeightsRef.current[index]) {
          updateItemSize(index, measured);
        }
      }
    });

    return (
      // <div style={{ ...style, height: isOpen ? EXPANDED_HEIGHT : COLLAPSED_HEIGHT }}>
      <div
        style={{
          ...style,
          //height: isOpen ? EXPANDED_HEIGHT : COLLAPSED_HEIGHT,
          height: isOpen ? expandedHeight : collapsedHeight,
          transition: `height ${TRANSITION_DURATION}ms ${EASING}`,
          marginBottom: 16,
          bgcolor: "background.paper",
        }}
      >
        <div ref={rowRef}>
          <motion.div
            layout
            key={alert.id}
            //initial={{ opacity: 0 }}
            initial={false}
            animate={{ opacity: 1 }} //, x: 0 , scale: isInTour ? 1.02 : 1}}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.2,
              layout: { duration: 0.2 },
            }}
            // transition={{
            //   duration: 0.5,
            //   delay: index * 0.1,
            //   type: "spring",
            //   stiffness: 100
            // }}
            style={{
              width: "98%", // cambia el ancho del Card.
              bgcolor: "background.paper",
            }}
          >
            <ListItemButton
              // button
              //component={motion.div}
              //disablePadding
              onClick={() => handleCollapseClick(alert.id)}
              elevation={isInTour ? 6 : 3}
              sx={{
                display: "flex",
                flexDirection: "column",
                // border: `2px solid  ${borderColor}`,
                border: `1px solid  gray`,
                borderRadius: "8px",
                transition: "all 0.3s ease",
                px: 0,
                py: 0,
                ...(isInTour && {
                  boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
                  transform: "scale(1.02)",
                }),
                bgcolor: "background.paper",
              }}
              component={motion.div}
              // whileHover={{ scale: isInTour ? 1.02 : 1.01 }}
            >
              
              {/**Fila inicial de arriba, contiene la hora, el nombre del vehículo y el icono de ubicacion*/}
<Box
  sx={{
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: `${borderColor}`,
    borderTopLeftRadius: "7px",
    borderTopRightRadius: "7px",
    px: 1,
  }}
>
  <Typography variant="caption" sx={{ color: "white" }}>{alert.fecha}</Typography>
  
  <Box sx={{ 
    display: "flex", 
    alignItems: "center", 
    justifyContent: "flex-end",
    overflow: "hidden" 
  }}>
    {/* Nombre del vehículo */}
    <Typography 
      variant="caption" 
      sx={{ 
        color: "white",
        marginRight: 1,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        maxWidth: "150px" // Ajusta según necesites
      }}
    >
      {alert.unidad}
    </Typography>
    
    {/* <IconButton
      onClick={(e) => {
        e.stopPropagation();
        onMapIconClick(alert.ubicacion, alert);
      }}
      size="small"
    >
      <Tooltip
        title="Ver ubicación en el mapa"
        arrow
        placement="top"
      >
        <LocationOnIcon
          sx={{
            color: "#FFFFFF",
            cursor: "pointer",
          }}
          fontSize="small"
        />
      </Tooltip>
    </IconButton> */}

<IconButton
  onClick={(e) => {
    e.stopPropagation();
    
    // Validar coordenadas
    const ubicacion = alert.ubicacion;
    if (!ubicacion || 
        !ubicacion.lat || 
        !ubicacion.lng || 
        isNaN(parseFloat(ubicacion.lat)) || 
        isNaN(parseFloat(ubicacion.lng))) {
      
      setErrorMessage(`No se pueden mostrar coordenadas para ${alert.unidad}: ubicación no disponible`);
      setErrorOpen(true);
      return;
    }
    
    // Si las coordenadas son válidas, proceder con la acción normal
    //onMapIconClick(alert.ubicacion, alert);
      const clearRouteEvent = new CustomEvent('clearMapRoute', { 
        detail: { alertId: alert.id }
      });
      window.dispatchEvent(clearRouteEvent);
      
      // Pequeña pausa para asegurar que los eventos se procesen
      setTimeout(() => {
        // Si las coordenadas son válidas, proceder con la acción normal
        onMapIconClick(alert.ubicacion, alert);
      }, 50);
  }}
  size="small"
>
  <Tooltip
    title="Ver ubicación en el mapa"
    arrow
    placement="top"
  >
    <LocationOnIcon
      sx={{
        color: "#FFFFFF",
        cursor: "pointer",
      }}
      fontSize="small"
    />
  </Tooltip>
</IconButton>


  </Box>
</Box>

                  <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    px: 1,
                    pt: 1,
                  }}
                  >
                  {/* Contenedor principal de información */}
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: 0.5,
                    position: "relative",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      left: "3px",
                      top: 0,
                      height: "100%",
                      width: "2px",
                    },
                  }}
                >
                  {/* Tipo Alerta */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      width: "100%",
                    }}
                  >
                    {/* Icono de alerta */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 0, // Quitar padding interno
                        margin: 0, // Quitar margin
                        marginRight: "5px",
                      }}
                    >
                      <img
                        src={getAlertIcon(alert.alert_type)}
                        alt={alert.alerta}
                        style={{ width: 15, height: 15 }}
                      />
                    </Box>
                    <Typography variant="caption">{alert.alerta}</Typography>
                  </Box>

                  {/* Nombre Alerta */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{ padding: 0, margin: 0, marginLeft: "20px" }}
                    >
                      {alert.nombreAlerta}
                    </Typography>
                  </Box>

                  {/* Vehiculo */}
                  {/* <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      borderBottom: `1px solid gray`,
                      pb: 1,
                    }}
                  >
                    <DirectionsCarIcon
                      sx={{ height: "15px", width: "15px", marginRight: "5px" }}
                    />
                    <Typography variant="caption">{alert.unidad}</Typography>
                  </Box> */}
                  {/* Contenedor de chofer, numero de chofer e icono flechita */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      borderBottom: `${isOpen ? "1px solid gray" : ""}`,
                    }}
                  >
                    {[
                      { value: alert.chofer || "Sin chofer" },
                      {
                        value: formatPhoneNumber(alert.telefono),
                      },
                    ].map((item) => (
                      <Box
                        key={item.label}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          width: "100%",
                        }}
                      >
                        <Typography
                          variant="caption"
                          // color="textSecondary"
                        >
                          {item.label}
                        </Typography>
                        <Typography variant="caption">{item.value}</Typography>
                      </Box>
                    ))}
                    <Tooltip
                      title={isOpen ? "Ocultar detalles" : "Mostrar detalles"}
                      arrow
                      placement="top"
                    >
                      <IconButton size="small">
                        {isOpen ? (
                          <ExpandLess fontSize="small" />
                        ) : (
                          <ExpandMore fontSize="small" />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
                  </Box>
              {/* Desplegable */}
              <Collapse
                in={isOpen}
                timeout={TRANSITION_DURATION}
                unmountOnExit
                sx={{
                  width: "100%",
                  transition: `all ${TRANSITION_DURATION}ms ${EASING}`,
                  // border: `2px solid  ${borderColor}`,
                  // borderRadius: "8px",
                  px: 1,
                  py: 1,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    marginBottom: 0,
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      height: "10px",
                      // background:
                      //   "linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%)",
                    },
                  }}
                >
                  {/* Primer bloque - mensaje */}
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      mb: 1,
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      alignSelf="stretch"
                      marginRight={"5px"}
                    >
                      <img
                        src={WarningIcon}
                        alt="Warning"
                        style={{
                          width: "15px",
                          height: "15px",
                        }}
                      />
                    </Box>
                    <Typography variant="caption">
                      {removeEmojis(alert?.adicional?.mensaje || "Sin mensaje")}
                    </Typography>
                  </Box>

                  {/* Segundo bloque - dirección */}
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      alignSelf="stretch"
                      marginRight={"5px"}
                    >
                      <img
                        src={LocationIcon}
                        alt="Location"
                        style={{
                          width: "15px",
                          height: "15px",
                        }}
                      />
                    </Box>
                    <Typography variant="caption">
                      {alert?.ubicacion.direccion || "No disponible"}
                    </Typography>
                  </Box>
                </Box>
              </Collapse>
            </ListItemButton>
          </motion.div>
        </div>
      </div>
    );
  });

  
  

  return (
    <Box
      ref={containerRef}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        pt: 1.5,
        pl: 2, // left padding
        pr: 2, // right padding
        pb: 2, // bottom padding
        bgcolor: "background.paper",
      }}
    >
      <VariableSizeList
        ref={listRef}
        height={altura}
        width="100%"
        itemCount={filteredAlertsMemo.length}
        itemSize={getItemSize}
        itemData={filteredAlertsMemo}
        overscanCount={3}
        itemKey={(index) => filteredAlertsMemo[index].id}
        style={{
          overflowX: "hidden",
          willChange: "transform",
        }}
      >
        {RenderRow}
      </VariableSizeList>

      <Snackbar 
  open={errorOpen} 
  autoHideDuration={5000} 
  onClose={() => setErrorOpen(false)}
  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
>
  <Alert 
    onClose={() => setErrorOpen(false)} 
    severity="warning" 
    variant="filled"
    sx={{ 
      width: '100%',
      '& .MuiAlert-message': { 
        display: 'flex', 
        alignItems: 'center'
      } 
    }}
  >
    <LocationOnIcon sx={{ mr: 1, color: "inherit" }} />
    {errorMessage}
  </Alert>
</Snackbar>


    </Box>
  );
};
export default AlertList;
