// utils/markerUtils.js
import { getAlertIcon } from './alertUtils';

import vehicleIcon from '../assets/svg_images/svg_vehiculo.svg';

const getVehicleSVG = (vehicleType) => {
  switch (vehicleType?.toLowerCase()) {
    case 'grua':
      return `<path id="obj_objeto" stroke="black" fill="currentColor" style="display:inline" d="m30.04128,18.51c-0.01,-0.86 -0.03,-2.22 -2.63,-2.22c-1.06,0 -3.75,0 -6.76,0l0,-0.47l6.91,0l0,-2.09l-18.04,0l0,2.09l7.01,0l0,0.47c-3.02,0 -5.75,0 -6.9,0c-2.81,0 -2.61,1.36 -2.61,2.22c0,9.44 0,20.9 0,22.88c0,3.08 2.61,5.65 5.63,5.65s8.55,0 11.73,0s5.66,-2.51 5.66,-5.7c0,-2.05 0.06,-13.37 0,-22.83zm-8.21,23.84l-6.57,0c-1.97,0 -3.57,-1.6 -3.57,-3.58l0,-17.84l4.85,0l0,6.34l0,0.94l0,5.83c0,0.97 0.67,1.78 1.56,2l0,1.96l-4.27,0c-0.26,0 -0.47,0.21 -0.47,0.47s0.21,0.47 0.47,0.47l9.34,0c0.26,0 0.47,-0.21 0.47,-0.47c0,-0.26 -0.21,-0.47 -0.47,-0.47l-4.08,0l0,-1.96c0.9,-0.22 1.56,-1.04 1.56,-2l0,-5.83l0,-0.94l0,-6.34l4.75,0l0,17.84c0,1.98 -1.6,3.58 -3.57,3.58zm-12.19,-29.15c2.92,0 14.86,0 17.78,0s2.63,-1.45 2.63,-2.33c0,-2.22 0,-3.63 0,-4.24c0,-3.14 -2.5,-6.64 -5.58,-6.64s-11.78,0.01 -11.78,0.01c-3.1,0 -5.66,3.53 -5.66,6.61c0,0.6 0,2.2 0,4.34c0.01,0.87 -0.31,2.25 2.61,2.25zm7.05,-3.61l-4.17,0c-0.37,0 -0.67,-0.3 -0.67,-0.67l0,-0.64c0,-0.37 0.3,-0.67 0.67,-0.67l4.18,0l0,1.98l-0.01,0zm3.1,-0.02l-2.5,0l0,-1.99l2.5,0l0,1.99zm5.45,-0.65c0,0.37 -0.3,0.67 -0.67,0.67l-4.18,0l0,-1.98l4.18,0c0.37,0 0.67,0.3 0.67,0.67l0,0.64zm1.07,-3.56l0.89,-2.61c0.77,0.77 1.62,2.24 1.62,3.87c0,1.4 -0.02,3.02 -0.03,4.08c-0.01,0.64 -0.53,1.15 -1.16,1.15l-1.31,0l0,-6.49l-0.01,0zm-13.84,-4.15l4.8,0l2.55,0l4.8,0c1.72,0 1.62,0.94 1.62,0.94l-0.94,2.72l-5.48,0l-2.55,0l-5.48,0l-0.94,-2.72c0,0 -0.1,-0.94 1.62,-0.94zm-2.63,1.54l0.89,2.61l0,6.48l-1.31,0c-0.64,0 -1.15,-0.51 -1.16,-1.15c-0.01,-1.06 -0.03,-2.67 -0.03,-4.08c0,-1.62 0.84,-3.09 1.61,-3.86z"/>`;

    case 'moto':
      return `<path id="obj_objeto" stroke="black" fill="currentColor" d="m24.99524,22.7l0,-2.7l4.5,0c0,-1.1 -0.9,-2 -2,-2l-2.5,0l0,0l-0.2,0c0.6,0 1,-0.5 0.9,-1.1l-0.9,-8.2c-0.2,-2 -1.5,-3.6 -3.3,-4.3l0,-2.5c0,-1.1 -0.9,-1.9 -1.9,-1.9c-1.1,0 -1.9,0.9 -1.9,1.9l0,2.5c-1.8,0.7 -3.1,2.3 -3.3,4.3l-0.9,8.2c0,0.6 0.4,1.1 1,1.1l-0.2,0l-2.5,0c-1.1,0 -2,0.9 -2,2l4.6,0l0,2.7l0,0c0,0 -0.4,1.3 -0.4,3.2c-0.2,11.7 2.4,21.1 5.6,21.1c3.2,0 5.8,-9.5 5.8,-21.1c0,-1.6 -0.4,-3.2 -0.4,-3.2l0,0zm-9.3,-6.8c0,0 -0.5,0.3 -0.9,0c-0.7,-0.5 0.9,-2.8 0.9,-2.8l0,2.8zm3.9,27.9c-1.6,0 -3,-4.7 -3,-10.4s1.3,-10.4 3,-10.4c1.6,0 3,4.7 3,10.4s-1.3,10.4 -3,10.4zm3,-32l-5.9,0l0,-3.8c0,-1.6 1.3,-3 3,-3s3,1.3 3,3l0,3.8l-0.1,0zm1.9,4.1c-0.4,0.3 -0.9,0 -0.9,0l0,-2.8c0,0 1.6,2.3 0.9,2.8z"/>`;

    case 'pipa':
      return `<path id="obj_objeto" stroke="black" fill="currentColor" d="m9.44353,15.31c2.92,0 14.86,0 17.78,0s2.63,-1.45 2.63,-2.33c0,-2.22 0,-5.74 0,-6.34c0,-3.14 -2.5,-6.64 -5.58,-6.64s-11.78,0 -11.78,0c-3.1,0 -5.66,3.53 -5.66,6.61c0,0.6 0,4.3 0,6.45c0,0.86 -0.31,2.25 2.61,2.25zm16.67,-8.71l0.89,-3.85c0.77,0.77 1.62,2.24 1.62,3.87c0,1.4 -0.02,5.13 -0.03,6.19c-0.01,0.64 -0.53,1.15 -1.16,1.15l-1.31,0l0,-7.36l-0.01,0zm-13.84,-5.39l4.8,0l2.55,0l4.8,0c1.72,0 1.62,0.94 1.62,0.94l-0.94,3.94l-13.51,0l-0.94,-3.94c0,0 -0.1,-0.94 1.62,-0.94zm-2.63,1.54l0.89,3.85l0,7.35l-1.31,0c-0.64,0 -1.15,-0.51 -1.16,-1.15c-0.02,-1.06 -0.03,-4.78 -0.03,-6.19c0,-1.62 0.84,-3.09 1.61,-3.86zm14.69,13.46l-11.95,0c-3.06,0 -5.53,2.48 -5.53,5.53l0,16.83c0,3.06 2.48,5.54 5.54,5.53l11.95,-0.03c3.05,-0.01 5.52,-2.48 5.52,-5.53l0,-16.8c0,-3.06 -2.47,-5.53 -5.53,-5.53zm-5.98,25.11c-3.07,0 -5.56,-2.49 -5.56,-5.56s2.49,-5.56 5.56,-5.56s5.56,2.49 5.56,5.56c0,3.07 -2.49,5.56 -5.56,5.56zm-11.53,3.94l23.04,0l0,1.77l-23.04,0l0,-1.77z"/>`;

    case 'truck':
      return `<path id="obj_objeto" stroke="black" fill="currentColor" d="m26.69998,17.9l-2.6,0l0,-0.8c0.7,0 1.3,0 1.7,0c1.8,0 1.8,-1.6 1.8,-2.4c0,-0.4 0,-11.3 0,-11.3c0,-1.2 -2.8,-2.2 -2.8,-2.2s-0.1,-1.2 -2.8,-1.2c-3.1,0 -3.3,0.6 -4,1.2c-1.6,0.5 -2.7,1.9 -2.7,2.5c0,0.3 0,10.4 0,11.3c0,1.7 1.1,2 1.8,2c0.2,0 0.9,0 1.7,0l0,0.8l-2.5,0c-0.9,0 -1.6,0.8 -1.6,1.6l0,25.9c0,0.9 0.7,1.6 1.6,1.6l10.5,0c0.9,0 1.6,-0.8 1.6,-1.6l0,-25.8c-0.1,-0.9 -0.8,-1.6 -1.7,-1.6zm0,-2.2l-0.9,-0.4l0,-1.9l0.8,0.1l0.1,2.2zm-0.1,-2.6l-0.8,0l0,0l0,-2l0.8,-2.1l0,4.1zm-9.4,2.1l-0.8,0.3l0,-2l0.8,-0.1l0,1.8zm0,-2.5l-0.7,0l0,-4.1l0.8,2.1l0,2l-0.1,0zm0.2,-2.7l-1.1,-2.6c0,0 2.3,-0.7 5.4,-0.7c3.1,0 4.9,0.8 4.9,0.8l-1.1,2.5c0,0 -1.2,-0.4 -4.1,-0.4s-4,0.4 -4,0.4z"/>`;

    case 'van':
      return `<path id="obj_objeto" stroke="black" fill="currentColor" d="m11.74999,47.1l17,0c1.7,0 3,-1.3 3,-3l0,-25l-0.8,0l0,-13.4c0,-3.1 -2.5,-5.7 -5.7,-5.7l-10.1,0c-3.1,0 -5.7,2.5 -5.7,5.7l0,13.4l-0.7,0l0,25c0,1.7 1.4,3 3,3zm18.2,-41.1l-4.8,-4.8c2.6,0 4.8,2.1 4.8,4.8zm-5.2,4.7c2.6,0 4.7,2.1 4.7,4.7l0,2.2c0,0 -4.1,-1.4 -9.2,-1.4c-5.6,0 -9.3,1.4 -9.3,1.4l0,-2.2c0,-2.6 2.1,-4.7 4.7,-4.7l9.1,0zm-9.3,-9.5l-4.8,4.8c-0.1,-2.7 2.1,-4.8 4.8,-4.8z"/>`;

    default: // 'vehiculo' o cualquier otro valor
      return `<path id="obj_objeto" stroke="black" fill="currentColor" d="m24.49095,0.21322l-11.759,0c-3.117,0 -5.643,3.467 -5.643,6.584l0,34.804c0,3.116 2.526,5.644 5.643,5.644l11.759,0c3.116,0 5.644,-2.527 5.644,-5.644l0,-34.804c-0.002,-3.117 -2.528,-6.584 -5.644,-6.584zm4.655,14.188l0,11.665l-2.729,0.351l0,-4.806l2.729,-7.21zm-1.432,-3.415c-1.016,3.9 -2.219,8.51 -2.219,8.51l-13.768,0l-2.222,-8.51c0.001,0 8.884,-3.018 18.209,0zm-16.877,10.94l0,4.492l-2.73,-0.349l0,-11.354l2.73,7.211zm-2.73,16.225l0,-10.359l2.73,0.343l0,8.196l-2.73,1.82zm1.557,2.944l2.218,-3.336l13.771,0l2.219,3.336l-18.208,0zm16.753,-5.077l0,-7.872l2.729,-0.355l0,10.048l-2.729,-1.821z"/>`;
  }
};

export const createVehicleMarkerHTML = (alert) => {
  const rotation = alert.cog ? parseInt(alert.cog) : 0;
  const vehicleColor = alert.Color || "#000000";
  const vehicleSVG = getVehicleSVG(alert.IconoVehiculo);

  return `
    <div class="vehicle-marker-container" style="position: relative; text-align: center;">
      <svg width="65" height="75" viewBox="-10 -10 70 70" xmlns="http://www.w3.org/2000/svg"
      style="transform: rotate(${rotation}deg); transform-origin: center;">
        <g width="100%" height="100%">
          ${vehicleSVG.replace('currentColor', vehicleColor)}
          <circle cx="8.5218" cy="10.50861" r="8.63539" fill="white" stroke="black"/>
          <image 
            href="${getAlertIcon(alert.alert_type)}" 
            width="17" 
            height="17"
            x="0.0218" 
            y="2.00861" 
            alt="${alert.alerta}"
          />
        </g>
      </svg>
      
      <!-- Etiqueta con el nombre de la unidad -->
      <div style="
        position: absolute;
        bottom: -35px; /* Aumentado para separar más del ícono */
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(0,0,0,0.6);
        color: white;
        border-radius: 10px;
        padding: 2px 6px;
        font-size: 10px;
        font-weight: bold;
        white-space: nowrap;
        box-shadow: 0 1px 2px rgba(0,0,0,0.3);
        border: 1px solid white;
        z-index: 1; /* Asegurarse que esté por encima */
      ">
        ${alert.unidad || 'Sin ID'}
      </div>
    </div>
  `;
};

// // utils/markerUtils.js
// import { getAlertIcon } from './alertUtils';

// import vehicleIcon from '../assets/svg_images/svg_vehiculo.svg';

// export const createVehicleMarkerHTML = (alert) => {
//   const rotation = alert.cog ? parseInt(alert.cog) : 0;

//   return `
//     <div class="vehicle-marker-container" style="position: relative; text-align: center;">
//       <svg width="65" height="75" viewBox="-10 -10 70 70" xmlns="http://www.w3.org/2000/svg"
//       style="transform: rotate(${rotation}deg); transform-origin: center;">
//         <g width="100%" height="100%">
//           <path id="obj_objeto" stroke="black" fill="#000000" d="m24.49095,0.21322l-11.759,0c-3.117,0 -5.643,3.467 -5.643,6.584l0,34.804c0,3.116 2.526,5.644 5.643,5.644l11.759,0c3.116,0 5.644,-2.527 5.644,-5.644l0,-34.804c-0.002,-3.117 -2.528,-6.584 -5.644,-6.584zm4.655,14.188l0,11.665l-2.729,0.351l0,-4.806l2.729,-7.21zm-1.432,-3.415c-1.016,3.9 -2.219,8.51 -2.219,8.51l-13.768,0l-2.222,-8.51c0.001,0 8.884,-3.018 18.209,0zm-16.877,10.94l0,4.492l-2.73,-0.349l0,-11.354l2.73,7.211zm-2.73,16.225l0,-10.359l2.73,0.343l0,8.196l-2.73,1.82zm1.557,2.944l2.218,-3.336l13.771,0l2.219,3.336l-18.208,0zm16.753,-5.077l0,-7.872l2.729,-0.355l0,10.048l-2.729,-1.821z"/>
//           <circle cx="8.5218" cy="10.50861" r="8.63539" fill="white" stroke="black"/>
//           <image 
//             href="${getAlertIcon(alert.alert_type)}" 
//             width="17" 
//             height="17"
//             x="0.0218" 
//             y="2.00861" 
//             alt="${alert.alerta}"
//           />
//         </g>
//       </svg>
      
//       <!-- Etiqueta con el nombre de la unidad -->
//       <div style="
//         position: absolute;
//         bottom: -35px; /* Aumentado para separar más del ícono */
//         left: 50%;
//         transform: translateX(-50%);
//         background-color: rgba(0,0,0,0.6);
//         color: white;
//         border-radius: 10px;
//         padding: 2px 6px;
//         font-size: 10px;
//         font-weight: bold;
//         white-space: nowrap;
//         box-shadow: 0 1px 2px rgba(0,0,0,0.3);
//         border: 1px solid white;
//         z-index: 1; /* Asegurarse que esté por encima */
//       ">
//         ${alert.unidad || 'Sin ID'}
//       </div>
//     </div>
//   `;
// };
