// src/components/GroupedPanel.js
import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import {
  Paper,
  Typography,
  Box,
  Grid,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  Popover,
  InputLabel,
  Tooltip,
  Button,
} from "@mui/material";
import { BarChart as ChartIcon } from "@mui/icons-material";
import { HexColorPicker } from "react-colorful";
import { motion } from "framer-motion";
import ChartComponent from "./Chart";
import Table from "./Table";
import DetailTable from "./DetailTable";
import {
  BarChart as BarChartIcon,
  PieChart as PieChartIcon,
  DonutLarge as DonutIcon,
  TableChart as TableIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";
import { BarChart, TableChart } from "@mui/icons-material";

import { ViewModule as PanelViewIcon } from "@mui/icons-material";

const generateUniqueColors = (num) => {
  const colors = [];
  const saturation = 70; // porcentaje
  const lightness = 50; // porcentaje

  for (let i = 0; i < num; i++) {
    const hue = Math.floor((360 / num) * i);
    colors.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);
  }

  return colors;
};

// Función para obtener valores anidados
const getNestedValue = (obj, path) => {
  if (obj.hasOwnProperty(path)) {
    return obj[path];
  }
  const value = path
    .split(".")
    .reduce(
      (acc, key) => (acc && acc[key] !== undefined ? acc[key] : null),
      obj
    );

  //console.log(`Obteniendo valor para path "${path}":`, value);
  return value;
};

const GroupedPanel = ({
  groupedData,
  groupBy,
  panelSettings,
  updatePanelSettings,
  setGroupedData,
  fields,
  dataSource,
}) => {
  const { showChart, chartType } = panelSettings;
  const [localColoredData, setLocalColoredData] = useState(groupedData);
  const chartRef = useRef(null);
  const [hiddenGroups, setHiddenGroups] = useState(new Set());
  const [globalGroupBy, setGlobalGroupBy] = useState("");
  const [detailGroupBy, setDetailGroupBy] = useState("EconomicoAlias"); // useState(groupBy);
  const [tableViews, setTableViews] = useState({});
  const detailTableRef = useRef({});
  const [tableGroupings, setTableGroupings] = useState({});
  const [expandedGroups, setExpandedGroups] = useState(new Set());

  const [isGlobalChartView, setIsGlobalChartView] = useState(false);

  /*Mapeo de nombres para la tabla */
  const customHeaders = {
    EconomicoAlias: "Vehiculo",
    Fecha: "Fecha",
    Message: "Mensaje",
    "DatosAlerta.NombreTipo": "Tipo de Alerta",
    NombreChofer: "Chofer",
    Direccion: "Direccion",
    NoEconomico: "Vehiculo",
    FechaHora_Notificacion: "Fecha",
    Nombre_TipoAlerta: "Tipo de Alerta",
    chofer: "Chofer",
  };

  useEffect(() => {
    if (dataSource === "notificacionService") {
      setDetailGroupBy("EconomicoAlias");
    } else {
      setDetailGroupBy("NoEconomico");
    }
  }, [dataSource]);

  const handleSegmentClick = (field1Value) => {
    setExpandedGroups((prev) => {
      const newSet = new Set();
      if (!prev.has(field1Value)) {
        newSet.add(field1Value);
      }
      return newSet;
    });

    // Limpiar las agrupaciones de la tabla
    setTableGroupings({});
  };

  // const handleSegmentClick = (groupKey) => {
  //   setExpandedGroups(prev => {
  //     const newSet = new Set(prev);
  //     if (newSet.has(groupKey)) {
  //       newSet.delete(groupKey);
  //     } else {
  //       newSet.add(groupKey);
  //     }
  //     return newSet;
  //   });
  // };

  const originalData = useMemo(() => {
    return groupedData.flatMap((group) => group.items);
  }, [groupedData]);

  const groupData = (data, groupByField) => {
    const grouped = data.reduce((acc, item) => {
      const key = getNestedValue(item, groupByField);
      const groupKey = key !== null && key !== undefined ? key : "Otros";
      if (!acc[groupKey]) {
        acc[groupKey] = { items: [], count: 0 };
      }
      acc[groupKey].items.push(item);
      acc[groupKey].count += 1;
      return acc;
    }, {});

    // Calcular el total para los porcentajes
    const totalCount = Object.values(grouped).reduce(
      (sum, g) => sum + g.count,
      0
    );

    return Object.entries(grouped).map(([key, value]) => ({
      groupKey: key,
      items: value.items,
      count: value.count,
      percentage: (value.count / totalCount) * 100,
    }));
  };

  const recomputedGroupedData = useMemo(() => {
    if (!detailGroupBy) {
      return groupedData;
    }
    return groupData(originalData, detailGroupBy);
  }, [originalData, detailGroupBy, groupedData]);

  //   useEffect(() => {
  //   console.log('groupedData:', groupedData);
  //   console.log('originalData:', originalData);
  // }, [groupedData, originalData]);

  const handleChartTypeChange = (e) => {
    updatePanelSettings({
      ...panelSettings,
      chartType: e.target.value,
    });
  };

  const toggleShowChart = () => {
    updatePanelSettings({
      ...panelSettings,
      showChart: !showChart,
    });
  };

  const toggleGroupVisibility = (groupKey) => {
    setHiddenGroups((prev) => {
      const newHidden = new Set(prev);
      if (newHidden.has(groupKey)) {
        newHidden.delete(groupKey);
      } else {
        newHidden.add(groupKey);
      }
      return newHidden;
    });
  };

  const total = groupedData.reduce(
    (acc, curr) => {
      acc.total += curr.count;
      acc.percentage += curr.percentage;
      return acc;
    },
    { total: 0, percentage: 0 }
  );

  const totalRecords = useMemo(() => {
    return recomputedGroupedData.reduce(
      (sum, group) => sum + group.items.length,
      0
    );
  }, [recomputedGroupedData]);

  useEffect(() => {
    if (showChart) {
      //console.log('Chart is shown, generating colors for grouped data.');
      const colors = generateUniqueColors(groupedData.length);
      const newColoredData = groupedData.map((group, index) => {
        const color = group.color || colors[index];
        //console.log(`Group ${group.groupKey} assigned color: ${color}`);
        return {
          ...group,
          color,
        };
      });
      setLocalColoredData(newColoredData);
      //console.log('New colored data set:', newColoredData);
      // Remove this line to prevent overwriting parent state
      // setGroupedData(newColoredData);
    } else {
      //console.log('Chart is hidden, using original grouped data.');
      setLocalColoredData(groupedData);
    }
  }, [groupedData, showChart]);

  // Estado para el selector de colores
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const handleColorClick = (event, group) => {
    setAnchorEl(event.currentTarget);
    setSelectedGroup(group);
  };

  const handleColorChange = (newColor) => {
    if (selectedGroup) {
      const updatedData = localColoredData.map((group) =>
        group.groupKey === selectedGroup.groupKey
          ? { ...group, color: newColor }
          : group
      );

      // Actualizar solo el color en el dataset del gráfico
      if (chartRef.current) {
        const datasetIndex = 0; // Asumiendo que solo tenemos un dataset
        const itemIndex = localColoredData.findIndex(
          (item) => item.groupKey === selectedGroup.groupKey
        );

        chartRef.current.data.datasets[datasetIndex].backgroundColor[
          itemIndex
        ] = newColor;
        chartRef.current.update("none"); // Actualizar sin animación
      }
      setGroupedData(updatedData);
      setLocalColoredData(updatedData);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedGroup(null);
  };

  useEffect(() => {
    updatePanelSettings({ ...panelSettings, fromPanel: true });
  }, []);

  const visibleData = localColoredData.filter(
    (group) => !hiddenGroups.has(group.groupKey)
  );

  const GroupingSelector = () => (
    <Box sx={{ mb: 4 }}>
      <FormControl sx={{ minWidth: 200 }}>
        <InputLabel>Agrupar todos por</InputLabel>
        <Select
          value={globalGroupBy}
          onChange={(e) => setGlobalGroupBy(e.target.value)}
          label="Agrupar todos por"
        >
          <MenuItem value="">Ninguno</MenuItem>
          {fields.map((field) => (
            <MenuItem key={field} value={field}>
              {field}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );

  const handleViewChange = (groupKey, newViewType) => {
    setTableViews((prev) => ({
      ...prev,
      [groupKey]: newViewType,
    }));
  };

  const open = Boolean(anchorEl);
  const id = open ? "color-popover" : undefined;

  const handleGlobalToggle = () => {
    const newView = isGlobalChartView ? "table" : "panel";
    setIsGlobalChartView(!isGlobalChartView);
    Object.values(detailTableRef.current).forEach((detailTable) => {
      if (detailTable && detailTable.handleViewTypeChange) {
        detailTable.handleViewTypeChange(newView);
      }
    });
  };

  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "600px", //'100%',
          width: "100%",
          overflow: "hidden",
          backgroundColor: "background.paper",
        }}
      >
        <Box
          sx={{
            width: showChart ? "50%" : "100%",
            display: "flex",
            flexDirection: "column",
            height: "100%", // Take full height
            // width: showChart ? '50%' : '100%',
            // maxHeight: '100%',
            // overflowY: 'auto',
            // p: 2,
            // boxSizing: 'border-box'
          }}
        >
          <Box
            sx={{
              p: 2,
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">
                {/* {groupBy} Summary */}
                Alertas Generadas
              </Typography>
              <Box sx={{ display: "flex", gap: 1 }}>
                {process.env.REACT_APP_SUPERUSERACTIVATE === "true" && (
                  <>
                    {showChart && (
                      <FormControl size="small">
                        <Select
                          value={chartType}
                          onChange={handleChartTypeChange}
                          sx={{ minWidth: 120 }}
                        >
                          <MenuItem value="BarChart">Bar Chart</MenuItem>
                          <MenuItem value="PieChart">Pie Chart</MenuItem>
                          <MenuItem value="DoughnutChart">
                            Doughnut Chart
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}
                    <IconButton
                      onClick={toggleShowChart}
                      color={showChart ? "primary" : "default"}
                    >
                      <ChartIcon />
                    </IconButton>
                  </>
                )}

                <Tooltip
                  title={
                    isGlobalChartView
                      ? "Detalles en Tablas"
                      : "Detalles en Gráficos"
                  }
                >
                  <Button onClick={handleGlobalToggle}>
                    {isGlobalChartView ? <TableChart /> : <BarChart />}
                  </Button>
                </Tooltip>
                {/* <Button onClick={handleGlobalToggle}>
                  {isGlobalChartView ? 'Mostrar Tablas' : 'Mostrar Gráficos'}
                </Button> */}
              </Box>
            </Box>
          </Box>

          {/* <Box sx={{ 
          flex: 1,
          overflowY: 'auto',
          p: 2
        }}> */}
          {/* 
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}> */}
          {/* <Box sx={{ 
              flex: 1,
              overflowY: 'auto',
              p: 2,
              maxHeight: 'calc(100% - 80px)', // Reserve space for total card
            }}> */}
          {/* <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              gap: 1
            }}> */}
          <Box
            sx={{
              flex: 1,
              overflow: "auto",
              p: 2,
            }}
          >
            {/* {coloredData.map((group) => ( */}
            {localColoredData.map((group) => (
              <Box
                key={group.groupKey}
                sx={{
                  p: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {showChart && (
                  <IconButton
                    onClick={(e) => handleColorClick(e, group)}
                    sx={{
                      width: 24,
                      height: 24,
                      borderRadius: "50%",
                      backgroundColor: group.color,
                      marginRight: 1,
                      border: "1px solid #ccc",
                      padding: 0,
                      "&:hover": {
                        border: "1px solid #000",
                      },
                    }}
                  />
                )}
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="medium"
                      onClick={() => toggleGroupVisibility(group.groupKey)}
                      sx={{
                        cursor: "pointer",
                        textDecoration: hiddenGroups.has(group.groupKey)
                          ? "line-through"
                          : "none",
                        "&:hover": {
                          opacity: 0.8,
                        },
                      }}
                    >
                      {group.groupKey}
                    </Typography>

                    {/* <Typography variant="subtitle1" fontWeight="medium">
                        {group.groupKey}
                      </Typography> */}
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>Eventos : {group.count}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>{group.percentage.toFixed(1)}%</Typography>
                  </Grid>
                </Grid>
              </Box>
            ))}

            {/* <Paper 
                elevation={1}
                sx={{
                  p: 2,
                  mt: 2,
                  bgcolor: 'primary.light',
                  color: 'primary.contrastText',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography fontWeight="bold">Total</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography fontWeight="bold">{total.total}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography fontWeight="bold">
                      {total.percentage.toFixed(1)}%
                    </Typography>
                  </Grid>
                </Grid>
              </Paper> */}
          </Box>

          {/* </Box> */}

          <Box sx={{ p: 2 }}>
            <Paper
              elevation={1}
              sx={{
                p: 2,
                bgcolor: "primary.light",
                color: "primary.contrastText",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography fontWeight="bold">
                    Total de Alertas Generadas
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography fontWeight="bold">{total.total}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography fontWeight="bold">
                    {total.percentage.toFixed(1)}%
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Box>

          {/* </Box> */}
        </Box>

        {showChart && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            style={{ flex: 1, height: "100%" }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                boxSizing: "border-box",
                backgroundColor: "background.paper",
              }}
            >
              <ChartComponent
                type={chartType}
                data={visibleData}
                fields={["groupKey", "count", "color"]}
                fromPanel={true}
                onSegmentClick={handleSegmentClick}
              />
            </Box>
          </motion.div>
        )}

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {selectedGroup && (
            <Box sx={{ padding: 2 }}>
              <HexColorPicker
                color={selectedGroup.color}
                onChange={(color) => handleColorChange(color)}
              />
            </Box>
          )}
        </Popover>
      </Paper>

      {process.env.REACT_APP_SUPERUSERACTIVATE === "true" && (
        <>
          <Box sx={{ mb: 2 }}>
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel>Agrupar detalles por</InputLabel>
              <Select
                value={detailGroupBy}
                onChange={(e) => setDetailGroupBy(e.target.value)}
                label="Agrupar detalles por"
              >
                <MenuItem value="">Ninguno</MenuItem>
                {fields.map((field) => (
                  <MenuItem key={field} value={field}>
                    {field}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </>
      )}

      {recomputedGroupedData.map((group) => (
        <Box key={group.groupKey} sx={{ mt: 4 }}>
          <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
            {/* Header de la tabla */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              {/* Título y total */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Typography variant="h6">
                  {` ${group.groupKey}`}
                  {/* ${detailGroupBy || groupBy}: */}
                </Typography>
                <Typography
                  variant="h6"
                  sx={
                    {
                      // backgroundColor: 'primary.main',
                      // color: 'primary.contrastText',
                      // px: 1,
                      // py: 0.5,
                      // borderRadius: 1
                    }
                  }
                >
                  {group.items.length}/{totalRecords}
                </Typography>
              </Box>

              {/* Controles */}
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                {process.env.REACT_APP_SUPERUSERACTIVATE === "true" && (
                  <>
                    <FormControl size="small" sx={{ minWidth: 120 }}>
                      <Select
                        value={tableGroupings[group.groupKey] || ""}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setTableGroupings((prev) => ({
                            ...prev,
                            [group.groupKey]: newValue,
                          }));
                          detailTableRef.current[
                            group.groupKey
                          ]?.handleGroupByChange(newValue);
                        }}
                        size="small"
                      >
                        <MenuItem value="">Ninguno</MenuItem>
                        {fields.map((field) => (
                          <MenuItem key={field} value={field}>
                            {field}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}

                <Tooltip title="Tabla">
                  <IconButton
                    onClick={() =>
                      detailTableRef.current[
                        group.groupKey
                      ]?.handleViewTypeChange("table")
                    }
                    size="small"
                  >
                    <TableIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Grafico">
                  <IconButton
                    onClick={() =>
                      detailTableRef.current[
                        group.groupKey
                      ]?.handleViewTypeChange("panel")
                    }
                    //color={viewType === 'panel' ? 'primary' : 'default'}
                  >
                    <PanelViewIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>

            {/* Línea divisoria */}
            <Box
              sx={{
                height: 2,
                backgroundColor: "error.main",
                mb: 2,
              }}
            />

            <Box
              sx={{
                //maxHeight: '400px',
                //overflow: 'auto',
                // Add a subtle shadow to indicate scrollable content
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#888",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: "#555",
                },
              }}
            >
              <DetailTable
                ref={(el) => (detailTableRef.current[group.groupKey] = el)}
                data={group.items.map((item) => {
                  const filteredItem = {};
                  fields.forEach((field) => {
                    const value = getNestedValue(item, field);
                    filteredItem[field] = value;
                  });
                  return filteredItem;
                })}
                headers={customHeaders}
                fields={fields}
                defaultGroupBy={detailGroupBy}
                totalRecords={totalRecords}
                dataSource={dataSource}
              />
            </Box>
          </Paper>
        </Box>
      ))}
    </div>
  );
};

export default GroupedPanel;
