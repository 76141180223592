// src/services/apiService.js
import axios from "axios";
import { API_CONFIG } from "../config/apiConfig";
import { decrypt } from "../utils/encryption";

// Create axios instance
const apiClient = axios.create({
  baseURL: API_CONFIG.BASE_URL,
  timeout: API_CONFIG.TIMEOUT,
  headers: {
    ...API_CONFIG.HEADERS,
    "x-api-key": API_CONFIG.X_API_KEY,
  },
});

// Request interceptor
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function formatDateLocal(date) {
  const padZero = (num) => num.toString().padStart(2, "0");
  return (
    date.getFullYear() +
    "-" +
    padZero(date.getMonth() + 1) +
    "-" +
    padZero(date.getDate()) +
    " " +
    padZero(date.getHours()) +
    ":" +
    padZero(date.getMinutes()) +
    ":" +
    padZero(date.getSeconds())
  );
}

// Response interceptor
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("auth");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);
/*-------------------------------------*/

// Create axios instance
const apitestcotalogos = axios.create({
  baseURL: "https://localhost:7102", //API_CONFIG.BASE_URL,
  timeout: API_CONFIG.TIMEOUT,
  headers: {
    ...API_CONFIG.HEADERS,
    "x-api-key": API_CONFIG.X_API_KEY,
  },
});

// Request interceptor
apitestcotalogos.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
apitestcotalogos.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("auth");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

// API methods
export const authService = {
  login: async (credentials) => {
    const response = await apiClient.post("/Auth/login", credentials);
    return response.data;
  },
  logout: async () => {
    const response = await apiClient.post("/Auth/Logout");
    return response.data;
  },
};

export const empresaService = {
  getEmpresas: async (id = "") => {
    try {
      id = localStorage.getItem("uuid_empresa");
      const response = await apiClient.get("/services/Empresa/coleccion", {
        params: {
          Id: id,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching empresas:", error.response?.data);
      throw error;
    }
  },
};

export const geocercaService = {
  // getGeocercas: async (uid_empresa, id = '', uid_externo = '') => {
  //   const response = await apiClient.get(`/services/Geocerca/listado-geocercas`, {
  //     params: { uid_empresa, id, uid_externo }
  //   });
  //   return response.data;
  // },

  getGeocercas: async (
    uid_empresa,
    id = "",
    uid_externo = "",
    status = "1",
    filterByUserGroups = true
  ) => {
    uid_empresa = localStorage.getItem("uuid_empresa");

    const requestBody = {
      uid_empresa,
      id,
      uid_externo,
      status,
    };
    
    // If filtering by user groups is enabled, add groups to the request
    if (filterByUserGroups) {
      const storedGroups = localStorage.getItem("groups");
      
      if (storedGroups) {
        const groups = JSON.parse(storedGroups);
        
        if (groups && groups.length > 0) {
          // Extract just the group IDs into an array
          const groupIds = groups.map(group => group.grupo_id || group.GrupoUid);
          
          // Add the group IDs to the request body
          requestBody.GrupoUID = groupIds;
          
          console.log("Requesting geocercas with groups:", groupIds);
        }
      }
    }
    
    const response = await apiClient.request({
      method: "post",
      url: "/services/Geocerca/listado-geocercas",
      data: JSON.stringify(requestBody),
    });

    // const response = await apiClient.request({
    //   method: "post",
    //   url: "/services/Geocerca/listado-geocercas",
    //   data: JSON.stringify({
    //     uid_empresa,
    //     id,
    //     uid_externo,
    //     status,
    //   }),
    // });
    return response.data;
  },
  createGeocerca: async (data) => {
    const response = await apiClient.post("/services/Geocerca/agregar", data);
    return response.data;
  },
  updateGeocerca: async (data) => {
    const response = await apiClient.put("/services/Geocerca/editar", data);
    return response.data;
  },
  deleteGeocerca: async (id, type) => {
    const response = await apiClient.delete(
      `/services/Geocerca/eliminar/${id}/${type}`
    );
    return response.data;
  },
};

export const userService = {
  firsttimeoff: async () => {
    // const data = {
    //   nuevo_usuario: 0,
    //   Id: localStorage.getItem("uuid_usuario")
    // };
    const data = {
      Usuario: {
        Id: localStorage.getItem("uuid_usuario"),
        nuevo_usuario: 0,
      },
    };
    const response = await apiClient.put("/services/Usuario/editar", data);
    return response.data;
  },

  getProfile: async () => {
    const response = await apiClient.get("/User/Profile");
    return response.data;
  },
  updateProfile: async (data) => {
    const response = await apiClient.put("/User/Profile", data);
    return response.data;
  },
};

export const menuService = {
  getMenu: async () => {
    const response = await apiClient.get(
      "/services/Menu/coleccion?activo=true"
    );
    return response.data;
  },
};

export const vehiculoService = {
  createVehiculo: async (data) => {
    try {
      const response = await apiClient.post("/services/Vehiculo/agregar", data);
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
  updateVehiculo: async (data) => {
    try {
      const response = await apiClient.put(
        "/services/Vehiculo/actualizar",
        data
      );
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
  getVehiculos: async (contactoId = "", status = 1, uid_externo = "", filterByUserGroups = true) => {
    try {
      const uidEmpresa = localStorage.getItem("uuid_empresa");
      
      // Create base params object
      const params = {
        Id: contactoId,
        UID_Empresa: uidEmpresa,
        UID_Externo: uid_externo,
        Status: status,
      };
      
      // If filterByUserGroups is true, add user's groups to the params
      if (filterByUserGroups) {
        // Get user groups from localStorage
        const storedGroups = localStorage.getItem("groups");
        
        if (storedGroups) {
          const groups = JSON.parse(storedGroups);
          
          if (groups && groups.length > 0) {
            // Create a URLSearchParams object for building the query string
            const queryParams = new URLSearchParams();
            
            // Add the base params
            for (const [key, value] of Object.entries(params)) {
              if (value) queryParams.append(key, value);
            }
            
            // Add each group_uid as a separate parameter
            groups.forEach(group => {
              const groupId = group.grupo_id || group.GrupoUid;
              if (groupId) {
                queryParams.append('grupo_uid', groupId);
              }
            });
            
            // Make API request with custom query string
            const response = await apiClient.get(
              `/services/Vehiculo/coleccion?${queryParams.toString()}`
            );
            return response.data;
          }
        }
      }
      
      // If no groups or filterByUserGroups is false, make regular request
      const response = await apiClient.get(`/services/Vehiculo/coleccion`, {
        params: params
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching vehicles:", error);
      return { statusCode: 500, data: [], message: "Error fetching vehicles" };
    }
  },
  deleteVehiculo: async (data) => {
    try {
      const response = await apiClient.delete(
        `/services/Vehiculo/eliminar/${data}`
      );
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
};

export const eventoService = {
  getEventos: async () => {
    try {
      const response = await apiClient.get("/services/evento/coleccion", {
        params: {
          activo: true,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
};

export const contactoService = {
  getContactos: async (contactoId, status) => {
    const uidEmpresa = localStorage.getItem("uuid_empresa");
    const response = await apiClient.get("/services/Contacto/coleccion", {
      params: {
        Id: contactoId,
        UID_Empresa: uidEmpresa,
        Status: status,
      },
    });
    return response.data;
  },

  createContacto: async (data) => {
    try {
      const response = await apiClient.post("/services/Contacto/agregar", data);
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },

  updateContacto: async (data) => {
    try {
      const response = await apiClient.put(
        "/services/Contacto/actualizar",
        data
      );
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },

  updateContactos: async (data) => {
    try {
      console.log(data);
      const response = await apiClient.put(
        "/services/Contacto/actualizarContactos",
        data
      );
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },

  deleteContacto: async (id) => {
    try {
      const response = await apiClient.delete(
        `/services/Contacto/eliminar/${id}`
      );
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
};

export const clasificacionService = {
  getClasificaciones: async (clasificacionId, status) => {
    const response = await apiClient.get(
      "/services/Clasificaciones/coleccion",
      {
        params: {
          id: clasificacionId,
          status: status,
        },
      }
    );
    return response.data;
  },
  createClasificacion: async (data) => {
    try {
      const response = await apiClient.post(
        "/services/Clasificaciones/agregar",
        data
      );
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
  updateClasificaciones: async (data) => {
    try {
      const response = await apiClient.put(
        "/services/Clasificaciones/actualizar",
        data
      );
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },

  deleteClasificacion: async (id) => {
    try {
      const response = await apiClient.delete(
        `/services/Clasificaciones/eliminar/${id}`
      );
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
};

export const telegramService = {
  getLink: async (id) => {
    try {
      const response = await apiClient.get(`/services/Telegram/link`, {
        headers: {
          Id: id,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
  registrarTelegram: async (data) => {
    try {
      const response = await apiClient.post(
        `/services/Telegram/registrar-telegram`,
        data
      );
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
};

//https://omisiones.quro.com.mx:8056/
const localApiClient = axios.create({
  baseURL: "https://omisiones.quro.com.mx:8056", //'http://localhost:8057',//''http://localhost:8057',//''http://localhost:8057',//
  timeout: 0, //API_CONFIG.TIMEOUT,
  headers: {
    "Content-Type": "application/json",
  },
  //withCredentials: true
});

//http://enlaces.quro.com.mx:8055
const enlacesQuro = axios.create({
  baseURL: "http://enlaces.quro.com.mx:8055",
  timeout: 0,
  headers: {
    "Content-Type": "application/json",
  },
});

export const notificacionService = {
  getNotificaciones: async () => {
    try {
      const response = await apiClient.post(
        "/services/Notificaciones/coleccion",
        { Status: 1 }
      );
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
  getNotificacionesLocal: async (startDate, endDate, limit = 100) => {
    try {
      let empresauid = localStorage.getItem("uid_externo"); //'7ca085a2-e29c-40dd-85d6-249ad45d21b2';//
      console.log("empresauid:", empresauid);
      const response = await localApiClient.get("/data/notificaciones", {
        params: {
          Fecha_gte: startDate,
          Fecha_lte: endDate,
          limit: limit,
          EmpresaUid: empresauid, //localStorage.getItem('uid_externo')
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching local notifications:", error);
      return null;
    }
  },
  getNotificacionesproductivo: async (
    startDate,
    endDate,
    limit = 100,
    skip = 0,
    fields = ["Message", "Status", "Fecha"],
    sort = "Fecha",
    VehiculoUid,
    Tipo
  ) => {
    try {
      // Get current date
      const now = new Date();

      if (!startDate) {
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate());
        startDate = new Date(
          yesterday.getFullYear(),
          yesterday.getMonth(),
          yesterday.getDate(),
          0,
          0,
          0
        )
          .toISOString()
          .slice(0, 19)
          .replace("T", " ");
      }

      // If endDate is not provided, set to the end of the current day
      if (!endDate) {
        const today = new Date(now);
        endDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          23,
          59,
          59
        )
          .toISOString()
          .slice(0, 19)
          .replace("T", " ");
      }

      const empresauid = localStorage.getItem("uid_externo");

      const response = await localApiClient.post(
        "/data_2_paged/notificaciones",
        {
          skip: skip,
          limit: limit,
          fields: fields.join(","),
          sort: sort,
          Fecha_gte: startDate,
          Fecha_lte: endDate,
          EmpresaUid: empresauid,
          VehiculoUid: VehiculoUid,
          DatosAlertaTipo: Tipo,
          //"DatosAlerta.Tipo": Tipo
        }
      );

      //console.log('response de notificaciones:', response.data);

      return response.data;
    } catch (error) {
      console.error("Error fetching local notifications:", error);
      return null;
    }
  },
  getNotificacionesOmisiones: async (
    startDate,
    endDate,
    limit = 1,
    skip = 0,
    fields = [],
    sort = "FechaHora_Notificacion",
    VehiculoUid,
    Tipo
  ) => {
    try {
      // Get current date
      const now = new Date();

      if (!startDate) {
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate());
        startDate = formatDateLocal(
          new Date(
            yesterday.getFullYear(),
            yesterday.getMonth(),
            yesterday.getDate(),
            0,
            0,
            0
          )
        );
      }

      // Si endDate no se proporciona, establecer al final del día actual
      if (!endDate) {
        const today = new Date(now);
        endDate = formatDateLocal(
          new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            23,
            59,
            59
          )
        );
      }

      // if (!startDate) {
      //   const yesterday = new Date(now);
      //   yesterday.setDate(now.getDate());
      //   startDate = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 0, 0, 0)
      //     .toISOString()
      //     .slice(0, 19)
      //     .replace('T', ' ');
      // }

      // // If endDate is not provided, set to the end of the current day
      // if (!endDate) {
      //   const today = new Date(now);
      //   endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)
      //     .toISOString()
      //     .slice(0, 19)
      //     .replace('T', ' ');
      // }

      const empresauid = localStorage.getItem("uuid_empresa");

      const response = await localApiClient.post("/notificaciones_omisiones", {
        skip: skip,
        limit: limit,
        fields: fields.join(","),
        sort: sort,
        FechaHora_Notificacion_gte: startDate,
        FechaHora_Notificacion_lte: endDate,
        UID_Empresa: empresauid,
        UID_Vehiculo: VehiculoUid,
        Tipo_Alerta: Tipo,
      });

      //console.log('response de notificaciones:', response.data);

      return response.data;
    } catch (error) {
      console.error("Error fetching local notifications:", error);
      return null;
    }
  },

  getAlertas: async () => {
    try {
      const response = await localApiClient.get(`/alertas`, {
        // params: {
        //   activo: 1,
        // },
      });
      //console.log(JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
  getVehiculo: async () => {
    const empresauid = localStorage.getItem("uid_externo");
    try {
      const response = await localApiClient.get(`/vehiculo/${empresauid}`);
      //console.log(JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
};

export const RiesgoService = {
  getRiesgos: async (activo = 1) => {
    try {
      const response = await apiClient.get(`/services/Riesgo/coleccion`, {
        query: { activo },
      });
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
};

export const alertaService = {
  createAlerta: async (data) => {
    try {
      const response = await apiClient.post("/services/Alerta/agregar", data);
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
  updateAlerta: async (data) => {
    try {
      const response = await apiClient.put("/services/Alerta/editar", data);
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
  getAlertList: async (filtros) => {
    try {
      const uid_empresa = localStorage.getItem("uuid_empresa");

      // Agregar uid_empresa al objeto filtros, manteniendo las demás propiedades
      const filtrosActualizados = { ...filtros, uid_empresa };

      const response = await apiClient.post(
        `/services/Alerta/listado-alertas`,
        filtrosActualizados
      );

      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
  deleteAlert: async (params) => {
    try {
      const response = await apiClient.post(
        `/services/Alerta/eliminar`,
        params
      );
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
};

export const ClasificacionEventoService = {
  AsignarEventos: async (data) => {
    try {
      const response = await apiClient.post(
        "/services/ClasificacionEvento/AgregarEventosAsignados",
        data
      );
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
  FiltrarAsignaciones: async (iud_clasificacion = "") => {
    try {
      const response = await apiClient.post(
        "/services/ClasificacionEvento/listado-asignaciones",
        {
          uid_clasificacion: iud_clasificacion,
        }
      );
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
  getClasificacionesActivas: async () => {
    const response = await apiClient.get(
      "/services/Clasificaciones/coleccion",
      {
        params: {
          status: 1,
        },
      }
    );
    return response.data;
  },
};

//Clasificaciones geocercas
export const ClasificacionesGeocercasService = {
  getClasificacionesGeocercas: async () => {
    try {
      const uidEmpresa = localStorage.getItem("uuid_empresa");
      const response = await apiClient.get(
        "/services/clasificacionGeocerca/coleccion",
        {
          params: {
            uidEmpresa: uidEmpresa,
            status: 1,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
};

// Tipos de geocercas
export const tiposGeocercasService = {
  getTiposGeocercas: async (id, nombre, status) => {
    try {
      const response = await apiClient.get("/services/TipoGeocerca/coleccion", {
        params: {
          id: id,
          nombre: nombre,
          status: status,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
};
/**
 * INICIO API DE ENLACES QURO
 */
export const loginQuro = {
  getToken: async () => {
    const generalUser = localStorage.getItem("user");
    const [id, user] = generalUser.split("@");
    const password_quro = localStorage.getItem("password_quro");
    const pass = decrypt(password_quro);
    const response = await enlacesQuro.get("/api/login", {
      params: {
        ID: id,
        User: user,
        Password: pass,
      },
    });
    return response;
  },
};

export const geocercaQuroService = {
  crearGeocerca: async (data) => {
    const response = await enlacesQuro.post("/api/Geocerca", {
      data,
    });
    return response.data;
  },
};

/**
 * FIN API DE ENLACES QURO
 */
// Grupos de geocercas
export const gruposGeocercasService = {
  getGruposGeocercas: async (token = "") => {
    try {
      const uidEmpresa = localStorage.getItem("uid_externo");
      const uidGrupo = "";
      const idGrupo = "";
      const claveExternaERPGrupo = "";
      const claveExternaERPEmpresa = "";
      const response = await enlacesQuro.get("/api/Grupos", {
        params: {
          Token: token,
          EmpresaUID: uidEmpresa,
          UidGrupo: uidGrupo,
          IDGrupo: idGrupo,
          ClaveExterna_ERP_Grupo: claveExternaERPGrupo,
          ClaveExterna_ERP_Empresa: claveExternaERPEmpresa,
        },
      });
      return response;
    } catch (error) {
      console.error(error);
    }
  },
};

//Grupos conectado a api nodejs de sincronizacioens
export const groupService = {
  getGroups: async (status = 1) => {
    try {
      console.log("getGroups called with status:", status);
      
      // First check if groups are in localStorage
      const storedGroups = localStorage.getItem("groups");
      console.log("Stored groups in getGroups:", storedGroups);
      
      if (storedGroups) {
        const groups = JSON.parse(storedGroups);
        console.log("Parsed groups from localStorage:", groups);
        
        if (groups && groups.length > 0) {
          // Return groups in their original format without transformation
          return groups;
        }
      }
      
      // If no valid groups in storage, fetch them from API
      console.log("No valid groups in localStorage, fetching from API");
      const empresaUid = localStorage.getItem("uid_externo");
      console.log("empresaUid for API call:", empresaUid);
      
      const apiBaseUrl = process.env.REACT_APP_GROUPS_API_URL || 'https://omisiones.quro.com.mx:50070';
      
      const response = await axios.get(`${apiBaseUrl}/api/groups`, {
        params: {
          empresa: empresaUid,
          status: status
        }
      });
      
      console.log("API response for groups:", response);
      
      if (!response || !response.data) {
        console.log("No data in API response");
        return [];
      }
      
      // Store the fetched groups in localStorage
      localStorage.setItem("groups", JSON.stringify(response.data));
      
      return response.data;
    } catch (error) {
      console.error("Error fetching groups:", error);
      return [];
    }
  }
};


// Cliente HTTP específico para la API de mensajes
const messageApiClient = axios.create({
  baseURL: process.env.REACT_APP_CONSUMOS_API_URL || 'https://omisiones.quro.com.mx:8095',
  timeout: API_CONFIG.TIMEOUT,
  headers: {
    'Content-Type': 'application/json'
  }
});


// Modificación del interceptor de solicitud de messageApiClient
messageApiClient.interceptors.request.use(
  async (config) => {
    try {
      // Generar un nuevo token para cada petición en lugar de reutilizar uno almacenado
      const user = localStorage.getItem("user");
      const encryptedPassword = localStorage.getItem("password_quro");
      
      if (user && encryptedPassword) {
        const [userId, username] = user.split("@");
        const password = decrypt(encryptedPassword);
        
        // Hacer la autenticación directamente para cada petición
        const authResponse = await axios.post(
          `${process.env.REACT_APP_CONSUMOS_API_URL || 'https://omisiones.quro.com.mx:8095'}/api/auth/login`,
          {
            userId,
            username,
            password
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        
        // Si se obtuvo un token, agregarlo al encabezado
        if (authResponse.data && authResponse.data.data && authResponse.data.data.token) {
          const token = authResponse.data.data.token;
          config.headers.Authorization = `Bearer ${token}`;
          console.log("Nuevo token generado para petición a API de mensajes");
        }
      }
      
      return config;
    } catch (error) {
      console.error("Error al generar token para API de mensajes:", error);
      // Continuar con la petición sin token
      return config;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Ya no necesitamos el interceptor de respuesta para refresh token
messageApiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("Error en petición a API de mensajes:", error.message);
    return Promise.reject(error);
  }
);

// Modificación del servicio de autenticación para la API de mensajes
export const messageAuthService = {
  login: async (forceCredentials = null) => {
    try {
      // Si se proporcionan credenciales directamente, usarlas
      if (forceCredentials) {
        const { userId, username, password } = forceCredentials;
        
        // Hacer petición de API con las credenciales proporcionadas
        const response = await axios.post(
          `${process.env.REACT_APP_CONSUMOS_API_URL || 'https://omisiones.quro.com.mx:8095'}/api/auth/login`,
          {
            userId,
            username,
            password
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        
        if (response.data && response.data.data && response.data.data.token) {
          return response.data.data.token;
        }
        throw new Error("Respuesta de autenticación inválida");
      }
      
      // Flujo original usando localStorage
      const user = localStorage.getItem("user");
      if (!user) {
        throw new Error("No hay información de usuario disponible");
      }
      
      const [userId, username] = user.split("@");
      
      const encryptedPassword = localStorage.getItem("password_quro");
      if (!encryptedPassword) {
        throw new Error("No hay contraseña disponible");
      }
      
      const password = decrypt(encryptedPassword);
      
      const response = await axios.post(
        `${process.env.REACT_APP_CONSUMOS_API_URL || 'https://omisiones.quro.com.mx:8095'}/api/auth/login`,
        {
          userId,
          username,
          password
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      
      if (response.data && response.data.data && response.data.data.token) {
        return response.data.data.token;
      } else {
        throw new Error("Respuesta de autenticación inválida");
      }
    } catch (error) {
      console.error("Error autenticando con la API de mensajes:", error);
      return null;
    }
  },
  
  // Este método ahora simplemente llama a login() para generar un nuevo token cada vez
  getToken: async () => {
    return await messageAuthService.login();
  }
};

// Añadir interceptor de solicitud para agregar el token automáticamente
// messageApiClient.interceptors.request.use(
//   async (config) => {
//     try {
//       // Verificar si los intentos están bloqueados temporalmente
//       const retryBlocked = localStorage.getItem("message_api_token_retry_blocked");
//       const retryTimestamp = localStorage.getItem("message_api_token_retry_timestamp");
//       const currentTime = Date.now();
      
//       // Si los intentos están bloqueados y han pasado menos de 2 minutos, no intentar renovar
//       if (retryBlocked === "true" && retryTimestamp && (currentTime - parseInt(retryTimestamp)) < 120000) {
//         console.log("Intentos de renovación de token bloqueados temporalmente. Usando petición sin token.");
//         return config;
//       }
      
//       // Si han pasado más de 2 minutos, desbloquear los intentos
//       if (retryBlocked === "true") {
//         localStorage.removeItem("message_api_token_retry_blocked");
//         localStorage.removeItem("message_api_token_retry_timestamp");
//       }
      
//       // Obtener token y su timestamp del localStorage
//       let token = localStorage.getItem("message_api_token");
//       const tokenTimestamp = localStorage.getItem("message_api_token_timestamp");
      
//       // Verificar si el token existe y si ha pasado más de 1 hora (3600000 ms)
//       const tokenExpired = !tokenTimestamp || (currentTime - parseInt(tokenTimestamp)) > 3600000;
      
//       // Si no hay token o ha expirado, obtener uno nuevo
//       if (!token || tokenExpired) {
//         console.log("Token de API de mensajes expirado o no existe. Renovando...");
//         token = await messageAuthService.login();
//       }
      
//       // Si se obtuvo un token, agregarlo al encabezado
//       if (token) {
//         config.headers.Authorization = `Bearer ${token}`;
//       }
      
//       return config;
//     } catch (error) {
//       console.error("Error en interceptor de solicitud:", error);
//       return config;
//     }
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// // Añadir interceptor de respuesta para manejar errores 401 (token expirado)
// messageApiClient.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;
    
//     // Si es un error 401 (Unauthorized) y no es un retry
//     if (error.response && error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
      
//       try {
//         // Forzar login para obtener nuevo token
//         console.log("Error 401 en API de mensajes. Renovando token...");
//         localStorage.removeItem("message_api_token");
//         localStorage.removeItem("message_api_token_timestamp");
//         const token = await messageAuthService.login();
        
//         // Reintentar la solicitud original con el nuevo token
//         if (token) {
//           originalRequest.headers.Authorization = `Bearer ${token}`;
//           return messageApiClient(originalRequest);
//         }
//       } catch (refreshError) {
//         console.error("Error al renovar token expirado:", refreshError);
//       }
//     }
    
//     return Promise.reject(error);
//   }
// );

// Servicio de autenticación para la API de mensajes
// export const messageAuthService = {
//   login: async (forceCredentials = null) => {
//     try {
//       // If credentials are directly provided, use them instead of localStorage
//       if (forceCredentials) {
//         const { userId, username, password } = forceCredentials;
        
//         // Make API request with provided credentials
//         const response = await axios.post(
//           `${process.env.REACT_APP_CONSUMOS_API_URL || 'https://omisiones.quro.com.mx:8095'}/api/auth/login`,
//           {
//             userId,
//             username,
//             password
//           },
//           {
//             headers: {
//               'Content-Type': 'application/json'
//             }
//           }
//         );
        
//         // Process response as before
//         if (response.data && response.data.data && response.data.data.token) {
//           const token = response.data.data.token;
//           localStorage.setItem("message_api_token", token);
//           localStorage.setItem("message_api_token_timestamp", Date.now().toString());
//           console.log("Token de API de mensajes renovado exitosamente con credenciales directas");
//           return token;
//         }
//         throw new Error("Respuesta de autenticación inválida");
//       }
      
//       // Original flow using localStorage (as a fallback)
//       const user = localStorage.getItem("user");
//       if (!user) {
//         throw new Error("No hay información de usuario disponible");
//       }
      
//       const [userId, username] = user.split("@");
      
//       const encryptedPassword = localStorage.getItem("password_quro");
//       if (!encryptedPassword) {
//         throw new Error("No hay contraseña disponible");
//       }
      
//       const password = decrypt(encryptedPassword);
      
//       // Original API request logic...
//       const response = await axios.post(
//         `${process.env.REACT_APP_CONSUMOS_API_URL || 'https://omisiones.quro.com.mx:8095'}/api/auth/login`,
//         {
//           userId,
//           username,
//           password
//         },
//         {
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         }
//       );
      
//       // Original response processing...
//       if (response.data && response.data.data && response.data.data.token) {
//         const token = response.data.data.token;
//         localStorage.setItem("message_api_token", token);
//         localStorage.setItem("message_api_token_timestamp", Date.now().toString());
//         console.log("Token de API de mensajes renovado exitosamente");
//         return token;
//       } else {
//         throw new Error("Respuesta de autenticación inválida");
//       }
//     } catch (error) {
//       console.error("Error autenticando con la API de mensajes:", error);
      
//       // Only block retries if we weren't using direct credentials
//       if (!forceCredentials) {
//         localStorage.setItem("message_api_token_retry_blocked", "true");
//         localStorage.setItem("message_api_token_retry_timestamp", Date.now().toString());
//       }
      
//       return null;
//     }
//   },
  
//   // Obtener el token (de localStorage o generando uno nuevo)
//   getToken: async () => {
//     let token = localStorage.getItem("message_api_token");
//     const tokenTimestamp = localStorage.getItem("message_api_token_timestamp");
//     const currentTime = Date.now();
    
//     // Verificar si el token existe y si ha pasado más de 1 hora (3600000 ms)
//     const tokenExpired = !tokenTimestamp || (currentTime - parseInt(tokenTimestamp)) > 3600000;
    
//     // Si no hay token o ha expirado, obtener uno nuevo
//     if (!token || tokenExpired) {
//       token = await messageAuthService.login();
//     }
    
//     return token;
//   }
// };


export const messageService = {
  // getMessageBalance: async () => {
  //   try {
  //     // Verificar si hay bloqueo de intentos (indica problemas CORS u otros)
  //     const retryBlocked = localStorage.getItem("message_api_token_retry_blocked");
      
  //     // Si hay problemas con la API, retornar directamente valores por defecto
  //     if (retryBlocked === "true") {
  //       console.log("Usando balance de mensajes por defecto debido a problemas con la API");
  //       // Return empty array for compatibility with the UI
  //       return {
  //         success: false,
  //         data: []
  //       };
  //     }
      
  //     // Obtener paquetes de notificaciones desde el endpoint correcto
  //     // Obtener ID de la empresa desde localStorage
  //       const companyId = localStorage.getItem("uuid_empresa");
        
  //       // Configurar parámetros para filtrar solo paquetes activos de la empresa
  //       const params = {
  //         companyId: companyId,
  //         estatus: 'activo',
  //         limit: 100
  //       };
        
  //       // Realizar petición con los filtros adecuados
  //       const response = await messageApiClient.get('/api/notification-packages', { params });

      
  //     // Mantener el formato original para compatibilidad con el componente
  //     if (response.data && response.data.success && Array.isArray(response.data.data)) {
  //       // Devolver los datos directamente como array, que es lo que espera el componente
  //       return {
  //         success: true,
  //         data: response.data.data.map(pkg => ({
  //           _id: pkg._id,
  //           notificationTypeId: pkg.notificationTypeId || { 
  //             _id: `unknown-${pkg._id}`,
  //             nombre: 'Desconocido'
  //           },
  //           cantidad: pkg.cantidad || 0,
  //           utilizadas: pkg.utilizadas || 0,
  //           disponibles: pkg.disponibles || 0,
  //           costo_unitario: pkg.costo_unitario || 0,
  //           costo_total: pkg.costo_total || 0,
  //           fecha_inicio: pkg.fecha_inicio,
  //           fecha_fin: pkg.fecha_fin,
  //           estatus: pkg.estatus
  //         }))
  //       };
  //     }
      
  //     // Si la respuesta no tiene el formato esperado
  //     console.warn('Formato de respuesta inesperado:', response.data);
  //     return {
  //       success: response.data?.success || false,
  //       data: [] // Array vacío para compatibilidad con el componente
  //     };
  //   } catch (error) {
  //     console.error('Error al obtener balance de mensajes:', error);
  //     // Retornar un array vacío en caso de error
  //     return {
  //       success: false,
  //       data: []
  //     };
  //   }
  // },
  
  // getMessageBalance: async (format = "default", specificCompanyId = null) => {
  //   try {
  //     // Verificar si hay bloqueo de intentos (indica problemas CORS u otros)
  //     const retryBlocked = localStorage.getItem("message_api_token_retry_blocked");
      
  //     // Si hay problemas con la API, retornar directamente valores por defecto
  //     if (retryBlocked === "true") {
  //       console.log("Usando balance de mensajes por defecto debido a problemas con la API");
        
  //       if (format === "summary") {
  //         // Formato para MessageBalance.js en monitor
  //         return {
  //           success: false,
  //           data: {
  //             MensajeMailDisponible: 0,
  //             MensajeMailUtilizado: 0,
  //             MensajeWatsDisponible: 0,
  //             MensajeWatsUtilizado: 0,
  //             MensajetextoDisponible: 0,
  //             MensajetextoUtilizado: 0,
  //             MensajeVozDisponible: 0,
  //             MensajeVozUtilizado: 0
  //           }
  //         };
  //       } else {
  //         // Formato para MessageBalancePage.js
  //         return {
  //           success: false,
  //           data: []
  //         };
  //       }
  //     }
      
  //     // Obtener ID de la empresa desde localStorage
  //     const companyId = specificCompanyId || localStorage.getItem("uuid_empresa");
      
  //     // Configurar parámetros para filtrar solo paquetes activos de la empresa
  //     const params = {
  //       companyId: companyId,
  //       estatus: 'activo',
  //       limit: 100
  //     };
      
  //     // Realizar petición con los filtros adecuados
  //     const response = await messageApiClient.get('/api/notification-packages', { params });
      
  //     // Si la respuesta tiene el formato esperado
  //     if (response.data && response.data.success && Array.isArray(response.data.data)) {
  //       // Procesar los paquetes
  //       const packages = response.data.data.map(pkg => ({
  //         _id: pkg._id,
  //         notificationTypeId: pkg.notificationTypeId || { 
  //           _id: `unknown-${pkg._id}`,
  //           nombre: 'Desconocido'
  //         },
  //         cantidad: pkg.cantidad || 0,
  //         utilizadas: pkg.utilizadas || 0,
  //         disponibles: pkg.disponibles || 0,
  //         costo_unitario: pkg.costo_unitario || 0,
  //         costo_total: pkg.costo_total || 0,
  //         fecha_inicio: pkg.fecha_inicio,
  //         fecha_fin: pkg.fecha_fin,
  //         estatus: pkg.estatus
  //       }));
        
  //       // Si se solicita el formato resumen (para monitor)
  //       if (format === "summary") {
  //         // Inicializar contadores
  //         let emailDisponible = 0;
  //         let emailUtilizado = 0;
  //         let whatsappDisponible = 0;
  //         let whatsappUtilizado = 0;
  //         let smsDisponible = 0;
  //         let smsUtilizado = 0;
  //         let vozDisponible = 0;
  //         let vozUtilizado = 0;
          
  //         // Sumar disponibilidad por tipo de notificación
  //         packages.forEach(pkg => {
  //           const tipoNombre = pkg.notificationTypeId?.nombre?.toLowerCase() || '';
            
  //           if (tipoNombre.includes('email') || tipoNombre.includes('correo')) {
  //             emailDisponible += pkg.cantidad || 0;
  //             emailUtilizado += pkg.utilizadas || 0;
  //           } else if (tipoNombre.includes('whatsapp') || tipoNombre.includes('wats')) {
  //             whatsappDisponible += pkg.cantidad || 0;
  //             whatsappUtilizado += pkg.utilizadas || 0;
  //           } else if (tipoNombre.includes('sms') || tipoNombre.includes('texto')) {
  //             smsDisponible += pkg.cantidad || 0;
  //             smsUtilizado += pkg.utilizadas || 0;
  //           } else if (tipoNombre.includes('voz') || tipoNombre.includes('llamada')) {
  //             vozDisponible += pkg.cantidad || 0;
  //             vozUtilizado += pkg.utilizadas || 0;
  //           }
  //         });
          
  //         // Devolver en formato compatible con MessageBalance.js
  //         return {
  //           success: true,
  //           data: {
  //             MensajeMailDisponible: emailDisponible,
  //             MensajeMailUtilizado: emailUtilizado,
  //             MensajeWatsDisponible: whatsappDisponible,
  //             MensajeWatsUtilizado: whatsappUtilizado,
  //             MensajetextoDisponible: smsDisponible,
  //             MensajetextoUtilizado: smsUtilizado,
  //             MensajeVozDisponible: vozDisponible,
  //             MensajeVozUtilizado: vozUtilizado
  //           }
  //         };
  //       }
        
  //       // Si no se solicita formato especial, devolver array para MessageBalancePage.js
  //       return {
  //         success: true,
  //         data: packages
  //       };
  //     }
      
  //     // Si la respuesta no tiene el formato esperado
  //     console.warn('Formato de respuesta inesperado:', response.data);
      
  //     if (format === "summary") {
  //       return {
  //         success: response.data?.success || false,
  //         data: {
  //           MensajeMailDisponible: 0,
  //           MensajeMailUtilizado: 0,
  //           MensajeWatsDisponible: 0,
  //           MensajeWatsUtilizado: 0,
  //           MensajetextoDisponible: 0,
  //           MensajetextoUtilizado: 0,
  //           MensajeVozDisponible: 0,
  //           MensajeVozUtilizado: 0
  //         }
  //       };
  //     } else {
  //       return {
  //         success: response.data?.success || false,
  //         data: [] // Array vacío para compatibilidad con el componente
  //       };
  //     }
  //   } catch (error) {
  //     console.error('Error al obtener balance de mensajes:', error);
      
  //     if (format === "summary") {
  //       return {
  //         success: false,
  //         data: {
  //           MensajeMailDisponible: 0,
  //           MensajeMailUtilizado: 0,
  //           MensajeWatsDisponible: 0,
  //           MensajeWatsUtilizado: 0,
  //           MensajetextoDisponible: 0,
  //           MensajetextoUtilizado: 0,
  //           MensajeVozDisponible: 0,
  //           MensajeVozUtilizado: 0
  //         }
  //       };
  //     } else {
  //       return {
  //         success: false,
  //         data: []
  //       };
  //     }
  //   }
  // },

  getMessageBalance: async (format = "default", specificCompanyId = null) => {
    let retryCount = 0;
    const MAX_RETRIES = 2;
    const RETRY_DELAY = 2000; // 2 segundos entre reintentos
    
    // Función para esperar un tiempo determinado
    const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    
    // Función para hacer la petición con reintentos
    const fetchWithRetry = async () => {
      try {
        console.log(`Intento ${retryCount + 1}/${MAX_RETRIES + 1} para obtener balance de mensajes`);
        
        // Verificar si hay bloqueo de intentos (indica problemas CORS u otros)
        // const retryBlocked = localStorage.getItem("message_api_token_retry_blocked");
        
        // // Si hay problemas con la API, retornar directamente valores por defecto
        // if (retryBlocked === "true") {
        //   console.log("Usando balance de mensajes por defecto debido a problemas con la API");
        //   return getDefaultBalance(format);
        // }
        
        // Obtener ID de la empresa desde localStorage
        const companyId = specificCompanyId || localStorage.getItem("uuid_empresa");
        console.log(`Obteniendo balance para companyId: ${companyId}`);
        
        if (!companyId) {
          console.error("No se pudo obtener ID de empresa");
          return getDefaultBalance(format);
        }
        
        // Configurar parámetros para filtrar solo paquetes activos de la empresa
        const params = {
          companyId: companyId,
          estatus: 'activo',
          limit: 100
        };
        
        console.log("Enviando petición a /api/notification-packages con params:", params);
        
        // Configurar timeout más largo para producción
        const requestConfig = { 
          params,
          timeout: 15000 // 15 segundos de timeout
        };
        
        // Realizar petición con timeout extendido
        const response = await messageApiClient.get('/api/notification-packages', requestConfig);
        
        console.log("Respuesta recibida:", response.status, 
          response.data ? (response.data.success ? "success:true" : "success:false") : "sin data");
        
        // Si la respuesta tiene el formato esperado
        if (response.data && response.data.success && Array.isArray(response.data.data)) {
          console.log(`Procesando ${response.data.data.length} paquetes de notificación`);
          
          // Procesar los paquetes
          const packages = response.data.data.map(pkg => ({
            _id: pkg._id,
            notificationTypeId: pkg.notificationTypeId || { 
              _id: `unknown-${pkg._id}`,
              nombre: 'Desconocido'
            },
            cantidad: pkg.cantidad || 0,
            utilizadas: pkg.utilizadas || 0,
            disponibles: pkg.disponibles || 0,
            costo_unitario: pkg.costo_unitario || 0,
            costo_total: pkg.costo_total || 0,
            fecha_inicio: pkg.fecha_inicio,
            fecha_fin: pkg.fecha_fin,
            estatus: pkg.estatus
          }));
          
          // Si se solicita el formato resumen (para monitor)
          if (format === "summary") {
            // Inicializar contadores
            let emailTotal = 0;
            let emailUtilizado = 0;
            let whatsappTotal = 0;
            let whatsappUtilizado = 0;
            let smsTotal = 0;
            let smsUtilizado = 0;
            let vozTotal = 0;
            let vozUtilizado = 0;
            
            // Sumar disponibilidad por tipo de notificación
            packages.forEach(pkg => {
              const tipoNombre = pkg.notificationTypeId?.nombre?.toLowerCase() || '';
              
              if (tipoNombre.includes('email') || tipoNombre.includes('correo')) {
                emailTotal += pkg.cantidad || 0;
                emailUtilizado += pkg.utilizadas || 0;
              } else if (tipoNombre.includes('whatsapp') || tipoNombre.includes('wats')) {
                whatsappTotal += pkg.cantidad || 0;
                whatsappUtilizado += pkg.utilizadas || 0;
              } else if (tipoNombre.includes('sms') || tipoNombre.includes('texto')) {
                smsTotal += pkg.cantidad || 0;
                smsUtilizado += pkg.utilizadas || 0;
              } else if (tipoNombre.includes('voz') || tipoNombre.includes('llamada')) {
                vozTotal += pkg.cantidad || 0;
                vozUtilizado += pkg.utilizadas || 0;
              }
            });
            
            console.log("Balance calculado:", {
              email: `${emailUtilizado}/${emailTotal}`,
              whatsapp: `${whatsappUtilizado}/${whatsappTotal}`,
              sms: `${smsUtilizado}/${smsTotal}`,
              voz: `${vozUtilizado}/${vozTotal}`
            });
            
            // Devolver en formato compatible con MessageBalance.js
            return {
              success: true,
              data: {
                MensajeMailDisponible: emailTotal,
                MensajeMailUtilizado: emailUtilizado,
                MensajeWatsDisponible: whatsappTotal,
                MensajeWatsUtilizado: whatsappUtilizado,
                MensajetextoDisponible: smsTotal,
                MensajetextoUtilizado: smsUtilizado,
                MensajeVozDisponible: vozTotal,
                MensajeVozUtilizado: vozUtilizado
              }
            };
          }
          
          // Si no se solicita formato especial, devolver array para MessageBalancePage.js
          return {
            success: true,
            data: packages
          };
        }
        
        // Si la respuesta no tiene el formato esperado
        console.warn('Formato de respuesta inesperado:', response.data);
        
        // Si aún hay reintentos disponibles, lanzar error para activar reintento
        if (retryCount < MAX_RETRIES) {
          throw new Error("Formato de respuesta inesperado");
        }
        
        return getDefaultBalance(format);
        
      } catch (error) {
        console.error(`Error al obtener balance de mensajes (intento ${retryCount + 1}/${MAX_RETRIES + 1}):`, 
          error.message, error.response?.status);
        
        // Si hay reintentos disponibles, esperar y reintentar
        if (retryCount < MAX_RETRIES) {
          retryCount++;
          console.log(`Reintentando en ${RETRY_DELAY/1000} segundos...`);
          await wait(RETRY_DELAY);
          return fetchWithRetry();
        }
        
        // Si se agotaron los reintentos, devolver valores por defecto
        console.error("Se agotaron los reintentos. Devolviendo valores por defecto.");
        return getDefaultBalance(format);
      }
    };
    
    // Función para obtener balance por defecto según formato
    const getDefaultBalance = (format) => {
      if (format === "summary") {
        return {
          success: false,
          data: {
            MensajeMailDisponible: 0,
            MensajeMailUtilizado: 0,
            MensajeWatsDisponible: 0,
            MensajeWatsUtilizado: 0,
            MensajetextoDisponible: 0,
            MensajetextoUtilizado: 0,
            MensajeVozDisponible: 0,
            MensajeVozUtilizado: 0
          }
        };
      } else {
        return {
          success: false,
          data: []
        };
      }
    };
    
    // Iniciar el proceso con reintentos
    return await fetchWithRetry();
  },

  getNotificationTypes: async () => {
    try {
      // Verificar si existe token
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No hay token, no se puede continuar');
        return { 
          success: false, 
          message: 'No hay sesión activa' 
        };
      }
      
      // Configurar headers con token de autenticación
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      };
      
      // Realizar la petición GET
      const response = await messageApiClient.get('/api/notification-types', config);
      return response.data;
    } catch (error) {
      console.error('Error al obtener tipos de notificación:', error);
      return { 
        success: false, 
        message: 'Error al obtener tipos de notificación',
        error: error.response?.data || error.message
      };
    }
  },
  
  createNotificationPackage: async (packageData) => {
    try {
      // Verificar si existe token
      const token = await messageAuthService.getToken();
      if (!token) {
        console.error('No hay token, no se puede continuar');
        return { 
          success: false, 
          message: 'No hay sesión activa' 
        };
      }
      
      // Asegurar que la cantidad sea un entero (floor)
      if (packageData.cantidad) {
        packageData.cantidad = Math.floor(packageData.cantidad);
      }
      
      // Si no se proporciona fecha_inicio, usar la fecha actual
      if (!packageData.fecha_inicio) {
        packageData.fecha_inicio = new Date().toISOString();
      }
      
      // Configurar companyId si no está presente
      if (!packageData.companyId) {
        packageData.companyId = localStorage.getItem("uuid_empresa");
      }
      

      const uid_externo_usuario = localStorage.getItem('id_externo');
      const uid_externo_empresa = localStorage.getItem('uid_externo');
      
      if (uid_externo_usuario) {
        packageData.uid_externo_usuario = uid_externo_usuario;
      }
      
      if (uid_externo_empresa) {
        packageData.uid_externo_empresa = uid_externo_empresa;
      }
      
      console.log('Creando paquete con datos:', packageData);


      // Realizar la petición POST
      const response = await messageApiClient.post(
        '/api/notification-packages',
        packageData
      );
      
      return response.data;
    } catch (error) {
      console.error('Error al crear paquete de notificaciones:', error);
      return { 
        success: false, 
        message: 'Error al crear paquete de notificaciones',
        error: error.response?.data || error.message
      };
    }
  },
  
  updateNotificationPackage: async (packageId, updateData) => {
    try {
      // Verificar si existe token
      const token = await messageAuthService.getToken();
      if (!token) {
        console.error('No hay token, no se puede continuar');
        return { 
          success: false, 
          message: 'No hay sesión activa' 
        };
      }
      
      // Asegurar que la cantidad sea un entero (floor)
      if (updateData.cantidad) {
        updateData.cantidad = Math.floor(updateData.cantidad);
      }
      
      // Verificar si falta costo_unitario que es requerido por la API
      if (updateData.costo_unitario === undefined) {
        console.warn('costo_unitario es requerido pero no está presente en updateData, obteniendo paquete original');
        
        try {
          // Obtener el paquete original para extraer el costo_unitario
          const originalPackage = await messageApiClient.get(`/api/notification-packages/${packageId}`);
          if (originalPackage?.data?.data?.costo_unitario) {
            updateData.costo_unitario = originalPackage.data.data.costo_unitario;
          } else {
            // Si no podemos obtener el valor original, establecer un valor predeterminado
            updateData.costo_unitario = 0;
          }
        } catch (fetchError) {
          console.error('Error al obtener paquete original:', fetchError);
          // Si hay error, establecer valor predeterminado
          updateData.costo_unitario = 0;
        }
      }
      
      // Asegurar que otros campos requeridos estén presentes
      if (updateData.costo_total === undefined && updateData.cantidad && updateData.costo_unitario) {
        updateData.costo_total = updateData.cantidad * updateData.costo_unitario;
      }

      const uid_externo_usuario = localStorage.getItem('id_externo');
      const uid_externo_empresa = localStorage.getItem('uid_externo');
      
      if (uid_externo_usuario) {
        updateData.uid_externo_usuario = uid_externo_usuario;
      }
      
      if (uid_externo_empresa) {
        updateData.uid_externo_empresa = uid_externo_empresa;
      }
      
      console.log('Enviando datos de actualización:', {
        packageId,
        updateData
      });
      
      // Realizar la petición PUT
      const response = await messageApiClient.put(
        `/api/notification-packages/${packageId}`,
        updateData
      );
      
      return response.data;
    } catch (error) {
      console.error('Error al actualizar paquete de notificaciones:', error);
      console.error('Detalles del error:', error.response?.data);
      return { 
        success: false, 
        message: 'Error al actualizar paquete de notificaciones',
        error: error.response?.data || error.message
      };
    }
  },
  
  saveOrUpdatePackage: async (packageData) => {
    try {
      // Si tiene _id, actualizar paquete existente

      const uid_externo_usuario = localStorage.getItem('id_externo');
      const uid_externo_empresa = localStorage.getItem('uid_externo');
      
      if (uid_externo_usuario) {
        packageData.uid_externo_usuario = uid_externo_usuario;
      }
      
      if (uid_externo_empresa) {
        packageData.uid_externo_empresa = uid_externo_empresa;
      }
      
      console.log('saveOrUpdatePackage con datos:', packageData);

      if (packageData._id) {
        const { _id, ...updateData } = packageData;
        return await messageService.updateNotificationPackage(_id, updateData);
      } else {
        // Si no tiene _id, crear nuevo paquete
        return await messageService.createNotificationPackage(packageData);
      }
    } catch (error) {
      console.error('Error al guardar/actualizar paquete:', error);
      return { 
        success: false, 
        message: 'Error al guardar/actualizar paquete',
        error: error.response?.data || error.message
      };
    }
  }
};
 

export default apiClient;
